import React, {useCallback} from 'react';
import { useDrag, useDrop, DragObjectWithType } from 'react-dnd';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem';
//import { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } from 'react-contextmenu';
import MoreIcon from '@material-ui/icons/MoreVert';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import NestedMenuItem from "material-ui-nested-menu-item";
import Divider from '@material-ui/core/Divider';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChipInput from 'material-ui-chip-input';     
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import SvgIcon from '@material-ui/core/SvgIcon';
import SubjectIcon from '@material-ui/icons/Subject';


  const columnTypesMap = {
    'date':'Дата',
    'member':'Пользователь',
    'chatmember':'Чат-пользователь',
    "link": "Ссылка",
    'list':'Таблица',
    'text':'Текст',
    'title':'Заголовок',
    'number':'Число',
    'check':'Флаг',
    'file':'Файлы и фото',
  }

  function LookupIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
      </SvgIcon>
    );
  }  

   

  function ChatMemberIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M20 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4l4 4l4-4h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2m-8 2.3c1.5 0 2.7 1.2 2.7 2.7c0 1.5-1.2 2.7-2.7 2.7c-1.5 0-2.7-1.2-2.7-2.7c0-1.5 1.2-2.7 2.7-2.7M18 15H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" />
      </SvgIcon>
    );
  }  

  function PinIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M16 12V4h1V2H7v2h1v8l-2 2v2h5.2v6h1.6v-6H18v-2l-2-2z" />
      </SvgIcon>
    );
  }    

  function UnPinIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M2 5.27L3.28 4L20 20.72L18.73 22l-5.93-5.93V22h-1.6v-6H6v-2l2-2v-.73l-6-6M16 12l2 2v2h-.18L8 6.18V4H7V2h10v2h-1v8z" />
      </SvgIcon>
    );
  }    



const useStyles = makeStyles((theme) => ({
       
  menuItemText: {
      margin: theme.spacing(1),
    }, 
 
}));


export default function AddColHeaderRenderer(props) {
  const classes = useStyles();  
  const divRef = React.useRef();




  const handleOpenColumnMenu = (e) => {
    console.log("handleOpenColumnMenu 2 ", props.lastColIdx)
    //props.setAnchorElColEdit(divRef.current)
    var rect = divRef.current.getBoundingClientRect();
    props.setAnchorElColEdit({ top: rect.top, left: rect.left })     
    props.setEditingSets({idx:props.lastColIdx, isNew:true})    
  };  

  

  return (   
    <div      
      ref={divRef}         
      onClick={handleOpenColumnMenu}   

      aria-haspopup="true"
      aria-controls={"simple-menu-new-col"}
      style = {{    
        textAlign: "center", 
        //backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'pointer'      
      }}
    >
      
    <Tooltip title={'Нажмите, чтобы добавить колонку'}>
  <IconButton
    size="small"            
    onClick={handleOpenColumnMenu}

  >
    <AddCircleOutlineIcon fontSize="small" color="text.disabled"/>
  </IconButton></Tooltip>

      </div>  
  );
}