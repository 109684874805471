import React, {useState, useContext, useCallback, useEffect, useLayoutEffect,  useMemo, useRef   } from 'react';
import {useHistory, useParams, Link} from 'react-router-dom'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {NavContext} from '../context/NavContext'
import {useHttp} from '../hooks/http.hook';
import _ from "lodash";
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment-timezone';
import WorkItemsTable2 from './WorkItemsTable/WorkItemsTable'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChipInput from 'material-ui-chip-input';     
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddBoxIcon from '@material-ui/icons/AddBox';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import StopIcon from '@material-ui/icons/Stop';
import SaveIcon from '@material-ui/icons/Save';
import ToggleButton from '@material-ui/lab/ToggleButton';
//import {QueryBuilder, formatQuery} from 'react-querybuilder';
//import GooglePicker from 'react-google-picker'
import Backdrop from '@material-ui/core/Backdrop';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import StyleIcon from '@material-ui/icons/Style';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewListIcon from '@material-ui/icons/ViewList';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import ProfileMenu from './ProfileMenu'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DnsIcon from '@material-ui/icons/Dns';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SendIcon from '@material-ui/icons/Send';
import ShareIcon from '@material-ui/icons/Share';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { Scrollbars } from 'react-custom-scrollbars';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import EmailIcon from '@material-ui/icons/Email';
import LinkIcon from '@material-ui/icons/Link';
import ChatIcon from '@material-ui/icons/Chat';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TelegramIcon from '@material-ui/icons/Telegram';
import LanguageIcon from '@material-ui/icons/Language';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DialogEditor from './DialogEditor'
import 'moment/locale/ru'
import io from 'socket.io-client';
import ReactResizeDetector from 'react-resize-detector';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {Query as QbQuery, Builder, BasicConfig, Utils as QbUtils} from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import './QueryBuilder.css'
//import 'react-awesome-query-builder/lib/css/styles.css';
//import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import EditIcon from '@material-ui/icons/Edit';
import Board from 'react-trello'
import RootRef from "@material-ui/core/RootRef";
import Popover from '@material-ui/core/Popover';
import { ruRU } from '@material-ui/core/locale';
import MenuList from '@material-ui/core/MenuList';
import InputAdornment from '@material-ui/core/InputAdornment';
import ruLocale from  'date-fns/locale/ru';//'@fullcalendar/core/locales/ru';
import Avatar from '@material-ui/core/Avatar';
import { ColorPicker } from 'material-ui-color';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import './DbEditor.css'
import { orange, green,yellow, purple } from '@material-ui/core/colors';
import SplitPane from 'react-split-pane';
import Query from '../Utils/query' 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CheckIcon from '@material-ui/icons/Check';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ReportEditor from './ReportEditor';
import MemberEditor from './WorkItemsTable/MemberEditor'
import ChatMemberEditor from './WorkItemsTable/ChatMemberEditor'
import SelectEditor from './WorkItemsTable/SelectEditor'
import LinkEditor from './WorkItemsTable/LinkEditor'
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import FormGroup from '@material-ui/core/FormGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import cronstrue from 'cronstrue/i18n';
import SubjectIcon from '@material-ui/icons/Subject';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DialogEditorForPanel from './DialogEditorForPanel'
import { Allotment } from "allotment";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { defaultPropsDecorator } from 'substyle';

function ChatMemberIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4l4 4l4-4h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2m-8 2.3c1.5 0 2.7 1.2 2.7 2.7c0 1.5-1.2 2.7-2.7 2.7c-1.5 0-2.7-1.2-2.7-2.7c0-1.5 1.2-2.7 2.7-2.7M18 15H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" />
    </SvgIcon>
  );
}  

function LookupIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
    </SvgIcon>
  );
}  

function AddRowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M22,10A2,2 0 0,1 20,12H4A2,2 0 0,1 2,10V3H4V5H8V3H10V5H14V3H16V5H20V3H22V10M4,10H8V7H4V10M10,10H14V7H10V10M20,10V7H16V10H20M11,14H13V17H16V19H13V22H11V19H8V17H11V14Z" />
    </SvgIcon>
  );
}

function UpdateRowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M4 3H18C19.11 3 20 3.9 20 5V12.08C18.45 11.82 16.92 12.18 15.68 13H12V17H13.08C12.97 17.68 12.97 18.35 13.08 19H4C2.9 19 2 18.11 2 17V5C2 3.9 2.9 3 4 3M4 7V11H10V7H4M12 7V11H18V7H12M4 13V17H10V13H4M18.44 15V17H22.44V19H18.44V21L15.44 18L18.44 15" />
    </SvgIcon>
  );
}

function StartDialogIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M18 14H20V17H23V19H20V22H18V19H15V17H18V14M12 3C17.5 3 22 6.58 22 11C22 11.58 21.92 12.14 21.78 12.68C20.95 12.25 20 12 19 12C15.69 12 13 14.69 13 18L13.08 18.95L12 19C10.76 19 9.57 18.82 8.47 18.5C5.55 21 2 21 2 21C4.33 18.67 4.7 17.1 4.75 16.5C3.05 15.07 2 13.14 2 11C2 6.58 6.5 3 12 3Z" />
    </SvgIcon>
  );
}

function AddItemFromChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M11,6V9H8V11H11V14H13V11H16V9H13V6H11Z" />
    </SvgIcon>
  );
}

function UpdateItemsFromChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M8,14H6V12H8V14M8,11H6V9H8V11M8,8H6V6H8V8M15,14H10V12H15V14M18,11H10V9H18V11M18,8H10V6H18V8Z" />
    </SvgIcon>
  );
}

function UpdateItemFromChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.11 18 22 17.11 22 16V4C22 2.89 21.1 2 20 2M10.47 14L7 10.5L8.4 9.09L10.47 11.17L15.6 6L17 7.41L10.47 14Z" />
    </SvgIcon>
  );
}

function SendItemstoChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M6,9H18V11H6M14,14H6V12H14M18,8H6V6H18" />
    </SvgIcon>
  );
}


function SendMsgtoChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M3 7C2.4 7 2 7.4 2 8S2.4 9 3 9H5V7H3M2 11C1.4 11 1 11.4 1 12S1.4 13 2 13H5V11H2M1 15C.4 15 0 15.4 0 16C0 16.6 .4 17 1 17H5V15H1M20 5H9C7.9 5 7 5.9 7 7V21L11 17H20C21.1 17 22 16.1 22 15V7C22 5.9 21.1 5 20 5Z" />
    </SvgIcon>
  );
}

function PollInChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M13 14H11V12H13V14M14.8 9C14.5 9.4 14.1 9.6 13.7 9.8C13.4 10 13.3 10.1 13.2 10.3C13 10.5 13 10.7 13 11H11C11 10.5 11.1 10.2 11.3 9.9C11.5 9.7 11.9 9.4 12.4 9.1C12.7 9 12.9 8.8 13 8.6C13.1 8.4 13.2 8.1 13.2 7.9C13.2 7.6 13.1 7.4 12.9 7.2C12.7 7 12.4 6.9 12.1 6.9C11.8 6.9 11.6 7 11.4 7.1C11.2 7.2 11.1 7.4 11.1 7.7H9.1C9.2 7 9.5 6.4 10 6C10.5 5.6 11.2 5.5 12.1 5.5C13 5.5 13.8 5.7 14.3 6.1C14.8 6.5 15.1 7.1 15.1 7.8C15.2 8.2 15.1 8.6 14.8 9Z" />
    </SvgIcon>
  );
}

function SendEmailIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M13 17H17V14L22 18.5L17 23V20H13V17M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H11.35A5.8 5.8 0 0 1 11 18A6 6 0 0 1 22 14.69V6A2 2 0 0 0 20 4M20 8L12 13L4 8V6L12 11L20 6Z" />
    </SvgIcon>
  );
}

function HttpRequestIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.37 19.97 12.73 19.92 13.08C20.61 13.18 21.25 13.4 21.84 13.72C21.94 13.16 22 12.59 22 12C22 6.5 17.5 2 12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22C12.59 22 13.16 21.94 13.72 21.84C13.26 21 13 20.03 13 19C13 18.71 13.03 18.43 13.07 18.15C12.75 18.78 12.4 19.39 12 19.96C11.17 18.76 10.5 17.43 10.09 16H13.81C14.41 14.96 15.31 14.12 16.4 13.6C16.46 13.07 16.5 12.54 16.5 12M12 4.03C12.83 5.23 13.5 6.57 13.91 8H10.09C10.5 6.57 11.17 5.23 12 4.03M4.26 14C4.1 13.36 4 12.69 4 12S4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12S7.56 13.34 7.64 14H4.26M5.08 16H8C8.35 17.25 8.8 18.45 9.4 19.56C7.57 18.93 6.03 17.65 5.08 16M8 8H5.08C6.03 6.34 7.57 5.06 9.4 4.44C8.8 5.55 8.35 6.75 8 8M14.34 14H9.66C9.56 13.34 9.5 12.68 9.5 12S9.56 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12S14.43 13.34 14.34 14M14.59 4.44C16.43 5.07 17.96 6.34 18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44M22.5 17.25L17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25Z" />
    </SvgIcon>
  );
}





function IconForViewBot (props){
    return (<QuestionAnswerIcon fontSize="small" {...props} color="secondary"/>)
  }  

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },

    drawerContainer: {
      overflow: 'auto',
    },

    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      //padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0//-drawerWidth,
    },
    contentShift: {
      height: '100vh',
      overflow: 'auto',      
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },    
    menuButton: {
      marginRight: theme.spacing(2),
    },    
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    paper_send_invite: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: purple[50]
    },    

    paper_invite_links: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
    },       
    question_list: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
      job_card: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
      },      
      subheader: {
      backgroundColor: theme.palette.background.paper,
    },

    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    grow: {
      flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    fabButton:  {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 99
    },
    fileinput: {
      display: 'none',
    },    
    selectEmpty: {
        marginTop: theme.spacing(2),
      }, 
    scheduler:{
        display: 'flex',
    },
    settingsPaper: {
      //marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)      
    },
    dialogCloseButton:{
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      padding: "4px",
      color: theme.palette.grey[500],
    },
    dialogTitleText:{
      position: 'absolute',
      left: theme.spacing(5),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },     
    nonSelectedListItemSecondaryAction:{
      visibility: "hidden",
      //cursor:"move"
    },
    selectedListItemSecondaryAction:{
      
    },    
    nonSelectedListItem: {
      "&:hover $nonSelectedListItemSecondaryAction": {
        visibility: "inherit"
      }
    }       
  }));

  const default_job = {
    //run_type:"auto",
    respondents:[],
    run_period:"not_repeate",
    trigger_type: "at_scheduled_time",
    action_type: "",
    //start_date: new Date(),
    is_on:true,
    time:"10:00",
    every_minutes:30,
    every_hours:3,
    every_days:1,
    days_of_week: {
        thu: false,
        wed: false,
        sun: false,
        tue: true,
        mon: false,
        sat: false,
        fri: false
      },
      all_respondents:true,  
      respondentsType: "all"    
}

function AddValueForCreateItemButton(props) {
  //const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles(); 

  const handleClickOpen = (event) => {
    //setGroup(props.groups[0]._id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const open = Boolean(anchorEl);
  const id = open ? 'AddValueForCreateItemButton-popover' : undefined;

  return (
    <React.Fragment>
      
      <Tooltip title="Выбрать колонку для установки значения">
          <Button size="small" variant="text" onClick={handleClickOpen} startIcon={<AddCircleOutlineIcon /> }>            
            Выбрать колонку
      </Button>   
      </Tooltip>     
      <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper >
      <List  dense={true}>
      {props.columns.map((column, idx)=>(
      <ListItem
      button
        onClick={e=> {setAnchorEl(null); props.addColumnForCreateItem(column)}}
    >
      <ListItemIcon>
      {column.type==='text' && <TextFieldsIcon fontSize="small" color="disabled"/>}
      {column.type==='title' && <TextFieldsIcon fontSize="small" color="disabled"/>}
    {column.type==='number' && <Looks3Icon fontSize="small"  color="disabled"/>}
    {column.type==='date' && <EventIcon fontSize="small"  color="disabled"/>}
    {column.type==='select' && <FilterListIcon fontSize="small"  color="disabled"/>}
    {column.type==='multiline' && <SubjectIcon fontSize="small"  color="disabled"/>}
    {column.type==='member' && <PersonIcon fontSize="small"  color="disabled" />}
    {column.type==='chatmember' && <ChatMemberIcon fontSize="small"  color="disabled" />}
    {column.type==='link' && <LinkIcon fontSize="small"  color="disabled" />}
    {column.type==='geolocation' && <LocationOnIcon fontSize="small"  color="disabled" />}                        
    {column.type==='attachments' && <AttachFileIcon fontSize="small"  color="disabled" />}                        
    {column.type==='check' && <CheckBoxIcon fontSize="small"  color="disabled"/>}
    {column.type==='lookup' && <LookupIcon fontSize="small"  color="disabled"/>}
      </ListItemIcon>
      <ListItemText id={"switch-column-label-"+idx} primary={column.name} /> 
      </ListItem>           
      ))}

      </List>        
        </Paper>
      </Popover>  
    </React.Fragment>
  );
}    

function AddSkillDialog(props) {
  const [open, setOpen] = useState(false);
  const [tableId, setTableId] = useState(null);
  const [tableViewList, setTableViewList] = useState([]);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(null);
  const [dialogSettings, setDialogSettings] = useState({});   
  const [permissionType, setPermissionType] = useState("l"); 

  const classes = useStyles();

  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const onChangeTableId = (event) => {
    setTableId(event.target.value)     
 };

const onChangeDialogHandler = (event) => {
  setDialogSettings({...dialogSettings, [event.target.name]:event.target.value})     
};


  const handleClickOpen = () => {
    setOpen(true);
    setTableId(props.tableId)
    setTitle("")
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = (event) => {
    if(!title || title.trim().length<3){
      setTitleError('Название меньше 3 симоволов!')
      return
    }
    dialogSettings.questions = []

    if(dialogSettings.type==="standup"){
      if(!dialogSettings.work_item_list){
        setTitleError('Не выбрана таблица, в которую следует добавить элемент!')
        return
      }
      if(!dialogSettings.filter){
        setTitleError('Не выбран вид, в который следует добавить элемент!')
        return
      }
    } else if(dialogSettings.type==="update_item_request"){
      if(!dialogSettings.work_item_list){
        setTitleError('Не выбрана таблица, содержащая обновляемый элемент!')
        return
      }
      if(!dialogSettings.filter){
        setTitleError('Не выбран вид, содержащий обновляемый элемент!')
        return
      }
    } else if(dialogSettings.type==="update_request"){
      if(!dialogSettings.work_item_list){
        setTitleError('Не выбрана таблица, содержащая список элементов!')
        return
      }
      if(!dialogSettings.filter){
        setTitleError('Не выбран вид, содержащий список элементов!')
        return
      }
    }
    if(dialogSettings.type==="standup" || dialogSettings.type==="update_item_request"){
      //заполним вопросы для заполнения полей 
      var cols = []
      var view = props.tables[dialogSettings.work_item_list].views[dialogSettings.filter]
      if (view) {
        if(view.viewtype === 'list' || view.viewtype === 'kanban' ) {
        //console.log('useEffect setViewColumns!', props.columns)
        //props.columns?[...props.columns]:[]
        var nextIndex = 99//columnsKeys.length; 
        var columns = props.tables[dialogSettings.work_item_list].columns
        for (let ic = 0; ic < columns.length; ic++) {
          let colOpt = columns[ic]
          let colOpt2 = {}
          if (colOpt && view.listColumnsOptions) {
            colOpt2 = view.listColumnsOptions[colOpt._id];
            if (!colOpt2) {
              colOpt2 = {
                index: nextIndex,
                frozen: false,
                width: 0
              }
              nextIndex++
            }
          } 
  
          colOpt = {
            ...colOpt,
            ...colOpt2,
          }        
          //colOpt.idx = colOpt.index
          let system_col = false
          if(colOpt._id==="ex_rAnswered" ||
          colOpt._id==="ex_requestDt" ||
          colOpt._id==="ex_rAnswerDt" ||
          colOpt._id==="ex_updatedAt" ||
          colOpt._id==="ex_createdAt" ||
          colOpt._id==="ex_changeAutor" ||
          colOpt._id==="ex_createdAutor" ||
          colOpt._id==="ex_requestAc" ||
          colOpt._id==="_id"
      )  {
        system_col = true;
      }  
      let editable = colOpt.hasOwnProperty("editable")?colOpt.editable:true;
      //console.log('create dialogSettings col ', colOpt, system_col , editable)
      if(!system_col && editable && colOpt.width>0 && colOpt.type !== "lookup"){
        let q_text = "Введите "+colOpt.name
        if(colOpt.type === 'select' || colOpt.type === "member" || colOpt.type ===   "chatmember" || colOpt.type ===   "link"){
          q_text = "Выберите из списка значение для заполнения "+colOpt.name
        } else if(colOpt.type ===   "geolocation"){
          q_text = "Введите свои координаты (геопозицию) для заполнения "+colOpt.name
        } else if(colOpt.type ===   "attachments"){
          q_text = "Пришлите файлы или фото для заполнения "+colOpt.name
        }
        dialogSettings.questions.push({
          "answer_choice": [],
          "question_text": q_text,
          "intent": colOpt._id,
          "answer_type": colOpt.type,
          "condition": "",
          "list_style": "suggestedAction"
        })
      }
        }
  
  
      } else if (view.viewtype === 'calendar'){

      }
       
      }

    } else if (dialogSettings.type === "update_request") {
      /*
      let q_text = "Элемент из " + props.tables[dialogSettings.work_item_list].views[dialogSettings.filter].title + " \n "
      let cols = props.tables[dialogSettings.work_item_list].views[dialogSettings.filter].columns
      if (cols && cols.length > 0) {
        for (let i = 0; i < cols.length; i++) {
          let column = cols[i];
          if (column.width > 0) {
            q_text = q_text + column.name + `: {{__${column._id}__||__${column.name}__}}  \n`
          }
        }
      }
      dialogSettings.questions.push({
        "answer_choice": [],
        "question_text": q_text,
        "intent": "",
        "answer_type": "text",
        "condition": "",
        "list_style": ""
      })
      */
    }
    //console.log('create dialogSettings ', dialogSettings)
    props.addDialog({ ...dialogSettings, name:title}, (dialogId)=>{
      
      props.setEditedDialogId(dialogId); props.setEditedDialogTableId(dialogSettings.work_item_list); props.setIsEditedJobOpen('dialog')
    })
    handleClose();
  };      

  useEffect(()=>{
    var _tableViewList = []
   
    if(props.tables && dialogSettings.work_item_list && props.tables[dialogSettings.work_item_list]){

  
      for(var  iv in props.tables[dialogSettings.work_item_list].views){
        if(props.tables[dialogSettings.work_item_list].views[iv].viewtype!=="dialog"){
          _tableViewList.push(props.tables[dialogSettings.work_item_list].views[iv])
  
        }
        
      }
    } 


    setTableViewList(_tableViewList)
  
  
  },[props.tables, dialogSettings.work_item_list])   

  return (
    <>
<Button 
     // size="small"
      edge="end" 
      color="primary" 
      startIcon={<AddCircleOutlineIcon fontSize="small"/>}
      onClick={handleClickOpen}
      variant={props.variant}
      style={{
        marginTop:10
      }}
      >
      
      {props.text}
    </Button>         
      <Dialog
        fullWidth={true} 
        maxWidth="sm"
        //fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="add_view-dialog-title"
      >
        <DialogTitle id="add_view-dialog-title">Добавить новый диалог           
        </DialogTitle>
        <DialogContent>
       
          <DialogContentText> 
          <Alert variant="standard" severity="info">Диалог - это сценарий взаимодействия системы с пользователем в чате, чтобы выполнить определенное действие по обработке данных в базе</Alert>                                              
          <TextField
                        id="add_view_title"
                        name="view_title"
                        //label="Название вида"
                        error={Boolean(titleError)}
                        helperText={titleError}
                        value={title}
                        onChange={(e)=>{setTitle(e.target.value); setTitleError(null)}}
                        placeholder="Введите название диалога"
                        //helperText=""                            
                        margin="normal"
                        fullWidth
                        
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div> 
            
            <TextField
                          id="dialog-type"
                          name="type"
                          label="Выполняемое действие"
                          value={dialogSettings.type}
                          onChange={onChangeDialogHandler}
                          placeholder=""
                          helperText=""
                          fullWidth
                          select
                          InputLabelProps={{
                              shrink: true,
                          }}
                      >
          <MenuItem key="simple" value="simple">Вывод сообщений в чат (без привязки к данным)</MenuItem>          
          <MenuItem key="standup" value="standup">Добавление элемента в таблицу</MenuItem>          
          <MenuItem key="update_item_request" value="update_item_request">Обновление элемента в таблице</MenuItem>          
          <MenuItem key="update_request" value="update_request">Вывод списка элементов из таблицы</MenuItem>          
</TextField> 

      {(dialogSettings.type==="update_request" || dialogSettings.type==="update_item_request" || dialogSettings.type==="standup") &&
            <>         
             <TextField
                      id="dialog-work_item_list"
                      name="work_item_list"
                      label={"Лист"}
                      value={dialogSettings.work_item_list}
                      onChange={onChangeDialogHandler}
                      placeholder="Выберите лист"
                      helperText=""
                      fullWidth
                      select
                      InputLabelProps={{
                          shrink: true,
                      }}
                  >
                          { props.tablesList &&  props.tablesList.map(tb => (
                          <MenuItem key={tb._id} value={tb._id}>
                            {tb.title}
                          </MenuItem>
                          )

                          ) }     
                </TextField> 
                      <TextField
                          id="dialog-filter"
                          name="filter"
                          label={"Вид"}
                          value={dialogSettings.filter}
                          onChange={onChangeDialogHandler}
                          placeholder="Выберите вид данных"
                          helperText=""
                          fullWidth
                          select
                          InputLabelProps={{
                              shrink: true,
                          }}
                      >
                      {tableViewList && tableViewList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.title}
                          </MenuItem>
                        ))}
</TextField> 
</>
                  }              
              </div>                                
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>
            Отмена
          </Button>            
          <Button onClick={handleCreate} color="primary">
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}  


  function JobEditDialog(props) {
    const {token} = useContext(AuthContext);
    //const {loading, error, request} = useHttp();
    const [open, setOpen] = useState(false);
    const [job, setJob] = useState(default_job);
    const [dialogHref, setDialogHref] = useState('');
    const [finishedDialogHref, setFinishedDialogHref] = useState('');
    const [createItemValuesList, setCreateItemValuesList] = useState([]);
    const [createItemValuesColumns, setCreateItemValuesColumns] = useState([]);
    const [tableViewList, setTableViewList] = useState([]);
    const classes = useStyles();
    const history = useHistory();    


    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);
      
      props.setOpen(true);
    };
  
    const handleClose = () => {      
      props.setOpen(false);
    };

    const handleDel = () => {
      props.deleteJob(job._id);
      handleClose();
    };     
  
    const handleSave = () => {
        if(job._id){
            props.saveJob(job)
        } else {
            props.addJob(job)
        }
                
        handleClose();        
    };

    const onChangeRunPeriod = (e) => {
        const v = e.target.value;
         setJob(curJob=>{
             var newJob={...curJob}
             newJob.run_period=v
             return newJob
         })    
    }   

    const onChangeTriggerType = (e) => {
      const v = e.target.value;
       setJob(curJob=>{
           var newJob={...curJob}
           newJob.trigger_type=v
           return newJob
       })    
    }     

    const onChangeActionType = (e) => {
      const v = e.target.value;
       setJob(curJob=>{
           var newJob={...curJob}
           newJob.action_type=v
           return newJob
       })    
    }      
    
    
        
    const onChangeStartDate = (data) => {
        const start_date = data;
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.start_date=start_date
            return newJob
         })                
    }   

    const onChangeDialogEveryMonth = (e) => {
        const v = Number(e.target.value);
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.every_month=v
            return newJob
         })                
    } 

    const onChangeDialogEveryDays = (e) => {
      const v = Number(e.target.value);
       setJob(curJob=>{
          var newJob={...curJob}
          newJob.every_days=v
          return newJob
       })                
  } 

  const onChangeDialogEveryHours = (e) => {
    const v = Number(e.target.value);
     setJob(curJob=>{
        var newJob={...curJob}
        newJob.every_hours=v
        return newJob
     })                
  }   

  const onChangeDialogEveryMinutes = (e) => {
    const v = Number(e.target.value);
     setJob(curJob=>{
        var newJob={...curJob}
        newJob.every_minutes=v
        return newJob
     })                
  }     
    

    const onChangeTitle = (e)=>{
      const v = e.target.value;
      setJob(curJob=>{
         var newJob={...curJob}
         newJob.title=v
         return newJob
      })        
    }

    const onChangeTime = (e) => {
        const v = e.target.value;
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.time=v
            return newJob
         })                
    }      
    const onChangeDialogDaysOfWeekHandle = (event, newFormats) => {
        const newDw = {
            thu: false,
            wed: false,
            sun: false,
            tue: false,
            mon: false,
            sat: false,
            fri: false
          }
          for(var ii=0; ii< newFormats.length; ii++){
            newDw[newFormats[ii]] = true;
          }
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.days_of_week=newDw
            return newJob
         })       
         
         
    }      
    
    const onChangeDialog = (e) => {
        const v = e.target.value;
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.dialog=v
            return newJob
         })     
    }

    const onChangeRespondentsType = (e) => {
      const v = e.target.value;
       setJob(curJob=>{
          var newJob={...curJob}
          newJob.respondentsType=v
          return newJob
       })     
  }          
  const onChangeChatMessage = (e) => {
    const v = e.target.value;
     setJob(curJob=>{
        var newJob={...curJob}
        newJob.chat_message=v
        return newJob
     })     
}     

const onChangeInterruptCurrentDialog = (event) => {
  const v = event.target.checked;    
  setJob(curJob=>{
    var newJob={...curJob}
    newJob.interrupt_current_dialog=v
    return newJob
 })    
}  
  
    const onChangeFinishedDialog = (e) => {
      const v = e.target.value;
       setJob(curJob=>{
          var newJob={...curJob}
          newJob.finished_dialog=v
          return newJob
       })     
  }   
  
  const onChangeCreateItemWorkitemlist = (e) => {
    const v = e.target.value;
     setJob(curJob=>{
        var newJob={...curJob}
        newJob.action_item_workitemlist=v
        return newJob
     })     
  }   

  const onChangeTriggerTable = (e) => {
    const v = e.target.value;
     setJob(curJob=>{
        var newJob={...curJob}
        newJob.trigger_table=v
        return newJob
     })     
  }  
  

  const onChangeActionItemView = (e) => {
    const v = e.target.value;
     setJob(curJob=>{
        var newJob={...curJob}
        newJob.action_item_view=v
        return newJob
     })     
  }     

  

  const onChangeCreateItemValue = (colId, value) => {
    const v = value;
    const cId = colId
     setJob(curJob=>{
        var newJob={...curJob}
        var naw_create_item_values = {...newJob.create_item_values}
        naw_create_item_values[cId] = v
        newJob.create_item_values=naw_create_item_values        
        return newJob
     })     
  }     

  const onDelActionItemValue = (colId) => {
    const cId = colId
     setJob(curJob=>{
        var newJob={...curJob}
        var naw_create_item_values = {...newJob.create_item_values}
        delete naw_create_item_values[cId]
        newJob.create_item_values=naw_create_item_values        
        return newJob
     })     
  }     

  

  

    const onChangeDialogСron = (e) => {
        const v = e.target.value;
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.cron=v
            return newJob
         })                
    }  

    const onChangeAllRespondents = (e) => {
        const v = e.target.checked;
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.all_respondents=v
            return newJob
         })                
    }   
    const onChangeDialogRespondents = (event, newValue) => {
        const value = newValue.map((selected) => {
            return selected?selected._id:null;
         });
         setJob(curJob=>{
            var newJob={...curJob}
            newJob.respondents=value
            return newJob
         })                
    }            
    const onChangeDialogTimeout = (event) => {
      const value = Number(event.target.value);      
      setJob(curJob=>{
        var newJob={...curJob}
        newJob.timeout=value
        return newJob
     })  
  }        
                      
  const onChangeTimeoutMessage = (event) => {
    const value = event.target.value;     
    setJob(curJob=>{
      var newJob={...curJob}
      newJob.timeout_message=value
      return newJob
   })      
}    

const onChangeEmailTitle = (event) => {
  const value = event.target.value;     
  setJob(curJob=>{
    var newJob={...curJob}
    newJob.email_title=value
    return newJob
 })      
}    

const onChangeEmailText = (previousRange, source, editor) => {
  const value = editor.getHTML() //event.target.value;     
  setJob(curJob=>{
    var newJob={...curJob}
    newJob.email_text=value
    return newJob
 })      
}    

const addColumnForCreateItem = (col) => {
  const value = col //event.target.value;  
  if(col && col._id)   {
  setJob(curJob=>{
    var newJob={...curJob}
    var naw_create_item_values = {...newJob.create_item_values}
    naw_create_item_values[col._id] = ""
    newJob.create_item_values=naw_create_item_values
    return newJob
 })      
}
}   



const onChangeDialogChatDelivery = (event, newValue) => {
  //console.log('onChangeDialogChatDelivery', newValue)
    const value = newValue.map((selected) => {
         return selected?selected._id:null;
    });
    //props.onChangeDialog('chat_delivery', value)      
    setJob(curJob=>{
      var newJob={...curJob}
      newJob.chat_delivery=value
      return newJob
   })  
   };   
   
   const onChangeDialogChatGroupDelivery = (event, newValue) => {
    const value = newValue.map((selected) => {
      return selected?selected._id:null;
    });
    //props.onChangeDialog('chatgroup_delivery', value) 
    setJob(curJob=>{
      var newJob={...curJob}
      newJob.chatgroup_delivery=value
      return newJob
   })  
   }; 

   const onAddDialogEmail = (newVal) => {
    const v = newVal;
    const email = [...job.email];
    email.push(v);
    //props.onChangeDialog('email', email) 
    setJob(curJob=>{
      var newJob={...curJob}
      newJob.email=email
      return newJob
   }) 
}  

const onDeleteDialogEmail = (newVal, index) => {
    const vidx = index        
    var email = [...job.email];
    email.splice(vidx,1);
    //console.log('onDeleteDialogEmail ', props.dialog.email, email);
    //props.onChangeDialog('email', email)         
    setJob(curJob=>{
      var newJob={...curJob}
      newJob.email=email
      return newJob
   }) 
}      

useEffect(()=>{
  if(props.editedJobId){
    var idx = props.jobs.findIndex(e => e._id === props.editedJobId)
    if(props.jobs[idx]){
      setJob(props.jobs[idx])
    } else {
      default_job.action_item_workitemlist = props.tableId
      default_job.action_item_view = props.viewId
      setJob(default_job)
    }
  } else {
    default_job.action_item_workitemlist = props.tableId
    default_job.action_item_view = props.viewId
    setJob(default_job)
  }
},[props.editedJobId, props.jobs, props.tableId, props.viewId])

  useEffect(()=>{
    if(props.dbId && props.dialogs && job.dialog){
      var dialog = props.dialogs.find(d=>d._id===job.dialog)
      if(dialog){
        return setDialogHref(`/db/${props.dbId}/${dialog.work_item_list}/${job.dialog}` )
      }
      
      
    } 

    setDialogHref("")
},[props.dbId, props.dialogs, job.dialog])  

useEffect(()=>{
  if(props.dbId && props.dialogs && job.finished_dialog){
    var dialog = props.dialogs.find(d=>d._id===job.finished_dialog)
    if(dialog){
      return setFinishedDialogHref(`/db/${props.dbId}/${dialog.work_item_list}/${job.finished_dialog}` )
    }
    
    
  }   
  setFinishedDialogHref("")
},[props.dbId, props.dialogs, job.finished_dialog])    

useEffect(()=>{
  var colList = []
  var colums = []
  var _tableViewList = []
  console.log('useEffect props.tables ', props.tables, job.action_item_workitemlist)
  if(props.tables && job.action_item_workitemlist && props.tables[job.action_item_workitemlist]){
    colums = [...props.tables[job.action_item_workitemlist].columns].filter(
      col=> 
      col._id !== "ex_rAnswered" &&
      col._id !== "ex_requestDt" &&
      col._id !== "ex_rAnswerDt" &&
      col._id !== "ex_updatedAt" &&
      col._id !== "ex_createdAt" &&
      col._id !== "ex_changeAutor" &&
      col._id === "ex_createdAutor" &&
      col._id !== "ex_requestAc"  &&
      col._id !== "_id"
    )

    for(var  iv in props.tables[job.action_item_workitemlist].views){
      if(props.tables[job.action_item_workitemlist].views[iv].viewtype!=="dialog"){
        _tableViewList.push(props.tables[job.action_item_workitemlist].views[iv])
      }
      
    }
  } 
  if(job.create_item_values && job.action_item_workitemlist){    
    for(var colId in job.create_item_values)
    {
      var col = colums.find(cl=> cl._id===colId)
      
      var icon = <TextFieldsIcon color="disabled" fontSize="small"/>
      if(col){
      if(col.type==='text') icon =  <TextFieldsIcon color="disabled" fontSize="small"/>
      if(col.type==='title') icon =  <TextFieldsIcon color="disabled" fontSize="small"/>
      if(col.type==='number') icon =  <Looks3Icon color="disabled" fontSize="small"/>
      if(col.type==='date') icon =  <EventIcon color="disabled" fontSize="small"/>
      if(col.type==='select') icon =  <FilterListIcon color="disabled" fontSize="small"/>
      if(col.type==='multiline' ) icon =  <SubjectIcon color="disabled" fontSize="small"/>
      if(col.type==='member' ) icon =  <PersonIcon color="disabled" fontSize="small"/>
      if(col.type==='chatmember' ) icon =  <ChatMemberIcon color="disabled" fontSize="small"/>
      if(col.type==='link' ) icon =  <LinkIcon color="disabled" fontSize="small"/>
      if(col.type==='geolocation' ) icon =  <LocationOnIcon color="disabled" fontSize="small"/>
      if(col.type==='attachments' ) icon =  <AttachFileIcon color="disabled" fontSize="small"/>
      if(col.type==='check' ) icon =  <CheckBoxIcon color="disabled" fontSize="small"/>
      if(col.type==='lookup' ) icon =  <LookupIcon color="disabled" fontSize="small"/>
      
      colList.push({colId: colId, val: job.create_item_values[colId], type:col.type, colName: col.name, icon: icon })
    }
    }
    
    
  }   


  setCreateItemValuesColumns(colums)
  setCreateItemValuesList(colList)

  setTableViewList(_tableViewList)


},[job.create_item_values, props.tables, job.action_item_workitemlist])    

  

    return ( 
      <React.Fragment>  

              <Paper className={classes.job_card} elevation={3}> 
          <div >

  <TextField
        id="job-trigger_type"
        label="Триггер"
        fullWidth
        name="trigger_type" 
        select
        className={classes.textField}
        value={job.trigger_type} 
        onChange={onChangeTriggerType}
        InputLabelProps={{
          shrink: true,
        }}
      >   
                            <MenuItem key="manually" value="manually">
                            Нет
                            </MenuItem>      
                            <MenuItem key="at_scheduled_time" value="at_scheduled_time">
                            В назначенное время
                            </MenuItem>
                            <MenuItem key="dialog_finished" value="dialog_finished">
                            Диалог завершен
                                </MenuItem>
                                <MenuItem key="item_created" value="item_created">
                                Элемент создан
                                </MenuItem>
                                <MenuItem key="item_updated" value="item_updated">
                                Элемент обновлен
                                </MenuItem> 
                                <MenuItem key="on_webhook" value="on_webhook">
                                Webhook получен
                                </MenuItem>                                                                                                                                 
      </TextField>    
    </div>   

    {job.trigger_type==="at_scheduled_time" && <div>
    <div >
    <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup row>      
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
    <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd.MM.yyyy"
          margin="normal"
          //fullWidth
          //hidden={props.job.run_type!=="auto"?true:false}
          id="job-start_date"
          label="Дата"
          value={job.start_date}
          onChange={onChangeStartDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          style={{width:"150px"}}
        />        
  </MuiPickersUtilsProvider>
  <TextField
        id="job-time"
        label="Время"
        //helperText="Время"
        margin="normal"
        type="time"
        value={job.time}
        defaultValue="10:00"
        className={classes.textField}
        onChange={onChangeTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />  
        </FormGroup>
      </FormControl>      
  </div>
  <div >
  <TextField
        id="job-run_period"
        label="Переодичность"
        fullWidth
        name="run_period" 
        defaultValue="not_repeate"
        margin="normal"
        select
        className={classes.textField}
        value={job.run_period} 
        onChange={onChangeRunPeriod}
        InputLabelProps={{
          shrink: true,
        }}
      >   
                            <MenuItem key="not_repeate" value="not_repeate">
                            Не повторять
                            </MenuItem>
                            <MenuItem key="minutes" value="minutes">
                            Поминутно
                                </MenuItem>                            
                            <MenuItem key="hours" value="hours">
                            Ежечасно
                                </MenuItem>       
                                <MenuItem key="days" value="days">
                            Ежедневно
                                </MenuItem>                                                         
                            <MenuItem key="weekly" value="weekly">
                            Еженедельно
                                </MenuItem>
                                <MenuItem key="monthly" value="monthly">
                                Ежемесячно
                                </MenuItem>
                                <MenuItem key="as_cron" value="as_cron">
                                Дополнительно
                                </MenuItem>                                                                
      </TextField>    
    </div>
    <div hidden={( job.run_period!=="minutes")?true:false}>
    <TextField
                            id="job-every_minutes"
                            label="Каждые"
                            value={job.every_minutes}
                            onChange={onChangeDialogEveryMinutes}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            defaultValue={1}
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}>                              
                            <MenuItem key={15} value={15}>
                                15 минут
                                </MenuItem>
                                <MenuItem key={30} value={30}>
                                30 минут
                                </MenuItem>
                                <MenuItem key={45} value={45}>
                                45 минут
                                </MenuItem>
                                <MenuItem key={60} value={60}>
                                60 минут
                                </MenuItem>
                        </TextField>     
                    </div>     
                    <div hidden={( job.run_period!=="hours")?true:false}>
    <TextField
                            id="job-every_hours"
                            label="Каждые"
                            value={job.every_hours}
                            onChange={onChangeDialogEveryHours}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            defaultValue={1}
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}>                              
                            <MenuItem key={1} value={1}>
                                1 час
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                2 часа
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                3 часа
                                </MenuItem>
                                <MenuItem key={4} value={4}>
                                4 часа
                                </MenuItem>
                                <MenuItem key={5} value={5}>
                                5 часов
                                </MenuItem>
                                <MenuItem key={6} value={6}>
                                6 часов
                                </MenuItem>
                                <MenuItem key={7} value={7}>
                                7 часов
                                </MenuItem>
                                <MenuItem key={8} value={8}>
                                8 часов
                                </MenuItem>
                                <MenuItem key={9} value={9}>
                                9 часов
                                </MenuItem>
                                <MenuItem key={10} value={10}>
                                10 часов
                                </MenuItem>
                                <MenuItem key={11} value={11}>
                                11 часов
                                </MenuItem>
                                <MenuItem key={12} value={12}>
                                12 часов
                                </MenuItem>
                                <MenuItem key={13} value={13}>
                                13 часов
                                </MenuItem>
                                <MenuItem key={14} value={14}>
                                14 часов
                                </MenuItem>
                                <MenuItem key={15} value={15}>
                                15 часов
                                </MenuItem>
                                <MenuItem key={16} value={16}>
                                16 часов
                                </MenuItem>
                                <MenuItem key={17} value={17}>
                                17 часов
                                </MenuItem>
                                <MenuItem key={18} value={18}>
                                18 часов
                                </MenuItem>
                                <MenuItem key={19} value={19}>
                                19 часов
                                </MenuItem>
                                <MenuItem key={20} value={20}>
                                20 часов
                                </MenuItem>
                                <MenuItem key={21} value={21}>
                                21 час
                                </MenuItem>
                                <MenuItem key={22} value={22}>
                                22 часа
                                </MenuItem>
                                <MenuItem key={23} value={23}>
                                23 часа
                                </MenuItem>
                                <MenuItem key={24} value={24}>
                                24 часа
                                </MenuItem>                                
                        </TextField>     
                    </div>         
                    <div hidden={( job.run_period!=="days")?true:false}>
    <TextField
                            id="job-every_days"
                            label="Каждые"
                            value={job.every_days}
                            onChange={onChangeDialogEveryDays}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            defaultValue={1}
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}>                              
                            <MenuItem key={1} value={1}>
                                1 день
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                2 дня
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                3 дня
                                </MenuItem>
                                <MenuItem key={4} value={4}>
                                4 дня
                                </MenuItem>
                                <MenuItem key={5} value={5}>
                                5 дней
                                </MenuItem>
                                <MenuItem key={6} value={6}>
                                6 дней
                                </MenuItem>
                                <MenuItem key={7} value={7}>
                                7 дней
                                </MenuItem>
                                <MenuItem key={8} value={8}>
                                8 дней
                                </MenuItem>
                                <MenuItem key={9} value={9}>
                                9 дней
                                </MenuItem>
                                <MenuItem key={10} value={10}>
                                10 дней
                                </MenuItem>
                                <MenuItem key={11} value={11}>
                                11 дней
                                </MenuItem>
                                <MenuItem key={12} value={12}>
                                12 дней
                                </MenuItem>
                                <MenuItem key={13} value={13}>
                                13 дней
                                </MenuItem>
                                <MenuItem key={14} value={14}>
                                14 дней
                                </MenuItem>
                                <MenuItem key={15} value={15}>
                                15 дней
                                </MenuItem>
                                <MenuItem key={16} value={16}>
                                16 дней
                                </MenuItem>
                                <MenuItem key={17} value={17}>
                                17 дней
                                </MenuItem>
                                <MenuItem key={18} value={18}>
                                18 дней
                                </MenuItem>
                                <MenuItem key={19} value={19}>
                                19 дней
                                </MenuItem>
                                <MenuItem key={20} value={20}>
                                20 дней
                                </MenuItem>
                                <MenuItem key={21} value={21}>
                                21 день
                                </MenuItem>
                                <MenuItem key={22} value={22}>
                                22 дня
                                </MenuItem>
                                <MenuItem key={23} value={23}>
                                23 дня
                                </MenuItem>
                                <MenuItem key={24} value={24}>
                                24 дня
                                </MenuItem>    
                                <MenuItem key={25} value={25}>
                                25 дней
                                </MenuItem>  
                                <MenuItem key={26} value={26}>
                                26 дней
                                </MenuItem>  
                                <MenuItem key={27} value={27}>
                                27 дней
                                </MenuItem>  
                                <MenuItem key={28} value={28}>
                                28 дней
                                </MenuItem>  
                                <MenuItem key={29} value={29}>
                                29 дней
                                </MenuItem>  
                                <MenuItem key={30} value={30}>
                                30 дней
                                </MenuItem>                                                                                                                              
                        </TextField>     
                    </div>                                       
    <div hidden={( job.run_period!=="monthly")?true:false}>
    <TextField
                            id="job-every_month"
                            label="Каждые"
                            value={job.every_month}
                            onChange={onChangeDialogEveryMonth}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            defaultValue={1}
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}>                              
                            <MenuItem key={1} value={1}>
                                1 месяц
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                2 месяца
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                3 месяца
                                </MenuItem>
                                <MenuItem key={6} value={6}>
                                6 месяца
                                </MenuItem>
                                <MenuItem key={9} value={9}>
                                9 месяца
                                </MenuItem>
                                <MenuItem key={12} value={12}>
                                Год
                                </MenuItem>

                        </TextField>     
                    </div>
                    <div hidden={( job.run_period!=="weekly")?true:false}>
    <ToggleButtonGroup size="small" value={job.days_of_week?Object.keys(job.days_of_week).map(k=>{return job.days_of_week[k]?k:null}):null} onChange={onChangeDialogDaysOfWeekHandle} aria-label="text formatting">
      <ToggleButton size="small" value="mon" aria-label="mon">
        Пн
      </ToggleButton>
      <ToggleButton size="small" value="tue" aria-label="tue">
        Вт
      </ToggleButton>
      <ToggleButton size="small" value="wed" aria-label="wed">
        Ср
      </ToggleButton>
      <ToggleButton size="small" value="thu" aria-label="thu">
        Чт
      </ToggleButton>
      <ToggleButton size="small" value="fri" aria-label="fri">
        Пт
      </ToggleButton>
      <ToggleButton size="small" value="sat" aria-label="sat">
        Сб
      </ToggleButton>
      <ToggleButton size="small" value="sun" aria-label="sun">
        Вс
      </ToggleButton>
    </ToggleButtonGroup>            
    </div>      
    <div hidden={( job.run_period!=="as_cron")?true:false}>
    <TextField
                            id="job-cron"
                            label="Выражение CRON"
                            value={job.cron}
                            onChange={onChangeDialogСron}
                            placeholder=""
                            helperText=""
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />  
            </div>
    </div>}
    {job.trigger_type==="dialog_finished" && <div>
      
      <TextField
                            id="job-finished_dialog"
                            label="Диалог"
                            value={job.finished_dialog}
                            onChange={onChangeFinishedDialog}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Link  to={finishedDialogHref} color="inherit">
                                  <IconForViewBot fontSize="small" />
                                  </Link>
                                </InputAdornment>
                              ),
                            }}       > 
                            { props.dialogs &&  props.dialogs.map(dialog => (
                            <MenuItem key={dialog._id} value={dialog._id}>
                              {dialog.name}
                            </MenuItem>
                            )

                            ) }                         

                                                                                               
                        </TextField>       
    </div>}
    {(job.trigger_type==="item_created"  || job.trigger_type==="item_updated")&& <div>
    <TextField
                            id="job-trigger_table"
                            label="На листе"
                            value={job.trigger_table}
                            onChange={onChangeTriggerTable}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}  >                  
                            { props.tablesList &&  props.tablesList.map(tb => (
                            <MenuItem key={tb._id} value={tb._id}>
                              {tb.title}
                            </MenuItem>
                            )

                            ) }                         

                                                                                               
                        </TextField>        
    </div>}
  </Paper> 

  <Paper className={classes.job_card} elevation={3}>
  <div >
  <TextField
        id="job-action_type"
        label="Действие"
        fullWidth
        name="action_type" 
        defaultValue="not_repeate"
        select
        className={classes.textField}
        value={job.action_type} 
        onChange={onChangeActionType}
        InputLabelProps={{
          shrink: true,
        }}
      >   
                            <MenuItem key="start_dialog" value="start_dialog">
                            <StartDialogIcon fontSize="small"/>
                            Запустить диалог
                            </MenuItem>
                            <MenuItem key="send_chat_message" value="send_chat_message">
                            <StartDialogIcon fontSize="small"/>
                            Отправить сообщение в чат
                            </MenuItem>
                            <MenuItem key="send_email" value="send_email">
                            <SendEmailIcon fontSize="small"/>
                            Отправить эл. письмо
                                </MenuItem>
                                <MenuItem key="create_item" value="create_item">
                                <AddRowIcon fontSize="small"/>
                                Добавить элемент
                                </MenuItem>
                                <MenuItem key="update_items" value="update_items">
                                <UpdateRowIcon fontSize="small"/>
                                Обновить элементы
                                </MenuItem> 
                                <MenuItem key="http_request" value="http_request">
                                <HttpRequestIcon fontSize="small"/>
                                HTTP запрос
                                </MenuItem>                                                                                                                                 
      </TextField>    
    </div>   
          {(job.action_type==="start_dialog" || job.action_type==="send_chat_message") && 
          <div>
          {job.action_type==="start_dialog"  && <TextField
                            id="job-dialog"
                            label="Диалог"
                            value={job.dialog}
                            onChange={onChangeDialog}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Link  to={dialogHref} color="inherit">
                      <IconForViewBot fontSize="small" />
                      </Link>
                    </InputAdornment>
                  ),
                }}          >                  
                            { props.dialogs &&  props.dialogs.map(dialog => (
                            <MenuItem key={dialog._id} value={dialog._id}>
                              {dialog.name}
                            </MenuItem>
                            )

                            ) }                         

                                                                                               
                        </TextField> }
                        <TextField
                            id="job-respondentsType"
                            label="Чат-пользователи"
                            value={job.respondentsType}
                            onChange={onChangeRespondentsType}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}>                  
                            <MenuItem key="all" value="all">
                              Для всех
                            </MenuItem>                        
                            <MenuItem key="selected" value="selected">
                            Для выбранных
                            </MenuItem>  
                                                                                               
                        </TextField>                         
                <div hidden={job.respondentsType==="all"}>
  <Autocomplete
    multiple
    id="job-respondents"
    options={props.chatmembers?props.chatmembers:[]}
    getOptionLabel={(option) => {return option?(option.first_name?(option.first_name+(option.last_name?(" "+option.last_name):"")):option.name):''}}
    defaultValue={[]}
    value={(job.respondents && props.chatmembers)?job.respondents.map((resId)=>{
        return props.chatmembers.find(res => {
            //console.log(' res ', res);
            //console.log(' resId ', resId);
            return res._id === resId
          })
    }):[]}
    onChange={onChangeDialogRespondents}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="standard"
        //label="Выбранных чат-пользователей"
        placeholder="Выберите чат-пользователей"
      />
    )}
  />               
  </div>  
  {job.action_type==="send_chat_message"  &&  <div>
                          <TextField
                            id="job-chat_message"
                            label="Сообщение"
                            value={job.chat_message}
                            onChange={onChangeChatMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}         
                          /> 
                <FormControl component="fieldset" size="small">


                  <FormControlLabel
                    control={<Switch size="small" checked={job.interrupt_current_dialog} onChange={onChangeInterruptCurrentDialog} />}
                    label={<span>Прерывать активные диалоги при отправке сообщения</span>}
                  />



                </FormControl>
                          </div>
                          
                        } 
  {job.action_type==="start_dialog" && <div>
  <div>
  <TextField
        id="job-timeout"
        label="Завершить диалог через"
        type="number"
        inputProps={{ min: "0", max: "360", step: "1" }}
        value={job.timeout}
        //defaultValue={30}
        onChange={onChangeDialogTimeout}
        placeholder=""
        helperText="" 
        fullWidth                           
        margin="normal"
        InputLabelProps={{
            shrink: true,
        }}
        InputProps={{
            endAdornment: <InputAdornment position="end">мин.</InputAdornment>         
        }}                            
    />          
    </div>                      
                  
                  {(job.timeout && job.timeout>0) ? <TextField
                            id="dialog-timeout_message"
                            label="Сообщение при завершении:"
                            value={job.timeout_message}
                            onChange={onChangeTimeoutMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            margin="normal"
                            variant="outlined"
                            //rows={2}                            
                        /> : <></>} 
      </div>}                        
                        </div>}            

            {job.action_type === "send_email" &&
              <div>
                    <ChipInput
                            id={"dialog-email"+props.idx}
                            label="Кому:"
                            fullWidth
                            value={job.email}
                            onAdd={(chip) => onAddDialogEmail(chip)}
                            onDelete={(chip, index) => onDeleteDialogEmail(chip, index)}
                        />   
                    <TextField
                            id="dialog-email_title"
                            label="Тема:"
                            value={job.email_title}
                            onChange={onChangeEmailTitle}
                            placeholder=""
                            helperText=""
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            //rows={2}                            
                        /> 
<div>
            <ReactQuill
              //hide = { hiddenDesc }
              theme="snow"
              placeholder="Напишите содержание письма..."
              defaultValue={job.email_text}
              modules={{
                toolbar: [
                  //[{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                  //[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link'],
                  //['clean']
                ],
              }}
              formats={[
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                //'list', 'bullet', 'indent',
                'link'
              ]}
              //onChange={onChangeEmailText}
              onBlur={onChangeEmailText}
              //onBlur={handleChangeDesc}
              />
          </div>                                                                                    
              </div>}
              {(job.action_type === "create_item" || job.action_type === "update_items") &&
              <div>
          <TextField
                            id="job-action_item_workitemlist"
                            label="Лист"
                            value={job.action_item_workitemlist}
                            onChange={onChangeCreateItemWorkitemlist}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}  >                  
                            { props.tablesList &&  props.tablesList.map(tb => (
                            <MenuItem key={tb._id} value={tb._id}>
                              {tb.title}
                            </MenuItem>
                            )

                            ) }                         

                                                                                               
                        </TextField>   
                        <TextField
                            id="job-action_item_view"
                            label={"Вид"}
                            value={job.action_item_view}
                            onChange={onChangeActionItemView}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            className={classes.selectEmpty}
                            InputLabelProps={{
                                shrink: true,
                            }}  >                  
                            { tableViewList &&  tableViewList.map(vw => (
                            <MenuItem key={vw._id} value={vw._id}>
                              {vw.title}
                            </MenuItem>
                            )

                            ) }                         

                                                                                               
                        </TextField>                         
                {createItemValuesList && createItemValuesList.map( ci=> (
                    <TextField
                    id={"dialog-create_item_values-"+ci.colId}
                    label={ci.colName}
                    value={ci.val}
                    onChange={e=> onChangeCreateItemValue(ci.colId, e.target.value)}
                    placeholder=""
                    helperText=""
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {ci.icon}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton onClick={e=> onDelActionItemValue(ci.colId)} size="small">
                          <DeleteIcon color="disabled" fontSize="small"/>
                        </IconButton>
                      )
                    }}                                                 
                />                   
                )
                )}
              <AddValueForCreateItemButton 
              columns={createItemValuesColumns}
              addColumnForCreateItem={addColumnForCreateItem}
              />
                
              </div>}   
              {job.action_type === "update_items" &&
              <div>

              </div>}  
              {job.action_type === "http_request" &&
              <div>

              </div>}                                            
                                         

          <DialogActions>
            {job._id && <Button size="small" variant="contained" disabled={props.accessLevel<9} onClick={handleDel}>
                Удалить
            </Button> }  
            {job.is_active ?
                    <Button disabled={props.accessLevel<5} size="small" variant="contained" onClick={e => props.onStopJob(job._id)}>Стоп</Button> :
                    <Button disabled={props.accessLevel<5} size="small" variant="contained" onClick={e => props.onRunJob(job._id)}>Пуск</Button>
            }                                                         
              <Button size="small" variant="contained" color="primary" disabled={props.accessLevel<5} onClick={handleSave}>
                Сохранить
              </Button>
          </DialogActions>
          </Paper>  
        </React.Fragment>
    );
  } 

  function getScheduleText(job) {
    if (job && job.trigger_type === "at_scheduled_time" ) {
      var start_date_str = ""

      if (!job.run_period || job.run_period == 'not_repeate') {
        if(job.start_date){
          start_date_str = moment.tz(job.start_date, job.timezone).format("DD.MM.YYYY HH:mm")
  
        }         
          return start_date_str
      }else if (job.run_period) {
        if(job.start_date){
          start_date_str = moment.tz(job.start_date, job.timezone).format("DD.MM.YYYY")
  
        }          
        const schedule_text = cronstrue.toString(job.cron, { locale: "ru" });
        return "C "+start_date_str+" "+schedule_text
      }
    } else {
  
        return "" 
    }
  }  

  function JobItemTrigger({job, dialog, dbId, tables}){
    var trigger_text = ""
    var trigger_icon = ""
    switch (job.trigger_type) {
      case 'at_scheduled_time':
        trigger_text = <span>{getScheduleText(job)}</span>
        trigger_icon = <AlarmOnIcon fontSize="small"/>
        break;
      case 'dialog_finished':
        trigger_text = <span>Когда диалог {dialog?<Link  to={`/db/${dbId}/${dialog.work_item_list}/${dialog._id}`} color="inherit">{dialog.name}</Link>:""} завершен </span>
        trigger_icon = <ChatIcon fontSize="small"/>
        break;
      case 'item_created':
        trigger_text = <span>Когда элемент {job.trigger_table?
          <Link  to={`/db/${dbId}/${job.trigger_table}/`} color="inherit">{tables[job.trigger_table]?tables[job.trigger_table].title:""}</Link>:"____"} создан </span>
        trigger_icon = <ChatIcon fontSize="small"/>
        break;
      case 'item_updated':
        trigger_text = <span>Когда элемент {job.trigger_table?
          <Link  to={`/db/${dbId}/${job.trigger_table}/`} color="inherit">{tables[job.trigger_table]?tables[job.trigger_table].title:""}</Link>:"____"} обновлен</span>
        trigger_icon = <ChatIcon fontSize="small"/>
        break;
      case 'on_webhook':
        trigger_text = <span>Когда получен webhook </span>
        trigger_icon = <ChatIcon fontSize="small"/>
        break;
      default:
        return (<span></span>)
    }
    return trigger_text
  }
  
  function JobItemAction({job, dialog, dbId, tables}) {
    
    if(job.action_type==='send_email'){
      return (

          <>Отправить письмо {job.email?job.email.length:0} чел.</>

      );
    } else if(job.action_type=='start_dialog'){
      return (

          <> Запустить диалог {dialog?dialog.name:""} для {job.respondentsType==="all"?"ВСЕХ":job.respondents.length} чат-пользователей</>
    
      );
    } else if(job.action_type=='send_chat_message'){
      return (

          <span> Отправить сообщение в чат для {job.respondentsType==="all"?"ВСЕХ":job.respondents.length} пользователей</span>
    
      );
    } else if(job.action_type=='create_item'){
      return (

          <span>Создать элемент {job.action_item_workitemlist?
          <Link  to={`/db/${dbId}/${job.action_item_workitemlist}/`} color="inherit">{tables[job.action_item_workitemlist].title}</Link>:"____"} </span>
    
      );
    } else if(job.action_type=='update_items'){
      return (

          <span>Обновить элемент {job.action_item_workitemlist?
          <Link  to={`/db/${dbId}/${job.action_item_workitemlist}/`} color="inherit">{tables[job.action_item_workitemlist].title}</Link>:"____"} </span>
    
      );      
    } else if(job.action_type=='http_request'){
      return (
          
          <span>HTTP(-S) запрос </span>
    
      );      
   } else {
     return <></>
   } 
  
  }
  
  
  
  function JobItemMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleDel = () => {
      props.deleteJob(props.jobIdx);
      handleClose();
    };  
  
    const handleEdit = () => {
      //props.onEditJob(props.jobIdx);
      handleClose();
    };  
  
  
    const handleCopy = () => {
      //props.onCopyJob(props.jobIdx);
      handleClose();
    }
  
    const handleRun = () => {
      props.onRunJob(props.job._id);
      handleClose();
    }
  
    const handleStop = () => {
      props.onStopJob(props.job._id);
      handleClose();
    }
  
    return (
      <ListItemSecondaryAction >
        <IconButton edge="end" aria-label="menu" aria-controls="job-item-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Menu
          id="job-item-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {(props.job && props.job.is_active)?
            <MenuItem onClick={handleStop}>Завершить</MenuItem>
            :<MenuItem onClick={handleRun}>Выполнить сейчас</MenuItem>}
          <MenuItem component={Link} to={`/jobs/${props.job.work_item_list}/${props.job._id}`}>Редактировать</MenuItem>
          <MenuItem onClick={handleCopy}>Дублировать</MenuItem>
          <MenuItem onClick={handleDel}>Удалить</MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    );
  }  

  export default function JobsEditor(props) {
    const {token} = useContext(AuthContext);
    const [editedJob, setEditedJob] = useState(null);
    const [openList, setOpenList] = useState(0); //0 - открыты обе панели, 1 - только список контактов, 2 только диалог
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const history = useHistory();    


    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);      
      setOpen(true);
    };
  
    const handleClose = () => {      
      setOpen(false);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        handleClose();        
    };



    const onRunJob = (jobId) => {
      props.runJob(jobId);
    }   
  
    const onStopJob = (jobId) => {
      props.stopJob(jobId);
    }   
  
    
  
    const onDelJob = (jobId) => {
      props.deleteJob(jobId);
    }  

    const onChangeIsOn = (jobId, is_on)=>{
      if(is_on){
        props.onJob(jobId)
      } else {
        props.offJob(jobId)
      }
    }    

    const onEditJobHandle = (jobId) => {
      //history.push(`/jobs/${groupid}/${jobId}`);
    }    

  useEffect(()=>{
    
  },[])
  

    return (
      <React.Fragment>
        
        <div  style={{ height: `calc(100vh - 88px)`,  backgroundColor:"white"  }} > 
            

  
            <Allotment
      defaultSizes={[300,350]}
      onChange = {(sizes) => {
        var fullSize = sizes[0]+sizes[1]
        if(fullSize < 555 && openList === 0){
          if(props.isEditedJobOpen){
            setOpenList(2)
          } else {
            setOpenList(1)
          }
          
        } else if(fullSize >= 555 && openList >0){
          //открываем оба списка
          setOpenList(0)
        }
      }}
      >
        <Allotment.Pane
                    minSize={250}
                    maxSize={560}
                    visible={(openList === 0 || openList === 1)}
        >
                      <div  style={{ height: `calc(100vh - 118px)`, overflow:"auto", backgroundColor:"white"  }} >    
            <Paper className={classes.job_card} elevation={3}> 
            <DialogActions>
            <Typography
          variant="body"
          component="h5" 
          color="inherit" 
          noWrap
          style={{
            flexGrow: 1,
          }}          
          >
            Задания
            </Typography> 
            {props.jobs && props.jobs.length>0 && <Button variant="text" size="small"
        edge="end" 
        aria-label="menu" 
        aria-controls="bot-menu" 
        aria-haspopup="true"
        color="primary" 
        startIcon={<AddCircleOutlineIcon fontSize="small"/>}
        onClick={e=>{props.setEditedJobId(null); props.setIsEditedJobOpen('job');       
          if(openList === 1){
          setOpenList(2)
        }}}>
        
        Добавить
      </Button>}
            </DialogActions>
            <List 
                        dense
                        disablePadding
            >
            
            
            {props.jobs && props.jobs.map(job => (
              <>
                        <ListItem
                          disableGutters
                          button
                          onClick={ e => { props.setEditedJobId(job._id); props.setIsEditedJobOpen('job')  
                            if(openList === 1){
                              setOpenList(2)
                            }

                          }}
                          //onClick={e => handleClick(e, tb)}
                          //selected={tb._id === props.wiId}
                          //ContainerComponent="li"
                          //ContainerProps={{ ref: provided.innerRef, className: classes.nonSelectedListItem }}
                                
                          //{...provided.draggableProps}
                          //{...provided.dragHandleProps}
              >
                <ListItemIcon style={{
                  minWidth:"32px"
                }}>

                    {job.action_type === "start_dialog" ?
                      <StartDialogIcon fontSize="small" color={job.is_on?"secondary":"disabled"}/> :
                      job.action_type === "send_chat_message" ?
                      <StartDialogIcon fontSize="small" color={job.is_on?"secondary":"disabled"}/> :
                      job.action_type === "send_email" ?
                        <SendEmailIcon fontSize="small" color={job.is_on?"secondary":"disabled"}/> :
                        job.action_type === "create_item" ?
                          <AddRowIcon fontSize="small" color={job.is_on?"secondary":"disabled"}/> :
                          job.action_type === "update_items" ?
                            <UpdateRowIcon fontSize="small" color={job.is_on?"secondary":"disabled"}/> :
                            job.action_type === "http_request" ?
                              <HttpRequestIcon fontSize="small" color={job.is_on?"secondary":"disabled"}/> : <></>
                    }

                </ListItemIcon>
<ListItemText
  //id={"tb-el-label-" + idx}
  primary={<JobItemAction job={job} 
                  dialog={props.dialogs.find(d => d._id === job.dialog)} 
                  dbId={props.dbId} 
                  tables={props.tables}/>}
                  secondary={<JobItemTrigger 
                    job={job}
                    dialog={props.dialogs.find(d => d._id === job.finished_dialog)}
                    dbId={props.dbId}
                    tables={props.tables}                  
                  />}
  //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
/>
<ListItemSecondaryAction
  onClick={ e => { onChangeIsOn(job._id, !job.is_on) }}
>
  <Tooltip title={job.is_on?"Выключить задание":"Включить задание"}>
    <IconButton size="small" edge="end" aria-label="on_off_job">
      {job.is_on?<ToggleOnIcon color="primary"  fontSize="small"/>:
      <ToggleOffOutlinedIcon color="disabled" fontSize="small"/>
      }
    </IconButton>  
    </Tooltip>
</ListItemSecondaryAction>

                        </ListItem>                
              <Divider variant="inset" component="li" />
              </>



            ))}

            
            </List>
            {(!props.jobs || props.jobs.length===0) && 
            <Grid   container
            direction="column"
            justifyContent="center"
            alignItems="center"
            >
              <Grid item>
              <img src='/objectivies.png' height={200} />
              </Grid>
              <Grid item>
              <Alert variant="outlined" severity="info">Автоматизируйте рутинную работу с помощью заданий </Alert>
              </Grid>
              <Grid item>
              <Button variant="contained" size="small"
              edge="end" 
              aria-label="menu" 
              color="primary" 
              aria-controls="bot-menu" 
              aria-haspopup="true"
              style={{
                marginTop:10
              }}
              startIcon={<AddCircleOutlineIcon fontSize="small"/>}
              onClick={e=>{setEditedJob(null); props.setIsEditedJobOpen('job')
                if(openList === 1){
                  setOpenList(2)
                }

              }}>
              
              Добавить задание
            </Button>
            </Grid>
            </Grid>
            }
            </Paper>
            <Paper className={classes.job_card} elevation={3}> 
            <DialogActions>
            <Typography
          variant="body"
          component="h5" 
          color="inherit" 
          noWrap
          style={{
            flexGrow: 1,
          }}          
          >
            Диалоги
            </Typography> 
            
            {props.dialogs && props.dialogs.length>0 &&  <AddSkillDialog 
            tableId = {props.tableId}
            addDialog = {props.addDialog}
            tables={props.tables}
            tablesList={props.tablesList}
            setEditedDialogId={props.setEditedDialogId}
            setEditedDialogTableId={props.setEditedDialogTableId}
            setIsEditedJobOpen = {props.setIsEditedJobOpen}
            variant="text"
            text="Добавить"
            />}
            
            
            </DialogActions>  
            <List 
            dense
            disablePadding
            >
            { 
            props.dialogs && props.dialogs.length>0 && props.dialogs.map(dialog => (
              <>
                        <ListItem
                          disableGutters
                          button
                          onClick={ e => { props.setEditedDialogId(dialog._id); props.setEditedDialogTableId(dialog.work_item_list); props.setIsEditedJobOpen('dialog')
                            if(openList === 1){
                              setOpenList(2)
                            }
                          }}
                          //onClick={e=> linkToDialog(dialog)}
                          //onClick={e => handleClick(e, tb)}
                          //selected={dialog._id === props.viewId}
                          //ContainerComponent="li"
                          //ContainerProps={{ ref: provided.innerRef, className: classes.nonSelectedListItem }}
                                
                          //{...provided.draggableProps}
                          //{...provided.dragHandleProps}
              >
                <ListItemIcon style={{
                  minWidth:"32px"
                }}>
                   {dialog && dialog.type==="standup"?
                   <AddItemFromChatIcon fontSize="small" color="primary"/> :
                   dialog.type==="update_request"?
                   <UpdateItemsFromChatIcon fontSize="small" color="primary"/> :
                   dialog.type==="update_item_request"?
                   <UpdateItemFromChatIcon fontSize="small" color="primary"/>:
                   <SendMsgtoChatIcon fontSize="small" color="primary"/>
                    }
                </ListItemIcon>
<ListItemText
  //id={"tb-el-label-" + idx}
  primary={dialog.name}
  secondary={
    <Tooltip arrow title={<div style={{whiteSpace:"pre-wrap"}}>{dialog.chat_keywords?dialog.chat_keywords.join('\n'):""}</div>}><span>{dialog.chat_keywords?dialog.chat_keywords[0]:""}</span></Tooltip>
  }
  //primaryTypographyProps={{ noWrap: true, style: { cursor: "pointer" } }}
/>


                        </ListItem>                
              <Divider variant="inset" component="li" />
              </>



            )

            )}
            </List>        
            {(!props.dialogs || props.dialogs.length===0) && 
            <Grid   container
            direction="column"
            justifyContent="center"
            alignItems="center"
            >
              <Grid item>
              <img src='/meetings.png' height={200} />
              </Grid>
              <Grid item>
              <Alert variant="outlined" severity="info">Автоматизируйте работу системы с клиентами и сотрудниками в чате с помощью диалогов </Alert>
              </Grid>
              <Grid item>
              <AddSkillDialog 
            tableId = {props.tableId}
            addDialog = {props.addDialog}
            tables={props.tables}
            tablesList={props.tablesList}
            setEditedDialogId={props.setEditedDialogId}
            setEditedDialogTableId={props.setEditedDialogTableId}
            text="Добавить диалог"
            variant="contained"
            />
            </Grid>
            </Grid>
            }  
            </Paper>
            </div>
          </Allotment.Pane>
          <Allotment.Pane
            minSize={300}
            //maxSize={500}
            visible={(openList === 0 || openList === 2)}
            >
              <Toolbar style={{ 
          minHeight: "33px", 
          paddingLeft:"8px"
          //backgroundColor:  "rgba(0, 0, 0, 0.1)",    
          //color: "#fff" 
          }}>
                      {openList===2 && <Button
            startIcon={<ArrowBackIosIcon fontSize="small" />}
            onClick={e=>{props.setIsEditedJobOpen(null); props.setEditedJobId(null);  props.setEditedDialogId(null); setOpenList(1)}}   
            size="small"
            >
            В список
            </Button>}
        <Typography
          variant="subtitle2"
          component="h3" 
          color="inherit" 
          noWrap
          style={{
            flexGrow: 1,
            paddingLeft:8
          }}     
            
          >
            { props.isEditedJobOpen==="job" && (props.editedJobId? "Редактировать задание": "Новое задание")}
            { props.isEditedJobOpen==="dialog" && (props.editedDialogId? "Редактировать диалог": "Новый диалог")}
            </Typography>         
        </Toolbar>
        <div  style={{ height: `calc(100vh - 118px)`, overflow:"auto", backgroundColor:"white"  }} >    
        {props.isEditedJobOpen!=="job"  && props.isEditedJobOpen!=="dialog" &&
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Alert style={{marginTop:10}} icon={<KeyboardBackspaceIcon fontSize="inherit" />} variant="outlined" severity="info">Нажмите "Добавить" или выберите, чтобы отредактировать
            </Alert>
            </Grid>
            </Grid>
            }
                          {props.isEditedJobOpen==='job' && <JobEditDialog 
            editedJobId={props.editedJobId} 
            {...props}
            setOpen={props.setIsEditedJobOpen} 
            deleteJob={props.deleteJob}
            onStopJob={props.stopJob}
            onRunJob  = {props.runJob}     
            />}
             {props.isEditedJobOpen==='dialog' &&       <DialogEditorForPanel             
                                   {...props} 
                                   dialogId={props.editedDialogId} 
                                   dialogTableId = {props.editedDialogTableId} 
                 
                             //minHeight={`calc(100vh - ${headerHeight + extPanelsHeight}px)`}
                             //dialogviews={props.dialogviews}
                 
                                   
                             />}
                             </div>
              </Allotment.Pane>
        </Allotment>      


    </div>
        </React.Fragment>
    );
  }  