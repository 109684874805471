import React, {useState, useContext, useCallback, useEffect, useRef  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {useHttp} from '../hooks/http.hook';
import {useHistory, Link} from 'react-router-dom'
import moment from 'moment-timezone';
//import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Query, Builder, Utils as QbUtils} from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import './QueryBuilder.css'
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import ChatIcon from '@material-ui/icons/Chat';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LinkIcon from '@material-ui/icons/Link';
import 'moment/locale/ru'
import { IconButton, Tooltip } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import MenuIcon from '@material-ui/icons/Menu';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { MentionsInput, Mention } from './Mentions'
import SubjectIcon from '@material-ui/icons/Subject';
import './DialogEditor.css'
import ColumnSettingsDialog from './WorkItemsTable/ColumnSettingsDialog'
import _clone from 'lodash/clone'
import _escapeRegExp from 'lodash/escapeRegExp'
import AceEditor from 'react-ace'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListSubheader from '@material-ui/core/ListSubheader';
import Chip from '@material-ui/core/Chip';
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import {  MessageBox } from './ChatApp/src'//'react-chat-elements';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { ruRU } from '@material-ui/core/locale';
import DialogActions from '@material-ui/core/DialogActions';
import Popover from '@material-ui/core/Popover';
const InitialConfig = MaterialConfig;

moment.locale('ru');


//const MenuProps = {
//  PaperProps: {
//    style: {
//      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//      width: 250,
//    },
//  },
//};

//function getStyles(name, personName, theme) {
//    return {
//      fontWeight:
//        personName.indexOf(name) === -1
//          ? theme.typography.fontWeightRegular
//          : theme.typography.fontWeightMedium,
//    };
//  }

//const Transition = React.forwardRef(function Transition(props, ref) {
//    return <Slide direction="up" ref={ref} {...props} />;
//  });
function LookupIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
    </SvgIcon>
  );
}  


function ChatMemberIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4l4 4l4-4h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2m-8 2.3c1.5 0 2.7 1.2 2.7 2.7c0 1.5-1.2 2.7-2.7 2.7c-1.5 0-2.7-1.2-2.7-2.7c0-1.5 1.2-2.7 2.7-2.7M18 15H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" />
    </SvgIcon>
  );
}

function SendLocationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12 2A7 7 0 0 0 5 9C5 14.25 12 22 12 22S19 14.25 19 9A7 7 0 0 0 12 2M16.5 9H14V14H10V9H7.5L12 4.5Z" />
    </SvgIcon>
  );
}

function SendFilesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2M18 20H6V4H13V9H18V20M16 11V18.1L13.9 16L11.1 18.8L8.3 16L11.1 13.2L8.9 11H16Z" />
    </SvgIcon>
  );
}


  
  const useStyles = makeStyles((theme) => ({
    
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    question_paper_selected: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      borderLeft: "5px solid "+theme.palette.primary.main
    },    
    question_paper: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },    
    question_list: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
      },
      question_menu: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
      },      
      subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    grow: {
      flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    fabButton:  {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    fileinput: {
      display: 'none',
    },    
    selectEmpty: {
        marginTop: theme.spacing(2),
      }, 
    scheduler:{
        display: 'flex',
    },
    smallControlLabel: {
      fontSize: "12px"
    },
    job_card: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },      
  }));

  function normilizeQuestionText (qtext){
    let displayText = _clone(qtext)
    const tags = qtext.match(/\{\{[^\}]+\}\}/gi) || []
    tags.map(myTag => {
      const tagData = myTag.slice(2, -2)
      const tagDataArray = tagData.split('||')
      const tagDisplayValue = "{"+tagDataArray[1]+"}"
      displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
    })
    return displayText
  }
  

  function QuestionIntentViewButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [anchorElColEdit, setAnchorElColEdit] = React.useState(null);
    const [editingSets, setEditingSets] = React.useState(null);
    const [showBt, setShowBt] = React.useState(false);

    const handleClick = () => {
      if(props.question.intent){
        const  col = props.editedColumns.find(e => { return e._id === props.question.intent });
        //setAnchorElColEdit(anchorRef.current)
        var rect = anchorRef.current.getBoundingClientRect();
        setAnchorElColEdit({ top: rect.top, left: rect.left })                    
  
        setEditingSets(col) 
      }
    };
  
    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };

    var intentIcon = undefined;
    var intentText = ""; 
    if(props.question.intent) {
      var currentCol = props.editedColumns.find(col=> col._id===props.question.intent)
      if(currentCol){
      intentIcon = currentCol.type==='text'? <TextFieldsIcon fontSize="small" color="disabled"/>:
      currentCol.type==='number'? <Looks3Icon fontSize="small"  color="disabled"/>:
      currentCol.type==='date'?<EventIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='select' ? <FilterListIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='multiline' ? <SubjectIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='member' ? <PersonIcon fontSize="small"  color="disabled" />:
      currentCol.type==='chatmember' ? <ChatMemberIcon fontSize="small"  color="disabled" />:
      currentCol.type==='link' ? <LinkIcon fontSize="small"  color="disabled" />:
      currentCol.type==='geolocation' ? <LocationOnIcon fontSize="small"  color="disabled" />:
      currentCol.type==='attachments' ? <AttachFileIcon fontSize="small"  color="disabled" />:
      currentCol.type==='check' ? <CheckBoxIcon fontSize="small"  color="disabled"/>: undefined      
      intentText =  currentCol.name 
    } else {
        //нет такой колонки
        if(props.question.intent === "__exly_dialog_menu"){
          intentText = 'Меню'
          intentIcon =<MenuIcon fontSize="small" color="disabled"/>
        } else if(props.question.intent === "__exly_child_dialog"){
          intentText = 'Дочерний диалог'
            intentIcon =<ChatIcon fontSize="small" color="disabled"/>
                          
        } else {
          intentText = 'Колонка удалена!'
        }
        
      }
    } else {
      
      intentText = 'Не требует ответа'
    }

    //console.log('QuestionIntentButton ', props)
  
    return (
      <>
          <div
      //onContextMenu={handleOpenColumnContextMenu}
      //ref={useCombinedRefs(drag, drop, divRef)} 
      ref={anchorRef}         
      onClick={handleClick}   
      onMouseEnter={() => setShowBt(true)}
      onMouseLeave={() => setShowBt(false)}    
      aria-haspopup="true"
      aria-controls={"question-intent-"+props.idx}
      style={{
        //opacity: isDragging ? 0.5 : 1,
        //backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'pointer',   
        display: 'flex',  
        fontSize:13,
        fontWeight:400 //был толстый 700      
      }}
    >
      
      <ListItem
        button
        ContainerComponent="div" 
        disableGutters
        style={{
          padding:0,
          //width: props.column.width-8
        }}
        id={'question-intent-column-'+currentCol._id}   
      >
      <ListItemIcon style={{minWidth:"25px"}}>
      {intentIcon}
      </ListItemIcon>
      <ListItemText 
      id={"question-intent-column-label-"+currentCol._id} 
      style={{marginTop:"2px", marginBottom:0}}
      primary={props.column.name} 
      //color={props.question.hasCondition?"secondary":"default"}
      disableTypography
      />
      
        
      {showBt && <ListItemSecondaryAction
       style={{right:"3px"}}
       >
            <IconButton size="small" onClick={handleClick} style={{float: "right"}}><ArrowDropDownIcon       style={{
              cursor: 'pointer'
            }} color="disabled" fontSize="small"/>
            </IconButton>
            </ListItemSecondaryAction>}
    </ListItem>      

    </div>          
          <ColumnSettingsDialog  
              tableId={props.tableId} 
              open={anchorElColEdit} 
              setOpen={setAnchorElColEdit} 
              editingSets={editingSets} 
              setEditingSets={setEditingSets} 
              onColumnInsert={props.onColumnInsert} 
              onChangeColumn={props.onChangeColumn} 
              tables={props.tables}
              tablesList={props.tablesList} 
              viewId={props.viewId}
              columns={props.columns}
              />           
          </>
    );
  }

  function QuestionIntentButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [anchorElColEdit, setAnchorElColEdit] = React.useState(null);
    const [editingSets, setEditingSets] = React.useState(null);
    const [showBt, setShowBt] = React.useState(false);
    const classes = useStyles();

    const handleClick = () => {
      if(props.question.intent && props.question.intent!=="__exly_dialog_menu"){
        const  col = props.editedColumns.find(e => { return e._id === props.question.intent });        
        //setAnchorElColEdit(anchorRef.current)
        var rect = anchorRef.current.getBoundingClientRect();
        setAnchorElColEdit({ top: rect.top, left: rect.left })         
        setEditingSets(col)        
      }
    };

    const onAddQuestionIntent = () =>{
      //setAnchorElColEdit(anchorRef.current)
      var rect = anchorRef.current.getBoundingClientRect();
      setAnchorElColEdit({ top: rect.top, left: rect.left })       
      setEditingSets({idx:props.columns.length, isNew:true})         
    }

    /**
     * Добавили новую колонку в диалоге
     * @param {*} newCol 
     */
    const onNewColumnCallback = (newCol) =>{
      props.onChangeQuestionIntent(props.idx, newCol._id); 
      setOpen(false);      
    }
         

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };  

    const toggleIsFormula = () => {
      props.onChangeQuestionToggleIsFormula(props.idx, !props.question.is_excel_formula);
    }    

    const toggleIsJSFormula = () => {
      props.onChangeQuestionToggleIsJSFormula(props.idx, !props.question.is_formula);
    }       

    const onDeleteQuestion = () => {
      //const v = event.target.value;
      props.onDeleteQuestion(props.idx);
  }    

    const handleToggle = (e) => {
      setOpen((prevOpen) => !prevOpen);
      e.stopPropagation();
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };


    //console.log('QuestionIntentButton ', props)
  
    return (
      <>
<Grid
  container
  direction="row"
  justifyContent="space-between"
  alignItems="center"
>

          <Grid
      //onContextMenu={handleOpenColumnContextMenu}
      //ref={useCombinedRefs(drag, drop, divRef)} 
      ref={anchorRef}         
      onClick={handleClick}   
      onMouseEnter={() => setShowBt(true)}
      onMouseLeave={() => setShowBt(false)}    
      aria-haspopup="true"
      aria-controls={"question-intent-"+props.idx}
      style={{
        //opacity: isDragging ? 0.5 : 1,
        //backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'pointer',   
        display: 'flex',  
        fontSize:13,
        fontWeight:400 //был толстый 700      
      }}
    >
      
      <ListItem
        button
        ContainerComponent="div" 
        disableGutters
        style={{
          //padding:0,
          //width: props.column.width-8
        }}
        id={'question-intent-column-'+props.idx}   
      >
      <ListItemIcon style={{minWidth:"25px"}}>      
      {props.intentIcon}
      </ListItemIcon>
      <ListItemText 
      id={"question-intent-column-label-"+props.idx} 
      style={{marginTop:"2px", marginBottom:0}}
      primary={props.intentText} 
      //color={props.question.hasCondition?"secondary":"default"}
      disableTypography
      />
      </ListItem>
      </Grid>
      <Grid>
    {props.question.intent 
            && props.question.intent !== "__exly_dialog_menu"  
            && props.question.intent !== "__exly_child_dialog"             
            && props.editingQuestionIdx === props.idx && 
            <>
              <FormControlLabel
                classes={{label: classes.smallControlLabel}}
                control={<Switch  size="small" checked={props.question.is_excel_formula} onChange={toggleIsFormula} />}
                label="Формула"
                labelPlacement= 'start'
              />
              {props.question.is_formula && <FormControlLabel
                classes={{label: classes.smallControlLabel}}
                control={<Checkbox size="small" checked={props.question.is_formula} onChange={toggleIsJSFormula} />}
                label="JS"
                labelPlacement= 'start'
              />}
              </>
            }  
            <span
            style={{
              marginLeft:"20px"
            }}
            >
              <Tooltip title={'Удалить вопрос/сообщение'} >
            <IconButton 
            size="small" 
            aria-label="delete"             
            onClick={onDeleteQuestion}>
                <DeleteOutline fontSize="small" />
              </IconButton>  
              </Tooltip>
              </span>               
    </Grid>
   

    </Grid>              
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 99 }} disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" dense>
                    <MenuItem
                          key="nothing"
                          value=""
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {props.onChangeQuestionIntent(props.idx, ""); setOpen(false);}}
                          >
                          <ListItemIcon>
                          
                          </ListItemIcon>                              
                          <em>НЕ ТРЕБУЕТ ОТВЕТА</em>
                        </MenuItem>                            
                        <MenuItem
                          key="__exly_dialog_menu"
                          value="__exly_dialog_menu"
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {props.onChangeQuestionIntent(props.idx, "__exly_dialog_menu"); setOpen(false);}}
                          >
                          <ListItemIcon>
                          <MenuIcon fontSize="small"/>
                          </ListItemIcon>                              
                          МЕНЮ
                        </MenuItem>                                                                                                                
                      {props.editedColumns.map((option, index) => (
                        <MenuItem
                          key={option._id}
                          value={option._id}
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {props.onChangeQuestionIntent(props.idx, option._id); setOpen(false);}}
                          >
                          <ListItemIcon size="small">
                          {option.type==='text' && <TextFieldsIcon fontSize="small" color="disabled"/>}
                          {option.type==='title' && <TextFieldsIcon fontSize="small" color="disabled"/>}
                        {option.type==='number' && <Looks3Icon fontSize="small"  color="disabled"/>}
                        {option.type==='date' && <EventIcon fontSize="small"  color="disabled"/>}
                        {option.type==='select' && <FilterListIcon fontSize="small"  color="disabled"/>}
                        {option.type==='multiline' && <SubjectIcon fontSize="small"  color="disabled"/>}
                        {option.type==='member' && <PersonIcon fontSize="small"  color="disabled" />}
                        {option.type==='chatmember' && <ChatMemberIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='link' && <LinkIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='geolocation' && <LocationOnIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='attachments' && <AttachFileIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='check' && <CheckBoxIcon fontSize="small"  color="disabled"/>}
                          </ListItemIcon>                              
                          {option.name}
                        </MenuItem>
                      ))}
                        <MenuItem
                          key="__exly_dialog_menu_add_intent"
                          value="__exly_dialog_menu_add_intent"
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {onAddQuestionIntent()}}
                          >
                          <ListItemIcon>
                          <AddCircleOutlineIcon fontSize="small"/>
                          </ListItemIcon>                              
                          Добавить колонку
                        </MenuItem>                       
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <ColumnSettingsDialog  
              tableId={props.tableId} 
              open={anchorElColEdit} 
              setOpen={setAnchorElColEdit} 
              editingSets={editingSets} 
              setEditingSets={setEditingSets} 
              onColumnInsert={props.onColumnInsert} 
              onChangeColumn={props.onChangeColumn} 
              tables={props.tables}
              tablesList={props.tablesList} 
              viewId={props.viewId}
              columns={props.columns}
              onNewColumnCallback={onNewColumnCallback}
              />           
          </>
    );
  }  

function QuestionEditor(props){
    const classes = useStyles();
    
    const [questionText, setQuestionText]  = useState("");
    const [questionSelectedType, setQuestionSelectedType]  = useState(null);
    const [answerType, setAnswerType]  = useState(null);
    const [condition, setCondition] = useState(null);
    const [childDialogName, setChildDialogName] = useState("");
    const [formulaText, setFormulaText] = useState("") 
    const history = useHistory();
    const inputRef = useRef(null);

    const onChangeQuestionText = (event, newValue, newPlainTextValue, mention) => {
        //console.log('onChangeQuestionText ', event, newValue, newPlainTextValue, mention)
        const v = newValue //event.target.value;        
        setQuestionText(v);
    }


    const onChangeFormulaText = (event) => {
      //console.log('onChangeQuestionText ', event, newValue, newPlainTextValue, mention)
      const v = event.target.value;        
      setQuestionText(v);
  }    

  const onChangeJSFormulaText = (newValue) => {
    //console.log('onChangeQuestionText ', event, newValue, newPlainTextValue, mention)
    const v = newValue //event.target.value;        
    setQuestionText(v);
}      

  const onSaveQuestionText = (e, c)=>{
    
    //setQuestionText(cur => {
      if (questionText !== props.question.question_text) {
        console.log('onSaveQuestionText ', questionText, props.question.question_text)
        props.onChangeQuestionText(props.idx, questionText);
        
      }
    //})
  }



    const onChangeQuestionMenuItem = (idx, value) => {      
      props.onChangeQuestionMenuItem(props.idx, idx, value);
  } 

  
  const onChangeQuestionMenuItemType = (idx, value) => {      
    props.onChangeQuestionMenuItemType(props.idx, idx, value);
}    

const onChangeQuestionMenuItemParam = (idx, value) => {      
  props.onChangeQuestionMenuItemParam(props.idx, idx, value);
}    

const onAddQuestionMenuItem = () => {
  //const v = event.target.value;
  props.onAddQuestionMenuItem(props.idx, {
    cmd:"Команда",
    cmd_type: "next_step",
    cmd_param:null
  });
}



const onDeleteQuestionMenuItem = (item_index) => {
  //const v = event.target.value;
  props.onDeleteQuestionMenuItem(props.idx, item_index);
}
    
    const onChangeQuestionAnswerType = (event) => {
        const v = event.target.value;
        props.onChangeQuestionAnswerType(props.idx, v);
    }

    const onChangeQuestionListStyle = (event) => {
      const v = event.target.value;
      props.onChangeQuestionListStyle(props.idx, v);
  }  

  const onChangeQuestionDelay = (event) => {
    const v = event.target.value;
    props.onChangeQuestionDelay(props.idx, v);
}    

const onChangeQuestionPhoneButtonText = (event) => {
  const v = event.target.value;
  props.onChangeQuestionPhoneButtonText(props.idx, v);
}  

const onChangeQuestionChildDialog = (event) => {
  const v = event.target.value;
  props.onChangeQuestionChildDialog(props.idx, v);
}  


  
  const onChangeQuestionSendField = (event) => {
    const v = event.target.value;
    props.onChangeQuestionSendField(props.idx, v);
}    


  
    
    


    
    
    
    const onAddQuestion = () => {
        //const v = event.target.value;
        props.onAddQuestion(props.idx);
    }
    
    const onChangeQuestionIntent = (event) => {
        const v = event.target.value;
        props.onChangeQuestionIntent(props.idx, v);
    }

    const onChangeQuestionNextQuestionParam = (midex, v) => {
      var next_question = parseInt(v, 10);
      if (isNaN(next_question)) { next_question = 1; } 
      next_question = next_question 
      onChangeQuestionMenuItemParam(midex, next_question)
  }    
    

    const handleAddAnswerChoice = (chip) => {
        props.handleAddAnswerChoice(props.idx, chip);
    }  
    
    const handleDeleteAnswerChoice = (chip, index) => {
        props.handleDeleteAnswerChoice(props.idx, chip, index);
    }     

    const onChangeQuestionCondition = (event) => {
        const v = event.target.value;
        props.onChangeQuestionCondition(props.idx, v);
    }     

    const toggleHasCondition = () => {
      props.onChangeQuestionToggleHasCondition(props.idx, !props.question.hasCondition);
    }

    const toggleTyping = () => {
      props.onChangeQuestionToggleTyping(props.idx, !props.question.typing);
    }    

    const toggleHasPhoneButton = () => {
      props.onChangeQuestionToggleHasPhoneButton(props.idx, !props.question.has_phone_button);
    }      

         
    const onChildDialogClick = (e) =>{
      if(props.dbId && props.dialogs && props.question.child_dialog){
        var dialog = props.dialogs.find(d=>d._id===props.question.child_dialog)
        if(dialog){
          history.push(`/db/${props.dbId}/${dialog.work_item_list}/${dialog._id}` )
        }
    }
  }
    const onChangeEditedQuestion = (e) =>{
      if(props.editingQuestionIdx!==  props.idx) {
        props.setEditingQuestionIdx(props.idx)
        e.stopPropagation()
        //console.log('inputRef ', inputRef)
      }
      
    }

       

  const getFormulaText = (txt) => {
    let displayText = _clone(txt)
    //console.log('getFormulaText txt', txt )
    if (props.question.is_excel_formula) {
      var _columnsNameById = {}
      if (props.columns) {
        props.columns.forEach(cl => {
          _columnsNameById[cl._id] = cl.name
        });
      }

      const tags = displayText.match(/var_\w+/gi) || []
      //console.log('getFormulaText tags in', tags )
      tags.map(myTag => {
        const tagData = myTag.substring(4)
        const tagDisplayValue = "{" + _columnsNameById[tagData] + "}"
        displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
      })
    }
    return displayText
  }


    const renderBuilder = (props) => (
      <div className="query-builder-container" >
        <div className="query-builder qb-lite">
            <Builder {...props} />
        </div>
      </div>
    )    

    const onChange = (immutableTree, config) => {
      // Tip: for better performance you can apply `throttle` - see `examples/demo`
      //setTree(immutableTree);
      //setConfig(config);
      setCondition(immutableTree)
      props.onChangeQuestionCondition(props.idx, QbUtils.getTree(immutableTree))
      //const jsonTree = QbUtils.getTree(immutableTree);
      //console.log(jsonTree);
      // `jsonTree` can be saved to backend, and later loaded to `queryValue`
    }  

    const onPasteParamInQuestionText = (param)=> {
      const newText = props.question.question_text+" ["+param+"] "
      props.onChangeQuestionText(props.idx, newText);
    }
    
    const onBlurQuestionText = (e) =>{
      if (questionText !== props.question.question_text) {
        //console.log('onSaveQuestionText ', questionText, props.question.question_text)
        let displayText = _clone(questionText)
        if(props.question.is_excel_formula){
          //преобразуем формулу
          var _columnsIdByName = {}
          props.columns.forEach(cl => {
            _columnsIdByName[cl.name] = cl._id
          });      
          
          const tags = displayText.match(/{([^}]+)}/gi) || []

          tags.map(myTag => {
            const tagData = myTag.slice(1, -1)        
            const tagDisplayValue = "var_"+_columnsIdByName[tagData]
            displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
          })  
        }        
        props.onChangeQuestionText(props.idx, displayText);
        
      }            
    };
    

    useEffect(()=>{
      var _filter = props.question.condition?props.question.condition:{"id": QbUtils.uuid(), "type": "group"}

      var _immutableTree = QbUtils.checkTree(QbUtils.loadTree(_filter), props.config)
      setCondition(_immutableTree) //QbUtils.checkTree(QbUtils.loadTree({"id": QbUtils.uuid(), "type": "group"}), _config))             
      if(props.question){
        
        let displayText = _clone(props.question.question_text)
        //console.log('getFormulaText txt', txt )
        if (props.question.is_excel_formula) {
          var _columnsNameById = {}
          if (props.columns) {
            props.columns.forEach(cl => {
              _columnsNameById[cl._id] = cl.name
            });
          }
    
          const tags = displayText.match(/var_\w+/gi) || []
          //console.log('getFormulaText tags in', tags )
          tags.map(myTag => {
            const tagData = myTag.substring(4)
            const tagDisplayValue = "{" + _columnsNameById[tagData] + "}"
            displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
          })
        }
        setQuestionText(displayText)                  
      } else {
        setQuestionText("")
      }
      
    }, [props.question, props.config])   
    
    useEffect(()=>{

        if(props.question.intent) {
          var currentCol = props.editedColumns.find(col=> col._id===props.question.intent)
          if(currentCol){
            setAnswerType(currentCol.type)
            if (currentCol.type==='select' 
            || currentCol.type==='member' 
           || currentCol.type==='chatmember'
            || currentCol.type==='link' 
            || currentCol.type==='check'){
              setQuestionSelectedType(currentCol.type)
            } else {
              setQuestionSelectedType(null)
              setChildDialogName("")
            }
        } else {
            //нет такой колонки
            if(props.question.intent === "__exly_dialog_menu"){
              setQuestionSelectedType("__exly_dialog_menu")
            } else  if(props.question.intent === "__exly_child_dialog"){
                setQuestionSelectedType("__exly_child_dialog")
                var child_dialog_name = ""
                if(props.question.child_dialog && props.dialogs){
                  var child_dialog = props.dialogs.find(v=> v._id===props.question.child_dialog)
                  if(child_dialog){
                    child_dialog_name = child_dialog.name
                  }
                  
                }
                setChildDialogName(child_dialog_name)
            } else {
              setQuestionSelectedType(null)
              setChildDialogName("")
            }
          }   
        } else {
          setQuestionSelectedType(null)
          setChildDialogName("")
        }          
    }, [props.question, props.editedColumns, props.dialogs])      

    
    useEffect(()=>{
      //console.log('useEffect inputRef!')
      if (inputRef && inputRef.current) {
          //console.log('inputRef focus!')
          inputRef.current.onblur = onBlurQuestionText
      }     

    }, [inputRef.current, questionText, props.idx, props.question.question_text])  

    var intentIcon = undefined;
    var intentText = ""; 
    var demoAnswerText = "";
    if(props.question.intent) {
      var currentCol = props.editedColumns.find(col=> col._id===props.question.intent)
      if(currentCol){
      intentIcon = (currentCol.type==='text' || currentCol.type==='title') ? <TextFieldsIcon fontSize="small" color="disabled"/>:
      currentCol.type==='number'? <Looks3Icon fontSize="small"  color="disabled"/>:
      currentCol.type==='date'?<EventIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='select' ? <FilterListIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='multiline' ? <SubjectIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='member' ? <PersonIcon fontSize="small"  color="disabled" />:
      currentCol.type==='chatmember' ? <ChatMemberIcon fontSize="small"  color="disabled" />:
      currentCol.type==='link' ? <LinkIcon fontSize="small"  color="disabled" />:
      currentCol.type==='geolocation' ? <LocationOnIcon fontSize="small"  color="disabled" />:
      currentCol.type==='attachments' ? <AttachFileIcon fontSize="small"  color="disabled" />:
      currentCol.type==='check' ? <CheckBoxIcon fontSize="small"  color="disabled"/>: undefined      
      intentText =  currentCol.name 
      demoAnswerText = currentCol.name 
    } else {
        //нет такой колонки
        if(props.question.intent === "__exly_dialog_menu"){
          intentText = 'Меню'
          intentIcon =<MenuIcon fontSize="small" color="disabled"/>
          demoAnswerText = (props.question.menu_items && props.question.menu_items.length>0) ? props.question.menu_items[0].cmd : "-"
        } else if(props.question.intent === "__exly_child_dialog"){
          intentText = 'Запустить дочерний диалог'
            intentIcon =<ChatIcon fontSize="small" color="disabled"/>
            intentIcon =<QuestionAnswerIcon fontSize="small" color="disabled"/>
        } else {
          intentText = 'Колонка удалена!'
        }
        
      }
    } else {
      intentIcon =<ChatIcon fontSize="small" color="disabled"/>
      intentText = 'Простое сообщение'
    }


    return(
        <React.Fragment             
            
             >
                                        
                        <Paper             
                        className={props.editingQuestionIdx=== props.idx  ? classes.question_paper_selected : classes.question_paper}     
                        variant={props.editingQuestionIdx=== props.idx  ? 'elevation' : "outlined"} 
                        elevation={props.editingQuestionIdx=== props.idx  ? 5 : 0} 
                        onClick={onChangeEditedQuestion}
                         > 

                          <QuestionIntentButton {...props} intentText={intentText} intentIcon={intentIcon}/> 
                           

                         
                        {props.question.is_formula && !props.question.is_excel_formula &&
                         <AceEditor
                         placeholder="Введите код"
                         mode="javascript"
                         theme="monokai"
                         name="formula_questionText"
                         //onLoad={this.onLoad}
                         onChange={onChangeJSFormulaText}
                         onBlur={onBlurQuestionText}
                         fontSize={12}
                         showPrintMargin={true}
                         showGutter={true}
                         highlightActiveLine={true}
                         value={questionText}
                         maxLines={15}
                         minLines={5}
                         setOptions={{
                         enableBasicAutocompletion: true,
                         enableLiveAutocompletion: true,
                         enableSnippets: false,
                         showLineNumbers: true,
                         tabSize: 2,
                         }}/>}
                        {!props.question.is_formula && props.question.is_excel_formula &&
                               <TextField
                               id={"quiestion_formula"}
                               value={questionText}
                       name="formula"
                       onChange={onChangeFormulaText}
                       onBlur={onBlurQuestionText}                 
                       placeholder="Введите формулу расчета"
                       helperText=""
                       multiline
                       fullWidth
                       variant="outlined"
                       size="small"
                       onPaste={event => event.stopPropagation()}
                       onCopy={event => event.stopPropagation()}    
                       InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={{marginTop:"0px"}}>
                            =
                          </InputAdornment>
                        ),                                            
                      }}                           
                       //margin="normal"
                   />                         
                        }
                {!props.question.is_formula 
                && !props.question.is_excel_formula 
                && props.question.intent!=="__exly_child_dialog"
                && 
                <MentionsInput
                    autoFocus={props.editingQuestionIdx===  props.idx }
                   value={questionText} //{props.question.question_text}
                 //defaultValue={getFormulaText(props.question.question_text?props.question.question_text:"")}
          onChange={onChangeQuestionText}
          inputRef={inputRef}
           //defaultValue = {props.question.question_text}
           //onBlur={onSaveQuestionText}
          //displayTransform={(id, display) => `[${id}]`}        
          placeholder="Используйте { для вставки значения полей в текст"
          className="mentions"
        >
          <Mention
            type="user"
            markup="{{__id__||__display__}}" 
            displayTransform={(id, display) => `{${display}}`}  
            trigger="{"
            data={props.columns.map(col=> { return{id:col._id, display: col.name}})}
            className="mentions__mention"
          />
        </MentionsInput>   
        }
                              
                     
{props.question.intent==="__exly_dialog_menu" &&
  <Paper className={classes.question_menu}>
    <Grid container alignItems="flex-end" spacing={1}>

    <Grid item xs={4} >Пункты меню</Grid>  
    
    <Grid item xs={4} >Команда</Grid>  
         
    <Grid item xs={3} ></Grid>  
    <Grid item xs={1} ></Grid>
    </Grid>
    {props.question.menu_items && props.question.menu_items.map( (mi, midex)=>(
    <Grid container alignItems="flex-end" spacing={1}>      
    <Grid item xs={4} >
      <TextField

                    id={"dialog-question-"+props.idx+"menu-item"+midex}
                    //label="Команда"
                    value={mi.cmd}
                    onChange={event=> onChangeQuestionMenuItem(midex, event.target.value)}                    
                    placeholder="Введите название команды"
                    helperText=""
                    fullWidth      
                    InputProps={{style: {fontSize: 12}}}   
                    InputLabelProps={{ style: { fontSize: 12 } }}                               
                    //variant="outlined"                                  
                />    
                  </Grid>                              
                  <Grid item xs={4} >
          <TextField

            id={"dialog-question-" + props.idx + "menu-item-type" + midex}
           // label="Тип"
            value={mi.cmd_type}
            onChange={event => onChangeQuestionMenuItemType(midex, event.target.value)}
            placeholder="Введите тип команды"
            helperText=""
            fullWidth
            select
            InputProps={{style: {fontSize: 12}}}   
            InputLabelProps={{ style: { fontSize: 12 } }}   
          //variant="outlined"                                  
          >
                            <MenuItem style={{ fontSize: 12 }} key="next_step" value="next_step">
                                Продолжить текущий диалог
                                </MenuItem>
                                <MenuItem style={{ fontSize: 12 }}  key="next_dialog" value="next_dialog">
                                Перейти в другой диалог
                                </MenuItem>
                                <MenuItem style={{ fontSize: 12 }}  key="next_question" value="next_question">
                                Перейти к другому вопросу
                                </MenuItem>                                
                                <MenuItem style={{ fontSize: 12 }}  key="end_dialog" value="end_dialog">
                                Завершить текущий диалог
                                </MenuItem>
                                <MenuItem style={{ fontSize: 12 }}  key="repeate_dialog" value="repeate_dialog">
                                Повторить текущий диалог
                                </MenuItem>              
          </TextField>
                    </Grid>                      
                    <Grid item xs={3} >
                      {mi.cmd_type ==="next_dialog" && 
          <TextField

          id={"dialog-question-" + props.idx + "menu-item-params" + midex}
         // label="Следующий диалог"
          value={mi.cmd_param}
          onChange={event => onChangeQuestionMenuItemParam(midex,event.target.value)}
          placeholder="Введите тип команды"
          helperText=""
          fullWidth
          select
          InputProps={{style: {fontSize: 12}}}   
          InputLabelProps={{ style: { fontSize: 12 } }}   
        //variant="outlined"                                  
        >      
                            { props.dialogs &&  props.dialogs.map(dialog => (
                            <MenuItem style={{ fontSize: 12 }}  key={dialog._id} value={dialog._id}>
                              {dialog.name}
                            </MenuItem>
                            )

                            ) }             
        </TextField>                      
                      }
                      {mi.cmd_type ==="next_question" && 
          <TextField
          id={"dialog-question-" + props.idx + "menu-item-params" + midex}
         // label="Следующий диалог"
          value={mi.cmd_param}
          onChange={event => onChangeQuestionNextQuestionParam(midex, event.target.value)}
          placeholder="Номер вопроса"
          helperText=""
          fullWidth   
          InputProps={{style: {fontSize: 12}}}   
          InputLabelProps={{ style: { fontSize: 12 } }}                          
        />}                      
                      </Grid> 
                      <Grid item xs={1} style={{textAlign:"right"}} >
                        <IconButton size="small" onClick={e=>onDeleteQuestionMenuItem(midex)}>
                          <HighlightOffIcon fontSize='small'/>
                        </IconButton>
                        </Grid>   

    </Grid>
    ))}
    <Grid container justify="center" alignItems="flex-end">
    </Grid>
    <Grid container justify="center" alignItems="flex-end">
      <Button onClick={e=>onAddQuestionMenuItem()} size="small" color="secondary">
        Добавить пункт меню
      </Button>
    </Grid>
  </Paper>
}

            {props.question.intent === "__exly_child_dialog" &&
              <Paper className={classes.question_menu}>
                <TextField
                  id={"dialog-question-" + props.idx + "-child_dialog"}
                  //label="Запустить дочерний диалог"
                  value={props.question.child_dialog}
                  onChange={onChangeQuestionChildDialog}
                  placeholder="Выберите диалог"
                  helperText=""
                  fullWidth
                  select
                //variant="outlined"                                  
                >
                  {props.dialogs && props.dialogs.map(dialog => (
                    <MenuItem style={{ fontSize: 12 }} key={dialog._id} value={dialog._id}>
                      {dialog.name}
                    </MenuItem>
                  )

                  )}
                </TextField>
              </Paper>
            }

{props.editingQuestionIdx===  props.idx  &&  answerType==="text" &&
            <div>
            <FormGroup row>
            <FormControlLabel
              control={<Switch size="small" checked={props.question.has_phone_button} onChange={toggleHasPhoneButton} />}
              label = "Кнопка отправки телефона"
              labelPlacement = "end"
            />
              {props.question.has_phone_button &&
              <TextField
                id={"dialog-question-" + props.idx + "_phone_button_text"}
                value={props.question.phone_button_text}
                onChange={onChangeQuestionPhoneButtonText}                
                helperText="текст на кнопке"
              //variant="outlined"                                  
              ></TextField>
              }
            </FormGroup>

          </div>  
}

{props.editingQuestionIdx===  props.idx  &&   questionSelectedType 
&& props.question.intent !== "__exly_child_dialog" 
&& !props.question.is_formula 
&& !props.question.is_excel_formula 
&& <div>
<TextField
id={"dialog-question-" + props.idx + "_list_style"}
label="Представление списка"
value={props.question.list_style}
onChange={onChangeQuestionListStyle}
//placeholder="Введите тип команды"
helperText=""
fullWidth
select
//variant="outlined"                                  
>
                <MenuItem key="auto" value="auto">
                    Автоматическое определение
                    </MenuItem>
                    <MenuItem key="inline" value="inline">
                    Внутри сообщения
                    </MenuItem>
                    <MenuItem key="list" value="list">
                    Списком
                    </MenuItem>
                    <MenuItem key="suggestedAction" value="suggestedAction">
                    Клавиатурные кнопки
                    </MenuItem> 
                    <MenuItem key="heroCard" value="heroCard">
                      Кнопки под сообщением
                    </MenuItem>                                  
</TextField>  
              </div>}   



              {props.editingQuestionIdx===  props.idx  &&   <div>
              <FormGroup row>
              <FormControlLabel
                control={<Switch size="small" checked={props.question.typing} onChange={toggleTyping} />}
                label = "Задержка перед отправкой (мс)"
                labelPlacement = "end"
              />

                <TextField
                  id={"dialog-question-" + props.idx + "_delay"}
                  value={props.question.delay}
                  onChange={onChangeQuestionDelay}
                  type="number"
                  helperText="длительностью (мс)"
                //variant="outlined"                                  
                ></TextField>
              </FormGroup>

            </div> }
            {props.editingQuestionIdx===  props.idx  ?    <div>
                <FormGroup>
  <FormControlLabel
    control={<Switch size="small" checked={props.question.hasCondition} onChange={toggleHasCondition} />}
    label="Выполнять при соблюдении условий"
  />
</FormGroup>      
         
                { props.question.hasCondition &&  <Query
            {...props.config} 
            value={condition}//QbUtils.checkTree(QbUtils.loadTree(props.question.condition?props.question.condition:{"id": QbUtils.uuid(), "type": "group"}), props.config)}
            onChange={onChange}
            renderBuilder={renderBuilder}
        />}
        </div>: <div> {props.question.hasCondition && <Alert variant="outlined" severity="warning">
        Выводится при соблюдении условия
</Alert> }  </div> }                  
        </Paper>

        </React.Fragment>        
    )
}

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
      <div
        role="tabpanel"      
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Paper className={classes.paper}>
            <Typography>{children}</Typography>
          </Paper>
        )}
      </div>
    );
  }

  function AddQuestionForCreateItemButton(props) {
    //const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
  
    const classes = useStyles(); 
  
    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  
  
    const open = Boolean(anchorEl);
    const id = open ? 'AddValueForCreateItemButton-popover' : undefined;
  
    return (
      <React.Fragment>
        
        <Tooltip title="Выбрать поле для установки значения">
            <Button size="small" variant="text" color="primary" onClick={handleClickOpen} startIcon={<AddCircleOutlineIcon /> }>            
              Добавить вопрос
        </Button>   
        </Tooltip>     
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper >
        <List  dense={true}>
        {props.columns.map((column, idx)=>(
        <ListItem
        button
          onClick={e=> {setAnchorEl(null); props.onAddQuestionWithIntent(column._id, "Введите "+column.name)}}
      >
        <ListItemIcon>
        {column.type==='__exly_dialog_menu' && <MenuIcon fontSize="small" color="disabled"/>}
                {column.type==='__exly_child_dialog' && <QuestionAnswerIcon fontSize="small" color="disabled"/>}              
                {column.type==='__exly_dialog_subdialog' && <ChatIcon fontSize="small" color="disabled"/>}
        {column.type==='text' && <TextFieldsIcon fontSize="small" color="disabled"/>}
        {column.type==='title' && <TextFieldsIcon fontSize="small" color="disabled"/>}
      {column.type==='number' && <Looks3Icon fontSize="small"  color="disabled"/>}
      {column.type==='date' && <EventIcon fontSize="small"  color="disabled"/>}
      {column.type==='select' && <FilterListIcon fontSize="small"  color="disabled"/>}
      {column.type==='multiline' && <SubjectIcon fontSize="small"  color="disabled"/>}
      {column.type==='member' && <PersonIcon fontSize="small"  color="disabled" />}
      {column.type==='chatmember' && <ChatMemberIcon fontSize="small"  color="disabled" />}
      {column.type==='link' && <LinkIcon fontSize="small"  color="disabled" />}
      {column.type==='geolocation' && <LocationOnIcon fontSize="small"  color="disabled" />}                        
      {column.type==='attachments' && <AttachFileIcon fontSize="small"  color="disabled" />}                        
      {column.type==='check' && <CheckBoxIcon fontSize="small"  color="disabled"/>}
      {column.type==='lookup' && <LookupIcon fontSize="small"  color="disabled"/>}
      {(!column.type || column.type==='') && <ChatIcon fontSize="small" color="disabled"/>}
        </ListItemIcon>
        <ListItemText id={"switch-column-label-"+idx} primary={column.name} /> 
        </ListItem>           
        ))}
  
        </List>        
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  }    



export default function DialogEditorForPanel(props) {
    const {token} = useContext(AuthContext);
    const {request} = useHttp();
    
    //const unblockHandle = useRef();
    //const [isShowSaving, setShowSaving] = useState(false)
    //const [nextPage, setNextPage] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null);
    const [anchorElColEdit, setAnchorElColEdit] = React.useState(null);
    const [editingSets, setEditingSets] = React.useState(null);    
    const anchorRef = React.useRef(null);
    //const [config, setConfig] = useState({}); 
    const [fields, setFields] = useState({});
    //const [workitemlist, setWorkItemList] = useState(null);
    const [editedColumns, setEditedColumns] = useState([]);  
    const [columns, setColumns] = useState([]);  
    const [editedColumnsInQuestions, setEditedColumnsInQuestions] = useState({}); 
    const [loadingDialog, setLoadingDialog] = useState(false);
    //const history = useHistory();
    //const [respondents, setRespondents] = useState([]);
    //const [chatgroups, setChatgroups] = useState([]);
    const [editingQuestionIdx, setEditingQuestionIdx] = useState(-1);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [goodbyeMessage, setGoodbyeMessage] = useState('');
    const [cancelKeywords, setCancelKeywords] = useState('');
    const [chatKeywords, setChatKeywords] = useState('');
    const [cancelMessage, setCancelMessage] = useState('');
    const [helpKeywords, setHelpKeywords] = useState('');
    const [helpMessage, setHelpMessage] = useState('');
    const [config, setConfig] = useState({}); 
    const [emptyMessage, setEmptyMessage] = useState('');
    const [selectItemMessage, setSelectItemMessage] = useState('');
    const [dialog, setDialog] = useState(null);
    const [title, setTitle] = useState("");
    
    const [dialogEdited, setDialogEdited] = useState(false);
    const [titleEdited, setTitleEdited] = useState(false);
    const [dialogMessageTypeList, setDialogMessageTypeList] = useState([]);
    //const wiId = useParams().wi;
    //const dialogId = useParams().dialogid;
    const classes = useStyles();
    //const theme = useTheme();
    const [tab, setTab] = React.useState(0);
    const [tabIdx, setTabIdx] = React.useState({schedule:2, dialog:0, delivery:1});


    /*
     const init = useCallback(async()=>{
        try {
            //setLoadingDialog(true);         
             //var data_respondents = await request('/api/respondent','GET',null, {Authorization: `Bearer ${token}`});
             // setRespondents(data_respondents.map(r => {return {value: r.channelId, label: r.name}}));                                              
              var data_chatgroups = await request('/api/chatgroup','GET',null, {Authorization: `Bearer ${token}`});
              console.log('getchatgroups data', data_chatgroups); 
              setChatgroups(data_chatgroups);
              setRespondents(props.respondents);
              //if(props.isTemplate){
                var jb = props.dialog;
              //} else {              
              //var data = await request(`/api/dialog/${wiId}/${dialogId}`,'GET',null, {Authorization: `Bearer ${token}`});             
              //console.log('getDialog data', data);  
              //}
            if(jb.type==='send_report'){
                setTabIdx({schedule:0, delivery:1})
            } else {
                setTabIdx({ schedule:0, dialog:1, delivery:2})
                    //var workitems = await request(`/api/workitemlist/${data.work_item_list}`,'GET',null, {Authorization: `Bearer ${token}`});
                    //console.log('getDialog workitems', workitems);                      
                    //if(workitems.columns){
                    //    for(var i=0; i<workitems.columns.length; i++){
                    //        if(workitems.columns[i].type ===   "assigned"){
                    //            workitems.columns[i].lookup = Object.assign({}, ...data_respondents.map(item => ({ [item.channelId]: item.name })));
                    //        }
                    //    }
                    //} 
                    setColumns(props.columns);   
                    var resp_cols = [];
                    for(let ic=0; ic<props.columns.length; ic++){
                      if(props.columns[ic].type==='assigned'){
                        resp_cols.push(props.columns[ic]);
                      }
                    }
                    setRespondentColumns(resp_cols);   
                    setWITitle(props.title)               
            }
            setDialog(jb);          
            setLoadingDialog(false);
            //setDialogEdited(false);
        } catch (e) {
            console.log('getRespondents error', e); 
            setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
        }
    },[token, props.columns, props.dialog, props.respondents, props.title, request])    
*/

    /*
    const saveDialog = useCallback(async()=>{
        try {
            setLoadingDialog(true);
            console.log('saveDialog dialog', dialog);    
            var req_dialog = await request(`/api/dialog/${wiId}/${dialogId}`,'POST',dialog, {Authorization: `Bearer ${token}`});
            console.log('saveDialog data', req_dialog);
            setDialog(req_dialog);   
            setLoadingDialog(false);      
            setDialogEdited(false);
            //history.push(`/dialogs/${groupId}`);                
        } catch (e) {
            console.log('getDialog error', e); 
            setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
        }
    },[token, wiId, dialog, request]) 
    */

  //  const runDialog = useCallback(async()=>{
  //    try {
  //        props.runDialog(dialog._id);
            //var data = await request(`/api/dialog/${wiId}/${dialogId}/run`,'GET',null, {Authorization: `Bearer ${token}`});
  //          init(); 
  //    } catch (e) {
  //        console.log('runDialog error', e);
  //        setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...') 
  //    }
  //},[token, dialog, request])

  //const stopDialog = useCallback(async()=>{
  //  try {
        //var data = await request(`/api/dialog/${wiId}/${dialogId}/stop`,'GET',null, {Authorization: `Bearer ${token}`});
        //console.log('stopDialog data', data); 
  //      props.stopDialog(dialog._id); 
  //      init();
                  
  //  } catch (e) {
  //      console.log('getDialogList error', e); 
  //      setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
  //  }
//},[token, dialog, props, request, init])   

const onAddColumn = () =>{
  //setAnchorElColEdit(anchorRef.current)
  var rect = anchorRef.current.getBoundingClientRect();
  setAnchorElColEdit({ top: rect.top, left: rect.left })   
  setEditingSets({idx:props.columns.length, isNew:true})         
}

const onNewColumnCallback = (newCol) =>{
  //props.onChangeQuestionIntent(props.idx, newCol._id); 
  //setOpen(false);      
}



//console.log('Апдейт!!! ') 

 
/*
    useEffect(()=>{
        init();              
    }, [init])
    useEffect(()=>{
      if(dialogEdited){
      unblockHandle.current = history.block((targetLocation) => {
        // take some custom action here
        // i chose to show my custom modal warning user froim going back
        // rather than relying on the browser's alert
        console.log('unblockHandle ', targetLocation)      
        setNextPage(targetLocation.pathname);
        setShowSaving(true);
        return false;
      });
      return function () {
        unblockHandle.current.current && unblockHandle.current.current()
      }
      } else if (unblockHandle && unblockHandle.current){
        unblockHandle.current();
        setShowSaving(false);
      }
    }, [dialogEdited])    

    const handleSavingClose = () => {
      setShowSaving(false);
      if (unblockHandle) {
        unblockHandle.current()
      }            
      if(nextPage){
        history.push(nextPage) 
      }          
        history.push(`/reports/${wiId}`);
      };

      const handleClose = () => {      
          history.push(`/reports/${wiId}`);
        };      

        const handleSavingSave = () => {
          if (unblockHandle) {
            unblockHandle.current()
          }        
          saveDialog(); 
          if(nextPage){
            history.push(nextPage) 
          }        
        };   


*/    


    //const onChangeDialogName = (event) => {
    //    const v = event.target.value;
    //    props.onChangeDialog('name', v)
        //setDialog((prevDialog) =>{
        //  var newDialog =  {...prevDialog};
        //  newDialog.name = v;     
        //  return newDialog;
        //}); 
    //    setDialogEdited(true);         
    //}

    const onChangeDialog = (prop, value) => {
      const pr = prop;
      const v =value;
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js[pr] = v;
        return js;
      })  
      setDialogEdited(true)       
    }    
    
    const onChangeDialogQuestionMenuItem = (qidx, idx, prop, value) => {
      const pr = prop;
      const v =value;
      const iq = qidx;
      const ii = idx;
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js.questions[iq]["menu_items"][ii][pr] = v;
        return js;
      })   
      setDialogEdited(true)   
    }       
    
    const onAddQuestionMenuItem = (qidx, newItem) =>{
      const iq = qidx;
      const ni = newItem
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        if(!js.questions[iq]["menu_items"]){
          js.questions[iq]["menu_items"] = []
        }
        js.questions[iq]["menu_items"].push(ni);
        return js;
      })   
      setDialogEdited(true)       
    }

    const onDeleteQuestionMenuItem = (qidx, item_index) =>{
      const iq = qidx;
      const ni = item_index
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        if(js.questions[iq]["menu_items"]){
          js.questions[iq]["menu_items"].splice(item_index,1); 
        }
        return js;
      })   
      setDialogEdited(true)       
    }    

    const onChangeDialogQuestion = (qidx, prop, value) => {
      const pr = prop;
      const v =value;
      const iq = qidx;
      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js.questions[iq][pr] = v;
        return js;
      })   
      setDialogEdited(true)   
    }       

    const onChangeQuestions = (newQuestions) => {
      const qs = newQuestions;

      setDialog(prevDialog =>{
        var js = {...prevDialog};
        js.questions = newQuestions;
        return js;
      })   
      setDialogEdited(true)      
    }     
      
    const onChangeWelcomeMessage = (event) => {
        const v = event.target.value;
        setWelcomeMessage(v)
        setDialogEdited(true);           
    }   

    const onSaveWelcomeMessage = () => {
      //const v = event.target.value;
      onChangeDialog('welcome_message', welcomeMessage)
      //setDialogEdited(true);          
  }    
  
const onChangeSelectItemViewField = (event) => {
  const v = event.target.value;
  onChangeDialog('select_item_view_field', v)
  //setDialogEdited(true);          
}

const onChangeDontInterruptCurrentDialogue = (event) => {
  const v = event.target.checked;
  onChangeDialog('dont_interrupt_current_dialogue', v)
  //setDialogEdited(true);          
}     

   

    const onChangeQuestionText = (idx, newVal) => {
        const qidx = idx;
        const v = newVal
        onChangeDialogQuestion(qidx, 'question_text', v)
        //setDialogEdited(true); 
    }
    
    const onChangeQuestionCondition = (idx, newVal) => {
        const qidx = idx;
        const v = newVal
        onChangeDialogQuestion(qidx, 'condition', v)
        //setDialogEdited(true); 
    }

    const onChangeQuestionToggleHasCondition = (idx, v) =>{
      onChangeDialogQuestion(idx, 'hasCondition', v)
    }

    const onChangeQuestionToggleTyping = (idx, v) =>{
      onChangeDialogQuestion(idx, 'typing', v)
    } 
    
    const onChangeQuestionToggleHasPhoneButton = (idx, v) =>{
      onChangeDialogQuestion(idx, 'has_phone_button', v)
    }     

        

    const onChangeQuestionToggleIsFormula = (idx, v) =>{
      onChangeDialogQuestion(idx, 'is_excel_formula', v)
    }    

    const onChangeQuestionToggleIsJSFormula = (idx, v) =>{
      onChangeDialogQuestion(idx, 'is_formula', v)
    }    
    
    
    
    const onChangeQuestionAnswerType = (idx, newVal) => {
        const qidx = idx;
        const v = newVal
        onChangeDialogQuestion(qidx, 'answer_type', v)
          //setDialogEdited(true); 
    }    

    const onChangeQuestionListStyle = (idx, newVal) => {
      const qidx = idx;
      const v = newVal
      onChangeDialogQuestion(qidx, 'list_style', v)
        //setDialogEdited(true); 
  }   

  const onChangeQuestionDelay = (idx, newVal) => {
    const qidx = idx;
    const v = newVal
    onChangeDialogQuestion(qidx, 'delay', v)
      //setDialogEdited(true); 
}     

const onChangeQuestionPhoneButtonText = (idx, newVal) => {
  const qidx = idx;
  const v = newVal
  onChangeDialogQuestion(qidx, 'phone_button_text', v)
    //setDialogEdited(true); 
}    

const onChangeQuestionChildDialog = (idx, newVal) => {
  const qidx = idx;
  const v = newVal
  onChangeDialogQuestion(qidx, 'child_dialog', v)
    //setDialogEdited(true); 
}    



  const onChangeQuestionSendField = (idx, newVal) => {
    const qidx = idx;
    const v = newVal
    onChangeDialogQuestion(qidx, 'send_field', v)
      //setDialogEdited(true); 
}     
  
  


    const onDeleteQuestion = (idx) => {

        const newQuestions = [...dialog.questions];
        newQuestions.splice(idx,1); 
        onChangeQuestions(newQuestions)

          //setDialogEdited(true); 
    }     
 
    const onAddQuestion = () => {  
        const newQuestions = [...dialog.questions];
        newQuestions.push({
          "answer_choice": [],
          "question_text": "",
          "intent": "",
          "answer_type": "text",
          "condition": ""
        }); 
        onChangeQuestions(newQuestions)
        setEditingQuestionIdx(newQuestions.length-1)
        //setDialogEdited(true); 
    }    
    
    const onAddQuestionWithIntent = (intent, question_text) => {  
      const newQuestions = [...dialog.questions];           
      var menu_items = [] 
      if(intent==="__exly_dialog_menu"){        
        question_text =  "Выберите пункт меню"
        menu_items = [
          {
            cmd:"Команда 1",
            cmd_type:"next_step",
            cmd_param:null
          },
          {
            cmd:"Команда 2",
            cmd_type:"end_dialog",
            cmd_param:null
          }, 
          {
            cmd:"Команда 3",
            cmd_type:"end_dialog",
            cmd_param:null
          },                               
        ]        
      }   else if(intent==="") {
        question_text =  "Сообщение"
      }        
      newQuestions.push({
        "answer_choice": [],
        "question_text": question_text,
        "intent": intent,
        "answer_type": "text",
        "condition": "",
        "menu_items":menu_items
      }); 
      onChangeQuestions(newQuestions)
      setEditingQuestionIdx(newQuestions.length-1)
      //setDialogEdited(true); 
  }       
 
    const handleAddAnswerChoice = () => {
        //setDialog((prevDialog) =>{
        //    var newDialog =  {...prevDialog};            
        //    newDialog.questions[qidx].answer_choice.push(v);    
        //    return newDialog;
        //  }); 
          //setDialogEdited(true); 
    } 
    
    const handleDeleteAnswerChoice = () => {
        //const qidx = idx;
        //const vidx = idx2
        //setDialog((prevDialog) =>{
        //    var newDialog =  {...prevDialog};           
        //    newDialog.questions[qidx].answer_choice.splice(vidx,1);    
        //    return newDialog;
        //  }); 
          //setDialogEdited(true); 
    }   
    const onChangeQuestionIntent = (idx, newVal) => {
        const v = newVal;
        const qidx = idx;  
        onChangeDialogQuestion(qidx, 'intent', v)
    
    }    

    const onChangeQuestionMenuItem = (qidx, idx, value)=>{
      onChangeDialogQuestionMenuItem(qidx, idx, 'cmd', value)  
    }

    const onChangeQuestionMenuItemType = (qidx, idx, value)=>{
      onChangeDialogQuestionMenuItem(qidx, idx, 'cmd_type', value)  
    }
    
    const onChangeQuestionMenuItemParam = (qidx, idx, value)=>{
      onChangeDialogQuestionMenuItem(qidx, idx, 'cmd_param', value)  
    }    



const onChangeEmptyMessage = (event) => {
  const v = event.target.value;
  setEmptyMessage(v)
  setDialogEdited(true);          
}   

const onSaveEmptyMessage = () => {
//const v = event.target.value;
onChangeDialog('empty_message', emptyMessage)
setDialogEdited(true);          
}    

    const onChangeGoodbyMessage = (event) => {
      const v = event.target.value;
      setGoodbyeMessage(v)
      setDialogEdited(true);          
  }   

  const onSaveGoodbyMessage = () => {
    //const v = event.target.value;
    onChangeDialog('goodbye_message', goodbyeMessage)
    setDialogEdited(true);          
}      

const onChangeCancelKeywords = (event) => {
  const v = event.target.value;
  setCancelKeywords(v)
  setDialogEdited(true);          
}   

const onSaveCancelKeywords = () => {
//const v = event.target.value;
onChangeDialog('cancel_keywords', cancelKeywords)
  setDialogEdited(true);          
}    

const onChangeChatKeywords = (event) => {
  const v = event.target.value;
  setChatKeywords(v)
  setDialogEdited(true);          
}   

const onSaveChatKeywords = () => {
//const v = event.target.value;
onChangeDialog('chat_keywords', chatKeywords?(chatKeywords.split(/\r?\n/)):[])
setDialogEdited(true);          
}

const onChangeTitle = (event) => {
  const v = event.target.value;
  setTitle(v)
  setDialogEdited(true);    
  setTitleEdited(true)      
}   


const onChangeCancelMessage = (event) => {
  const v = event.target.value;
  setCancelMessage(v)
  setDialogEdited(true);          
}   

const onSaveCancelMessage = () => {
//const v = event.target.value;
onChangeDialog('cancel_message', cancelMessage)
setDialogEdited(true);          
} 

const onChangeHelpKeywords = (event) => {
  const v = event.target.value;
  setHelpKeywords(v)
  setDialogEdited(true);          
}   

const onSaveHelpKeywords = () => {
//const v = event.target.value;
onChangeDialog('help_keywords', helpKeywords)
setDialogEdited(true);          
}     

const onChangeHelpMessage = (event) => {
  const v = event.target.value;
  setHelpMessage(v)
  setDialogEdited(true);          
}   

const onSaveHelpMessage = () => {
//const v = event.target.value;
onChangeDialog('help_message', helpMessage)
setDialogEdited(true);          
} 

    const onChangeTimeoutMessage = (event) => {
      const v = event.target.value;
      onChangeDialog('timeout_message', v)      
      //setDialogEdited(true);          
  }   
    

  const handleSave = ()=> {
     
    if(dialogEdited){
      props.saveDialog({
        ...dialog, 
        name:title, 
        welcome_message:welcomeMessage, 
        goodbye_message:goodbyeMessage, 
        empty_message:emptyMessage,  
        chat_keywords: chatKeywords?(chatKeywords.split(/\r?\n/)):[],
        cancel_keywords: cancelKeywords,
        cancel_message: cancelMessage,
        help_keywords: helpKeywords,
        help_message: helpMessage
      })
     }
    props.setIsEditedJobOpen(null)
    props.setEditedDialogTableId(null)
    props.setEditedDialogId(null)
  }

  const handleDel = ()=> {
    props.deleteDialog(props.dialogId);
    props.setIsEditedJobOpen(null)
    props.setEditedDialogTableId(null)
    props.setEditedDialogId(null)
  }

  const handleClose = ()=> {
    props.setIsEditedJobOpen(null)
    props.setEditedDialogTableId(null)
    props.setEditedDialogId(null)
  }

    const onDragEndQuestion = (result) => {
      //console.log('onDragEndQuestion ', result.source, result.destination)
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const sourceIndex = result.source.index;
      const targetIndex = result.destination.index;      
      if(result.source.droppableId === "dialog_columns_droppable" && result.destination.droppableId === "questions_droppable")
      {
        //т.е. перетащили колонку из списка в список вопросов.
        //добавим вопрос
        var sourseColumn = editedColumns[sourceIndex]
        console.log('onDragEndQuestion sourseColumn ', sourseColumn, editedColumns)
        if(sourseColumn && sourseColumn.name ){  
          var question_text =  "Введите "+sourseColumn.name  
          var menu_items = []
          if(sourseColumn.type==="__exly_dialog_menu"){
            question_text =  "Выберите пункт меню"
            menu_items = [
              {
                cmd:"Команда 1",
                cmd_type:"next_step",
                cmd_param:null
              },
              {
                cmd:"Команда 2",
                cmd_type:"end_dialog",
                cmd_param:null
              }, 
              {
                cmd:"Команда 3",
                cmd_type:"end_dialog",
                cmd_param:null
              },                               
            ]
          }   else if(sourseColumn.type==="") {
            question_text =  "Сообщение"
          }  
        const newQuestion = {
          "answer_choice": [],
          "question_text": question_text,
          "intent": sourseColumn._id,
          "answer_type": "text",
          "menu_items": menu_items,
          "condition": ""
        }

        var _questions = [...dialog.questions];

        _questions.splice(
          targetIndex,
          0,
          newQuestion
        ); 
        
        setEditingQuestionIdx(targetIndex)
        onChangeQuestions(_questions)  
        }

      } else if(result.source.droppableId === "dialog_columns_droppable" && result.destination.droppableId === "dialog_columns_droppable")
      {
        //передвигаем колонки между собой

      } else if(result.source.droppableId === "questions_droppable" && result.destination.droppableId === "questions_droppable")
      {
        //передвигаем вопросы между собой
        var _questions = [...dialog.questions];


        _questions.splice(
          targetIndex,
          0,
          _questions.splice(sourceIndex, 1)[0]
        ); 
        if(editingQuestionIdx === sourceIndex )   {
          setEditingQuestionIdx(targetIndex)
        }
        onChangeQuestions(_questions)   
      }
    }    

    const setUnEditedQuestion = (e) =>{

        setEditingQuestionIdx(-1)
        //console.log('inputRef ', inputRef)
      
    }     

    function DialogText({ dialog, dbId, tables }) {
      const history = useHistory();    
      const onClick = e =>{
        //console.log(`DialogText  onClick /db/${dbId}/${dialogView.work_item_list}/${dialogView.filter}`)
        if(dialog && dialog.work_item_list && dialog.filter){
          
          history.push(`/db/${dbId}/${dialog.work_item_list}/${dialog.filter}`)
          e.stopPropagation();
        }
        
      }
      
      if ( dialog && dbId && dialog.work_item_list && tables && tables[dialog.work_item_list]) {
        var dialog = dialog
        if (dialog.type === "simple") {
          return (
    
            <span> <Button size="small" variant='text' color="primary">{tables[dialog.work_item_list].title}</Button></span>
    
          );
        } else if (dialog.type === "standup" && dialog.filter && tables[dialog.work_item_list].views && tables[dialog.work_item_list].views[dialog.filter]) {
          return (
    
            <span> <Button size="small" variant='text' onClick={onClick} color="primary">{tables[dialog.work_item_list].title}/{tables[dialog.work_item_list].views[dialog.filter].title}</Button></span>
    
          );
        } else if (dialog.type === "update_item_request" && dialog.filter && tables[dialog.work_item_list].views && tables[dialog.work_item_list].views[dialog.filter]) {
          return (
    
            <span><Button size="small" variant='text' onClick={onClick}   color="primary">{tables[dialog.work_item_list].title}/{tables[dialog.work_item_list].views[dialog.filter].title}</Button></span>
    
          );
        } else if (dialog.type === "update_request" && dialog.filter && tables[dialog.work_item_list].views && tables[dialog.work_item_list].views[dialog.filter]) {
          return (
    
            <span><Button size="small" variant='text' onClick={onClick}  color="primary">{tables[dialog.work_item_list].title}/{tables[dialog.work_item_list].views[dialog.filter].title}</Button></span>
    
          );
        }
      }
      
      return ""
      
    
    }

    useEffect(() => {
      let _fields = {}
      //console.log('useEffect dialogEditorForPanel ', props.dialogId, props.dialogTableId)

      if(dialog){


      var edited_cols = []
        var __columns = (dialog.work_item_list && props.tables[dialog.work_item_list])?props.tables[dialog.work_item_list].columns:[]
        setColumns(__columns)
      for (let ic = 0; ic < __columns.length; ic++) {
        if(__columns[ic].type!=='lookup'){ 
          if(__columns[ic]._id==="ex_rAnswered" ||
          __columns[ic]._id==="ex_requestDt" ||
          __columns[ic]._id==="ex_rAnswerDt" ||
          __columns[ic]._id==="ex_updatedAt" ||
          __columns[ic]._id==="ex_createdAt" ||
          __columns[ic]._id==="ex_changeAutor" ||  
          __columns[ic]._id==="ex_createdAutor" ||                   
          __columns[ic]._id==="ex_requestAc" ||
          __columns[ic]._id==="_id"
          ){
  
          } else {                     
            edited_cols.push(__columns[ic])
          }
        }
        _fields[__columns[ic]._id] = {
          label: __columns[ic].name,
          type: __columns[ic].type,
  
        }
        if (__columns[ic].type === 'select') {
          _fields[__columns[ic]._id].valueSources = ['value'];
          _fields[__columns[ic]._id].fieldSettings = {
            listValues: __columns[ic].options.map(opt => { return { value: opt.value, title: opt.label } }),
          }
        } else if (__columns[ic].type === 'check') {
          _fields[__columns[ic]._id].type = 'boolean'
        } else if (__columns[ic].type === 'link') {
          if (props.tables[__columns[ic].linkId] && props.tables[__columns[ic].linkId].columns) {
            var titleColId = __columns[ic].linkViewField//?props.columns[ic].linkViewField:props.tables[props.columns[ic].linkId].columns.find(cl=> cl.type === 'title')
            if (titleColId) {
              var tableValues = props.tables[__columns[ic].linkId].itemsList ? props.tables[__columns[ic].linkId].itemsList : []
              //console.log('filter tableValues', tableValues, titleColId)
              _fields[__columns[ic]._id].type = 'select'
              _fields[__columns[ic]._id].valueSources = ['value'];
              _fields[__columns[ic]._id].fieldSettings = {
  
                listValues: tableValues.map(opt => { return { value: opt._id, title: opt[titleColId] } }),
              }
            }
          }
        } else if (__columns[ic].type === 'member') {
          _fields[__columns[ic]._id].type = 'select'
          _fields[__columns[ic]._id].valueSources = ['value'];
          _fields[__columns[ic]._id].fieldSettings = {
            listValues: props.members.map(opt => { return { value: opt._id, title: opt.name ? opt.name : opt.email } }),
          }
        } else if (__columns[ic].type === 'chatmember') {
          _fields[__columns[ic]._id].type = 'select'
          _fields[__columns[ic]._id].valueSources = ['value'];
          _fields[__columns[ic]._id].fieldSettings = {
            listValues: props.chatmembers.map(opt => { return { value: opt._id, title: opt.first_name ? (opt.first_name + (opt.last_name ? (" " + opt.last_name) : "")) : opt.name } }),
          }
        } else if (__columns[ic].type === 'date' || __columns[ic].type === 'datetime') {
          _fields[__columns[ic]._id].funcs = ['TODAY']
          _fields[__columns[ic]._id].valueSources = ["value", "field", "func"]
        } else if (__columns[ic].type === 'title') {
          _fields[__columns[ic]._id].type = 'text'
        } else if (__columns[ic].type === 'multiline') {
          _fields[__columns[ic]._id].type = 'text'
        }
      }
 
      var _colunms = [
        {_id:"", type:"" , name: "Простое сообщение"}, 
        {_id:"__exly_dialog_menu", type:"__exly_dialog_menu", name: "МЕНЮ"},
        {_id:"__exly_child_dialog", type:"__exly_child_dialog", name: "Дочерний диалог"},
        //{_id:"__exly_dialog_subdialog", type:"__exly_dialog_subdialog" , name: "Переход в другой диалог"}      
      ]
      var _colunmsInQuestions = {}
      if(edited_cols && dialog.type!=="simple"){
        _colunms = [..._colunms, ...edited_cols]
        if(dialog && dialog.questions){          
          for(var iqx=0; iqx<dialog.questions.length; iqx++){
            if(dialog.questions[iqx].intent){
              const intent = dialog.questions[iqx].intent
              var icx = _colunms.findIndex(cl=> cl._id===intent)
              if(icx>=0){
                _colunmsInQuestions[_colunms[icx]._id] = iqx
              }              
            }
          }
        }
      }
      setEditedColumns(_colunms)
      setEditedColumnsInQuestions(_colunmsInQuestions)
 
    } else {
      setEditedColumns([])
      setEditedColumnsInQuestions([])
    }
      //setFields(_fields);
      var _funcs = {
        CurrentMember: {
          label: "@Текущий_пользователь",
          returnType: "string",
          mongoFunc: "$toLower",
          jsonLogic: ({ str }) => ({ "method": [str, "toLowerCase"] }),
          returnType: "text",
          args: {
            str: {
              label: "String",
              type: "text",
              valueSources: ["value", "field"],
            },
          }
        },
        ABCD: {
          label: "ABCD",
          returnType: "string",
          mongoFunc: "$toLower",
          jsonLogic: ({ str }) => ({ "method": [str, "toLowerCase"] }),
          returnType: "text",
          args: {
            str: {
              label: "String",
              type: "text",
              valueSources: ["value", "field"],
            },
          }
        },
        TODAY: {
          label: "Today",
          returnType: "date",
          //formatFunc: ({}, _) => `new Date()`,
          //sqlFormatFunc: ({}) => `new Date()`,
          //mongoFunc: `new Date()`,
          //jsonLogic: ({coef, bias, val}) => ({ "+": [ {"*": [coef, val]}, bias ] }),
          mongoFormatFunc: ({ }) => ("new Date()"),
          //mongoFunc: "$toLower",
          //jsonLogic: ({str}) => ({ "method": [ str, "toLowerCase" ] }),   
          //mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0))).toISOString(), '$lt':(new Date((new Date()).setHours(23,59,59))).toISOString() }}),                 
          args: {
            //str: {
            //  label: "String",
            //  type: "text",
            //  valueSources: ["value", "field"],
            //},
          }
        },
        LOWER: {
          label: "Lowercase",
          mongoFunc: "$toLower",
          jsonLogic: ({ str }) => ({ "method": [str, "toLowerCase"] }),
          returnType: "text",
          args: {
            str: {
              label: "String",
              type: "text",
              valueSources: ["value", "field"],
            },
          }
        },
        LINEAR_REGRESSION: {
          label: "Linear regression",
          returnType: "number",
          formatFunc: ({ coef, bias, val }, _) => `(${coef} * ${val} + ${bias})`,
          sqlFormatFunc: ({ coef, bias, val }) => `(${coef} * ${val} + ${bias})`,
          mongoFormatFunc: ({ coef, bias, val }) => ({ "$sum": [{ "$multiply": [coef, val] }, bias] }),
          jsonLogic: ({ coef, bias, val }) => ({ "+": [{ "*": [coef, val] }, bias] }),
          renderBrackets: ["", ""],
          renderSeps: [" * ", " + "],
          args: {
            coef: {
              label: "Coef",
              type: "number",
              defaultValue: 1,
              valueSources: ["value"],
            },
            val: {
              label: "Value",
              type: "number",
              valueSources: ["value"],
            },
            bias: {
              label: "Bias",
              type: "number",
              defaultValue: 0,
              valueSources: ["value"],
            }
          }
        },
      }
  
      const _config = {
        ...MaterialConfig,
        fields: _fields,
        //funcs:_funcs
      };
      _config.operators.current_member = {
        label: "@Текуший_пользователь",
        labelForFormat: "@Current_Member",
        //reversedOp:"not_today",  
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': props.currentMember ? props.currentMember._id : null } }),
        //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
  
      }
      _config.operators.after_now = {
        label: "> Сейчас",
        labelForFormat: "AFTER NOW",
        //reversedOp:"not_today",  
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$gte': "new Date()" } }),
        //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
  
      }
      _config.operators.befor_now = {
        label: "< Сейчас",
        labelForFormat: "BEFOR NOW",
        //reversedOp:"not_today",  
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$lt': "new Date()" } }),
        //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
  
      }
      _config.operators.for_today = {
        label: "За сегодня",
        labelForFormat: "FOR TODAY",
        //reversedOp:"not_today",  
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$gte': (new Date((new Date()).setHours(0, 0, 0, 0))), '$lt': (new Date((new Date()).setHours(23, 59, 59))) } }),
        //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
  
      }
      _config.operators.for_week = {
        label: "За неделю",
        labelForFormat: "FOR WEEK",
        //reversedOp:"not_today",  
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$gte': (new Date((new Date()).setHours(0, 0, 0, 0) - 604800000)), '$lt': (new Date((new Date()).setHours(23, 59, 59))) } }),
        //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
  
      }
      _config.operators.for_30_day = {
        label: "За 30 дней",
        labelForFormat: "FOR 30 DAY",
        //reversedOp:"not_today",  
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$gte': (new Date((new Date()).setHours(0, 0, 0, 0) - 2592000000)), '$lt': (new Date((new Date()).setHours(23, 59, 59))) } }),
        //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
  
      }
      _config.operators.for_current_month = {
        label: "За текущий месяц",
        labelForFormat: "FOR CURRENT MONTH",
        //reversedOp:"not_today",  
        cardinality: 0,
        formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
        mongoFormatOp: (field, op, value) => ({ [field]: { '$gte': (new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)), '$lt': (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)) } }),
        //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
  
      }
      console.log('_config.types', _config.types)
      //console.log('_config.types.date.operators ',_config.types.date.operators)
      if (_config.types.select.widgets.select.operators.findIndex(el => el === 'current_member') < 0) {
        _config.types.select.widgets.select.operators.push('current_member')
      }
      if (_config.types.date.widgets.date.operators.findIndex(el => el === 'after_now') < 0) {
        _config.types.date.widgets.date.operators.push('after_now')
      }
      if (_config.types.date.widgets.date.operators.findIndex(el => el === 'befor_now') < 0) {
        _config.types.date.widgets.date.operators.push('befor_now')
      }
      if (_config.types.date.widgets.date.operators.findIndex(el => el === 'for_today') < 0) {
        _config.types.date.widgets.date.operators.push('for_today')
      }
      if (_config.types.date.widgets.date.operators.findIndex(el => el === 'for_week') < 0) {
        _config.types.date.widgets.date.operators.push('for_week')
      }
      if (_config.types.date.widgets.date.operators.findIndex(el => el === 'for_30_day') < 0) {
        _config.types.date.widgets.date.operators.push('for_30_day')
      }
      if (_config.types.date.widgets.date.operators.findIndex(el => el === 'for_current_month') < 0) {
        _config.types.date.widgets.date.operators.push('for_current_month')
      }
      _config.types.geolocation = {
        valueSources: ['value'],
        defaultOperator: 'is_not_empty',
        widgets: {
          time: {
            operators: ['is_empty', 'is_not_empty'],
            opProps: {
            },
          },
        },
      }
  
  
      // _config.widgets.date.valueSrc = ['value', 'func']
      // _config.widgets.datetime.valueSrc = ['value', 'func']
      //console.log('_config ', _config)
  
      _config.settings.locale = {
        moment: 'ru',
        //antd: ru_RU,
        material: ruRU,
      };
      _config.settings.showNot = false;
      _config.settings.maxNesting = 1;
      _config.settings.groupActionsPosition = "bottomLeft"
      //Локализуем
      _config.conjunctions.AND.label = "И"
      _config.conjunctions.OR.label = "ИЛИ"
      _config.settings.valueLabel = "Значение"
      _config.settings.valuePlaceholder = "Значение"
      _config.settings.fieldLabel = "Поле"
      _config.settings.operatorLabel = "Оператор"
      _config.settings.funcLabel = "Функция"
      _config.settings.fieldPlaceholder = "Выберите поле"
      _config.settings.funcPlaceholder = "Выберите функцию"
      _config.settings.operatorPlaceholder = "Выберите оператор"
      _config.settings.deleteLabel = null//"Удалить значение"
      _config.settings.delGroupLabel = null//"Удалить группу"
      _config.settings.addGroupLabel = "Добавить группу"
      _config.settings.addRuleLabel = "Условие"
      _config.settings.notLabel = "Не"
      _config.settings.valueSourcesPopupTitle = "Выберите источник"
      //_config.removeRuleConfirmOptions = {}
      //_config.removeRuleConfirmOptions.title = "Вы уверены?"
      //_config.removeRuleConfirmOptions.okText = "Да"
      //_config.removeRuleConfirmOptions.okType = "Значение"
      //_config.removeGroupConfirmOptions.title = "Вы уверены?"
      //_config.removeGroupConfirmOptions.okText = "Да"
      //_config.removeGroupConfirmOptions.okType = "Значение"
      _config.operators.between.label = "Между"
      _config.operators.is_empty.label = "Пусто"
      _config.operators.is_not_empty.label = "Не пусто"
      _config.operators.like.label = "Подобно"
      _config.operators.multiselect_equals.label = "Равно"
      _config.operators.multiselect_not_equals.label = "Не равно"
      _config.operators.not_between.label = "Не между"
      _config.operators.not_like.label = "Не подобно"
      _config.operators.proximity.label = "Поиск"
      _config.operators.select_any_in.label = "Любой в"
      _config.operators.select_not_any_in.label = "Не в"
      _config.operators.starts_with.label = "Начинается с"
  
      _config.widgets.boolean.labelNo = "Нет"
      _config.widgets.boolean.labelYes = "Да"
  
      _config.widgets.date.valueLabel = "Дата"
      _config.widgets.date.valuePlaceholder = "Введите дату"
      _config.widgets.date.valueLabels[0] = { label: "От", placeholder: "Введите дату от" }
      _config.widgets.date.valueLabels[1] = { label: "До", placeholder: "Введите дату до" }
      _config.widgets.datetime.valueLabel = "Дата и время"
      _config.widgets.datetime.valuePlaceholder = "Введите дату и время"
      _config.widgets.datetime.valueLabels[0] = { label: "От", placeholder: "Введите дату от" }
      _config.widgets.datetime.valueLabels[1] = { label: "До", placeholder: "Введите дату до" }
      _config.widgets.field.valueLabel = "Поле для сравнения"
      _config.widgets.field.valuePlaceholder = "Выберите поле для сравнения"
      _config.widgets.func.valueLabel = "Функция"
      _config.widgets.func.valuePlaceholder = "Выберите функцию"
      _config.widgets.multiselect.valueLabel = "Значения"
      _config.widgets.multiselect.valuePlaceholder = "Выберите значения"
      _config.widgets.number.valueLabel = "Число"
      _config.widgets.number.valuePlaceholder = "Введите число"
      _config.widgets.number.valueLabels[0] = { label: "От", placeholder: "Введите число от" }
      _config.widgets.number.valueLabels[1] = { label: "До", placeholder: "Введите число до" }
      _config.widgets.rangeslider.valueLabel = "Диапазон"
      _config.widgets.rangeslider.valuePlaceholder = "Введите диапазон"
      _config.widgets.rangeslider.valueLabels[0] = { label: "От", placeholder: "Введите число от" }
      _config.widgets.rangeslider.valueLabels[1] = { label: "До", placeholder: "Введите число до" }
      _config.widgets.select.valueLabel = "Значение"
      _config.widgets.select.valuePlaceholder = "Выберите значение"
      _config.widgets.slider.valueLabel = "Число"
      _config.widgets.slider.valuePlaceholder = "Введите число или переместите слайдер"
      _config.widgets.text.valueLabel = "Текст"
      _config.widgets.text.valuePlaceholder = "Введите текст"
      _config.widgets.time.valueLabel = "Время"
      _config.widgets.time.valuePlaceholder = "Введите время"
      _config.widgets.time.valueLabels[0] = { label: "От", placeholder: "Введите время от" }
      _config.widgets.time.valueLabels[1] = { label: "До", placeholder: "Введите время до" }
      //console.log('_config ', _config)
      setConfig(_config)
    
    }, [props.tables, dialog, props.chatmembers, props.currentMember, props.members]) 

    useEffect(() => {

      //console.log('useEffect dialogEditorForPanel ', props.dialogId, props.dialogTableId)
      if( props.dialogId && props.dialogs && props.dialogs.length>0) 
      {
      var _dialog = props.dialogs.find(d => d._id === props.dialogId)
      if(_dialog){

      
      setDialog(_dialog)
      setTitle(_dialog.name)

      setWelcomeMessage(_dialog.welcome_message?_dialog.welcome_message:"")
      setGoodbyeMessage(_dialog.goodbye_message?_dialog.goodbye_message:"")
      setEmptyMessage(_dialog.empty_message?_dialog.empty_message:"")                        
      setChatKeywords(_dialog.chat_keywords?_dialog.chat_keywords.join('\n'):"") 

      setCancelKeywords(_dialog.cancel_keywords?_dialog.cancel_keywords:"")                        
      setCancelMessage(_dialog.cancel_message?_dialog.cancel_message:"")                        
      setHelpKeywords(_dialog.help_keywords?_dialog.help_keywords:"")                        
      setHelpMessage(_dialog.help_message?_dialog.help_message:"")     
      
    } else {
      setDialog(null)
      setTitle('')
      setWelcomeMessage("")
      setGoodbyeMessage("")
      setEmptyMessage("")                        
      setChatKeywords("") 

      setCancelKeywords("")                        
      setCancelMessage("")                        
      setHelpKeywords("")                        
      setHelpMessage("")    
    }      
    } else {
      setDialog(null)
      setTitle('')
      setWelcomeMessage("")
      setGoodbyeMessage("")
      setEmptyMessage("")                        
      setChatKeywords("") 

      setCancelKeywords("")                        
      setCancelMessage("")                        
      setHelpKeywords("")                        
      setHelpMessage("")    
    }    
    setEditingQuestionIdx(-1)
    }, [props.dialogId, props.dialogs]) 

    const dialogTypeTitle = {
      simple:"Отправка сообщений (без данных)",
      standup:"Добавление элемента в",
      update_item_request:"Обновление элемента в",
      update_request: "Вывод или обновление списка элементов из"
    }  


    return (
        <React.Fragment>     
          {errorMsg && <Alert  variant="filled"  severity="error" onClose={() => {setErrorMsg(null)}}>{errorMsg}</Alert>}                                
          {props.dialogId && dialog && <> 
                  <Paper className={classes.job_card} elevation={3} >  
                                 <TextField
                                 id="dialog-title"
                                 autoFocus
                                 //label="Название:"
                                 value={title}
                                 onClick={e=>setEditingQuestionIdx(-1)}
                                 //onBlur={onSaveTitle}
                                 onChange={onChangeTitle}
                                 placeholder=""
                                 //helperText="Введите разные фразы в отдельных строках"
                                 fullWidth
                                 //variant="outlined"

                                 margin="normal"
                                 style={{
                                   backgroundColor:"white"
                                 }}                            
                             />  

<div>
                                  <Typography variant='h6'>
                                    Фразы-триггеры
                                  </Typography>
                                  <Typography variant='caption'>
                                  Фразы-триггеры содержат разные формулировки, которые пользователь может использовать при взаимодействии с ботом по определенной теме. Распознавание естественного языка помогает определять темы на основе смысла сказанного, а не только конкретных слов. Для начала обучения боту требуется от 5 до 10 коротких фраз-триггеров. 
                                  </Typography>
                                </div>         
                         <TextField
                            id="dialog-chat_keywords"
                            //label="Фразы-триггеры:"
                            value={chatKeywords}
                            //onClick={e=>setEditingQuestionIdx(-1)}
                            //onBlur={onSaveChatKeywords}
                            onChange={onChangeChatKeywords}
                            placeholder=""
                            helperText="Введите разные фразы в отдельных строках"
                            fullWidth
                            multiline
                            variant="outlined"
                            maxRows={7}
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />  
                        </Paper>
                        <Paper className={classes.job_card} elevation={3} > 
                                  <Typography variant='h6'>
                                    Выполняемое действие:
                                  </Typography>
                                  <Typography 
                             variant='subtitile2' 
                             component="div" 
                             className={classes.dialog_subheader}
                             
                             >
                                           {dialogTypeTitle[dialog.type]}
                                           <DialogText 
                                             dialog = {dialog}
                                             dbId = {props.dbId}
                                             tables={props.tables}
                                             />
                                         </Typography>   
                                {( dialog.type==="update_request" || dialog.type==="update_item_request" || welcomeMessage)  && 
        <>
<div>
                                  <Typography variant='h6'>
                                  {dialog.type==="update_item_request"?"Сообщение для выбора элемента":"Приветственное сообщение:"}
                                  </Typography>
                                  <Typography variant='caption'>
                                  {dialog.type==="update_item_request"?"Введите сообщение, которое будет выведено вместе со списком элементов из вида для выбора нужного пользователем и дальнейшего обновления его":
                                  "Введите сообщение, которое будет выведено перед выводом списка элементов в чат"}
                                  </Typography>
                                </div> 
                    <TextField
                            id="dialog-welcome_message"
                            //label=
                            //defaultValue={welcomeMessage}
                            value={welcomeMessage}
                            onChange={onChangeWelcomeMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            rows={2}
                            margin="normal"
                            variant="outlined"
                            style={{
                              backgroundColor:"white"
                            }}
                        /> 

                  {dialog.type==="update_item_request"  && <TextField
                            id="dialog-select_item_view_field"
                            label={"Поле для представления элемента"}
                            //defaultValue={welcomeMessage}
                            //onClick={e=>setEditingQuestionIdx(-1)}
                            value={dialog.select_item_view_field}
                            onChange={onChangeSelectItemViewField}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            variant="outlined"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        >
                          {columns && columns.map(col=>
                <MenuItem key={col._id} value={col._id}>
                    {col.name} 
                    </MenuItem>)}
                          </TextField>      
                        }  
          </>}
            <div >

              {dialog.type === "update_request" &&
                <Typography variant='h6' >
                  Вопросы для заполнения полей каждого элемента из списка
                </Typography>
              }
              {dialog.type === "standup" &&
                <Typography variant='h6' >
                  Вопросы для заполнения полей нового элемента
                </Typography>
              }
              {dialog.type === "simple" &&
                <Typography variant='h6' >
                  Сообщения или меню
                </Typography>
              }
              {dialog.type === "update_item_request" &&
                <Typography variant='h6' >
                  Вопросы для обновления полей выбранного элемента
                </Typography>
              }
            </div>   
            <DragDropContext onDragEnd={onDragEndQuestion}>    
           
           <Droppable droppableId="questions_droppable">
             {provided => (
               <div 
               ref={provided.innerRef} 
               {...provided.droppableProps}
               style={{minHeight:70}}
               >
                 {dialog.questions && dialog.questions.length>0 &&  dialog.questions.map((question, index) => (
         <Draggable draggableId={"q_"+index} index={index}>
         {(provided, snapshot) => (
           <div
             {...provided.draggableProps}
             {...provided.dragHandleProps}
             ref={provided.innerRef}
             // style={divStyle}
           >
             <QuestionEditor key={'question_' + index} 
             dialog={dialog} 
             columns={columns} 
             editedColumns = {editedColumns}
             question={question} 
             idx={index} 
             config={config}
             onColumnInsert={props.onColumnInsert} 
             onChangeColumn={props.onChangeColumn} 
             tables={props.tables}
             tablesList={props.tablesList} 
             viewId={props.viewId}       
             tableId={props.tableId}  
             onChangeQuestionIntent={onChangeQuestionIntent} 
             handleDeleteAnswerChoice={handleDeleteAnswerChoice} 
             handleAddAnswerChoice={handleAddAnswerChoice} 
             onAddQuestion={onAddQuestion} 
             onDeleteQuestion={onDeleteQuestion}  
             onChangeQuestionCondition={onChangeQuestionCondition}  
             onChangeQuestionText={onChangeQuestionText} 
             onChangeQuestionAnswerType={onChangeQuestionAnswerType}
             onChangeQuestionListStyle={onChangeQuestionListStyle}
             onChangeQuestionDelay={onChangeQuestionDelay}
             onChangeQuestionSendField={onChangeQuestionSendField}
             onChangeQuestionToggleHasCondition={onChangeQuestionToggleHasCondition}
             onChangeQuestionToggleTyping={onChangeQuestionToggleTyping}
             onChangeQuestionToggleHasPhoneButton={onChangeQuestionToggleHasPhoneButton}
             onChangeQuestionPhoneButtonText={onChangeQuestionPhoneButtonText}
             onChangeQuestionChildDialog={onChangeQuestionChildDialog}
             editingQuestionIdx={editingQuestionIdx}
             setEditingQuestionIdx = {setEditingQuestionIdx}
             handleOpenColumnSetting={props.handleOpenColumnSetting}
             onChangeQuestionMenuItem={onChangeQuestionMenuItem}
             onChangeQuestionMenuItemType={onChangeQuestionMenuItemType}
             onChangeQuestionMenuItemParam={onChangeQuestionMenuItemParam}
             onAddQuestionMenuItem={onAddQuestionMenuItem}
             onDeleteQuestionMenuItem={onDeleteQuestionMenuItem}
             onChangeQuestionToggleIsFormula={onChangeQuestionToggleIsFormula}
             onChangeQuestionToggleIsJSFormula={onChangeQuestionToggleIsJSFormula}
             dialogs={props.dialogs}
             defaultColumns={props.defaultColumns}    
             dbId={props.dbId}      
             />
             
           </div>
         )}
       </Draggable>
                 ))}
   
               </div>
             )}
           </Droppable>
           </DragDropContext>
           <MessageBox
                        title={"Dinabot"}
                        avatar="https://dinabot.com/logo/logo_single.svg"
     position={'left'} 
     type={'system'}
     status="read"
     text={        <AddQuestionForCreateItemButton 
       columns={editedColumns}
       onAddQuestionWithIntent={onAddQuestionWithIntent}
       />}
     dateString={"-"}
   /> 
          {( dialog.type==="update_request" || dialog.type==="update_item_request" || goodbyeMessage) &&      <>                                             
                <div>
                                  <Typography variant='h6'>
                                  Прощальное сообщение
                                  </Typography>
                                  <Typography variant='caption'>
                                  Введите сообщение, которое будет выведено после вывода списка элементов в чат
                                  </Typography>
                                </div> 

                    <TextField
                            id="dialog-goodbye_message"
                            //label="Прощальное сообщение:"
                            value={goodbyeMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onChange={onChangeGoodbyMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />  
    </>}   
    {dialog.type==="update_request" &&   <>                                             
                <div>
                                  <Typography variant='h6'>
                                  Сообщение при пустом списке
                                  </Typography>
                                  <Typography variant='caption'>
                                  Введите сообщение, которое будет выведено, если список оказался пустым
                                  </Typography>
                                </div>  
                <TextField
                            id="dialog-empty_message"
                            //label="Сообщение при пустом списке:"
                            value={emptyMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onChange={onChangeEmptyMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />   
                        </>}

                        
          </Paper>
          <Paper
            className={classes.job_card}
            variant={'elevation'}
            elevation={3}

          >
            <FormControl component="fieldset" size="small">


              <FormControlLabel
                control={<Switch size="small" checked={dialog.dont_interrupt_current_dialogue} onChange={onChangeDontInterruptCurrentDialogue} />}
                label={<span>Не прерывать активный диалог при запуске этого диалога</span>}
              />



            </FormControl>
          </Paper>

          {false && <Paper             
          className={classes.job_card}     
          variant={ 'elevation'} 
          elevation={3} 
          
           >  

                         <TextField
                            id="dialog-cancel_keywords"
                            label="Стоп-фразы для остановки диалога:"
                            value={cancelKeywords}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onChange={onChangeCancelKeywords}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        /> 
                        
 



 
                {cancelKeywords &&    <TextField
                            id="dialog-cancel_message"
                            label="Сообщение при остановке диалога:"
                            value={cancelMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onChange={onChangeCancelMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />   } 

<TextField
          id="dialog-help_keywords"
          label="Фразы для вывода справки:"
          value={helpKeywords}
          onClick={e=>setEditingQuestionIdx(-1)}
          onChange={onChangeHelpKeywords}
          placeholder=""
          helperText=""
          fullWidth
          multiline
          variant="outlined"
          //rows={2}
          margin="normal"
          style={{
            backgroundColor:"white"
          }}                            
      />  
{helpKeywords &&    <TextField
          id="dialog-help_message"
          label="Текст справки:"
          value={helpMessage}
          onClick={e=>setEditingQuestionIdx(-1)}
          onChange={onChangeHelpMessage}
          placeholder=""
          helperText=""
          fullWidth
          multiline
          variant="outlined"
          //rows={2}
          margin="normal"
          style={{
            backgroundColor:"white"
          }}                            
      />   }     
      </Paper>}
      </>
        }

                             

<DialogActions>
          {dialog && <Button size="small" variant="contained" disabled={props.accessLevel<9} onClick={handleDel}>
              Удалить
          </Button> }                                                  
            <Button size="small" variant="contained" color="primary" disabled={props.accessLevel<5 || !dialogEdited} onClick={handleSave}>
              Сохранить
            </Button>
        </DialogActions>
                         

                </React.Fragment>
    );
  }

 // export default withStyles(styles, { withTheme: true })(AddDialogDialog); 