import React from 'react'
import PropTypes from 'prop-types'
import InlineInput from '../../widgets/InlineInput'
import {Title, LaneHeader, RightContent } from '../../styles/Base'
import LaneMenu from './LaneHeader/LaneMenu'
import Chip from '@material-ui/core/Chip';
import { IconButton } from '@material-ui/core';

const DinaLaneHeaderComponent = ({
  id, collapsed, laneId, color, onKanbanLaneClick, sharedView, updateTitle, canAddLanes, onDelete, onDoubleClick, editLaneTitle, label, title, titleStyle, labelStyle, t, laneDraggable
}) => {


  const onClick = (e) => {
    onKanbanLaneClick(e, id)
  }


    return (
      <div>
        <header
          style={{
            borderBottom: '2px solid #c5c5c5',
            paddingBottom: 6,
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minWidth: collapsed?"25px":"230px"
          }}>
  {laneId==='__unline'?
  (collapsed?<span 
  style={{
    minInlineSize: "7em",
    /* border: 1px solid red; */
    textAlign: "center",
    writingMode: "vertical-rl",
    transform: "scale(-1)"
  }}>{title}</span>: <span>{title}</span>)
  : 
  (collapsed?
<Chip
  size="medium"
  //icon={<FormatColorFillIcon />}
  //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
  label={<b>{title}</b>}
  clickable
  //color={o.color ? o.color : "default"}
  style={{ 
    backgroundColor: color?color:null, 
    //color:props.color?"white":null,  
    minInlineSize: "7em",
    /* border: 1px solid red; */
    textAlign: "center",
    writingMode: "vertical-rl",
    transform: "scale(-1)" 
  }}
  onClick={onClick}
  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
//deleteIcon={<DeleteIcon />}
/>       
    :<Chip
  size="medium"
  //icon={<FormatColorFillIcon />}
  //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
  label={<b>{title}</b>}
  clickable
  //color={o.color ? o.color : "default"}
  style={{ 
    backgroundColor: color?color:null, 
    //color:props.color?"white":null  

  }}
  onClick={onClick}
  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
//deleteIcon={<DeleteIcon />}
/>    )}            
         
          
         {!collapsed && <div style={{width: '30%', textAlign: 'right', fontSize: 13}}>
            {!sharedView && 
            <IconButton 
            //onClick={addItemHandler} 
            size="small"
            >
                ...
              </IconButton>}
            </div>}
          
        </header>
      </div>
    )
}

DinaLaneHeaderComponent.propTypes = {
  id: PropTypes.string, 
  collapsed: PropTypes.bool, 
  laneId: PropTypes.string,
  color: PropTypes.string, 
  onKanbanLaneClick: PropTypes.func,
  sharedView: PropTypes.bool,
  updateTitle: PropTypes.func,
  editLaneTitle: PropTypes.bool,
  canAddLanes: PropTypes.bool,
  laneDraggable: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onDoubleClick: PropTypes.func,
  t: PropTypes.func.isRequired
}

DinaLaneHeaderComponent.defaultProps = {
  updateTitle: () => {},
  editLaneTitle: false,
  canAddLanes: false
}

export default DinaLaneHeaderComponent;
