import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import {AuthContext} from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook';
import { Dropzone, FullScreenPreview, FileItem } from "dina-dropzone-ui";
import axios from "axios";

const imageExtensions = new Set(["bmp", "gif", "jpeg", "jpg", "tiff", "png"])

function getExtension(filename) {
  return filename.split(".").pop();
}

export default function LookupAttachmentsViewer(
  { dbId, 
    wiId, 
    itemId, 
    value, 
    colId, 
    onChange, 
    members, 
    rowHeight, 
    menuPortalTarget, 
    noOptionsMessage, 
    inForm, 
    view, 
    maxHeight, 
    minHeight, 
    compactStyle,
    fileIconWidth,
    fileIconHeight, 
    disableScroll,
    label,
    clickable,
    styleFilesContainer
  }
  ) {
  const { token } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [imgSource, setImgSource] = useState(undefined);
  const { request, error } = useHttp();
  var el = null //useRef()
  console.log('LookupAttachmentsViewer token ', token)


  
  const updateFiles = (incommingFiles) => {
    console.log('incommingFiles ',incommingFiles)
    setFiles(incommingFiles)
  };  


  useEffect(() => {
    if(value && value.length>0){
      
      setFiles(value.map(fl => {
        var isImage = false;
        if(fl.type === "image/bmp" 
        || fl.type === "image/gif" 
        || fl.type === "image/jpeg"
        || fl.type === "image/jpg" 
        || fl.type === "image/tiff" 
        || fl.type === "image/png" 
        
        ){
          isImage= true
        } else {
          if(!fl.type){            
            isImage = imageExtensions.has(getExtension(fl.name).toLowerCase());
          }
        } 
        
        return {
          file:fl,
          preview:true,
          uploadStatus:"success",
          //onDelete: async (id) => {
          //  try {      
          //    await request(`/api/files/${dbId}/${wiId}/${itemId}/${colId}/delfile`, 'DELETE', {fileName: id}, { Authorization: `Bearer ${token}` });
          //  } catch (e) {
            //setErrorMsg(e.message ? 'Ошибка:' + e.message : 'Произошла ошибка! Попробуйте еще раз...')
         //     console.log('onDelete file error', e);
          //  }      
          //},
          //onSee?: (imageUrl: string) => void;
          //errors
          id: fl.name,
          valid:true,

          info:true,
          onSee: (src) => {
            console.log("onSee ", src)
            setImgSource(src);
          },

          imageUrl: isImage?fl.preview:undefined,

          //uploadMessage          
          //hd:true,
          //localization
          //elevation
          //alwaysActive
          //resultOnTooltip          
        }
      }

      ))
    } else {
      setFiles([])
    }

}, [value])

    return (      
      <Dropzone 
      //onChange={updateFiles} 
      value={files} 
      style={{
        maxWidth:"350px", 
        borderStyle:"none",                 
      }}
      method="POST"
      maxFileSize={30*1024*1024}
      footer={false}
      header={false}
      uploadOnDrop={false}
      label={label?label:"Нажмите здесь или перетащите, чтобы добавить файлы"}
      localization="RU-ru"
      url = {`/api/files/${dbId}/${wiId}/${itemId}/${colId}`}
      view = {view?view:"grid"}
      maxHeight={maxHeight?maxHeight:"450px"}
      minHeight={minHeight?minHeight:"150px"}
      disableScroll={disableScroll}
      config= {{
        headers: {
          "content-type": "multipart/form-data;",
          "authorization": `Bearer ${token}`
        }
        }}
        clickable={clickable}
        styleFilesContainer={styleFilesContainer}
      >
      {files && files.length>0 && files.map((file) => (
        <FileItem 
        {...file}
        compactStyle={compactStyle}
        fileIconWidth={fileIconWidth}
        fileIconHeight={fileIconHeight} 
        style={{
          width:compactStyle?fileIconWidth:null,
          margin:compactStyle?2:null,

        }}
        />
      ))}
      {imgSource && <div>
        <FullScreenPreview
          imgSource={imgSource}
          openImage={imgSource}
          onClose={(e) => setImgSource(undefined)}
        /> 
        </div>
      }     
    </Dropzone>      
    );
  }
