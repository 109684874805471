import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import moment from 'moment-timezone'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const labels= {
  "none": "нет",
  "summary": "Сумма", 
  "empty": "Пустые", 
  "filled": "Запол.", 
  "unique": "Уник.",
  "%_empty": "% пустых",  
  "%_filled": "% запол.", 
  "%_unique": "% уник.", 
  "earliest_date": "Ран. дата",
  "latest_date": "Позд. дата",
  "date_range_day": "Диапаз. дат (дн)",
  "date_range_mon": "Диапаз. дат (м)"
} 

const typesFormules = {
  "number":["none", "summary", "empty", "filled", "unique"],
  'text': ["none", "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique"],
  'title': ["none", "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique"],
  'date': ["none",  "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique", "earliest_date", "latest_date", "date_range_day", "date_range_mon"],
  'datetime': ["none",  "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique", "earliest_date", "latest_date", "date_range_day", "date_range_mon"],
  'select': ["none", "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique"],
  'multiline': ["none", "empty", "filled",  "%_empty",  "%_filled"],
  'member': ["none", "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique"],
  'chatmember': ["none", "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique"],
  'link': ["none",  "empty", "filled", "unique", "%_empty",  "%_filled", "%_unique"],
  'geolocation': ["none",  "empty", "filled", "%_empty",  "%_filled", "%_unique"],
  'attachments': ["none",  "empty", "filled", "%_empty",  "%_filled", "%_unique"],
  'check': ["none", "empty", "filled",  "%_empty",  "%_filled"],
  'lookup': ["none"]  
}

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

export default function SummaryRowEditor({items, colType, formula, saveSummaryRow}) {
  const [value, setValue] = React.useState("");  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSummary, setShowSummary] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {    
    setAnchorEl(null);
  };

  const handleClickMenu = (vl) => {    
    saveSummaryRow(vl);
    setAnchorEl(null)
  };  



    


  useEffect(() => {
    var _value = ''
    if (formula) {
      if (formula === "summary" && colType==="number") {
        if (items && items.length>0) {
          let __value = items.reduce((previousItem, currentItem) => {
            var previousValue = parseInt(previousItem) //?previousItem[column.key]:0
            var currentValue = parseInt(currentItem) //?currentItem[column.key]:0
            if(isNaN(previousValue)) {
              previousValue=0
            }
            if(isNaN(currentValue)) {
              currentValue=0
            }
            return previousValue + currentValue
          })
          _value = (typeof __value ==='number')? __value.toLocaleString():0
        } else {
          _value = 0
        }
      } else if (formula === "empty") {     
        if (items && items.length>0) {
          _value = 0;
          for(var it=0; it<items.length; it++){
            let item = items[it]
            if(item===undefined || item===null || item===""){
              _value++
            }
          }
        } else {
          _value = 0
        }           
      } else if (formula === "filled") {     
        if (items && items.length>0) {
          _value = 0;
          for(var it=0; it<items.length; it++){
            let item = items[it]
            if(item!==undefined && item!==null && item!==""){
              _value++
            }
          }
        } else {
          _value = 0
        }  
      } else if (formula === "unique") {     
        if (items && items.length>0) {
          let uniq_obj = items.reduce( (acc, o) => (acc[o] = (acc[o] || 0)+1, acc), {} )
          //console.log("uniq_obj ", uniq_obj)
          _value = Object.keys(uniq_obj).length;
        } else {
          _value = 0
        }  
      } else if (formula === "%_empty") {     
        if (items && items.length>0) {
          _value = 0;
          for(var it=0; it<items.length; it++){
            let item = items[it]
            if(item===undefined || item===null || item===""){
              _value++
            }
          }
          _value = Math.round(_value/items.length*100) +"%"
        } else {
          _value = ""
        }           
      } else if (formula === "%_filled") {     
        if (items && items.length>0) {
          _value = 0;
          for(var it=0; it<items.length; it++){
            let item = items[it]
            if(item!==undefined && item!==null && item!==""){
              _value++
            }
          }
          _value =  Math.round(_value/items.length*100) +"%"
        } else {
          _value = ""
        }  
      } else if (formula === "%_unique") {     
        if (items && items.length>0) {
          let uniq_obj = items.reduce( (acc, o) => (acc[o] = (acc[o] || 0)+1, acc), {} )
          //console.log("uniq_obj ", uniq_obj)
          _value = Object.keys(uniq_obj).length;
          _value =  Math.round(_value/items.length*100) +"%"
        } else {
          _value = ""
        }   
      } else if (formula === "earliest_date" && (colType==="date" || colType==="datetime")) {     
        if (items && items.length>0) {
          _value = moment.min(items.filter((o) =>
          o !== null && o !== undefined
        ).map((date) =>
        moment(date) // converting to moment instance so that .max command will work
        ))

        if(_value){
          _value = moment(_value).format("DD.MM.YYYY HH:mm")
        } else {
          console.log("earliest_date !!_value")
        }
        
        } else {
          _value = ""
        } 
      } else if (formula === "latest_date" && (colType==="date" || colType==="datetime")) {     
        if (items && items.length>0) {
          _value = moment.max(items.filter((o) =>
          o !== null && o !== undefined
        ).map((date) =>
        moment(date) // converting to moment instance so that .max command will work
        ));       

        if(_value){
          _value = moment(_value).format("DD.MM.YYYY HH:mm")
        } else {
          console.log("earliest_date !!_value")
        }
        } else {
          _value = ""
        } 
      } else if (formula === "date_range_day" && (colType==="date" || colType==="datetime")) {     
        if (items && items.length>0) {
        var dts =  items.filter((o) =>
          o !== null && o !== undefined
        ).map((date) =>
        moment(date) // converting to moment instance so that .max command will work
        )          
        let maxdt = moment.max(dts);       
        let mindt = moment.min(dts);   
        
        if(maxdt && mindt){
          _value =  maxdt.diff(mindt, "d")
        } else {
          console.log("earliest_date !!_value")
        }
        } else {
          _value = ""
        } 
      } else if (formula === "date_range_mon" && (colType==="date" || colType==="datetime")) {     
        if (items && items.length>0) {
            var dts =  items.filter((o) =>
              o !== null && o !== undefined
            ).map((date) =>
            moment(date) // converting to moment instance so that .max command will work
            )          
            let maxdt = moment.max(dts);       
            let mindt = moment.min(dts);   
            
            if(maxdt && mindt){
              _value =  maxdt.diff(mindt, "month")
            } else {
              console.log("earliest_date !!_value")
            }
        } else {
          _value = ""
        }         

      }

      
    }
    setValue(_value)
  }, [items, colType, formula]);   

    return (
      <div 
      onMouseEnter={() => setShowSummary(true)}
      onMouseLeave={() => setShowSummary(false)}       
      onClick={handleClick}>
        {(formula && formula!=="none") ?<div style={{width:"100%", height:"25px", textAlign: "right", fontSize:12}}>
          <span>{labels[formula]} </span>
          <b>{value}</b>
          </div>
          :<div style={{width:"100%", height:"25px", textAlign: "right", alignContent:'center', fontSize:12}}>
          {!anchorEl && showSummary && <span
            style={{
              cursor: 'pointer',              
            }}          
          ><ArrowDropDownIcon  color="disabled" style={{fontSize:"14px"}}/> Рассчитать</span>}
          </div>}
      <Popover
        id={"summary-row-menu"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        //anchorPosition={{ top: top, left: left }}
        //anchorReference="anchorPosition"
        onClose={handleClose}
        transition
        placement="bottom-start"
      //        style={{minWidth:"250px"}}
      >        
        <ClickAwayListener onClickAway={handleClose}>
        <MenuList id="dialog-type-menu-list" dense>
        {colType && typesFormules[colType] && typesFormules[colType].map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={e=> handleClickMenu(option)}>
            {labels[option]}
          </MenuItem>
        ))}
        </MenuList>
        </ClickAwayListener>
      </Popover>
    </div>      
      
    );
  }