import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const SmallSwitch = withStyles((theme) => ({
    root: {
      width: 22,
      height: 12,
      padding: 0,
      display: 'flex',
      //marginRight: 4
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(10px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 9,
      height: 8,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 12 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  export default SmallSwitch