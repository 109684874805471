import React, { useEffect, useState, useContext } from 'react';
import {useHistory} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useMessage} from '../hooks/message.hook';
import {useHttp} from '../hooks/http.hook';
import {AuthContext} from '../context/AuthContext'
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import MaskedInput from "react-input-mask";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://dinabot.com/">
        Динатех ООО
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ExpirationMask = props => 
{
  const { inputRef, ...other } = props

  return <MaskedInput
      {...other}
      ref={ref => {inputRef(ref ? ref.inputElement : null)}}
      mask={['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',  /\d/, /\d/]}
      //placeholderChar={'\u2000'}
      //maskPlaceholder="+7"
    />
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  expInputRoot: {
    margin:'-8px 0 0 14px'
  }
}));



export default function SignUp() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
    const message = useMessage();
    const {loading, request, error, clearError}= useHttp();
    const [form, setForm] = React.useState({firstName:null, email:null, lastName:"", phone:null });

    const submitHandler = async (event) => { 
        try {            
            const data = await request('/api/auth/register','POST', form,{});
            window.ym(53785333,'reachGoal','registration')
            //console.log('registerHandler data ', data);
            auth.login(data.token, data.userId);
        } catch (e) {
            console.log('registerHandler error ', e);
        }
    }

    const  onChangeHandler = (event) =>{        
        setForm({...form, [event.target.name]:event.target.value})
    } 
         
        

    const  onChangeOferta = (event) =>{   
      const checked = event.target.checked;     
      setForm({...form, ['allowOferta']:checked})
    }       

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>        
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img alt="logo" style={{ height: "70px" }} src="/Dina-bot_logo.png" />
        </Grid>        
        <Grid item>
          <Typography component="h1" variant="h5">
          Регистрация в Dinabot
          </Typography>
          
        </Grid>        
        </Grid> 
        <form className={classes.form} noValidate>
          {error && <Alert variant="filled" severity="error" onClose={e=>clearError()}>{error}</Alert>}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                onChange={onChangeHandler}
                required
                fullWidth
                id="firstName"
                label="Имя"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                onChange={onChangeHandler}
                fullWidth
                id="lastName"
                label="Фамилия"
                name="lastName"
                autoComplete="lname"
              />
            </Grid> 
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                onChange={onChangeHandler}
                fullWidth
                id="email"
                label="Рабочий Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
                variant="outlined"
                required
                onChange={onChangeHandler}
                fullWidth
                id="phone"
                label="Телефон"
                name="phone"
                autoComplete="tel"
                InputProps={{
                  inputComponent: ExpirationMask 
                }}
              />
              </Grid>
            <Grid item xs={12}>
              <Typography  variant="subtitle2">Пароль будет выслан на Email</Typography>
            </Grid>                          
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowOferta" name="allowOferta" id="allowOferta" onChange={onChangeOferta} color="primary" />}
                label={<span>Я согласен с <a href="https://dinabot.com/oferta">Офертой</a> и с <a href="https://dinabot.com/privacy-policy-dinabot">Политикой конфиденциальности</a> сервиса Dinabot</span>}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitHandler}
          >
            Зарегистрировать
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href={"/signin"+window.location.search} variant="body2">
                Уже зарегистрированы? Войти
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}