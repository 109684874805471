import React,{ FC, useRef } from "react";
import { DownloadProps } from "./DownloadProps";
import { parseSize } from "../utils/utils";

function
    handleClickUtil<T extends HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | SVGSVGElement | HTMLInputElement>
    (
        evt: React.MouseEvent<T, MouseEvent>
    ) {
    evt.preventDefault();
    evt.stopPropagation();
}



const Download: FC<DownloadProps> = (props: DownloadProps) => {
  const { size, color, colorFill, onClick, style, className, downloadUrl,fileName  } = props;
  const finalSize = parseSize(size);
  const downloadRef = React.useRef<HTMLAnchorElement>(null);
  const finalStyle = style ? style : {};
  const innerDownload = () => {
    const anchorElement = downloadRef.current;
    if (anchorElement) {
      anchorElement.click();
    }
  };

  return (
    <React.Fragment>
    <svg
      className={className || ""}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      height={`${finalSize}px`}
      viewBox="0 0 24 24"
      width={`${finalSize}px`}
      fill={color ? color : "#000000"}
      onClick={() => innerDownload()}
    >
      <g>
        <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
      </g>
    </svg>
        <a
        ref={downloadRef}
        target={"_blank"}
        href={downloadUrl}
        download={fileName}
        hidden
        rel={"noopener noreferrer"}
        //
      >
    </a>
    </React.Fragment>
  );
};
export default Download;
