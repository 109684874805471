import React, {  useContext } from 'react';
import {Router, Route, useHistory, useParams, Redirect, BrowserRouter, Switch} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useMessage} from '../hooks/message.hook';
import {useHttp} from '../hooks/http.hook';
import {AuthContext} from '../context/AuthContext'
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import DialogActions from '@material-ui/core/DialogActions';
import Avatar from '@material-ui/core/Avatar';
import { ListItemAvatar } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



export default function ProfileMenu(props) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = event => {
      event.preventDefault();
      auth.logout();
      //history.push('/');
  }
  


  return (
    <React.Fragment>
      <IconButton  size="small" edge="end" aria-label="menu" color="inherit" aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick}>
        <Avatar 
          src={props.profile ? props.profile.photo: ''}
          className={classes.avatar}           
        />        
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>{props.profile? props.profile.email:''}</MenuItem>
        <MenuItem component={Link} href="/dashboard/profile" key="menu-profile" >Профиль</MenuItem>
        <MenuItem onClick={logoutHandler}>Выйти</MenuItem>


      </Menu>
    </React.Fragment>
  );
}
    


