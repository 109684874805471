import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTheme, alpha, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InputBase from '@material-ui/core/InputBase';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 221,
    fontSize: 13,
  },
  button: {
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: '#586069',
    fontWeight: 600,
    '&:hover,&:focus': {
      color: '#0366d6',
    },
    '& span': {
      width: '100%',
    },
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    //width: 300,
    zIndex: 9999,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 13,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}));

export default function MemberEditor({ value, onChange, onClose, options, width, noOptionsMessage,inForm, isDisabled }) {
  const classes = useStyles();  
  const [selectValue, setSelectValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const rowContextAnchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const handleCloseRowContextMenu = ()=> {
    setOpen(false)
    onClose()
  }

  useEffect(() => {
    //console.log('SelectEditor useEffect !')    
    setOpen(true)
    if(options && value){
      var _v = options.find(o => o._id === value)
      setSelectValue(_v)
    } else {
      setSelectValue(null)
    }
    setInputValue("")
  }, [value, options]);  

  //console.log('SelectEditor ',  value, options )
  return (
    <React.Fragment>
    <div
    ref={rowContextAnchorEl}
    style={{ 
      whiteSpace: "nowrap",
      overflow: 'hidden',
      //textOverflow: 'ellipsis',
      lineHeight: "normal", 
      whiteSpace: "normal", 
      height: "25px", 
      width: '100%',
      //display: "table-cell",
      //verticalAlign: "text-top",
      paddingTop: "3px",
      paddingLeft:"8px",      
      fontSize:13
    }}    
  >
      {selectValue ?<Chip
                  size="small"
                  icon={<AccountCircleIcon />}
                  label={selectValue.name}                
                  style={
                    { 
                        
                      fontSize:"12px", 
                      height: "20px"  
                    }
                  }
                /> 
      :""   
      } 
  </div>     
    <Popper
    id={"popover-row-select-editor"}
    open={open}
    placement="bottom-start"
    className={classes.popper}
    anchorEl={rowContextAnchorEl?rowContextAnchorEl.current:null}
    //anchorPosition={{ top: top, left: left }}
    //anchorReference="anchorPosition"
    onClose={handleCloseRowContextMenu}
    //transition
  //        style={{minWidth:"250px"}}
>


  <Autocomplete
  //multiple
  isDisabled={isDisabled}
  openOnFocus
  id="select-cell-editor"
  size="small"
  options={options}
  getOptionLabel={option => option.name}
  //defaultValue={[options.find(o => o.value === value)]}
  value={selectValue}
  inputValue={inputValue}
  noOptionsText="Нет значений"
  disablePortal
  onInputChange={(event, newInputValue) => {
    //console.log('SelectEditor onInputChange ', event, newInputValue); 
    if(event){
      setInputValue(newInputValue);
    }    
  }}  
  onClose={handleCloseRowContextMenu}
  style={{
    minWidth: 250,    
    width: width
  }}
  classes={{
    paper: classes.paper,
    option: classes.option,
    popperDisablePortal: classes.popperDisablePortal,
  }}  
  disableClearable
  onContextMenu={event => event.stopPropagation()}
  onChange={(event, o) => {
           
      setSelectValue(o)
      onChange(o?o._id:null)
    }
  }              

  renderInput={(params) => (
    <InputBase
      ref={params.InputProps.ref}
      inputProps={params.inputProps}
      autoFocus
      className={classes.inputBase}
      placeholder="Найдите значение"
    />
  )}
  renderOption={(option) => (
    <React.Fragment>
      <Chip
        size="small"
        icon={<AccountCircleIcon />}
        //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
        label={option.name}
        //avatar={<Avatar>M</Avatar>}
        //clickable
        //color={o.color ? o.color : "default"}
        style={
          {
            //backgroundColor: option.color, 
            fontSize: "12px", 
            height: "20px" 
          }
        }
      //onDelete={(e) => handleDeleteOptionChoice(o, index)}
      //deleteIcon={<DeleteIcon />}
      />
    </React.Fragment>
  )}    
  onKeyDown={event => {
    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      event.stopPropagation();
    }

    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
    if(event.key === 'Escape'){
    handleCloseRowContextMenu();    
    }
  }}                        
/>   

      </Popper>
      </React.Fragment>
)
  }