import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTheme, alpha, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InputBase from '@material-ui/core/InputBase';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Utils, BasicConfig} from 'react-awesome-query-builder';
import Query from '../../Utils/query' 

const useStyles = makeStyles((theme) => ({
  root: {
    width: 221,
    fontSize: 13,
  },
  button: {
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: '#586069',
    fontWeight: 600,
    '&:hover,&:focus': {
      color: '#0366d6',
    },
    '& span': {
      width: '100%',
    },
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    //width: 300,
    zIndex: 9999,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 13,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}));

export default function LinkEditor({ 
  value, 
  onChange, 
  onClose, 
  //options, 
  width, 
  noOptionsMessage,
  inForm, 
  isDisabled, 
  titleCol,
  filterView,
  tables,
  linkId,
  currentMember,
  members,
  chatmembers     
}) {
  const classes = useStyles();  
  const [selectValue, setSelectValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const rowContextAnchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const handleCloseRowContextMenu = ()=> {
    setOpen(false)
    onClose()
  }
  var options = []
    if(linkId){
      if(filterView 
        && tables[linkId].views[filterView] 
        && tables[linkId].views[filterView].filter)
        {
        
        var _fields = {}
     
          for(let ic=0; ic<tables[linkId].columns.length; ic++){
            _fields[tables[linkId].columns[ic]._id] = {
              label: tables[linkId].columns[ic].name,
              type: tables[linkId].columns[ic].type,
           
            }
            if(tables[linkId].columns[ic].type === 'select'){
              _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
              _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                listValues: tables[linkId].columns[ic].options.map(opt=>{return {value:opt.value, title:opt.label}}),
              }               
            } else if(tables[linkId].columns[ic].type === 'check'){
              _fields[tables[linkId].columns[ic]._id].type = 'boolean'            
            } else if(tables[linkId].columns[ic].type === 'link'){
              if(tables[tables[linkId].columns[ic].linkId] && tables[tables[linkId].columns[ic].linkId].columns){
                var titleColId = tables[linkId].columns[ic].linkViewField//?props.columns[ic].linkViewField:props.tables[props.columns[ic].linkId].columns.find(cl=> cl.type === 'title')
                if(titleColId){
                var tableValues = tables[tables[linkId].columns[ic].linkId].itemsList?tables[tables[linkId].columns[ic].linkId].itemsList:[]
                //console.log('filter tableValues', tableValues, titleColId)
                _fields[tables[linkId].columns[ic]._id].type = 'select'
                _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
                _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                  
                  listValues: tableValues.map(opt=>{return {value:opt._id, title:opt[titleColId]}}),
                } 
              }
              }  
            } else if(tables[linkId].columns[ic].type === 'member'){
              _fields[tables[linkId].columns[ic]._id].type = 'select'
              _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
              _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                listValues: members.map(opt=>{return {value:opt._id, title:opt.name?opt.name:opt.email}}),            
              } 
            } else if(tables[linkId].columns[ic].type === 'chatmember'){
              _fields[tables[linkId].columns[ic]._id].type = 'select'
              _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
              _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                listValues: chatmembers.map(opt=>{return {value:opt._id, title:opt.first_name?(opt.first_name+(opt.last_name?(" "+opt.last_name):"")):opt.name}}),
              }  
            } else if(tables[linkId].columns[ic].type === 'date' || tables[linkId].columns[ic].type === 'datetime'){
              _fields[tables[linkId].columns[ic]._id].funcs = ['TODAY']
              _fields[tables[linkId].columns[ic]._id].valueSources = ["value", "field", "func"]
            } else if(tables[linkId].columns[ic].type === 'title'){
              _fields[tables[linkId].columns[ic]._id].type = 'text'
            } else if(tables[linkId].columns[ic].type === 'multiline'){
              _fields[tables[linkId].columns[ic]._id].type = 'text'
            }     
          }       
                  
          const _config = {
            ...BasicConfig,
            fields: _fields
          };  
          _config.operators.current_member={
            label: "@Текуший_пользователь",
            labelForFormat: "@Current_Member",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$eq': currentMember?currentMember._id:null }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }          
          _config.operators.after_now={
            label: "> Сейчас",
            labelForFormat: "AFTER NOW",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': "new Date()" }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }     
          _config.operators.befor_now={
            label: "< Сейчас",
            labelForFormat: "BEFOR NOW",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$lt': "new Date()" }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }          
          _config.operators.for_today={
            label: "За сегодня",
            labelForFormat: "FOR TODAY",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0))), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }
          _config.operators.for_week={
            label: "За неделю",
            labelForFormat: "FOR WEEK",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0)-604800000)), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }    
          _config.operators.for_30_day={
            label: "За 30 дней",
            labelForFormat: "FOR 30 DAY",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0)-2592000000)), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }  
          _config.operators.for_current_month={
            label: "За текущий месяц",
            labelForFormat: "FOR CURRENT MONTH",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).getFullYear(), (new Date()).getMonth(),1)), '$lt':(new Date((new Date()).getFullYear(), (new Date()).getMonth()+1,0)) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }          
          console.log('_config.types', _config.types)
          //console.log('_config.types.date.operators ',_config.types.date.operators)
          if(_config.types.select.widgets.select.operators.findIndex(el=> el==='current_member')<0){
            _config.types.select.widgets.select.operators.push('current_member')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='after_now')<0){
          _config.types.date.widgets.date.operators.push('after_now')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='befor_now')<0){
          _config.types.date.widgets.date.operators.push('befor_now')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_today')<0){
          _config.types.date.widgets.date.operators.push('for_today')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_week')<0){
          _config.types.date.widgets.date.operators.push('for_week')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_30_day')<0){
          _config.types.date.widgets.date.operators.push('for_30_day')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_current_month')<0){
          _config.types.date.widgets.date.operators.push('for_current_month')
          }
          _config.types.geolocation = {
            valueSources: ['value'],
            defaultOperator: 'is_not_empty',
            widgets: {
                time: {
                  operators: ['is_empty', 'is_not_empty'],
                  opProps: {
                  },
              },          
            },
        }                



        var _immutableTree = Utils.checkTree(Utils.loadTree(tables[linkId].views[filterView].filter), _config)
        //console.log('filterMongo _immutableTree', _immutableTree)
        var mongoFilter = Utils.mongodbFormat(_immutableTree, _config)

        console.log('LinkFormEditor mongoFilter ', tables[linkId].columns, mongoFilter, tables[linkId].views[filterView].filter)
        options = Query.query(tables[linkId].itemsList, mongoFilter);
      } else{
        options = tables[linkId].itemsList
      }
      
    }  

  useEffect(() => {
    
    setOpen(true)
    var _v = options.find(o => o._id === value)
    setSelectValue(_v)
    setInputValue("")
  }, [value, options]);  

  //console.log('SelectEditor ',  value, options )
  return (
    <React.Fragment>
    <div
    ref={rowContextAnchorEl}
    style={{ 
      whiteSpace: "nowrap",
      overflow: 'hidden',
      //textOverflow: 'ellipsis',
      lineHeight: "normal", 
      whiteSpace: "normal", 
      height: "25px", 
      width: '100%',
      //display: "table-cell",
      //verticalAlign: "text-top",
      paddingTop: "3px",
      paddingLeft:"8px",      
      fontSize:13
    }}    
  >
      {(selectValue && titleCol) ?<Chip
                  size="small"
                  label={selectValue[titleCol]}                
                  style={
                    { 
                      
                      //left:"6px",
                      //top:"3px",    
                      fontSize:"12px", 
                      height: "20px"  
                    }
                  }
                /> 
      :""   
      } 
  </div>     
    <Popper
    id={"popover-row-select-editor"}
    open={open}
    placement="bottom-start"
    className={classes.popper}
    anchorEl={rowContextAnchorEl?rowContextAnchorEl.current:null}
    //anchorPosition={{ top: top, left: left }}
    //anchorReference="anchorPosition"
    onClose={handleCloseRowContextMenu}
    //transition
  //        style={{minWidth:"250px"}}
>


  <Autocomplete
  //multiple
  isDisabled={isDisabled}
  openOnFocus
  id="select-cell-editor"
  size="small"
  options={options}
  getOptionLabel={option => {if(titleCol && option && option[titleCol]){return option[titleCol]} else return ""}}
  //defaultValue={[options.find(o => o.value === value)]}
  value={selectValue}
  inputValue={inputValue}
  noOptionsText="Нет значений"
  disablePortal
  onInputChange={(event, newInputValue) => {
    //console.log('SelectEditor onInputChange ', event, newInputValue); 
    if(event){
      setInputValue(newInputValue);
    }    
  }}  
  onClose={handleCloseRowContextMenu}
  style={{
    minWidth: 250,    
    width: width
  }}
  classes={{
    paper: classes.paper,
    option: classes.option,
    popperDisablePortal: classes.popperDisablePortal,
  }}  
  disableClearable
  onContextMenu={event => event.stopPropagation()}
  onChange={(event, o) => {
           
      setSelectValue(o)
      onChange(o?o._id:null)
    }
  }              

  renderInput={(params) => (
    <InputBase
      ref={params.InputProps.ref}
      inputProps={params.inputProps}
      autoFocus
      className={classes.inputBase}
      placeholder="Найдите значение"
    />
  )}
  renderOption={(option) => (
    <React.Fragment>
      <Chip
        size="small"
        //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
        label={titleCol?option[titleCol]:""}
        //avatar={<Avatar>M</Avatar>}
        //clickable
        //color={o.color ? o.color : "default"}
        style={
          {
            //backgroundColor: option.color, 
            fontSize: "12px", 
            height: "20px" 
          }
        }
      //onDelete={(e) => handleDeleteOptionChoice(o, index)}
      //deleteIcon={<DeleteIcon />}
      />
    </React.Fragment>
  )}    
  onKeyDown={event => {
    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      event.stopPropagation();
    }

    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
    if(event.key === 'Escape'){
    handleCloseRowContextMenu();    
    }
  }}                        
/>   

      </Popper>
      </React.Fragment>
)
  }