import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import AttachmentsEditor from './AttachmentsEditor';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function MultilineTableEditor({ value, onClose, onChange, width }) {
  const inputRef = useRef(null);
  const rowContextAnchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [inputEl, setInputEl] = useState(null);


  const handleCloseRowContextMenu = ()=> {
    
    setOpen(false)
    onClose()
  }

  useEffect(()=>{
    setOpen(true)
    
 
  }, [])

  useEffect(()=>{
    
    if(inputEl){
      console.log('Автофокус тут!', inputEl  )
      inputEl.focus()
      //inputRef.current.focus({ preventScroll: true });
      //inputRef.current.select();
    }    
  }, [inputEl])

    return (      
      <React.Fragment>
      <div
        ref={rowContextAnchorEl}
      >
      </div>        
      <Popover
        id={"row-multiline-editor"}
        open={open}
        anchorEl={rowContextAnchorEl?rowContextAnchorEl.current:null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseRowContextMenu}
        
      >

            <ReactQuill
              ref = {el => setInputEl(el) }
              theme="snow"
              autoFocus          
              selection={{start:0, end:0}}    
              //hide = { hiddenDesc }
              //placeholder="Напишите содержание..."
              defaultValue={value}
              modules={{
                toolbar: [
                  //[{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                  //[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link'],
                  //['clean']
                ],
              }}
              formats={[
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                //'list', 'bullet', 'indent',
                'link'
              ]}
              //onChange={onChangeEmailText}
              onBlur={(previousRange, source, editor) => {  onChange(editor.getHTML())}}
              onContextMenu={event => event.stopPropagation()}
              onKeyDown={event => {
                //console.log('onKeyDown ', event.key)
                if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Home' || event.key === 'End') {
                  event.stopPropagation();
                } else if(event.key === 'Enter'){
                  //console.log('MultilineTableEditor  Enter', event)  
                  event.stopPropagation();
                } else if(event.key === 'Escape'){
                  //onClose()
                  console.log('inputEl ', inputEl)
                  try{
                    onChange(inputEl.value)
                    //inputEl.blur()
                  } catch (e) {

                  }
                  
                  //onChange(editor.getHTML())
                //  if (!inputRef.current) return;
                  //console.log('inputRef.current ', inputRef.current.value)
                //  const updated = inputRef.current.value;
                //  onChange(updated)
                }
              }}
              //onPaste={event => event.stopPropagation()}
              //onCopy={event => event.stopPropagation()}              
              //onBlur={handleChangeDesc}
              style={{ 
                outlineWidth:"3px", 
                outlineColor: "#66afe9", 
                minHeight: "120px", 
                minWidth: "250px",  
                width: width, 
                paddingLeft: 0, 
                fontSize: 13, 
              }}              
              />        
      </Popover>
      </React.Fragment>
    );
  }