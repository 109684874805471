import React, { useState, ReactNode, useRef } from 'react';
import { useDrag, useDrop, DragObjectWithType } from 'react-dnd';
import clsx from 'clsx';
import { Column, Row as GridRow, RowRendererProps, UpdateActions, CellNavigationMode, SortDirection } from 'dina-react-data-grid';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Box from '@material-ui/core/Box';
import CellActionsFormatter from './CellActionsFormatter'
import Grid from '@material-ui/core/Grid';
import './DraggableRowRenderer.css';
import { vi } from 'date-fns/locale';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

function useCombinedRefs(...refs) {
    const targetRef = React.useRef();
  
    React.useEffect(() => {
      refs.forEach(ref => {
        if (!ref) return;
  
        if (typeof ref === "function") {
          ref(targetRef.current);
        } else {
          ref.current = targetRef.current;
        }
      });
    }, [refs]);
  
    return targetRef;
  }

export default function DraggableRowRenderer(props) {
  const [viewActions, seViewActions] = useState(false);
  const [rowContextAnchorEl, setRowContextAnchorEl] = useState(null);  
  const rowGDRef = useRef()
  const contextManuRef = useRef()
  

  const onContextMenu = (event) =>{
    event.preventDefault();
    props.setRowContext(props.row)
    var rect = event.target.getBoundingClientRect();
    var left = event.clientX - rect.left; //x position within the element.
    var top = event.clientY - rect.top;   
    props.setRowContextAnchorPosition({ top: event.clientY, left: event.clientX })                    
    //console.log('onClick event ', left, top, event)
    props.setRowContextAnchorEl(event.target);
    event.stopPropagation();

  }

  const actions = [
    {
      icon: <DragIndicatorIcon       style={{
        cursor: 'move'
      }} color="disabled" fontSize="small"/>,
      callback() {
      }
    }, 
    {
      icon: <DeleteIcon       style={{
        cursor: "pointer"
      }} color="disabled" fontSize="small"/>,
      callback() {
        props.onItemDelete(props.tableId, props.row);
      }
    },          
    {
      icon: <OpenInNewIcon       style={{
        cursor: "pointer"
      }} color="primary" fontSize="small"/>,
      callback() {
        props.handleEditItemById( props.row._id)
      }
    }
                     
  ];

  const onItemDeleteHandler = e => {
    props.onItemDelete(props.tableId, props.row)
    setRowContextAnchorEl(null)
  }

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'box',
    item: { index: props.rowIdx, type: 'ROW_DRAG' },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'ROW_DRAG',
    drop({ index, type }) {
      if (type === 'ROW_DRAG') {
        props.onRowReorder(index, props.rowIdx);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const className = clsx(
    props.className,
    {
      'rdg-row-dragging': isDragging,
      'rdg-row-over': isOver
    }
  );


  return (
    <>
    <div ref={useCombinedRefs(preview, drop)} 
    onMouseEnter={e=>seViewActions(true)}
    onMouseLeave={e=>seViewActions(false)}   
    id={"row-main-"+props.rowIdx}
    >
      <div  
      id={"row-main-"+props.rowIdx}
      style={{
        top: props.top,
        contain: "strict",
        display: "flex",
        position: "absolute",
        left: 0,
        width: "var(--row-width)",
        height: props.height,
        //lineHeight: "var(--row-height)",
        visibility: "hidden",
        zIndex:99
        //background-color: #fff;        
      }}
      >
        <div
          style={{
            //backgroundColor: 'green',
            width: '60px',
            height: '25px',
            //position:"absolute",
            display: "inline-block",
            //marginRight: '0.75rem',
            //cursor: 'move',
            left: 0,
            paddingLeft: '3px',
            paddingTop: '3px',
            visibility: "visible",
            zIndex: 99,
            position: "-webkit-sticky",
            position: "sticky",

          }}

        >
          {(!viewActions && !props.selectedCellIdx) ?
            <Grid container >
              <Grid item xs={3}>
                <Box color="text.disabled" fontSize={11}>{(props.rowIdx + 1)}</Box>
              </Grid>
            </Grid> :
            <Grid container >
               <Grid item xs={4}>
               {!props.sharedView &&<span ref={drag} key={'drag-drop-row-icon' + props.rowIdx}>
                  <DragIndicatorIcon style={{
                    cursor: 'move'
                  }} color="disabled" fontSize="small" />
                </span>}
              </Grid>
              <Grid item xs={4}> 
              {!props.sharedView && <span ref={contextManuRef} key={'context-menu-row-icon' + props.rowIdx} onClick={event=>{
                  setRowContextAnchorEl(contextManuRef.current);               
              }}>
                  <ArrowDropDown style={{
                    cursor: 'pointer'
                  }} color="disabled" fontSize="small" />
                </span> }             
              </Grid>
              <Grid item xs={4}>
                <span key={'open-edit-row-icon' + props.rowIdx} onClick={e => props.handleEditItemById(props.row._id)}>
                  <OpenInNewIcon style={{
                    cursor: "pointer",
                    color: "#66afe9"
                  }} fontSize="small" />
                </span>
              </Grid>
            </Grid>}

        </div>
        <Popover 
        id={"row-context-menu-last"+props.rowIdx}
        open={Boolean(rowContextAnchorEl)}
        anchorEl={rowContextAnchorEl}
        //anchorPosition={props.open}
        onClose={e=>setRowContextAnchorEl(null)}
        //transition
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      //        style={{minWidth:"250px"}}
      >

            <MenuList id="row-context-menu-list" dense>
            <MenuItem onClick={onItemDeleteHandler}><ListItemIcon><DeleteIcon fontSize="small"/></ListItemIcon>Удалить строку</MenuItem>
            <MenuItem ><ListItemIcon><ArrowDownwardIcon fontSize="small"/></ListItemIcon>Добавить строку перед</MenuItem>
            <MenuItem ><ListItemIcon><ArrowUpwardIcon fontSize="small"/></ListItemIcon>Добавить строку после</MenuItem>
          </MenuList> 

          </Popover>   
      </div>
      </div>
    <GridRow
      //ref={useCombinedRefs(drag, drop)}
      ref = {rowGDRef}
      rowIdx={props.rowIdx}
      //height={100}
      isRowSelected={props.isRowSelected}
      className={className}
      {...props}
      left={props.left+50}
      onContextMenu={onContextMenu}
    />
    </>
  );
}