import {useState, useCallback, useEffect} from 'react'
//import ym from 'react-yandex-metrika';
const storageName = 'userData';

export const useAuth = () =>{
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [ready, setReady] = useState(false);

    const login = useCallback((jwtToken, id) =>{
        setToken(jwtToken);
        setUserId(id);
        if(window.ym){
            console.log('window.ym !!!')
            window.ym(53785333, 'userParams', {
                //vip_status: true,
                //child: 1,
                //child_age: 13,
                UserID: id
            });            
        }
        //console.log('window.ya ',window)
        //ym('userParams', {UserID: id});
        localStorage.setItem(storageName, JSON.stringify({userId:id, token:jwtToken}));
    }, []);

    const logout = useCallback(() =>{
        setToken(null);
        setUserId(null);

        localStorage.removeItem(storageName);
    }, []);

    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem(storageName));

        if(data && data.token){
            login(data.token, data.userId);
        }
        setReady(true);
    }, [login]);

    return {login, logout, token, userId, ready}
}