import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MemberFormEditor from './WorkItemsTable/MemberFormEditor'
import ChatMemberEditor from './WorkItemsTable/ChatMemberEditor'
import SelectFormEditor from './WorkItemsTable/SelectFormEditor'
import LinkFormEditor from './WorkItemsTable/LinkFormEditor'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import AttachmentsEditor from './WorkItemsTable/AttachmentsEditor';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";

export default function ComponentForField({columns, setEditedItem, editedItem, column, setEdited, members, chatmembers, tables, currentMember}){

    const lookupValue = (col) => {
      let lookupColumn = columns.find(cl => cl._id === col.lookupId)
      if (lookupColumn) {
        if (tables[lookupColumn.linkId] && editedItem[lookupColumn._id]) {
          let item = tables[lookupColumn.linkId].items[editedItem[lookupColumn._id]]
          if (item && col.lookupField && item[col.lookupField]) {
            var fieldCol = tables[lookupColumn.linkId].columns.find(cl => cl._id === col.lookupField)
  
            if (fieldCol && fieldCol.type === 'member') {
              var member = members.find(mb => mb._id === item[col.lookupField])
              if (member) {
                return member.name
              }
  
            }
            return item[col.lookupField]
          }
  
        }
      }
      return ""
    } 
    return (
      <Grid container alignItems="flex-end" style={{marginBottom:10}}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">{column.name}:</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {column.type === "member" ? (
                    <MemberFormEditor
                      value={editedItem[column._id]}
                      inForm={true}
                      disabled={column.is_formula || !column.editable}
                      onChange={value => {
                        const newVal = value;
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                        })
                        setEdited(true)
                      }}
                      members={members}
                      rowHeight={"25px"}
                      //menuPortalTarget={p.editorPortalTarget}
                      //onCreateOption={handleCreateColumnOpinion}
                      noOptionsMessage="Нет значений для выбора!"
                    />
                  ) :
                    (column.type === "chatmember") ? (
                      <ChatMemberEditor
                        value={editedItem[column._id]}
                        inForm={true}
                        disabled={column.is_formula || !column.editable}
                        onChange={value => {
                          const newVal = value;
                          const colkey = column._id
                          setEditedItem(curItem => {
                            var newItem = { ...curItem }
                            newItem[colkey] = newVal
                            return newItem
                          })
                          setEdited(true)
                        }}
                        members={chatmembers}
                        rowHeight={"25px"}
                        //menuPortalTarget={p.editorPortalTarget}
                        //onCreateOption={handleCreateColumnOpinion}
                        noOptionsMessage="Нет значений для выбора!"
                      />
                    ) : (column.type === "select") ? (
                      <SelectFormEditor
                        value={editedItem[column._id]}
                        disabled={column.is_formula || !column.editable}
                        inForm={true}
                        editable={column.editable}
                        onChange={value => {
                          const newVal = value;
                          const colkey = column._id
                          setEditedItem(curItem => {
                            var newItem = { ...curItem }
                            newItem[colkey] = newVal
                            return newItem
                          })
                          setEdited(true)
                        }}
                        options={column.options}
                        rowHeight={"25px"}
                        //menuPortalTarget={p.editorPortalTarget}
                        //onCreateOption={handleCreateColumnOpinion}
                        noOptionsMessage="Нет значений для выбора!"
                      />
                    ) : (column.type === "link" && column.linkId && tables[column.linkId]) ? (
                      <LinkFormEditor
                        //options, linkId, titleColName
                        value={editedItem[column._id]}
                        titleCol={column.linkViewField}
                        filterView={column.linkSelectView}                      
                        inForm={true}
                        editable={column.editable}
                        tables={tables}
                        linkId={column.linkId}
                        currentMember={currentMember}
                        members={members}
                        chatmembers = {chatmembers}
                        onChange={value => {
                          const newVal = value;
                          const colkey = column._id
                          setEditedItem(curItem => {
                            var newItem = { ...curItem }
                            newItem[colkey] = newVal
                            return newItem
                          })
                          setEdited(true)
                        }}
                        //options={props.tables[column.linkId].itemsList}//{props.tables}
                        rowHeight={"25px"}
                        //menuPortalTarget={p.editorPortalTarget}
                        //onCreateOption={handleCreateColumnOpinion}
                        noOptionsMessage="Нет значений для выбора!"
                      />
                    ) : (column.type === "lookup" && column.lookupId && column.lookupField) ? (
                      <TextField
                        id="item_lookup_editor"
                        multiline
                        value={lookupValue(column)}
                        disabled={column.is_formula || !column.editable}
                        //placeholder="Введите название для вида"
                        //helperText=""                            
                        margin="normal"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : (column.type === "number") ? (
                      <TextField
                        id="item_number_editor"
                        //label={column.name}
                        disabled={column.is_formula || !column.editable}
                        type="number"
                        //error={Boolean(titleError)}
                        //helperText={titleError}
                        value={editedItem[column._id]}
                        onChange={(e) => {
                          const newVal = e.target.value;
                          const colkey = column._id
                          setEditedItem(curItem => {
                            var newItem = { ...curItem }
                            newItem[colkey] = newVal
                            return newItem
                          })
                          setEdited(true)
                        }}
                        //placeholder="Введите название для вида"
                        //helperText=""                            
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      ) : (column.type === "geolocation") ? (
                        <TextField
                          id="item_geolocation_editor"
                          //label={column.name}
                          //type="number"
                          //error={Boolean(titleError)}
                          //helperText={titleError}
                          disabled={true}
                          value={(editedItem[column._id] && editedItem[column._id].longitude)?`${editedItem[column._id].longitude}, ${editedItem[column._id].latitude}`:"" }                        
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                                <IconButton target="_blank" href={(editedItem[column._id] && editedItem[column._id].longitude)?`https://yandex.ru/maps/?ll=${editedItem[column._id].longitude}%2C${editedItem[column._id].latitude}84&z=18`:"#"} size="small">
                                  <LinkIcon fontSize="small" />
                                </IconButton>
                            )
                          }}
                          
                        />                    
                        ) : (column.type === "attachments") ? (
                          <AttachmentsEditor
                            id="item_attachments_editor"
                            //label={column.name}
                            //type="number"
                            //error={Boolean(titleError)}
                            //helperText={titleError}
                            view="list"
                            maxHeight="250px"
                            value={editedItem[column._id] }                        
                            onChange={(e) => {
                              const newVal = e.target.checked;
                              const colkey = column._id
                              setEdited(true)
                            }}     
                                                 
                          />                    
                      ) : (column.type === 'check') ? (
                      <Checkbox
                        checked={editedItem[column._id]}
                        disabled={column.is_formula || !column.editable}
                        color="primary"
                        onChange={(e) => {
                          const newVal = e.target.checked;
                          const colkey = column._id
                          setEditedItem(curItem => {
                            var newItem = { ...curItem }
                            newItem[colkey] = newVal
                            return newItem
                          })
                          setEdited(true)
                        }} />
                    ) : (column.type === 'multiline') ? (
                      <ReactQuill
                      id="item_text_editor"
                      theme="snow"
                      disabled={column.is_formula || !column.editable}
                      //placeholder="Напишите содержание..."
                      defaultValue={editedItem[column._id]}
                      modules={{
                        toolbar: [
                          //[{ 'header': [1, 2, false] }],
                          ['bold', 'italic', 'underline','strike', 'blockquote'],
                          //[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                          ['link'],
                          //['clean']
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        //'list', 'bullet', 'indent',
                        'link'
                      ]}
                      onChange={(content, delta, source, editor) => {
                        const newVal = content//editor.getHTML();
                        const colkey = column._id
                        setEditedItem(curItem => {
                          var newItem = { ...curItem }
                          newItem[colkey] = newVal
                          return newItem
                        })
                        setEdited(true)
                      }}                                        
                      //onContextMenu={event => event.stopPropagation()}
                      //onPaste={event => event.stopPropagation()}
                      //onCopy={event => event.stopPropagation()}              
                      //onBlur={handleChangeDesc}            
                      />                       
                    ) : (column.type === 'date') ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                        <KeyboardDateTimePicker
                          disabled={column.is_formula || !column.editable}
                          disableToolbar
                          variant="inline"
                          format="dd.MM.yyyy HH:mm"
                          id="item-editor-date-picker-inline"
                          //label={column.name}
                          value={editedItem[column._id]}
                          onChange={(e) => {
                            const newVal = e;
                            const colkey = column._id
                            setEditedItem(curItem => {
                              var newItem = { ...curItem }
                              newItem[colkey] = newVal
                              return newItem
                            })
                            setEdited(true)
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    ) :
                      <TextField
                        id="item_text_editor"
                        //label={column.name}
                        //type="number"
                        //error={Boolean(titleError)}
                        //helperText={titleError}
                        disabled={column.is_formula || !column.editable}
                        value={editedItem[column._id]}
                        onChange={(e) => {
                          const newVal = e.target.value;
                          const colkey = column._id
                          setEditedItem(curItem => {
                            var newItem = { ...curItem }
                            newItem[colkey] = newVal
                            return newItem
                          })
                          setEdited(true)
                        }}
                        //placeholder="Введите название для вида"
                        //helperText=""                            
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                  }
                </Grid>
              </Grid>
    )
  }