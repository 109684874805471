import React, { useEffect, useState, useCallback, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useHttp} from '../hooks/http.hook';
import {AuthContext} from '../context/AuthContext'
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import DialogActions from '@material-ui/core/DialogActions';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import IconButton from '@material-ui/core/IconButton';
import MaskedInput from "react-input-mask";

function Copyright() { 
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://dinabot.com/">
        Динатех ООО
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  expInputRoot: {
    margin:'-8px 0 0 14px'
  }
}));



const ExpirationMask = props => {
  const { inputRef, ...other } = props

  return <MaskedInput
      {...other}
      ref={ref => {inputRef(ref ? ref.inputElement : null)}}
      mask={['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',  /\d/, /\d/]}
      placeholderChar={'\u2000'}
      //maskPlaceholder="+7"
    />
}

export default function Profile() {
    const {token} = useContext(AuthContext);
    const {loading, error, request} = useHttp();
    const [updated, setUpdated] = useState(false);
    const [profile, setProfile] = useState({email:'', firstName:'', lastName:''});
    const classes = useStyles();

    const getProfile = useCallback(async()=>{
        try {
            var data = await request('/api/auth/profile','GET',null, {Authorization: `Bearer ${token}`});
            setProfile(data);
            //console.log('profile data', data);            
        } catch (e) {
          console.log('profile error', error);  
        }
    },[token, error, request])

    useEffect(()=>{
        getProfile();
    }, [getProfile])

    const changeHandle = event => {
        setProfile({...profile, [event.target.name]:event.target.value})
    }

    
      const onSaveHandler = async () => {
        try {
            var data = await request('/api/auth/profile','POST',profile, {Authorization: `Bearer ${token}`});
            console.log('update data', data);
            setUpdated(true);
        } catch (e) {
            console.log('update error', e);
        }
      }   
      
      const onUpdateAPITokenHandler = async () => {
        try {
            var data = await request('/api/auth/updateAPIToken','GET',null, {Authorization: `Bearer ${token}`});
            console.log('update APIToken', data);
            setProfile(data);
        } catch (e) {
            console.log('update error', e);
        }
      }         
      

      if(loading){
          return (<Backdrop open={loading}>
            <CircularProgress color="inherit" />
            </Backdrop>)
      }

    return (

<Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Ваш профиль:
      </Typography>
      <form className={classes.form} noValidate>
        {error && <Alert variant="filled" severity="error" onClose={() => {}}>{error}</Alert>}
        {updated && <Alert variant="filled" severity="success" onClose={() => {setUpdated(false);}}>Ваши данные обновлены</Alert>}
        <p className={classes.text}>Логин: {profile.email}</p>
        <Grid container spacing={2}>        
        <Grid item xs={12} sm={6}>
              <TextField
                value={profile.firstName}
                name="firstName"
                variant="outlined"
                onChange={changeHandle}
                required
                fullWidth
                id="firstName"
                label="Имя"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                onChange={changeHandle}
                fullWidth
                id="lastName"
                label="Фамилия"
                name="lastName"
                value={profile.lastName}
              />
            </Grid>

          <Grid item xs={12}>
          <TextField
                variant="outlined"
                required
                value={profile.phone}
                onChange={changeHandle}
                fullWidth
                id="phone"
                label="Телефон"
                name="phone"
                autoComplete="tel"
                InputProps={{
                  inputComponent: ExpirationMask 
                }}
              />
            </Grid>
          <Grid item xs={9}>
            <TextField              
              name="APIToken"
              variant="outlined"
              value={profile.APIToken}
              disabled
              fullWidth
              id="APIToken"
              label="Token для внешних API"
              InputProps={{
                endAdornment: (
                  <CopyToClipboard text={profile.APIToken?profile.APIToken:""}>
                  <IconButton size="small">
                    <FilterNoneIcon fontSize="small"/>
                  </IconButton>
                </CopyToClipboard>  
                )
              }}    
            />           
          </Grid>     
          <Grid item xs={3}>
            <Button onClick={onUpdateAPITokenHandler}>Обновить</Button> 
          </Grid>       
        </Grid>
        <DialogActions>
            <Button onClick={onSaveHandler}>Сохранить</Button>    
        </DialogActions>  
      </form>
    </div>
    <Box mt={5}>
      <Copyright />
    </Box>
  </Container>)
}
    


