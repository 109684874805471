import React, { useEffect, useState, useCallback, useContext } from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useMessage} from '../hooks/message.hook';
import {useHttp} from '../hooks/http.hook';
import {AuthContext} from '../context/AuthContext'
import Alert from '@material-ui/lab/Alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://dinabot.com/">
        Динатех ООО
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Invite() {
  const {token} = useContext(AuthContext);
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const inviteToken = useQuery().get("inviteToken");
  const inviteId = useParams().inviteId
  const [errorMsg, setErrorMsg] = useState(null);
    const message = useMessage();
    const {loading, request, error, clearError}= useHttp();
    const [form, setForm] = React.useState({email:null, password:null});


    const goToWi = useCallback(async()=>{
      try {
          var data = await request(`/api/invite/${inviteId}`,'POST',{inviteToken:inviteToken}, {Authorization: `Bearer ${token}`});
          console.log('goToWi data', data); 
          history.push(`/db/${data.database}`);           
      } catch (e) {
          //console.log('onCopyHandle error', e); 
          setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
      }
  },[token, inviteId, inviteToken, request])       

    useEffect(()=>{           
      //var inviteToken = query.get("inviteToken")
      console.log('Invite useEffect', inviteToken, inviteId) 
      if(inviteId && inviteId.length>0 && inviteToken && inviteToken.length>0){
        //history.push("/"+query.get("inviteId")+"?inviteToken="+query.get("inviteToken"))  
        goToWi()   
      }            
    }, [inviteToken, inviteId])    

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>        
        {errorMsg && <Alert variant="filled" severity="error" onClose={() => {setErrorMsg(null)}}>{errorMsg}</Alert>}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}