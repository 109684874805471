import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';

function ExpandIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z" />
    </SvgIcon>
  );
}

function CollapseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M19.5,3.09L15,7.59V4H13V11H20V9H16.41L20.91,4.5L19.5,3.09M4,13V15H7.59L3.09,19.5L4.5,20.91L9,16.41V20H11V13H4Z" />
    </SvgIcon>
  );
}

function normalize_count_form (number, words_arr) {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    let options = [2, 0, 1, 1, 1, 2];
    return words_arr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
  }
  return words_arr[1];
}
const DinaLaneFooterComponent = ({
  id, collapsed, cardsLength, openKanbanCardAddDialog, onClick, laneId, color, onKanbanLaneClick, sharedView, updateTitle, canAddLanes, onDelete, onDoubleClick, editLaneTitle, label, title, titleStyle, labelStyle, t, laneDraggable
}) => {
  const addItemHandler = () => {
    openKanbanCardAddDialog(id)
  }


  return (
    collapsed?
    <div>
      <div
      style={{            
        position: "relative",            
        height: "30px",
        margin: "5px 0px 0px 0px",
        minInlineSize: "10em",
        /* border: 1px solid red; */
        textAlign: "center",
        writingMode: "vertical-rl",
        transform: "scale(-1)"             
        //font-weight: bold;
      }} 
    //onClick={onClick}
    >   
    <span>{cardsLength?cardsLength:0} карт </span>  
     </div> 
        <div      
        >        
        <IconButton size="small" onClick={onClick}>            
        {collapsed ?<ExpandIcon fontSize="small"/>:<CollapseIcon fontSize="small"/>}
              </IconButton>                              
        </div>
      </div>                      
    :
    <div
      style={{            
        position: "relative",            
        height: "30px",
        margin: "5px 0px 0px 0px",
        //font-weight: bold;
      }} 
    //onClick={onClick}
    >
      <div
      style={{
        float: "left"
      }}          
      >         
      <span>{" "+cardsLength +" "+ normalize_count_form(cardsLength, ['карта', 'карты', 'карт']) }  </span>
      </div> 
      {!sharedView && 
      <div
      style={{
        position: "absolute",
        left: "50%",
        top:"-15px",
        transform: "translate(-50%)"
      }}          
      >
      <IconButton 
      //size="small" 
      onClick={addItemHandler} color="primary">            
              <AddCircleIcon fontSize="large"/>
            </IconButton>
        </div>}
        <div
      style={{
        float: "right"
      }}          
      >        
      <IconButton size="small" onClick={onClick}>            
      {collapsed ?<ExpandIcon fontSize="small"/>:<CollapseIcon fontSize="small"/>}
            </IconButton>                              
      </div>
    </div>      
  )
}

DinaLaneFooterComponent.propTypes = {
  id: PropTypes.string, 
  collapsed: PropTypes.bool, 
  cardsLength: PropTypes.number,
  openKanbanCardAddDialog: PropTypes.func,
  laneId: PropTypes.string,
  color: PropTypes.string, 
  onKanbanLaneClick: PropTypes.func,
  sharedView: PropTypes.bool,
  updateTitle: PropTypes.func,
  editLaneTitle: PropTypes.bool,
  canAddLanes: PropTypes.bool,
  laneDraggable: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onDoubleClick: PropTypes.func,
  t: PropTypes.func.isRequired
}

DinaLaneFooterComponent.defaultProps = {
  updateTitle: () => {},
  editLaneTitle: false,
  canAddLanes: false
}

export default DinaLaneFooterComponent;
