import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import AttachmentsEditor from './AttachmentsEditor';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export default function AttachmentsTableEditor({ onClose, dbId, wiId, itemId, value, colId }) {
  const rowContextAnchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const handleCloseRowContextMenu = ()=> {
    setOpen(false)
    onClose()
  }

  useEffect(()=>{
    setOpen(true)
  }, [])

    return (      
      <React.Fragment>
      <div
      ref={rowContextAnchorEl}
      >
            <span>{value && value.length>0?value.length:"нет "} файлов</span>
            </div>
      <Popover
        id={"row-attachments-editor"}
        open={open}
        anchorEl={rowContextAnchorEl?rowContextAnchorEl.current:null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseRowContextMenu}
      //        style={{minWidth:"250px"}}
      >
        <ClickAwayListener onClickAway={handleCloseRowContextMenu}>
        <div onContextMenu={event => event.stopPropagation()}><AttachmentsEditor value={value} dbId={dbId} wiId={wiId} itemId={itemId} colId={colId} clickable={true}/></div>
        </ClickAwayListener>
      </Popover>
      </React.Fragment>
    );
  }