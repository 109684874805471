import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles, useTheme, withStyles, createTheme } from '@material-ui/core/styles';
import { useHistory, useParams, Link } from 'react-router-dom'
import { AutoSizer } from 'react-virtualized';
import DataGrid,  { Row as GridRow, useRowSelection} from 'dina-react-data-grid';
import chroma from 'chroma-js';
import { ContextMenu, MenuItem as MenuItemDom, SubMenu, ContextMenuTrigger } from 'react-contextmenu';
import { hideMenu } from 'react-contextmenu/modules/actions'
import MenuItem from '@material-ui/core/MenuItem';
import {AuthContext} from '../../context/AuthContext'
import {useHttp} from '../../hooks/http.hook';
//import './my_react_data_grid.css';
import './contextmenu.css';
import { DndProvider } from 'react-dnd';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CellActionsFormatter from './CellActionsFormatter'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import DraggableHeaderRenderer  from './DraggableHeaderRenderer';
import DraggableRowRenderer  from './DraggableRowRenderer';
import AddColHeaderRenderer  from './AddColHeaderRenderer';
import MenuColHeaderRenderer from './MenuColHeaderRenderer';
import TextField from '@material-ui/core/TextField';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Checkbox from '@material-ui/core/Checkbox';
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from "@date-io/moment";
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from 'moment-timezone'
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import TableContainer from '@material-ui/core/TableContainer';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import SyncIcon from '@material-ui/icons/Sync';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Chip from '@material-ui/core/Chip';
import { ThemeProvider } from "@material-ui/styles";
import { Scrollbars } from 'react-custom-scrollbars';
import Avatar from '@material-ui/core/Avatar';
import MemberEditor from './MemberEditor'
import ChatMemberEditor from './ChatMemberEditor'
import SelectEditor from './SelectEditor'
import LinkEditor from './LinkEditor'
import ColumnSettingsDialog from './ColumnSettingsDialog'
import DatePicker, {registerLocale} from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import ru from 'date-fns/locale/ru';
import 'react-virtualized/styles.css';
import { format } from 'date-fns';
import { groupBy as groupBy } from 'lodash';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import AttachmentsTableEditor from './AttachmentsTableEditor';
import AttachmentsEditor from './AttachmentsEditor';
import LookupAttachmentsViewer from './LookupAttachmentsViewer';
import MultilineTableEditor from './MultilineTableEditor';
import SummaryRowEditor from './SummaryRowEditor';
import "moment/locale/ru";
import { range } from "lodash";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';
//import DatePicker from "react-multi-date-picker"
//import Datetime from 'react-datetime';
//registerLocale('ru', ru)
//import { DatePicker } from 'react-nice-dates'
//import 'react-nice-dates/build/style.css'
moment.locale("ru");

const dateFormatter = new Intl.DateTimeFormat(navigator.language);

const materialTheme = createTheme({
  overrides: {
    MuiFormControl: 
    {
      marginNormal:{
        marginTop: "0px",
        marginBottom: "0px"
      }
    },
    MuiInputBase:{
      input: {
        padding:"0px"
      }
    },
    MuiInputAdornment: {
      positionEnd:{
        marginLeft: "0px"
      } 
    }

  },
});

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

//const respondents = [{value:'1234', label:'Петров Игорь'}, {value:'3421', label:'Иванов Иван'}];
//const respondentsMap = {'1234':'Петров Игорь', '3421':'Иванов Иван'};

function AddRowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M22,10A2,2 0 0,1 20,12H4A2,2 0 0,1 2,10V3H4V5H8V3H10V5H14V3H16V5H20V3H22V10M4,10H8V7H4V10M10,10H14V7H10V10M20,10V7H16V10H20M11,14H13V17H16V19H13V22H11V19H8V17H11V14Z" />
    </SvgIcon>
  );
}

function AddColumnIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M11,2A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H2V2H11M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M15,11H18V8H20V11H23V13H20V16H18V13H15V11Z" />
    </SvgIcon>
  );
}

function getChatMemberLabel(p, members) {
  //console.log('getChatMemberLabel p ', p)
  if(p.row[p.column._id] && members){
    var res = members.find(r => r._id === p.row[p.column._id])
    if(res){
      //console.log('getChatMemberLabel res', res)
      return res.name
    }
    
  }
  //p.row[p.column.key] && props.respondents && props.respondents.length>0)?props.respondents.find(r => r.value === p.row[p.column.key]).label:null  
  return null;
}

function getMemberLabel(p, members) {
  if(p.row[p.column._id] && members){
    var res = members.find(r => r._id === p.row[p.column._id])
    if(res){
      return res.name
    }
    
  }
  if(p.row[p.column._id] ==='exly_demo_resp1'){
    return "Петров Игорь"
  } else if(p.row[p.column._id] ==='exly_demo_resp2'){
    return "Иванова Ольга"
  } else if(p.row[p.column._id] ==='exly_demo_resp3'){
    return "Сидоров Антон"
  } else if(p.row[p.column._id] ==='exly_demo_resp4'){
    return "Самойлова Юля"
  } else if(p.row[p.column._id] ==='exly_demo_resp5'){
    return "Симонова Анна"
  }
  //p.row[p.column._id] && props.respondents && props.respondents.length>0)?props.respondents.find(r => r.value === p.row[p.column._id]).label:null  
  return null;
}

function CheckboxEditor2({ value, onChange, rowHeight, rowIdx, colKey, isFormater, editable, rowId, tableId }) {
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if(inputRef.current && !isFormater){
      //console.log('Автофокус тут 2!')
      inputRef.current.focus({ preventScroll: true });
      //inputRef.current.select();
    }
  }, []);

  return (
    <div
    style={{
      height: "var(--rdg-row-height)", 
      width: '100%', 
      fontSize: 13,       
      left:"6px",
      top:"3px",           
    }}    
    >
    <input
      ref={inputRef}
      checked={value}
      disabled={!editable}
      style={{
        outlineWidth:0, 
        //outlineColor: "#66afe9", 
        width: '100%', 
        fontSize: 13, 
      }}         
      type="checkbox" //_tableId, rowId, value, rix, colKey
      onChange={event => onChange(event.target.checked, rowIdx, colKey)} //tableId, rowId, event.target.checked, rowIdx, colKey)}
      //onKeyDown={event => {
      //  if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      //    event.stopPropagation();
      //  }
      //}}
    />
    </div>
  );
}

function DateEditor2(props) {
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const [value, setValue] = useState(null);

  useLayoutEffect(() => {
    if(inputRef.current){      
      console.log('Автофокус тут! ', inputRef.current)
      inputRef.current.focus({ preventScroll: true });
      //inputRef.current.setSelectionRange(0, 3);
    }
  }, []);

  const handleFocus = event => {
    
    event.preventDefault();
    const { target } = event;
    console.log('handleFocus ', target)
    //const extensionStarts = target.value.lastIndexOf('.');
    target.focus();
    target.setSelectionRange(0, 2);
  }



  useEffect(() => {
    console.log('WorkItemsTable useEffect!')
    setValue(props.value);
  }, [props.value]);

  return (
//<>
//<div onKeyDown={(e) => {console.log('onKeyDown!!!', e.key); setIsOpen(true)}}>
<MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"ru"}>
<ThemeProvider theme={materialTheme}>
<KeyboardDateTimePicker
  autoFocus
  ampm={false}
  views={["date" , "year" , "month" , "hours" , "minutes"]}
    //open
    //inputRef={inputRef}
    //ref={(r) => { inputRef = r; }}
    style={{
      width: props.width, 
    }}
    inputProps={{
      //ref:inputRef,
      onFocus: handleFocus, 
      autoComplete: "off",
      style:{      
        paddingLeft: 8, 
        paddingTop:0,
        //paddingLeft:0,
        paddingRight:0,
        fontSize: 13,
        height: "var(--rdg-row-height)",          
      }
      }}    
          //disableToolbar
          variant="inline"
          format={props.format?props.format:"dd.MM.yyyy HH:mm"}//"dd.MM.yyyy HH:mm"
          id="date-picker-inline"
          //label="Date picker inline"
          value={value}
          onChange={date => setValue(date)}
          onAccept={dt=> props.onChange(dt, props.rowIdx, props.colKey)}          
          KeyboardButtonProps={{
            style:{
              padding:0
            }
          }}
          keyboardIcon={
                <ArrowDropDownIcon fontSize="small" />
          }
          onKeyDown={event => {
            if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
              event.stopPropagation();
            } else if(event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowUp'){
              props.onChange(value, props.rowIdx, props.colKey)
            } else if(event.key === 'Escape'){
              props.onClose()              
            }
            }}          
          //TextFieldComponent={<ExampleCustomInput />}
                  
  />  
  </ThemeProvider>
</MuiPickersUtilsProvider>

/*
<DateTimePicker
    ref={(r) => { inputRef = r; }}
    //errorStyle={componentSyles.error}
    //textFieldStyle={componentSyles.textField}    
    DateTimeFormat={Intl.DateTimeFormat}
    cancelLabel={"Отмена"}
    //variant="dialog"
    format="dd.MM.yyyy HH:mm"
    open={isOpen}
    onOpen={() => setIsOpen(true)}
    onClose={() => setIsOpen(false)}    
    autoOk={true}
    showTodayButton
    value={props.value}
    onChange={date => props.onChange(date, props.rowIdx, props.colKey)}
    {...props}
     />
<DateTimePicker
    ref={(r) => { inputRef = r; }}
    //errorStyle={componentSyles.error}
    //textFieldStyle={componentSyles.textField}
    DateTimeFormat={Intl.DateTimeFormat}
    cancelLabel={"Отмена"}
    variant="dialog"
    format="dd.MM.yyyy HH:mm"
    open={isOpen}
    onOpen={() => setIsOpen(true)}
    onClose={() => setIsOpen(false)}    
    autoOk={true}
    value={props.value}
    onChange={date => props.onChange(date, props.rowIdx, props.colKey)}
    {...props}
     />
*/
//</div>
//<div
//    onClick={(e) => { inputRef.current.focus({ preventScroll: true }) }}
//    style={{ height: props.rowHeight - 1, width: '100%', paddingLeft: 6, fontSize: 14 }}>
//    {moment(props.value).format("DD.MM.YYYY HH:mm")}
//</div>
//</>
)
}

function NumberEditor({ value, onChange, rowHeight, rowIdx, colKey }) {
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if(inputRef.current){
      //console.log('Автофокус тут!')
      inputRef.current.focus({ preventScroll: true });
      //inputRef.current.select();
    }
  }, []);

  return (
    <input
      ref={inputRef}
      type="number"
      style={{
        outlineWidth:"3px", 
        outlineColor: "#66afe9", 
        height: "var(--rdg-row-height)", 
        width: '100%', 
        paddingLeft: 6, 
        fontSize: 13 
      }}
      defaultValue={value}
      onBlur={event => onChange(event.target.value)}
      //onChange={event => onChange(event.target.value)}
      onContextMenu={event => event.stopPropagation()}
      onKeyDown={event => {
        //console.log('onKeyDown ', event.key)
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
          event.stopPropagation();
        } else if(event.key === 'Enter'){
          if (!inputRef.current) return;
          //console.log('inputRef.current ', inputRef.current.value)
          const updated = inputRef.current.value;
          onChange(updated)
        }
      }}
    />
  );
}

function TextEditor({ value, onChange, rowHeight, rowIdx, colKey }) {
  const inputRef = useRef(null);

  const onPasteHandler  = event => {
    console.log('onPasteHandler ', event)
    event.stopPropagation();
    //event.stopImmediatePropagation()
  }


  useLayoutEffect(() => {
    if(inputRef.current){
      //console.log('Автофокус тут!')
      inputRef.current.focus({ preventScroll: true });
      //inputRef.current.select();
    }
  }, []);

  return (
    <input
      ref={inputRef}
      //type="number"
      style={{
        outlineWidth:"3px", 
        outlineColor: "#66afe9", 
        height: "var(--rdg-row-height)", 
        width: '100%', 
        paddingLeft: 6, 
        fontSize: 13 
      }}
      defaultValue={value}
      onBlur={event => onChange(event.target.value)}
      //onPaste={onPasteHandler}
      //onChange={event => onChange(event.target.value)}
      onContextMenu={event => event.stopPropagation()}
      onKeyDown={event => {
        //console.log('onKeyDown ', event.key)
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Home' || event.key === 'End') {
          event.stopPropagation();
        } else if(event.key === 'Enter'){
          if (!inputRef.current) return;
          //console.log('inputRef.current ', inputRef.current.value)
          const updated = inputRef.current.value;
          onChange(updated)
        }
      }}
    />
  );
}

function GeoLocationEditor({ value, onChange, rowHeight, rowIdx, colKey }) {
  const inputRef = useRef(null);

  const onPasteHandler  = event => {
    console.log('onPasteHandler ', event)
    event.stopPropagation();
    //event.stopImmediatePropagation()
  }


  useLayoutEffect(() => {
    if(inputRef.current){
      //console.log('Автофокус тут!')
      inputRef.current.focus({ preventScroll: true });
      //inputRef.current.select();
    }
  }, []);

  return (
    <input
      ref={inputRef}
      //type="number"
      style={{
        outlineWidth:"3px", 
        outlineColor: "#66afe9", 
        height: "var(--rdg-row-height)", 
        width: '100%', 
        paddingLeft: 6, 
        fontSize: 13 
      }}
      defaultValue={value}
      onBlur={event => onChange(event.target.value)}
      onPaste={onPasteHandler}
      //onChange={event => onChange(event.target.value)}
      onContextMenu={event => event.stopPropagation()}
      onKeyDown={event => {
        //console.log('onKeyDown ', event.key)
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Home' || event.key === 'End') {
          event.stopPropagation();
        } else if(event.key === 'Enter'){
          if (!inputRef.current) return;
          //console.log('inputRef.current ', inputRef.current.value)
          const updated = inputRef.current.value;
          onChange(updated)
        }
      }}
    />
  );
}

function onCopyToBuffer(v) {
  console.log('onCopyToBuffer ', v)
  if (window.isSecureContext) {
    navigator.clipboard.writeText(v);
  }          
}

function GroupRowDiv ({ children, rowHeight, whiteSpace, isExpanded }) {
  //const [isOverflowed, setIsOverflow] = useState(false);
  //const textElementRef = useRef();
  //useEffect(() => {
    //console.log('textElementRef.current.scrollHeight ', textElementRef.current.scrollHeight, rowHeight)
  //  setIsOverflow(textElementRef.current.scrollHeight > rowHeight);
  //}, []);
  var ex_icon = isExpanded?<ArrowDropDownOutlinedIcon style={{verticalAlign:"middle", marginRight:"5px"}}  color="action"/>:<ArrowRightOutlinedIcon style={{verticalAlign:"middle", marginRight:"5px"}} color="action"/>

  return (
      <div
        //ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: 'hidden',
          //textOverflow: 'ellipsis',
          lineHeight: "normal", 
          height: "35px", 
          width: '100%',
          display: "table-cell",
          verticalAlign:"middle",
          //paddingTop: "3px",
          //left: "6px",                  
          fontSize:13
        }}
      >{ex_icon} 
        {children}
      </div>    
  );
};  

function OverflowTip ({ children, rowHeight, whiteSpace }) {
  //const [isOverflowed, setIsOverflow] = useState(false);
  //const textElementRef = useRef();
  //useEffect(() => {
    //console.log('textElementRef.current.scrollHeight ', textElementRef.current.scrollHeight, rowHeight)
  //  setIsOverflow(textElementRef.current.scrollHeight > rowHeight);
  //}, []);
  return (
      <div        
        //ref={textElementRef}        
        style={{
          whiteSpace: whiteSpace,
          overflow: 'hidden',
          //textOverflow: 'ellipsis',
          lineHeight: "normal", 
          whiteSpace: "normal", 
          height: "var(--rdg-row-height)", 
          width: '100%',
          //display: "table-cell",
          //verticalAlign: "text-top",
          paddingTop: "3px",
          //left: "6px",          
          fontSize:13
        }}
      >
        {children}
      </div>    
  );
};  


function createRows() {
  const rows = [];
  for (let i = 1; i < 1000; i++) {
    rows.push({
      id: i,
      product: 'new product '+i,
      price: 99+i
    });
  }
  return rows;
}



const defaultParsePaste = str => {
  //console.log(str);
  return str.split(/\r\n|\n|\r/).map(row => row.split("\t"));
};

const changedValues = {};

export default function WorkItemsTable(props) {
  const {token} = useContext(AuthContext);
  const {loading, error, request} = useHttp();
  const history = useHistory();
  const [filters, setFilters] = useState({ });   
  const [saving, setSaving] = useState(true);
  const [saveError, setSaveError] = useState(null);
  const addColRef = React.useRef();
  //const [workitemlist, setWorkItemList] = useState({items:[], columns:[]});
  //const [respondents, setRespondents] = useState([]);
  //const [respondentsMap, setRespondentsMap] = useState({});
  //const jobId = useParams().jobid;
  //const [rows, setRows] = useState([{"id":1}]);
  const classes = useStyles();
  const [selectionCells, setSelectionCells] = useState({});
  const [selectedCell, setSelectedCell] = useState({});
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [nextId, setNextId] = useReducer((nextId) => nextId + 1, 1000);
  const [[sortColumn, sortDirection], setSort] = useState(['product', 'NONE']);
  //const [sortColumns, setSortColumns] = useState([]);
  const gridRef = useRef(null);
  const scrollbarRef = useRef(null);
  const [anchorElColEdit, setAnchorElColEdit] = React.useState(null);
  const [editingSets, setEditingSets] = React.useState(null);
  const [rowContext, setRowContext] = useState(null);
  const [rowContextAnchorPosition, setRowContextAnchorPosition] = useState(null);
  const [rowContextAnchorEl, setRowContextAnchorEl] = useState(null);
  const [rowContextValue, setRowContextValue] = useState(null);
  //const [expandedGroupIds, setExpandedGroupIds] = useState( () => new Set([]));
  const [mouseOverRowIdx, setMouseOverRowIdx] = useState(null);
  const contextManuRef = useRef()  

  function updateColumsFromWorkitemlist(respMap, resps){
    //console.log('updateColumsFromWorkitemlist respondents', respMap);

        //console.log('updateColumsFromWorkitemlist init_columns 2', init_columns); 
        //setColumns(init_columns);
        //return init_columns;
  }
  /*
  const init = useCallback(async()=>{
    try {                
      setSaving(true);      
         const resMap = {};
         const resps = [];
         for(var ir=0; ir<props.respondents.length; ir++) {
          var resp = props.respondents[ir];
          resMap[resp.channelId] = resp.name;
          resps.push({
            label: resp.name,
            value: resp.channelId
          })
         }
         

         setRespondentsMap(resMap);
         setRespondents(resps);                                

                var workitems = await request(`/api/workitemlist/${props.work_item_list}`,'GET',null, {Authorization: `Bearer ${token}`});
                console.log('getJob workitems', workitems);    
                
                //setWorkItemList(workitems);                                                 
                             
                setSaving(false);         
    } catch (e) {
        console.log('init error', e); 
    }
},[token, request])  

const saveWorkitemlist = useCallback(async(wi)=>{
  try {
    setSaving(true);
    setSaveError(null);
    //workitemlist.items = []
    console.log('saveWorkitemlist workitemlist', wi); 
    if(wi.columns[0].key==='ex_menu'){
      wi.columns.splice(0, 1);
    }
        
        var req_wi = await request(`/api/workitemlist/${props.work_item_list}`,'POST',wi, {Authorization: `Bearer ${token}`});
        console.log('saveWorkitemlist workitemlist data', req_wi);  
        //req_wi.columns = updateColumsFromWorkitemlist(req_wi.columns, respondentsMap, respondents);             
        setWorkItemList(req_wi); 
        //updateColumsFromWorkitemlist(req_wi.colums);
        //setRows(wi.items); 
    setSaving(false);                 
  } catch (e) {
      console.log('saveWorkitemlist error', e); 
      setSaveError('Ошибка сохранения: '+e.message);
      setSaving(false); 
  }
},[token, request]) 
*/



  const handleSort = useCallback((columnKey, direction) => {
    setSort([columnKey, direction]);
  }, []);

  const handleCreateColumnOpinion = () => {

  }

  const handleCloseRowContextMenu = () => {
    setRowContextAnchorEl(null)
  }


  

  function onRowReorder(fromIndex, toIndex) {
    console.log('onRowReorder ', fromIndex, toIndex)

    const fromItem = props.items[fromIndex]
    const toItem = props.items[toIndex]
    
    
    props.onRowReorder(props.tableId, fromIndex, toIndex, fromItem, toItem)
    /*
    const __tableId = _tableId
    const fi = fromIndex;
    const ti = toIndex;
    setTables(currentTables => {
      var newTables = { ...currentTables }
      var newitemsList = [...newTables[__tableId].itemsList];      
      
      var updatedItemsList = [] 
      for (let idx = 0; idx < newitemsList.length; idx++) {
        if (newitemsList[idx].index === fi) {
          newitemsList[idx].index = ti
          updatedItemsList.push(newitemsList[idx])
        } else if ((fi-ti)>0) {
          //Т.е. свинули снизу вверх
         if(newitemsList[idx].index > ti && newitemsList[idx].index<= fromIndex) {
          newitemsList[idx].index = newitemsList[idx].index+1;
          updatedItemsList.push(newitemsList[idx])
         }
        } else if ((ti-fi)>0) {
          //Т.е. свинули снизу вниз
         if(newitemsList[idx].index < ti && newitemsList[idx].index>= fromIndex) {
          newitemsList[idx].index = newitemsList[idx].index-1;
          updatedItemsList.push(newitemsList[idx])
         }           
        } else {        

        }
      }
             
      newTables[__tableId].itemsList = newitemsList
      newTables[__tableId].items = {...newTables[__tableId].items} //чтобы просто объект обновить!
      if (!props.isTemplate && updatedItemsList.length>0) {

        updateItems(__tableId, updatedItemsList) //TODO Сделать групповое обновление
      }        
      return newTables;
    }); 
    //setEdited(true);  
    */
  }   


  function RowRenderer(lprops) {
    //console.log('RowRenderer  lprops', lprops)
    /*
    lprops:

{
    "aria-rowindex": 2,
    "rowIdx": 0,
    "row": {
        "_id": "61a22bee532629bed06fb8e9",
        "617ae02f488c155194d65717": "619aae82211a0a5f27bd4e91",
        "617ae02f488c155194d65719": "yfdjd\n\nxcxcxcxcczxzx\n\ndfl,ds;ld,;lds\n\nd,,ds;sd,s,;sd;sdf",
        ...
        "ex_updatedAt": "2021-11-27T13:01:27.450Z",
        "ex_createdAt": "2021-11-02T15:46:08.363Z",
        "__v": 0
    },
    "viewportColumns": [
        {
            "_id": "ex_menu",
            "name": "",
            "frozen": true,
            "width": 50,
            "minWidth": 40,
            "maxWidth": 50,
            "formatterOptions": {
                "focusable": false
            },
            "idx": 0,
            "isLastFrozenColumn": true,
            "rowGroup": false,
            "sortable": false,
            "resizable": false
        },
        {
            "_id": "617ae02f488c155194d65717",
            "name": "Ответственный",
            "type": "member",
            "desc": "Ответственный менеджер",
            "readonly": false,
            "options": [],
            "resizable": true,
            "sortable": true,
            "editable": true,
            "is_formula": false,
            "date_format": "DD.MM.YYYY HH:mm",
            "index": 1,
            "frozen": false,
            "width": 150,
            "key": "617ae02f488c155194d65717",
            "minWidth": 0,
            "idx": 1,
            "isLastFrozenColumn": false,
            "rowGroup": false
        },
        ...
        {
            "_id": "ex_add_column",
            "name": "+",
            "width": 10,
            "lastColIdx": 24,
            "formatterOptions": {
                "focusable": false
            },
            "idx": 8,
            "frozen": false,
            "isLastFrozenColumn": false,
            "rowGroup": false,
            "sortable": false,
            "resizable": false
        }
    ],
    "isRowSelected": false,
    "top": 25,
    "height": 75,
    "lastFrozenColumnIndex": 0
}    
    */
    if(lprops.row.__ex_new_row){
      //console.log('new_row  lprops', lprops)
      /*
      return (        
        <div style={{top: 0,  height:30}}>  
        <Tooltip title="Добавить строку" aria-label="add">
          <IconButton onClick={(e)=>{ console.log('cellParams ', lprops); props.insertRow( { rowIdx:lprops.rowIdx});  }}>
          <AddRowIcon style={{
          cursor: 'pointer'
        }} color="primary" fontSize="small"/>
          </IconButton>
          </Tooltip>
          </div>)     
          */
         return (    
           <GridRow 
           {...lprops} 
           //selectedCellProps={null}
           height={25} 
           rowIdx={lprops.rowIdx} 
           //isRowSelected={lprops.isRowSelected}
           //onRowClick={ (rowIdx, row, column)=>{props.insertItem(props.tableId, {index:rowIdx});  }}
           />
         )         
    } else {     
      //console.log('new_row  lprops', lprops)
      /*
    return (
      <ContextMenuTrigger id="grid-context-menu" collect={() => ({ rowIdx: lprops.rowIdx })}>
        <DraggableRowRenderer {...lprops} tableId={props.tableId} onRowReorder={onRowReorder} />
      </ContextMenuTrigger>
    );
    */
      return (
        
          <DraggableRowRenderer
            {...lprops}
            tableId={props.tableId}
            onRowReorder={onRowReorder}
            handleEditItemById={props.handleEditItemById}
            setRowContextAnchorEl={setRowContextAnchorEl}
            setRowContextValue={setRowContextValue}
            onItemDelete = {props.onItemDelete}
            sharedView = {props.sharedView}
            setRowContext={setRowContext}
            setRowContextAnchorPosition={setRowContextAnchorPosition}
            selectedRows={selectedRows}
          />
        
      )
    }
  }



  const summaryRows = useMemo(() => {
    console.log("summaryRows useMemo!")
    const summaryRow = { ex_menu: props.items.length, totalCount: props.items.length, yesCount: props.items.filter(r => r.available).length };
    return [summaryRow];
  }, [props.items]);
 
  const expandedGroupIds = useMemo(() => {
    console.log("expandedGroupIds useMemo!")
     return new Set(props.expandedGroupIds)
  }, [props.expandedGroupIds]);

  const groupsValues = useMemo(() => {
    console.log("groupsValues useMemo!")
    if(props.groups && props.groups.length>0){
      var gr = []
     // gr.push(
     //   "ex_menu"
     // )
      props.groups.forEach(element => {
        if(element.column){
          gr.push(element.column)
        }
      });
      console.log("groupsValues gr ",  gr)
      return gr
    } else return null
  }, [props.groups]);

  const sortedRows = useMemo(() => {
    console.log("sortedRows useMemo!")
    //if (sortDirection === 'NONE') return rows;    
    //console.log('sortedRows props.sortColumns ', props.sortColumns)
    let rows = [...props.items]
    if(props.sortColumns && props.sortColumns.length>0){
      rows.sort((a, b) => {
        for(var isx=0; isx<props.sortColumns.length; isx++){
          let sort = props.sortColumns[isx] 
           var compResult = 0
          if(typeof a[sort.columnKey]==='string'){
             compResult = a[sort.columnKey]?a[sort.columnKey].localeCompare(b[sort.columnKey]):0
          } else if(typeof a[sort.columnKey]==='number' || typeof b[sort.columnKey]==='number'){            
             compResult =  ((a[sort.columnKey] === undefined || a[sort.columnKey] === null)?Number.MIN_VALUE:a[sort.columnKey])-((b[sort.columnKey] === undefined || b[sort.columnKey] === null)?Number.MIN_VALUE:b[sort.columnKey])
          }                
          if (compResult !== 0) {
            return sort.direction === 'ASC' ? compResult : -compResult;
          }
        }
        return 0;
      });
    } 
   
    if(!props.sharedView && (!props.groups || props.groups.length===0)) {
        //у нас нет группировок а значит одна строка добавления элемента
        rows.push({__ex_new_row:true, ...props.defaultValues})
    }
    return rows
    /*
    switch (sortColumn) {
      case 'task':
      case 'priority':
      case 'issueType':
        sortedRows = sortedRows.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]));
        break;
      case 'complete':
        sortedRows = sortedRows.sort((a, b) => a[sortColumn] - b[sortColumn]);
        break;
      default:
    }
    
    return sortDirection === 'DESC' ? sortedRows.reverse() : sortedRows;
    */

  }, [props.items, props.sortColumns, props.sharedView, props.groups, props.defaultValues]);

  const rowGrouper = (rows, columnKey) => {
    var g = groupBy(rows, columnKey)
    
    for(var k in g){
      if(g[k]){

        if(g[k].length>0 && g[k][g[k].length-1].__ex_new_row){
          if(k !== undefined){
            g[k][g[k].length-1][columnKey] = k
          }
        } else {
          var e = {__ex_new_row:true, ...props.defaultValues}
          if(k !== undefined){
            e[columnKey] = k
          }
          g[k].push(
            e
          )
        }

      }
    }
    console.log('rowGrouper columnKey ', columnKey, g)
    return g
  }

  const allRowsSelected = useMemo(() => {
    // no rows to select = explicitely unchecked
    console.log("allRowsSelected useMemo!")
    const length  = props.items.length;
    return (
      length !== 0 &&
      selectedRows != null &&
      selectedRows.size >= length &&
      props.items.every((row) => selectedRows.has(row._id))
    );
  }, [props.items, selectedRows]);   



  const onContextMenu = (event) =>{
    event.preventDefault();
    const { clientX, clientY } = event;
    console.log('onContextMenu ', clientX, clientY)
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: clientY,
        right: clientX,
        //bottom: clientY,
        //left: clientX
      })
    }; 
    setRowContextAnchorEl(virtualElement);    
    event.stopPropagation();

  }
  //useEffect(()=>{
  //  init();
//}, [init])

const tablesValues = useMemo(() => {
  console.log("tablesValues useMemo!")
  const tv = props.tables
  return tv
}, [props.tables]);

const openItem = useCallback(async (itemId) => {
  console.log("openItem useCallback!")
  history.push(`/db/${props.dbId}/${props.tableId}/${props.viewId}/${itemId}`)
}, [history, props.dbId, props.tableId, props.viewId])

const draggableColumns = useMemo(() => {
  function HeaderRenderer(lprops) {
    //console.log('HeaderRenderer lprops ', lprops)
    if(lprops.column.idx===0 && lprops.column.minWidth<130){
      lprops.column.minWidth=130
    }
    return <>
        <DraggableHeaderRenderer 
        {...lprops} 
        viewId={props.viewId} 
        onColumnResize={props.onColumnResize} 
        onColumnFrozen={props.onColumnFrozen} 
        handleOpenNewColumnSetting={props.handleOpenNewColumnSetting} 
        handleOpenColumnSetting={props.handleOpenColumnSetting} 
        onChangeColumn={props.onChangeColumn} 
        onColumnDelete={props.onColumnDelete} 
        onColumnInsert={props.onColumnInsert} 
        onColumnsReorder={props.handleColumnsReorder}  
        tableId={props.tableId} 
        anchorElColEdit={anchorElColEdit}
        setAnchorElColEdit={setAnchorElColEdit}   
        setEditingSets = {setEditingSets}   
        editingSets={editingSets}   
        sharedView = {props.sharedView}
        />
      </>;
  }  

  function menuColumnHeaderRenderer(lprops) {
    
    return <><MenuColHeaderRenderer
      {...lprops} 
      viewId={props.viewId} 
      onColumnResize={props.onColumnResize} 
      onColumnFrozen={props.onColumnFrozen} 
      handleOpenNewColumnSetting={props.handleOpenNewColumnSetting} 
      handleOpenColumnSetting={props.handleOpenColumnSetting} 
      onChangeColumn={props.onChangeColumn} 
      onColumnDelete={props.onColumnDelete} 
      onColumnInsert={props.onColumnInsert} 
      onColumnsReorder={props.handleColumnsReorder}  
      tableId={props.tableId} 
      tables={props.tables}
      tablesList={props.tablesList} 
      columns={props.columns}      
      anchorElColEdit={anchorElColEdit}
      setAnchorElColEdit={setAnchorElColEdit}  
      setEditingSets = {setEditingSets}      
      editingSets={editingSets}        
      sharedView={props.sharedView}
      allRowsSelected={allRowsSelected}
      setSelectedRows={setSelectedRows}
      rows = {props.items}
    />
   </>   
  }    

  function addColumnHeaderRenderer(lprops) {
    
    return <><AddColHeaderRenderer
      {...lprops} 
      viewId={props.viewId} 
      onColumnResize={props.onColumnResize} 
      onColumnFrozen={props.onColumnFrozen} 
      handleOpenNewColumnSetting={props.handleOpenNewColumnSetting} 
      handleOpenColumnSetting={props.handleOpenColumnSetting} 
      onChangeColumn={props.onChangeColumn} 
      onColumnDelete={props.onColumnDelete} 
      onColumnInsert={props.onColumnInsert} 
      onColumnsReorder={props.handleColumnsReorder}  
      tableId={props.tableId} 
      tables={props.tables}
      tablesList={props.tablesList} 
      columns={props.columns}      
      anchorElColEdit={anchorElColEdit}
      setAnchorElColEdit={setAnchorElColEdit}  
      setEditingSets = {setEditingSets}      
      editingSets={editingSets}        
    />
   </>   
  }    
    
  console.log('draggableColumns useMemo!')

  const menu_column = {
    _id: 'ex_menu',
    name: '',
    frozen:true,
    width: 60,
    minWidth:60,
    maxWidth:60,
    formatter(cellParams) {
      
        const cp = cellParams;
     
        //console.log('cp.row ', cellParams)
        console.trace('cp.row ')
        if(cp.row.__ex_new_row){
          return (        
            <div style={{top: 0,  height:30}}>  
            <Tooltip title="Добавить строку!" aria-label="add">
              <IconButton size="small" 
               onClick={(e)=>{ 
                 const idx = 999//cp.rowIdx;     
                 console.log('insertItem cp.row ', props.defaultValues)             
                 props.insertItem( props.tableId, {...props.defaultValues, index:idx});  
                 e.stopPropagation();
                }}
                 
              >
              <AddCircleOutlineIcon style={{
              cursor: 'pointer'
            }} color="text.disabled" fontSize="small"/>
              </IconButton>
              </Tooltip>
              </div>);            
        } else {        
          return (
            <div>
              <div
                style={{
                  fontSize:11,
                  display: "inline-block",
                  color: "rgba(0, 0, 0, 0.26)"
                }}
              >

            {!props.sharedView && <div                     
            
            class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"}

                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 12,
                    height: "100%",
                    cursor: "-moz-grab",
                    cursor: "-webkit-grab",
                    cursor: "grab",
                    backgroundImage: "url(/grabby.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "2px 2px",
                    //display: "none"
                  }}
                  >

                  </div>}
                   <span 
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-index-cell":"dinabot-index-cell"}
                    color="text.disabled" >
                      {(cp.rowIdx + 1)}
                    </span>

                    {!props.sharedView && cp.row && selectedRows.has(cp.row._id) &&  
                    <div  
                    size="small"
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"}
                    //ref={contextManuRef} 
                    key={'context-menu-row-icon' + cp.rowIdx} 
                    style={{
                      position: "absolute",
                      top: 5,
                      left: 11,
                      width: 16,
                      height: 16,
                      //display: "none"
                    }}
                    onClick={event => {
                      //onRowSelectionChange({ row: cp.row, checked:true, isShiftClick: false });
                      const newSelectedRows = new Set(selectedRows);
                      newSelectedRows.delete(cp.row._id)
                      setSelectedRows(newSelectedRows)
                    }}>

                      <CheckBoxSharpIcon style={{
                        cursor: 'pointer',
                        fontSize:16,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }} color="primary"  /> 

                    </div>}     
                    {!props.sharedView && cp.row && !selectedRows.has(cp.row._id) &&  
                    <div  
                    size="small"
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"}
                    //ref={contextManuRef} 
                    key={'context-menu-row-icon' + cp.rowIdx} 
                    style={{
                      position: "absolute",
                      top: 5,
                      left: 11,
                      width: 16,
                      height: 16,
                      //display: "none"
                    }}
                    onClick={event => {
                      //onRowSelectionChange({ row: cp.row, checked:true, isShiftClick: false });
                      const newSelectedRows = new Set(selectedRows);
                      newSelectedRows.add(cp.row._id)
                      setSelectedRows(newSelectedRows)
                    }}>

                     <CheckBoxOutlineBlankSharpIcon style={{
                        cursor: 'pointer',
                        fontSize:16,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }} color="disabled"  />
                    
                    </div>}   
                      
                    {cp.row.commentCount ? 
                    <div  
                    //class={(selectedCell && selectedCell.rowIdx===cp.rowIdx)?"dinabot-select-menu-cell":"dinabot-menu-cell"} 
                    key={'open-edit-row-icon' + cp.rowIdx} 
                    onClick={e => props.handleEditItemById(cp.row._id)}
                    size="small"
                    style={{
                      position: "absolute",
                      top: 4,
                      left: 37,
                      width: 18,
                      height: 18,
                      cursor: "pointer",
                    }}
                    >
                      <Tooltip title="Открыть элемент и посмотреть комментарии">
                        <div
                         style={{
                          display:"flex",
                          position: "absolute",
                          width: 18,
                          height: 18,
                          right: "auto",
                          backgroundColor: "#f2f2f2",
                          color: "#166ee1",
                          fill: "#166ee1",
                          "box-sizing": "border-box",
                          "-moz-box-sizing": "border-box",
                          "-webkit-box-sizing": "border-box",
                          borderRadius: "50%",
                          fontSize: 11,
                          fontWeight: 500,
                          alignItems: "center",
                          "-webkit-box-align": "center",
                          "-webkit-justify-content": "center",
                          "-ms-flex-pack": "center",
                          justifyContent: "center"
                        }}
                        >{cp.row.commentCount}
                        
                        </div>
                      </Tooltip>
                    </div>
                    :<div  
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"} 
                    key={'open-edit-row-icon' + cp.rowIdx} 
                    onClick={e => props.handleEditItemById(cp.row._id)}
                    size="small"
                    style={{
                      position: "absolute",
                      top: 4,
                      left: 37,
                      width: 18,
                      height: 18,

                      //display: "none"
                    }}
                    >
                      <Tooltip title="Открыть элемент">
                        <OpenInNewIcon style={{
                        cursor: "pointer",
                        color: "#166ee1",
                        fontSize:18,
                        position: "absolute",
                        top: 0,
                        left: 0
                      }} />
                      </Tooltip>
                    </div>}
                    

              </div>

            </div>
          );   
      }     
      
    },
    groupFormatter(p) {    
      return <></>
    },
    formatterOptions: {
      focusable: false
    },
    
           
  };

  menu_column.headerRenderer = menuColumnHeaderRenderer

  const columns = [...props.viewVisibleColumns]
  //console.log('init table columns ',columns)
  var initColumn = 0;
  var lastColIdx = (columns.length>0 && columns[columns.length-1].index)?(columns[columns.length-1].index+1):1
  if(!groupsValues || groupsValues.length===0){
   initColumn = 1;    
    columns.unshift(menu_column);
  }
  const add_column = {
    _id: 'ex_add_column',
    name: '+',
    width: 10,
    lastColIdx:lastColIdx,
    formatter(cellParams) {      
      return <></>;
    },
    formatterOptions: {
      focusable: false
    }      
  };

  add_column.headerRenderer = addColumnHeaderRenderer
  var columns_length = columns.length
  if(!props.sharedView){
    columns.push(add_column)
  }
  
  //const init_columns = [menu_column, ...wi_colums];     
  //console.log('updateColumsFromWorkitemlist init_columns', init_columns);            
  //if(workitems.columns){
    const initFilter = {};

    const multiline_rowHeight = ((props.rowHeight<70)?70:(props.rowHeight - 1))  

      for(var i=initColumn; i<columns_length; i++){
        var column = columns[i];        
        var system_col = false
        if(column._id==="ex_rAnswered" ||
        column._id==="ex_requestDt" ||
        column._id==="ex_rAnswerDt" ||
        column._id==="ex_updatedAt" ||
        column._id==="ex_createdAt" ||
        column._id==="ex_changeAutor" ||
        column._id==="ex_createdAutor" ||
        column._id==="ex_requestAc" ||
        column._id==="_id"
    )  {
      system_col = true;
    }       
    if(!column.minWidth && column.minWidth<60 ){
      column.minWidth = 60;
    } 
      
        //if(i===0){
        //  column.minWidth = 130;
        //}
        column.headerRenderer = HeaderRenderer;
        column.filterRenderer = p => (
          <Tooltip title={p.value} aria-label="add">
          <small style={{ fontWeight:"normal" }}>
            {p.value}
          </small>
           </Tooltip>          
        )        
   
          if(column.type ===   "member"){

            column.formatter = (p) => {                         
                var label = getMemberLabel(p, props.members)
                if(label){
                return     (<OverflowTip whiteSpace="nowrap">
                <Chip
                  size="small"
                  icon={<AccountCircleIcon />}
                  //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                  label={label}
                  //avatar={<Avatar>M</Avatar>}
                  //clickable
                  //color={o.color ? o.color : "default"}
                  onCopy={e=>onCopyToBuffer(label)}
                  style={
                    { 
                      left:"6px",
                      top:"3px",    
                      fontSize:"12px", 
                      height: "20px"  
                    }                    
                  }
                  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                //deleteIcon={<DeleteIcon />}
                />
                </OverflowTip>)
                } else {
                  return <></>
                }
            }              
            //newCol.editor = React.forwardRef((props, ref) => <DropDownEditor ref={ref} {...props} options={newCol.lookup} />);
            if(!system_col){
              column.editable = column.hasOwnProperty("editable")?column.editable:true;
            column.editor = p => (
              <MemberEditor
                value={p.row[p.column._id]}
                onClose={p.onClose}
                onChange={value => {
                  const newVal=value; 
                  const colkey = p.column._id
                  const indx = p.rowIdx   
                  const _id =    p.row._id            
                  p.row[p.column._id] = newVal; 
                  p.onRowChange({ ...p.row}, true)
                  //props.handleRowUpdate(props.tableId, // props.viewId,
                  //  {action: "CELL_UPDATE",
                  //  cellKey: colkey,
                  //  _id: _id,
                  //  fromRow: indx,
                  //  toRow: indx,
                  //  updated: _id?{[colkey]:newVal}:{...props.defaultValues, [colkey]:newVal}
                  //}) 
                }}  
                options={props.members}
                width={p.column.width}            
                menuPortalTarget={p.editorPortalTarget}
                onCreateOption={handleCreateColumnOpinion}
                noOptionsMessage="Нет пользователей, подключивших чат-бота! Для подключения см. раздел Боты"
              />     
            ) 
              } else {
                column.editable = false
              }         
            column.summaryFormatter= (cellParams) => {    
              //console.log('cellParams ', cellParams)
              return (        
                <SummaryRowEditor 
                items={props.items.map(it=>it[cellParams.column.key])} 
                colType={cellParams.column.type} 
                formula={props.summaryRow[cellParams.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                />  
              )
            } 
            column.groupFormatter = (p) => {    
              var v = p.groupKey        
              if (p.column.rowGroup) {
                var res = props.members.find(r => r._id === v)
                if (res) {
                  var label = res.name

                  return (
                    (<GroupRowDiv isExpanded={p.isExpanded}>
                      <Chip
                        size="small"
                        icon={<AccountCircleIcon />}
                        //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                        label={label}
                        //avatar={<Avatar>M</Avatar>}
                        //clickable
                        //color={o.color ? o.color : "default"}
                        style={
                          {
                            left: "6px",
                            top: "3px",
                            fontSize: "12px",
                            height: "20px"
                          }
                        }
                      //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                      //deleteIcon={<DeleteIcon />}
                      />
                    </GroupRowDiv>)
                  )
                } else {
                  return <GroupRowDiv isExpanded={p.isExpanded}></GroupRowDiv>
                }
              } else {
                return (        
                  <SummaryRowEditor 
                  items={p.childRows.map(it=>it[p.column.key])} 
                  colType={p.column.type} 
                  formula={props.summaryGroupRow[p.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                  />  
                )                
              }
              return ""
          }                                                           
              } else if(column.type ===   "chatmember"){
              //init_columns[i].options = Object.assign({}, ...filter_respondents.map(item => ({ [item.channelId]: item.name })));

              column.formatter = (p) => {                 
                  var label = getChatMemberLabel(p, props.chatmembers)
                  if(label){
                  return (<OverflowTip whiteSpace="nowrap">
                  <Chip
                    size="small"
                    //icon={<FormatColorFillIcon />}
                    //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                    label={label}
                    avatar={<Avatar>M</Avatar>}
                    //clickable
                    //color={o.color ? o.color : "default"}
                    style={{ 
                      fontSize:"12px", 
                      height: "20px"                       
                    }}
                    //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                  //deleteIcon={<DeleteIcon />}
                  />
                  </OverflowTip>)
                  } else {
                    return <></>
                  }
              }              
              //newCol.editor = React.forwardRef((props, ref) => <DropDownEditor ref={ref} {...props} options={newCol.lookup} />);
              column.editor = p => (
                <ChatMemberEditor
                  value={p.row[p.column._id]}
                  onChange={value => {
                    const newVal=value; 
                    const colkey = p.column._id
                    const indx = p.rowIdx   
                    const _id =    p.row._id            
                    p.row[p.column._id] = newVal; 
                    p.onRowChange({ ...p.row}, true)
                    //props.handleRowUpdate(props.tableId,
                    //  {action: "CELL_UPDATE",
                    //  cellKey: colkey,
                    //  _id: _id,
                    //  fromRow: indx,
                    //  toRow: indx,
                    //  updated: _id?{[colkey]:newVal}:{...props.defaultValues, [colkey]:newVal}
                    //}) 
                  }}
                  members={props.chatmembers}
                  rowHeight={p.rowHeight}
                  menuPortalTarget={p.editorPortalTarget}
                  onCreateOption={handleCreateColumnOpinion}
                  noOptionsMessage="Нет пользователей, подключивших чат-бота! Для подключения см. раздел Боты"
                />     
              )                  
              column.summaryFormatter= (cellParams) => {    

                return (        
                  <SummaryRowEditor 
                  items={props.items.map(it=>it[cellParams.column.key])} 
                  colType={cellParams.column.type} 
                  formula={props.summaryRow[cellParams.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                  />    
                    )
              }                                     
            } else if(column.type ===   "select"){
            if (!column.is_formula && !system_col) {
              column.editable = column.hasOwnProperty("editable")?column.editable:true;
              column.editor = p => (
                <SelectEditor
                  value={p.row[p.column._id]}
                  onClose={p.onClose}
                  onChange={value => {
                    const newVal = value;
                    const colkey = p.column._id
                    const indx = p.rowIdx
                    const _id = p.row._id
                    p.row[p.column._id] = newVal;
                    p.onRowChange({ ...p.row }, true)
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  }}
                  options={p.column.options}
                  width={p.column.width}
                  menuPortalTarget={p.editorPortalTarget}
                  onCreateOption={handleCreateColumnOpinion}
                  noOptionsMessage="Нет значений для выбора! Кликните на названии колонки и установите их в параметрах"
                />

              )
              column.editorOptions = {
                //renderFormatter: true
                onCellKeyDown: (event)=>{
                  console.log('select onCellKeyDown ', event)
                }
              }     
            } else {
              column.editable = false            
            }
            
            column.formatter = (p) => {              
              var v = p.row[p.column._id]
              var option = v?p.column.options.find(opt => { return opt.value === v }):null;
              if(option){
                return (
                  (<OverflowTip whiteSpace="nowrap">
                  <Chip
                              size="small"
                              label={<span         
                                //style={
                                //{                  
                                //  color:"white", 
                                  //mixBlendMode: "darken",
                                //}
                              //}
                              >{option ?option.label:""}</span>}                  
                              style={
                                { 
                                  backgroundColor: option ?option.color:null,     
                                  fontSize:"12px", 
                                  height: "20px"  
                                }
                              }
                            />    
                            </OverflowTip>)
                            )                
              } else {
                return ""
              }
            }         
            column.groupFormatter = (p) => {
             //console.log('groupFormatter ', p)
              /*p:
{
    "groupKey": "*Звезда*",
    "childRows": [
        {
            "_id": "61a5cbc1adea0ba8ccd25d0a",
            "617ae02f488c155194d65717": "61a5c95bc0b5a52553402f75",
            "work_item_list": "61a22be9532629bed06fb886",
            ...
            "ex_rAnswerDt": "2021-11-30T06:59:39.576Z",
            "617ae02f488c155194d65718": "*Звезда*",
            "618656bf98d2f9857e7feab2": "2021-11-30T06:59:32.150Z"
        }
    ],
    "column": {
        "_id": "617ae02f488c155194d65718",
        "name": "Статус",
        "type": "select",
        "readonly": false,
        "options": [
            {
                "label": "В работе",
                "value": "В работе",
                "color": "#5d9b92"
            },
            ...
            {
                "label": "папрпрпрпр",
                "value": "папрпрпрпр",
                "color": "darkblue"
            }
        ],
        "resizable": true,
        "sortable": true,
        "editable": true,
        "is_formula": false,
        "date_format": "DD.MM.YYYY HH:mm",
        "index": 3,
        "frozen": true,
        "width": 72.30206298828125,
        "key": "617ae02f488c155194d65718",
        "minWidth": 0,
        "editorOptions": {},
        "idx": 0,
        "isLastFrozenColumn": false,
        "rowGroup": true
    },
    "row": {
        "id": "*Звезда*",
        "groupKey": "*Звезда*",
        "isExpanded": false,
        "childRows": [
            {
                "_id": "61a5cbc1adea0ba8ccd25d0a",
                "617ae02f488c155194d65717": "61a5c95bc0b5a52553402f75",
                "work_item_list": "61a22be9532629bed06fb886",
                ...
                "ex_rAnswerDt": "2021-11-30T06:59:39.576Z",
                "617ae02f488c155194d65718": "*Звезда*",
                "618656bf98d2f9857e7feab2": "2021-11-30T06:59:32.150Z"
            }
        ],
        "level": 0,
        "posInSet": 8,
        "startRowIndex": 99,
        "setSize": 9
    },
    "isExpanded": false,
    "isCellSelected": false
}              
              */
              var v = p.groupKey
              var option = v?p.column.options.find(opt => { return opt.value === v }):null;
              if (p.column.rowGroup) {
                if (option) {
                  return (
                    (<GroupRowDiv isExpanded={p.isExpanded}>
                      <Chip
                        size="small"
                        label={<span
                          //style={
                          //  {
                          //    color: "white",
                              //mixBlendMode: "darken",
                          //  }
                          //}
                          >{option ? option.label : ""}</span>}
                        style={
                          {
                            backgroundColor: option ? option.color : null,
                            fontSize: "12px",
                            height: "20px",
                            verticalAlign: "middle"
                          }
                        }
                      />
                    </GroupRowDiv>)
                  )
                } else {
                  return <GroupRowDiv isExpanded={p.isExpanded}></GroupRowDiv>
                }
              } else {
                return (        
                  <SummaryRowEditor 
                  items={p.childRows.map(it=>it[p.column.key])} 
                  colType={p.column.type} 
                  formula={props.summaryGroupRow[p.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                  />  
                )                  
              }
              return ""
            }   
            column.summaryFormatter= (cellParams) => {    

              return (        
                <SummaryRowEditor 
                items={props.items.map(it=>it[cellParams.column.key])} 
                colType={cellParams.column.type} 
                formula={props.summaryRow[cellParams.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                />     
                  )
            }                  
          } else if(column.type ===   "lookup" && column.lookupId && column.lookupField){
            column.editable = false
            const lookupColumn = columns.find(cl=> cl._id === column.lookupId)
            const lookupField = column.lookupField
            if(lookupColumn && lookupColumn.linkId && lookupField){
            column.formatter = (p) => {               
              var val = ""
              if (tablesValues[lookupColumn.linkId] && p.row[lookupColumn._id]) {
                let item = tablesValues[lookupColumn.linkId].items[p.row[lookupColumn._id]]
                //if(item[lookupField]){
                // val = item[lookupField]
                //}
                if (item && item[lookupField]) {
                  var fieldCol = tablesValues[lookupColumn.linkId].columns.find(cl => cl._id === lookupField)
                  val = item[lookupField]
                  if (fieldCol && fieldCol.type === 'member') {

                    var member = props.members.find(mb => mb._id === item[lookupField])
                    //console.log('fieldCol member ', fieldCol, item[lookupField], props.members, member)
                    if (member) {
                      val = member.name
                    }
                    return <OverflowTip whiteSpace="nowrap">
                      {val}
                      </OverflowTip>
                  } else if (fieldCol && fieldCol.type === 'attachments') {
                    return <LookupAttachmentsViewer
                      value={val}
                      dbId={props.dbId}
                      wiId={props.tableId}
                      itemId={p.row._id}
                      colId={p.column._id}
                      view="list"
                      minHeight="var(--rdg-row-height)"
                      maxHeight="var(--rdg-row-height)"
                      compactStyle={true}
                      fileIconWidth={"" + props.rowHeight ? (props.rowHeight - 7) : "28px"}
                      fileIconHeight={"" + props.rowHeight ? (props.rowHeight - 7) : "28px"}
                      disableScroll
                      label=" "
                      clickable={false}
                      styleFilesContainer={{
                        justifyContent: "left",
                        padding: "0px"
                      }}
                    />
                  } else if (fieldCol && fieldCol.type === 'multiline') {
                    return  val?
                    <div        
                    //ref={textElementRef} 
                    class="dinabot-multiline-formater"                               
                    variant="subtitle2" 
                    dangerouslySetInnerHTML={{__html: val}}  
                  />    :''
                  } else {

                  return <OverflowTip whiteSpace="nowrap">
                    {val}
                  </OverflowTip>
                  }
                } else {
                  return ""
                }
              } else {
                return ""
              }
            }
          } else {
            column.formatter = (p) => {              
              return ""
            }
          }
          column.summaryFormatter= (cellParams) => {    

            return (        
              <SummaryRowEditor 
              items={props.items.map(it=>it[cellParams.column.key])} 
              colType={cellParams.column.type} 
              formula={props.summaryRow[cellParams.column.key]}
              saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
              />   
                )
          }
          column.groupFormatter = (p) => {    
            var v = p.groupKey        
            if (p.column.rowGroup) {              
              if (v) {
                return (
                  <GroupRowDiv isExpanded={p.isExpanded}>
                  </GroupRowDiv>
                )
              } else {
                return (<GroupRowDiv isExpanded={p.isExpanded}>

                </GroupRowDiv>)
              }
            } else {
              return (        
                <SummaryRowEditor 
                items={p.childRows.map(it=>it[p.column.key])} 
                colType={p.column.type} 
                formula={props.summaryGroupRow[p.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                />  
              )                
            }
            return ""
        }                  
          } else if(column.type ===   "link" && column.linkId){
            //const _columnTable =  props.tables?props.tables[column.linkId]: null;
            //console.log('column.type ===   "link" ', props.tables, column, _columnTable)
            //var _titleColName = _columnTable.columns.find(cl=> cl.type === 'title')
            //  if(!_titleColName){
            //    _titleColName = _columnTable.columns[0]
            //  }   
            //  const titleColName =  _titleColName
            //  const options = _columnTable.items
            if(tablesValues[column.linkId] && tablesValues[column.linkId].columns){
            const titleCol = column.linkViewField //?column.linkViewField:tablesValues[column.linkId].columns.find(cl=> cl.type === 'title')
            column.editable = column.hasOwnProperty("editable")?column.editable:true;
            column.editor = p => (
              
              <LinkEditor
                value={p.row[p.column._id]}
                tables={tablesValues}
                titleCol={titleCol}
                onClose={p.onClose}
                linkId={p.column.linkId}
                filterView={p.column.linkSelectView}
                currentMember={props.currentMember}
                members={props.members}
                chatmembers = {props.chatmembers}                
                onChange={value => {                  
                  const newVal=value; 
                  const colkey = p.column._id
                  const indx = p.rowIdx   
                  const _id =    p.row._id            
                  p.row[p.column._id] = newVal; 
                  p.onRowChange({ ...p.row}, true)
                  //props.handleRowUpdate(props.tableId,
                  //  {action: "CELL_UPDATE",
                  //  cellKey: colkey,
                  //  _id: _id,
                  //  fromRow: indx,
                  //  toRow: indx,
                  //  updated: _id?{[colkey]:newVal}:{...props.defaultValues, [colkey]:newVal}
                  //})                    
                }}     

                //options={tablesValues[p.column.linkId].itemsList?tablesValues[p.column.linkId].itemsList:[]}
                //menuPortalTarget={p.editorPortalTarget}
                onCreateOption={handleCreateColumnOpinion}
                noOptionsMessage="Нет значений для выбора! Кликните на названии колонки и установите их в параметрах"
              />     

            )  
            
            column.formatter = (p) => {               
              var v = p.row[p.column._id]
              const option = v?tablesValues[p.column.linkId].items[v]:null;
              const __selected_columns = tablesValues[p.column.linkId]?tablesValues[p.column.linkId].columns:[]
              if(option){
                return  (<OverflowTip whiteSpace="nowrap">
                <Chip
                  size="small"
                  //icon={<FormatColorFillIcon />}
                  //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                  label={titleCol?option[titleCol]:""}
                  //avatar={p.column.type==="member"?<Avatar>M</Avatar>:null}
                  clickable
                  onClick={e=>
                    openItem(option._id)
                  }
                  //color={o.color ? o.color : "default"}
                  style={{                                     
                    fontSize:"12px", 
                    height: "20px"   
                }}
                  //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                //deleteIcon={<DeleteIcon />}
                />
                </OverflowTip>)
              } else {
                return ""
              }
            }  
            column.groupFormatter = (p) => {    
              var v = p.groupKey   
              if (p.column.rowGroup) {
                const option = v ? tablesValues[p.column.linkId].items[v] : null;
                const __selected_columns = tablesValues[p.column.linkId] ? tablesValues[p.column.linkId].columns : []
                if (option) {
                  return (<GroupRowDiv isExpanded={p.isExpanded}>
                    <Chip
                      size="small"
                      //icon={<FormatColorFillIcon />}
                      //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                      label={titleCol ? option[titleCol] : ""}
                      //avatar={p.column.type==="member"?<Avatar>M</Avatar>:null}
                      clickable
                      onClick={e => 
                        openItem(option._id)
                      }
                      //color={o.color ? o.color : "default"}
                      style={{
                        fontSize: "12px",
                        height: "20px"
                      }}
                    //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                    //deleteIcon={<DeleteIcon />}
                    />
                  </GroupRowDiv>
                  )
                } else {
                  return <GroupRowDiv isExpanded={p.isExpanded}></GroupRowDiv>
                }
              } else {
                return (        
                  <SummaryRowEditor 
                  items={p.childRows.map(it=>it[p.column.key])} 
                  colType={p.column.type} 
                  formula={props.summaryGroupRow[p.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                  />  
                )                  
              } 
              return ""
          } 
          } else {
            column.formatter = (p) => {               
              return ""
            }
          }
          column.summaryFormatter = (cellParams) => {    

            return (        
              <SummaryRowEditor 
              items={props.items.map(it=>it[cellParams.column.key])} 
              colType={cellParams.column.type} 
              formula={props.summaryRow[cellParams.column.key]}
              saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
              />    
                )
          }
                           
          } else if(column.type ===   "number"){
            if (!column.is_formula && !system_col) {
              column.editable = column.hasOwnProperty("editable")?column.editable:true;
              column.editor = p => (
                <NumberEditor
                  value={p.row[p.column._id]}
                  onChange={value => {
                    const newVal = Number(value);
                    const colkey = p.column._id
                    const indx = p.rowIdx
                    const _id = p.row._id
                    p.row[colkey] = newVal;
                    p.onRowChange({ ...p.row }, true);
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  }
                  }
                  rowHeight={p.rowHeight}
                />

              )
            } else {
              column.editable = false            
            }
            column.formatter = (p) => {              
              return     <OverflowTip whiteSpace="nowrap">
                  {(p.row[p.column._id]!==undefined && p.row[p.column._id]!==null)?p.row[p.column._id]:""}
                  </OverflowTip>
             
            }            
            column.summaryFormatter= (cellParams) => {    

              return (        
                <SummaryRowEditor 
                items={props.items.map(it=>it[cellParams.column.key])} 
                colType={cellParams.column.type} 
                formula={props.summaryRow[cellParams.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                />   
                  )
            }  
            column.groupFormatter = (p) => {    
              var v = p.groupKey        
              if (p.column.rowGroup) {              
                if (v  && v!=="undefined") {
                  return (
                    <GroupRowDiv isExpanded={p.isExpanded}>
                      {v}
                    </GroupRowDiv>
                  )
                } else {
                  return (<GroupRowDiv isExpanded={p.isExpanded}>
  
                  </GroupRowDiv>)
                }
              } else {
                return (        
                  <SummaryRowEditor 
                  items={p.childRows.map(it=>it[p.column.key])} 
                  colType={p.column.type} 
                  formula={props.summaryGroupRow[p.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                  />  
                )                
              }  
            }                                                          
          } else if(column.type ===   "geolocation"){
            if (!column.is_formula && !system_col) {
              column.editable = column.hasOwnProperty("editable")?column.editable:true;
              column.editor = p => (
                <GeoLocationEditor
                  value={(p.row[p.column._id] && p.row[p.column._id].longitude)?`${p.row[p.column._id].latitude}, ${p.row[p.column._id].longitude}`:""}
                  onChange={value => {
                    if(value && value.indexOf(',')>0){
                      const [latitude, longitude] = value.split(",");
                      var newVal = {
                        longitude,
                        latitude
                      }
                      const colkey = p.column._id
                      const indx = p.rowIdx
                      const _id = p.row._id
                      p.row[colkey] = newVal;
                      p.onRowChange({ ...p.row }, true);
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  } else {
                    p.onClose();
                  }
                }
                  }
                  rowHeight={p.rowHeight}
                />
              )              
            } else {
              column.editable = false
            }
            
            column.formatter = (p) => {               
              return     <OverflowTip whiteSpace="nowrap">
                {(p.row[p.column._id] && p.row[p.column._id].longitude)?<a target="_blank" href={`https://yandex.ru/maps/?ll=${p.row[p.column._id].longitude}%2C${p.row[p.column._id].latitude}84&z=18`}>
                {`${p.row[p.column._id].latitude}, ${p.row[p.column._id].longitude}`}
                </a>:""}
                </OverflowTip>              
            }                     
            column.summaryFormatter= (cellParams) => {    

              return (        
                <SummaryRowEditor 
                items={props.items.map(it=>it[cellParams.column.key])} 
                colType={cellParams.column.type} 
                formula={props.summaryRow[cellParams.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                />    
                  )
            } 
            column.groupFormatter = (p) => {    
              var v = p.groupKey        
              if (p.column.rowGroup) {              
                if (v) {
                  return (
                    <GroupRowDiv isExpanded={p.isExpanded}>
                    </GroupRowDiv>
                  )
                } else {
                  return (<GroupRowDiv isExpanded={p.isExpanded}>
  
                  </GroupRowDiv>)
                }
              } else {
                return (        
                  <SummaryRowEditor 
                  items={p.childRows.map(it=>it[p.column.key])} 
                  colType={p.column.type} 
                  formula={props.summaryGroupRow[p.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                  />  
                )                
              }  
            }                                                
          } else if(column.type ===   "attachments"){
            //column.editable = check_false                        
            //console.log('column.type  attachments', value)
            column.editor = (p) => (               
                <AttachmentsTableEditor 
                colId={p.column._id}
                onClose={p.onClose}
                value={p.row[p.column._id]}
                dbId={props.dbId} 
                wiId={props.tableId}
                itemId={p.row._id}                
                />
            )
            column.formatter = (p) => {               
              return  <AttachmentsEditor 
                value={p.row[p.column._id]} 
                dbId={props.dbId} 
                wiId={props.tableId} 
                itemId={p.row._id} 
                colId={p.column._id}
                view="list"
                minHeight="var(--rdg-row-height)"
                maxHeight="var(--rdg-row-height)"
                compactStyle = {true}
                fileIconWidth={""+props.rowHeight?(props.rowHeight-7):"28px"}
                fileIconHeight={""+props.rowHeight?(props.rowHeight-7):"28px"}
                disableScroll
                label=" "
                clickable={false}
                styleFilesContainer={{
                  justifyContent: "left",
                  padding:"0px"
                }}
                />             
            }                   
            column.summaryFormatter= (cellParams) => {    

              return (        
                <SummaryRowEditor 
                items={props.items.map(it=>it[cellParams.column.key])} 
                colType={cellParams.column.type} 
                formula={props.summaryRow[cellParams.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                />   
                  )
            }        
            column.groupFormatter = (p) => {    
              var v = p.groupKey        
              if (p.column.rowGroup) {              
                if (v) {
                  return (
                    <GroupRowDiv isExpanded={p.isExpanded}>
                    </GroupRowDiv>
                  )
                } else {
                  return (<GroupRowDiv isExpanded={p.isExpanded}>
  
                  </GroupRowDiv>)
                }
              } else {
                return (        
                  <SummaryRowEditor 
                  items={p.childRows.map(it=>it[p.column.key])} 
                  colType={p.column.type} 
                  formula={props.summaryGroupRow[p.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                  />  
                )                
              }  
            }                                           
          } else if(column.type ===   'check'){
            if (!column.is_formula && !system_col) {
              column.editable = column.hasOwnProperty("editable")?column.editable:true;
              column.editor = p => (
                <CheckboxEditor2
                  isFormater={false}
                  editable={p.column.editable}
                  value={p.row[p.column._id]}
                  tableId={props.tableId}
                  onChange={value => {
                    //onChange( tableId,rowId, event.target.checked, rowIdx, colKey)}
                    const newVal = value;
                    const colkey = p.column._id
                    const indx = p.rowIdx
                    const _id = p.row._id
                    p.row[colkey] = newVal;
                    p.onRowChange({ ...p.row }, true)
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  }}
                  rowHeight={p.rowHeight}
                />

              )
            } else {
              column.editable = false            
            }
            //newCol.editable = false;
            column.formatter = (p) => {               
              //const rix = p.rowIdx;
              return           <CheckboxEditor2
              tableId={props.tableId}
              value={p.row[p.column._id]}
              isFormater={true}
              editable={p.column.editable}
              rowIdx={p.rowIdx}
              colKey = {p.column._id}
              rowId = {p.row._id}
                onChange={value => {
                  //onChange( tableId,rowId, event.target.checked, rowIdx, colKey)}
                  if (p.column.editable) {
                    const newVal = value;
                    const colkey = p.column._id
                    const indx = p.rowIdx
                    const _id = p.row._id
                    p.row[colkey] = newVal;
                    p.onRowChange({ ...p.row }, true)
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  }
                }}
              rowHeight={p.rowHeight}
            />
            }
            column.events = {
              onKeyDown: (event) => {
                if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                  event.stopPropagation();
                } 

              } 
            }          
            column.groupFormatter = (p) => {    
              var v = p.groupKey   
              if (p.column.rowGroup) {
                
                var option =  p.column.check_false
                if(v==="true"){
                  option = p.column.check_true
                } 
                  return (<GroupRowDiv isExpanded={p.isExpanded}>
                    <Chip
                      size="small"
                      //icon={<FormatColorFillIcon />}
                      //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
                      label={option}
                      //avatar={p.column.type==="member"?<Avatar>M</Avatar>:null}
                      //color={o.color ? o.color : "default"}
                      style={{
                        fontSize: "12px",
                        height: "20px"
                      }}
                    //onDelete={(e) => handleDeleteOptionChoice(o, index)}
                    //deleteIcon={<DeleteIcon />}
                    />
                  </GroupRowDiv>
                  )
                } else {
                  return (        
                    <SummaryRowEditor 
                    items={p.childRows.map(it=>it[p.column.key])} 
                    colType={p.column.type} 
                    formula={props.summaryGroupRow[p.column.key]}
                    saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                    />  
                  )                    
              } 
              return ""
          }             
            column.summaryFormatter= (cellParams) => {    

              return (        
                <SummaryRowEditor 
                items={props.items.map(it=>it[cellParams.column.key])} 
                colType={cellParams.column.type} 
                formula={props.summaryRow[cellParams.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                />     
                  )
            }                  
          } else if(column.type === 'date' ){
            if (!column.is_formula && !system_col) {
              column.editable = column.hasOwnProperty("editable")?column.editable:true;
              column.editor = p => (
                <DateEditor2
                  columnName={p.column.name}
                  value={p.row[p.column._id]}
                  width={p.column.width}
                  format = {p.column.date_format}
                  onClose={p.onClose}
                  onChange={value => {
                    const newVal = value;
                    const colkey = p.column._id
                    const indx = p.rowIdx
                    const _id = p.row._id
                    p.row[p.column._id] = newVal;
                    p.onRowChange({ ...p.row }, true)
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  }}
                />

              );
            } else {
              column.editable = false            
            }
            //column.editorOptions = {
              /** Default: true for editor1 and false for editor2 */
            //  createPortal: false,
              /** Default: false */
            //  editOnClick: true,
            //}

            column.formatter = (p) => {              
              return     <OverflowTip whiteSpace="nowrap">
                  {p.row[p.column._id]?moment(p.row[p.column._id]).local('ru').format(p.column.date_format):''}
                  </OverflowTip>
             
            }
            column.summaryFormatter= (cellParams) => {    

              return (        
                <SummaryRowEditor 
                items={props.items.map(it=>it[cellParams.column.key])} 
                colType={cellParams.column.type} 
                formula={props.summaryRow[cellParams.column.key]}
                saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                />    
                  )
            } 
            column.groupFormatter = (p) => {    
              var v = p.groupKey        
              if (p.column.rowGroup) {              
                if (v && v!=="undefined") {
                  return (
                    <GroupRowDiv isExpanded={p.isExpanded}>
                      {moment(v).local('ru').format(p.column.date_format)}
                    </GroupRowDiv>
                  )
                } else {
                  return (<GroupRowDiv isExpanded={p.isExpanded}>
  
                  </GroupRowDiv>)
                }
              } else {
                return (        
                  <SummaryRowEditor 
                  items={p.childRows.map(it=>it[p.column.key])} 
                  colType={p.column.type} 
                  formula={props.summaryGroupRow[p.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                  />  
                )                
              }  
            }                                
          } else if(column.type === 'multiline' ){
            if (!column.is_formula && !system_col) {
              column.editable = column.hasOwnProperty("editable")?column.editable:true;

              column.editor = p => (
                <MultilineTableEditor

                  colId={p.column._id}
                  dbId={props.dbId}
                  wiId={props.tableId}
                  itemId={p.row._id}
                  width={p.column.width}
                  value={p.row[p.column._id]}
                  onClose={p.onClose}
                  onChange={value => {
                    const newVal = value;
                    const colkey = p.column._id
                    const indx = p.rowIdx
                    const _id = p.row._id
                    p.row[colkey] = newVal;

                    p.onRowChange({ ...p.row }, true);
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  }
                  }
                />
              )
              column.editorOptions = {
                //renderFormatter: true
              }             
            } else {
              column.editable = false
            }
              column.formatter = (p) => {             
                return  p.row[p.column._id]?
                <div        
                //ref={textElementRef} 
                class="dinabot-multiline-formater"                               
                variant="subtitle2" 
                dangerouslySetInnerHTML={{__html: p.row[p.column._id]}}  
              />    :''
              }                 
              column.summaryFormatter= (cellParams) => {    

                return (        
                  <SummaryRowEditor 
                  items={props.items.map(it=>it[cellParams.column.key])} 
                  colType={cellParams.column.type} 
                  formula={props.summaryRow[cellParams.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                  />    
                    )
              } 
              column.groupFormatter = (p) => {    
                var v = p.groupKey        
                if (p.column.rowGroup) {              
                  if (v) {
                    return (
                      <GroupRowDiv isExpanded={p.isExpanded}>
                      </GroupRowDiv>
                    )
                  } else {
                    return (<GroupRowDiv isExpanded={p.isExpanded}>
    
                    </GroupRowDiv>)
                  }
                } else {
                  return (        
                    <SummaryRowEditor 
                    items={p.childRows.map(it=>it[p.column.key])} 
                    colType={p.column.type} 
                    formula={props.summaryGroupRow[p.column.key]}
                    saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                    />  
                  )                
                }  
              }                                        
          } else {  
            if (!column.is_formula && !system_col) {
              column.editable = column.hasOwnProperty("editable")?column.editable:true;
              column.editor = p => (
                <TextEditor
                  value={p.row[p.column._id]}
                  onChange={value => {
                    const newVal = value;
                    const colkey = p.column._id
                    const indx = p.rowIdx
                    const _id = p.row._id
                    p.row[colkey] = newVal;
                    p.onRowChange({ ...p.row }, true);
                    //props.handleRowUpdate(props.tableId,
                    //  {
                    //    action: "CELL_UPDATE",
                    //    cellKey: colkey,
                    //    _id: _id,
                    //    fromRow: indx,
                    //    toRow: indx,
                    //    updated: _id ? { [colkey]: newVal } : { ...props.defaultValues, [colkey]: newVal }
                    //  })
                  }
                  }
                  rowHeight={p.rowHeight}
                />
              )
            } else {
              column.editable = false            
            }
            column.formatter = (p) => {           
              return  p.row[p.column._id]?<OverflowTip rowHeight={props.rowHeight}>{p.row[p.column._id]}</OverflowTip>:''
            }   

              column.summaryFormatter= (cellParams) => {    

                return (        
                  <SummaryRowEditor 
                  items={props.items.map(it=>it[cellParams.column.key])} 
                  colType={cellParams.column.type} 
                  formula={props.summaryRow[cellParams.column.key]}
                  saveSummaryRow={newFormula => props.saveSummaryRow(props.tableId, props.viewId, {...props.summaryRow, [cellParams.column.key]:newFormula})}
                  />   
                    )
              }     
              column.groupFormatter = (p) => {    
                var v = p.groupKey        
                if (p.column.rowGroup) {              
                  if (v && v!=="undefined") {
                    return (
                      <GroupRowDiv isExpanded={p.isExpanded}>
                        {v}
                      </GroupRowDiv>
                    )
                  } else {
                    return (<GroupRowDiv isExpanded={p.isExpanded}>
    
                    </GroupRowDiv>)
                  }
                } else {
                  return (        
                    <SummaryRowEditor 
                    items={p.childRows.map(it=>it[p.column.key])} 
                    colType={p.column.type} 
                    formula={props.summaryGroupRow[p.column.key]}
                    saveSummaryRow={newFormula => props.saveSummaryGroupRow(props.tableId, props.viewId, {...props.summaryGroupRow, [p.column.key]:newFormula})}
                    />  
                  )                
                }  
              }                                                                           
          }
          
          //если это первая колонка в группировке то в нее надо выводить меню
          if(groupsValues && groupsValues.length >0){
            if(groupsValues[0]===column._id){
            column.inGroupFormatter = (cellParams) => {
        
              const cp = cellParams;
              console.log('cp ', cp)
              //console.log('cp.row ', cp.row)
              if(cp.row.__ex_new_row){
                return (        
                  <div style={{top: 0,  height:30}}>  
                  <Tooltip title="Добавить строку" aria-label="add">
                    <IconButton size="small" 
                     onClick={(e)=>{ 
                       const idx = 999//cp.rowIdx;                  
                       props.insertItem( props.tableId, {...props.defaultValues, index:idx});  
                       e.stopPropagation();
                      }}
                       
                    >
                    <AddCircleOutlineIcon style={{
                    cursor: 'pointer'
                  }} color="text.disabled" fontSize="small"/>
                    </IconButton>
                    </Tooltip>
                    </div>);            
              } else {        
                return (
                  <div>
                    <div
                      style={{
                        fontSize:11,
                        display: "inline-block",
                        color: "rgba(0, 0, 0, 0.26)"
                      }}
                    >
            {!props.sharedView && <div                     
            
            class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"}

                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 12,
                    height: "100%",
                    cursor: "-moz-grab",
                    cursor: "-webkit-grab",
                    cursor: "grab",
                    backgroundImage: "url(/grabby.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "2px 2px",
                    //display: "none"
                  }}
                  >

                  </div>}
                   <span 
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-index-cell":"dinabot-index-cell"}
                    color="text.disabled" >
                      {(cp.rowIdx + 1)}
                    </span>

                    {!props.sharedView && cp.row && selectedRows.has(cp.row._id) &&  
                    <div  
                    size="small"
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"}
                    //ref={contextManuRef} 
                    key={'context-menu-row-icon' + cp.rowIdx} 
                    style={{
                      position: "absolute",
                      top: 5,
                      left: 11,
                      width: 16,
                      height: 16,
                      //display: "none"
                    }}
                    onClick={event => {
                      //onRowSelectionChange({ row: cp.row, checked:true, isShiftClick: false });
                      const newSelectedRows = new Set(selectedRows);
                      newSelectedRows.delete(cp.row._id)
                      setSelectedRows(newSelectedRows)
                    }}>

                      <CheckBoxSharpIcon style={{
                        cursor: 'pointer',
                        fontSize:16,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }} color="primary"  /> 

                    
                    </div>}     
                    {!props.sharedView && cp.row && !selectedRows.has(cp.row._id) &&  
                    <div  
                    size="small"
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"}
                    //ref={contextManuRef} 
                    key={'context-menu-row-icon' + cp.rowIdx} 
                    style={{
                      position: "absolute",
                      top: 5,
                      left: 11,
                      width: 16,
                      height: 16,
                      //display: "none"
                    }}
                    onClick={event => {
                      //onRowSelectionChange({ row: cp.row, checked:true, isShiftClick: false });
                      const newSelectedRows = new Set(selectedRows);
                      newSelectedRows.add(cp.row._id)
                      setSelectedRows(newSelectedRows)
                    }}>

                     <CheckBoxOutlineBlankSharpIcon style={{
                        cursor: 'pointer',
                        fontSize:16,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }} color="disabled"  />
                    
                    </div>}   
                      
                    <div  
                    class={(cp.isRowSelected || cp.isRowHasCellSelected)?"dinabot-select-menu-cell":"dinabot-menu-cell"} 
                    key={'open-edit-row-icon' + cp.rowIdx} 
                    onClick={e => props.handleEditItemById(cp.row._id)}
                    size="small"
                    style={{
                      position: "absolute",
                      top: 4,
                      left: 37,
                      width: 18,
                      height: 18,
                      //display: "none"
                    }}
                    >
                      <Tooltip title="Открыть элемент">
                      <OpenInNewIcon style={{
                        cursor: "pointer",
                        color: "#66afe9",
                        fontSize:18,
                        position: "absolute",
                        top: 0,
                        left: 0
                      }} />
                      </Tooltip>
                    </div>
                    </div>
      
                  </div>
                );   
            }     
            
          }
          }      
          if(groupsValues.length >1)
          { 
            for(let ig=1; ig<groupsValues.length; ig++){
              if(groupsValues[ig]===column._id){
                column.inGroupFormatter = (cellParams) => {
                  return <></>
                }
                break;
              }

            }
            
          }  
        }   
          //if(questionsMap[column._id] && questionsMap[column._id].length>0) { 
          //  column.questions = questionsMap[column._id];
          //  if(questionsMap[column._id].length===1){
          //  initFilter[column._id] = '💬 '+questionsMap[column._id][0].question_text;
          //  } else {
          //    initFilter[column._id] = '💬 '+questionsMap[column._id].length+' связанных вопросов в чате';
          //  }
          //} else {
           // initFilter[column._id] = column.desc?('🛈 '+column.desc):'';//'💬 '+column.name;
          //}          
      }   
      //setFilters(initFilter);
      //console.log('columns itog ', columns)
      //console.log('init table columns itog ',columns)
  return columns;
}, [props.dbId, props.viewVisibleColumns, props.rowHeight, props.members, props.currentMember, tablesValues, props.tableId, props.defaultValues, groupsValues, props.sharedView, setSelectedRows, selectedRows]);


function onRowDelete(e, { rowIdx }) {
  //console.log('onRowDelete ' , rowIdx, sortedRows)
  props.onItemDelete(props.tableId, sortedRows[rowIdx])
}

function onRowInsertAbove(e, row) {
  //insertRow(rowIdx);
}

function onRowInsertBelow(e, row) {
  //insertRow(rowIdx + 1);
}  
/*
const onColumnResize = (idx, width) =>{
  //console.log('onColumnResize workitemlist', workitemlist)
  const cix = idx-1;  
  const w = width;
  //var newWI = {...workitemlist}
  //setWorkItemList((wi)=>{
    var newWI = {...props.workitemlist}
    newWI.columns[cix].width = w;
    props.setWorkitemlist(newWI)
  //  return newWI;
  //});
  

  //saveWorkitemlist(newWI)  
}
*/

  function handleFill(__props) {
    return { ...__props.targetRow, [__props.columnKey]: __props.sourceRow[__props.columnKey] };    
    //console.log('handleFill ', __props)
    /*
  {
      "columnKey": "617be2ad3bc2bb0f54b10a80",
      "sourceRow": {
          "_id": "61a22c18532629bed06fba92",
            ...
          "index": 2,
          "createdDate": "2021-11-27T13:01:12.719Z",
          "ex_updatedAt": "2021-11-11T17:16:06.897Z",
          "ex_createdAt": "2021-11-11T17:16:06.897Z",
          "__v": 0
      },
      "targetRow": {
          "_id": "61a22c19532629bed06fba96",
          "617be2ad3bc2bb0f54b10a80": "Вертикально-фрезерный станок 6т12",
          "617be2ad3bc2bb0f54b10a7f": "В работе",
          "6187d2305ec819295aae9a47": "Иванов Сергей",
            ...,
          "index": 3,
          "createdDate": "2021-11-27T13:01:13.140Z",
          "ex_updatedAt": "2021-11-12T19:33:26.236Z",
          "ex_createdAt": "2021-11-12T19:33:26.236Z"
      }
  }  
    
    
    if (__props.columnKey && __props.sourceRow && __props.sourceRow._id && __props.targetRow && __props.targetRow._id) {
      if (__props.targetRow[__props.columnKey] !== __props.sourceRow[__props.columnKey]) {
        
        var updatedRow = {...__props.targetRow, [__props.columnKey]: __props.sourceRow[__props.columnKey]}
        props.onItemUpdate(props.tableId, updatedRow)
        props.handleRowUpdate(props.tableId, // props.viewId,
          {
            action: "DINA_FILL",
            cellKey: __props.columnKey,
            _id: __props.targetRow._id,
            //fromRow: rowIdx,
            //toRow: rowIdx+pasteData.length,
            updated: { [__props.columnKey]: __props.sourceRow[__props.columnKey] }
          })
      }
    }
    */
    //return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

function handlePaste(props) {
  console.log('handlePaste ', props)
  /*
  const incompatibleColumns = ['email', 'zipCode', 'date'];
  if (
    sourceColumnKey === 'avatar'
    || ['id', 'avatar'].includes(targetColumnKey)
    || ((incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey)) && sourceColumnKey !== targetColumnKey)) {
    return targetRow;
  }

  return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey as keyof Row] };
  */
}


//пример копирования из буфера взят отсюда https://codesandbox.io/s/3qw4y?file=/src/index.js:1833-2284
const handleCopyBuf = e => {
  console.log('handleCopyBuf ', e);
  e.preventDefault();
  
  const { colIdx, rowIdx } = selectedCell;
  const columns = [{},...props.viewVisibleColumns]
  // Loop through each row
  const text = range(rowIdx, rowIdx + 1)
    .map(
      // Loop through each column
      textRowIdx =>
      columns
          .slice(colIdx, colIdx + 1)
          .map(
            // Grab the row values and make a text string
            col => sortedRows[textRowIdx][col._id]
          )
          .join("\t")
    )
    .join("\n");
  // console.log(text)
  e.clipboardData.setData("text/plain", text);
  //e.stopPropagation();
};

function handleCopy({sourceColumnKey, sourceRow}) {
  console.log('handleCopy ', sourceColumnKey, sourceRow)
  if (window.isSecureContext) {
    navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
  }
}


const handlePasteBuf =  e => {
  console.log(e)
  e.preventDefault();  
  const columns = [{},...props.viewVisibleColumns]
  const newRows = [];
  const { colIdx, rowIdx } = selectedCell;
  const pasteData = defaultParsePaste(e.clipboardData.getData("text/plain"));
  console.log('pasteData ', pasteData, colIdx, rowIdx)
  if(colIdx===null || rowIdx===null || !pasteData || pasteData.length===0 || !columns[colIdx]){
    return
  }
  if(columns[colIdx].type==="geolocation" 
  || columns[colIdx].type==="attachments"
  || columns[colIdx].type==="lookup"
  ){
    return
  }
  var updated_rows = []
  for(var idx=0; idx<pasteData.length; idx++){
    var row = pasteData[idx]
    const rowData = {};
    var updated_row = sortedRows[rowIdx+idx]
    console.log('updated_row ', updated_row, ' columns ', columns, rowIdx, colIdx, row) 
    if(!updated_row || !updated_row._id) {
      break
    }
    // Merge the values from pasting and the keys from the columns  
    updated_row[columns[colIdx]._id] = row[0];   
    updated_rows.push(updated_row)

    // Push the new row to the changes
    //newRows.push(rowData);
    //console.log('updated_row 2', updated_row)   
  }
  props.handleRowUpdate(props.tableId, // props.viewId,
    {action: "DINA_COPY_PASTE",
    cellKey: columns[colIdx]._id,
    _id: "",
    fromRow: rowIdx,
    toRow: rowIdx+pasteData.length,
    updated: updated_rows
  })  
  e.stopPropagation();
  //updateRows(topLeft.rowIdx, newRows);
}

const setSelection = _args => {
  //console.log('setSelection ', _args) //{idx: 3, rowIdx: 1}
  if(selectedRows && selectedRows.size>0){
    setSelectedRows(() => new Set())
  }
  
  //const args = _args
  //setSelectedCell({
  //  rowIdx:args.rowIdx,
  //  colIdx: args.idx
  //})

};
/**
useEffect(() => {
  if (props.gridRef && props.gridRef.current && props.gridRef.current.element) {
    
    props.gridRef.current.element.oncopy = (e)=> (handleCopy(e));
    console.log('props.gridRef ', props.gridRef)
      //return function cleanup() {
        //props.gridRef.current.element.removeEventListener("copy", handleCopy);
      //};
  }
}, []);



useEffect(()=>{
  document.addEventListener("copy", handleCopyBuf);
  //document.addEventListener("paste", handlePasteBuf);

  return () => {
    document.removeEventListener("copy", handleCopyBuf);
    //document.removeEventListener("paste", handlePasteBuf);
  }
}, [])
*/

//console.log('WorkItemsTable props.tableHeight ', props.tableHeight)
//var tableHeight = props.items?(props.rowHeight*props.items.length+300):props.tableHeight
  return (
    <DndProvider backend={HTML5Backend}>

          
 
          <DataGrid
            ref={props.gridRef}
            rowKey="_id"
            columns={draggableColumns}
            rows={sortedRows}
            rowKeyGetter={(row) => {
              return row._id;
            }}
            className="fill-grid"
            onColumnResize ={(colIdx, width)=>
              {
                //console.log('onColumnResize ! ', colIdx, width, draggableColumns[colIdx]);  
                if(colIdx===0 && width<80){
                  return
                }
                props.onColumnResize(props.tableId, props.viewId, draggableColumns[colIdx]._id, width)
              }
            } 
            style={{minHeight:props.tableHeight, height:props.tableHeight, width:"100%"}}
           //width={width}   
           //height={height}
            headerFiltersHeight={15}    
            headerRowHeight={25}
            rowHeight={({type, row}) => {
              return type==="GROUP"?35:props.rowHeight //а можно на каждую строку задать высоту, если использовать row!!!
              }
            }  
            minColumnWidth={0}
            //onSelectedCellChange={(position)=>{console.log('onSelectedCellChange ', scrollbarRef); scrollbarRef.current.scrollTop(position.rowIdx*props.rowHeight)}}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}   
            //rowSelection={{
            //  showCheckbox: true,
            //  enableShiftSelect: true,
            //}}         
            //sortColumn={sortColumn}
            //sortDirection={sortDirection}
            sortColumns={props.sortColumns}
            //onSortColumnsChange={(e)=>{console.log('onSortColumnsChange ', e)}}            
            summaryRows={summaryRows}
            summaryRowHeight= "25"
            //enableFilters={true}

            groupBy={groupsValues}
            rowGrouper={rowGrouper}
            expandedGroupIds={expandedGroupIds}
            onExpandedGroupIdsChange={props.onExpandedGroupIdsChange}
            onFill={handleFill}
            onPaste={handlePaste}
            onCopy={handleCopy}            
            filters={filters}            
            emptyRowsRenderer={props.EmptyRowsRenderer}
            //onRowsUpdate={changes=> props.handleRowUpdate(props.tableId, changes, sortedRows)}
            onRowsChange={props.onRowsChange}            
            //onSort={handleSort}      
            //enableCellCopyPaste = {true}     
            enableCellSelect= {true} 
            //cellRangeSelection={{
            //  onComplete: setSelection,
            //}}
            onSelectedPositionChange={setSelection}  
            components={{
              //sortIcon
              //checkboxFormatter
              //noRowsFallback
              rowRenderer:RowRenderer
            }}          
            
            cellNavigationMode="changeRow"
          />                      
  <ColumnSettingsDialog  
              tableId={props.tableId} 
              open={anchorElColEdit} 
              setOpen={setAnchorElColEdit} 
              editingSets={editingSets} 
              setEditingSets={setEditingSets} 
              onColumnInsert={props.onColumnInsert} 
              onChangeColumn={props.onChangeColumn} 
              tables={props.tables}
              tablesList={props.tablesList} 
              viewId={props.viewId}
              columns={props.columns}
              />  
              <Popover
                id={"row-context-menu-last" }
                open={Boolean(rowContext)}
                //anchorEl={rowContextAnchorEl}
                anchorPosition={rowContextAnchorPosition}
                anchorReference="anchorPosition"
                onClose={e => {
                  setRowContextAnchorEl(null)
                  setRowContext(null)
                }}
                //transition
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              //        style={{minWidth:"250px"}}
              >
                {selectedRows.size>1 ?
                <MenuList id="row-context-menu-list" dense>
                  <MenuItem onClick={e => 
                  {
                    props.onItemDelete(props.tableId, rowContext);                   
                    setRowContextAnchorEl(null)
                    setRowContext(null)
                  }}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>Удалить выделенные строки</MenuItem>
                </MenuList>
                :
                <MenuList id="row-context-menu-list" dense>
                <MenuItem onClick={e => 
                {
                  props.onItemDelete(props.tableId, rowContext);                   
                  setRowContextAnchorEl(null)
                  setRowContext(null)
                }}>
                  <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>Удалить строку</MenuItem>
                <MenuItem ><ListItemIcon><ArrowDownwardIcon fontSize="small" /></ListItemIcon>Добавить строку перед</MenuItem>
                <MenuItem ><ListItemIcon><ArrowUpwardIcon fontSize="small" /></ListItemIcon>Добавить строку после</MenuItem>
              </MenuList>
              }

              </Popover>


          {createPortal(
            <ClickAwayListener onClickAway={e=> hideMenu()}>
        <ContextMenu 
        id="row-context-menu"
        preventHideOnContextMenu
        preventHideOnResize
        preventHideOnScroll
        >
          
            <MenuList id="row-context-menu-list" dense>
            <MenuItemDom onClick={onRowDelete}><DeleteIcon fontSize="small"/><span>Удалить строку</span></MenuItemDom>
            <MenuItemDom ><ArrowUpwardIcon fontSize="small"/><span>Добавить строку перед</span></MenuItemDom>
            <MenuItemDom ><ArrowDownwardIcon fontSize="small"/><span>Добавить строку после</span></MenuItemDom>
          </MenuList>           
        </ContextMenu></ClickAwayListener>,
        document.body
      )}                               
           </DndProvider>
  );
}