import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

export default function SelectFormEditor({ value, onChange, options, rowHeight, menuPortalTarget, noOptionsMessage,inForm, isDisabled }) {
  const [selectValue, setSelectValue] = useState([]);

  useEffect(() => {
    var _v = options.find(o => o.value === value)
    setSelectValue([_v])
  }, [value, options]);  

  //console.log('SelectEditor ',  value, options )
  return (
  <Autocomplete
  multiple
  isDisabled={isDisabled}
  openOnFocus
  id="select-cell-editor"
  size="small"
  options={options}
  getOptionLabel={option => option.label}
  //defaultValue={[options.find(o => o.value === value)]}
  value={selectValue}
  style={{height: rowHeight}}
  disableClearable
  onContextMenu={event => event.stopPropagation()}
  onChange={(event, o) => {
      console.log('SelectEditor onChange ', o, event);
      var new_val = (o && o.length>0 && o[o.length-1])?o[o.length-1]:null
      setSelectValue([new_val])
      onChange((new_val)?new_val.value:null)
    }
  }              
  PopperComponent={(props) => {
    return <Popper {...props} style={{minWidth: 250}} placement="bottom-start" />;
 }}
  renderInput={(params) => (
    <TextField         
    {...params} 
    autoFocus = {!inForm}
    variant="standard" 
    placeholder="Веберите " 
    InputProps={{ ...params.InputProps, style:{fontSize:"12px", paddingLeft: 6, paddingTop:6}, disableUnderline: !inForm }}                
    />
  )}
  renderTags={(o) => (
    <React.Fragment>
      {o && o.length>0 && o[o.length-1] &&<Chip
        size="small"
        //icon={<AccountCircleIcon />}
        //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
        label={<span         
         // style={
         // {
         //   color:"white", 
            //mixBlendMode: "darken",
         // }
        //}
        >{(o[o.length-1])?o[o.length-1].label:""}</span>}
        //avatar={<Avatar>M</Avatar>}
        //clickable
        //color={o.color ? o.color : "default"}
        style={
          {
            backgroundColor: o[o.length-1].color, 

            fontSize: "12px", 
            height: "20px" 
          }
        }
      //onDelete={(e) => handleDeleteOptionChoice(o, index)}
      //deleteIcon={<DeleteIcon />}
      />}
    </React.Fragment>
  )}    
  renderOption={(option) => (
    <React.Fragment>
      <Chip
        size="small"
        //icon={<AccountCircleIcon />}
        //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
        label={<span         
        //  style={
        //  {
        //    color:"white", 
            //mixBlendMode: "darken",
        //  }
        //}
        >{option.label}</span>}
        //avatar={<Avatar>M</Avatar>}
        //clickable
        //color={o.color ? o.color : "default"}
        style={
          {
            backgroundColor: option.color, 
            fontSize: "12px", 
            height: "20px" 
          }
        }
      //onDelete={(e) => handleDeleteOptionChoice(o, index)}
      //deleteIcon={<DeleteIcon />}
      />
    </React.Fragment>
  )}    
  onKeyDown={event => {
    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      event.stopPropagation();
    }

    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  }}                        
/>   
)
  }