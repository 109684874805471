import React, {useState, useContext, useCallback, useEffect, forwardRef, useRef  } from 'react';
import {useHistory, Link, useParams} from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {useHttp} from '../hooks/http.hook';
import Paper from '@material-ui/core/Paper';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Tooltip } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import moment from 'moment-timezone';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ColorPicker from './WorkItemsTable/ColorPicker'
import DeleteIcon from '@material-ui/icons/Delete';
import Popover from '@material-ui/core/Popover';
import { Picker } from 'emoji-mart'
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import axios from "axios";
import 'moment/locale/ru'
import { SavingsPlans } from 'aws-sdk';
moment.locale('ru');

function RobotIcon(props) {
  return (
    <SvgIcon {...props}>
<path fill="currentColor" d="M14.6907 11.5031C15.69 11.5031 16.5 12.3131 16.5 13.3124V14.0019H16.4947C16.4607 14.7813 16.247 15.7588 15.3718 16.5574C14.4158 17.4297 12.7655 18 10 18C7.23446 18 5.58423 17.4297 4.6282 16.5574C3.75297 15.7588 3.53931 14.7813 3.50533 14.0019H3.5V13.3124C3.5 12.3131 4.31005 11.5031 5.3093 11.5031H14.6907Z"/>
<path fill="currentColor" d="M6.5 3C5.67157 3 5 3.67157 5 4.5V8.5C5 9.32843 5.67157 10 6.5 10H13.5C14.3284 10 15 9.32843 15 8.5V4.5C15 3.67157 14.3284 3 13.5 3H10.5C10.5 3 10.5 2.80859 10.5 2.5C10.5 2.19141 10.2761 2 10 2C9.72386 2 9.5 2.23047 9.5 2.5C9.5 2.76953 9.5 3 9.5 3H6.5ZM7 6.5C7 5.94771 7.44772 5.5 8 5.5C8.55228 5.5 9 5.94771 9 6.5C9 7.05228 8.55228 7.5 8 7.5C7.44772 7.5 7 7.05228 7 6.5ZM11 6.5C11 5.94771 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94771 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5Z"/>
    </SvgIcon>
  );
}

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  const useStyles = makeStyles((theme) => ({
    
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    question_list: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
      },
      subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    grow: {
      flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    selectEmpty: {
        marginTop: theme.spacing(2),
      }, 
    scheduler:{
        display: 'flex',
    },
    fabButton:  {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 99
      },  
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },     
      cardGrid: {
        paddingTop: theme.spacing(4),
        //paddingBottom: theme.spacing(4),
      },        
      card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      cardMedia: {
        paddingTop: '56.25%', // 16:9
      },
      cardContent: {
        flexGrow: 1,
      },
      cardTemlate: {
        //height: '150px',
        //display: 'flex',
        //flexDirection: 'column',
      },    
      cardHeaderContent: {
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "ellipsis"
      }     
  }));

  function DBCopyDialog({ onSelectHandle }) {

    const [open, setOpen] = useState(false);
    const [newTelegramBotToken, setNewTelegramBotToken] = useState("");
  
    const classes = useStyles();
    const history = useHistory();
  
    const onChangeTelegramBotToken = (event) => {
      const v = event.target.value;
      setNewTelegramBotToken(v)
    }
  
  
    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);
  
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleSave = () => {
      var new_bot_params = {
        channelId : 'telegram',
        token : newTelegramBotToken,
        //microsoftAppId = ,
        //microsoftAppPassword,
        //name,
        //mention_name,
        //tada_wss
      }
      onSelectHandle(new_bot_params)
      handleClose();
    };
  
  
  
    return (
      <React.Fragment>
        <MenuItem onClick={handleClickOpen}>
          <ListItemIcon>
            <FilterNoneIcon fontSize="small" />
          </ListItemIcon>Создать копию базы
        </MenuItem>
        <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" ><Typography variant="h6" noWrap>Введите API Token нового Telegram-бота </Typography>
          </DialogTitle>
          <DialogContent dividers>
  
            <Paper className={classes.job_card} elevation={3}>
              <div >
  
                <TextField
                  id={"new-telegram-bot-token"}
                  value={newTelegramBotToken}
                  onChange={onChangeTelegramBotToken}
                  placeholder="Telegram bot access token"
                  helperText=""
                  fullWidth
                  //multiline
                  //rows={3}
                  label="API Token"
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>            
            </Paper>
  
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Отмена
            </Button>
            <Button color="primary" onClick={handleSave}>
              Создать бота
            </Button>
          </DialogActions>
        </Dialog>
  
      </React.Fragment>
    );
  }

  function DbSettingsPopover(props) {    
    const {token} = useContext(AuthContext);
    const [title, setTitle] = useState("");
    //const [icon, setIcon] = useState("");
    const [viewEmoji, setViewEmoji] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
    const itemRef = useRef() 
    const screenUploader = useRef(null);
    const classes = useStyles();
  
    const palette = {
      red: '#ff0000',
      blue: '#0000ff',
      green: '#00ff00',
      yellow: 'yellow',
      cyan: 'cyan',
      lime: 'lime',
      gray: 'gray',
      orange: 'orange',
      purple: 'purple',
      black: 'black',
      pink: 'pink',
      darkblue: 'darkblue',
    };
  
    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClickOpenEmoji = (event) => {
      //setGroup(props.groups[0]._id);
        setEmojiAnchorEl(event.currentTarget);
      
    };  
    const handleCloseEmoji = () => {
      setEmojiAnchorEl(null);
    };  
  
    const onChangeTitle = (event) => {
      setTitle(event.target.value)
    }
  
    const onChangeTitleHandler = (event) => {
      //console.log('event.target.name',event.target.name);
      props.onChangeTitle(event.target.value)
    }
  
    const handleClose = () => {
      if(title!==props.title){
        props.onChangeTitle(props._id, title)
      }
      setAnchorEl(null);
    };
  
    const handleSave = () => {
      //onSelectHandle();        
      setAnchorEl(null);
    };
  
    const onSelectEmoji = (emoji) => {
      //console.log('onSelectEmoji ', emoji)
      props.onChangeIcon(props._id, emoji.native)
      setEmojiAnchorEl(null);
      //setIcon(emoji.native)
    }
  
    const handleDel = () => {
      props.handleDelete(props._id);
      handleClose();
    };
  
    const handleCopy = () => {
      props.handleCopy(props._id); //handleDelete={handleDelete} handleSaveAsTeamplate={handleSaveAsTeamplate} handleCopy={handleCopy}
      handleClose();
    }
    const handleChangePreview = async (ev) => {
      const [file] = ev.target.files;
      if (file) {
        console.log('handleChangePreview ', file)
        let formData = new FormData();
  
        formData.append("file", file);
        try {
        var resp = await axios.post(`/api/files/${props._id}/cover/a/a`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
          }
          //onUploadProgress,
        });
        console.log('upload cover ', resp)
        if(resp && resp.data && resp.data.link){
          props.onChangeDbScreen(props._id, resp.data.link)
        }
        setAnchorEl(null);
        } catch (err) {
        }
      }
    };    


  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    useEffect(() => {
      setTitle(props.title)
      //setIcon(props.icon)
    }, [props.title])
  
    return (
      <React.Fragment>
        <Card 
          className={classes.card}
          ref={itemRef}
        >
                <CardHeader
                  classes={{
                    content: classes.cardHeaderContent
                  }}
        avatar={
          <Avatar aria-label="recipe" style={{backgroundColor: props.dbBackgroundColor?props.dbBackgroundColor:"white"}}>
            {props.icon}
          </Avatar>
        }
        action={
          <IconButton 
          aria-label="menu" 
          onClick={handleClickOpen}
          >
            <MoreVertIcon fontSize="small"/>
          </IconButton>
        }
        title={props.title}
        //titleTypographyProps={}
        subheader={moment(props.ex_updatedAt).locale('ru').format('ll')}
      />                      
      <CardActionArea
      href={`/db/${props._id}`}
      >
                  <CardMedia
                    className={classes.cardMedia}
                    image={props.screen}
                    title={props.title}
                  />
                  <CardContent className={classes.cardContent}>
                  </CardContent>
                  </CardActionArea>   
                 {props.mention_name && <CardActions>
        <Button target="_blank" size="small" color="primary" href={props.invite_url}>
         @{props.mention_name}
        </Button>
        <CopyToClipboard text={props.invite_url}> 
        <IconButton size="small" >
              <FilterNoneIcon fontSize="small"/>                  
              </IconButton>
          </CopyToClipboard> 
      </CardActions> }
                </Card>


        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        //        style={{minWidth:"250px"}}
        >
          <Paper elevation={3} style={{maxWidth:"350px"}} >
            <div>
              <TextField
                autoFocus
                id={"table_title_field"}
                value={title}
                name="title"
                placeholder="Название"
                helperText=""
                onChange={onChangeTitle}
                //margin="normal"
                //variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Avatar 
                        onClick={handleClickOpenEmoji} 
                        variant="square" 
                        style={{ 
                          backgroundColor: "white",
                          cursor:"pointer" 
                          }}>
                          {props.icon}
                      </Avatar>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <ColorPicker 
                      //value={props.dbBackgroundColor} 
                      //disableAlpha 
                      value={props.dbBackgroundColor} 
                      //palette={palette} 
                      //hideTextfield 
                      onChange={(color) => props.onChangeDbBackgroundColor(props._id, color.hex)} 
                      />
                    </InputAdornment>
                  ),
  
  
                }}
              />
            </div>  
            <input 
            ref={screenUploader} 
            type="file" 
            accept="image/*" 
            onChange={handleChangePreview} 
            style={{
              display: "none"
            }}            
            />  
          <MenuItem
          onClick={() => screenUploader.current.click()}
          >
            <ListItemIcon>
            <WallpaperIcon fontSize="small" />
            </ListItemIcon>
            Изменить обложку
          </MenuItem>

        <MenuItem onClick={handleCopy}>
        <ListItemIcon>
          <FilterNoneIcon fontSize="small" />
        </ListItemIcon>Создать копию базы
      </MenuItem>
            <MenuItem onClick={handleDel}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>Удалить базу
            </MenuItem>
          </Paper>
          <Popover
          id={"db-emoji-picker"}
          open={Boolean(emojiAnchorEl)}
          anchorEl={emojiAnchorEl}
          //anchorPosition={props.open}
          onClose={handleCloseEmoji}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        //        style={{minWidth:"250px"}}
        >
  
              <Picker onSelect={onSelectEmoji}
                style={{ ".emoji-mart-scroll": "170px" }}
                showPreview={false}
                emojiSize={14}
                title=""
                native={true}
                showSkinTones={false}
                i18n={{
                  search: 'Поиск',
                  clear: 'Очистить', // Accessible label on "clear" button
                  notfound: 'Не найдены Emoji',
                  skintext: 'Выбрать цвет кожи',
                  categories: {
                    search: 'Результаты поиска',
                    recent: 'Часто используемые',
                    smileys: 'Эмоции',
                    people: 'Люди',
                    nature: 'Природа',
                    foods: 'Еда',
                    activity: 'Активности',
                    places: 'Путешествия',
                    objects: 'Цели',
                    symbols: 'Символы',
                    flags: 'Флаги',
                    custom: 'Порчие',
                  },
                  categorieslabel: 'Категории Emoji', // Accessible title for the list of categories
                  skintones: {
                    1: 'Default Skin Tone',
                    2: 'Light Skin Tone',
                    3: 'Medium-Light Skin Tone',
                    4: 'Medium Skin Tone',
                    5: 'Medium-Dark Skin Tone',
                    6: 'Dark Skin Tone',
                  }
                }} />
            </Popover>        
        </Popover>
      </React.Fragment>
    );
  }

  export default function MyTables({profile, searchBase}) {
    const {token} = useContext(AuthContext);
    const {loading, request} = useHttp();
    const [tables, setTables] = useState([]);
    const [tablesVisible, setTablesVisible] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [viewTemplates, setViewTemplates] = useState([])
    const [errorMsg, setErrorMsg] = useState(null);
    const history = useHistory();
    const wiId = useParams().wi;
    const classes = useStyles();
    const theme = useTheme();

    const isMDWidth = useMediaQuery(theme.breakpoints.up('md'));

    const getTemplates = useCallback(async()=>{
      try {
          var data = await request(`/api/tmpl/?recomended=true`,'GET',null, {Authorization: `Bearer ${token}`});
          setTemplates(data);
          console.log('getTemplates data', data);            
      } catch (e) {
          //console.log('getTemplates error', e); 
      }
  },[token, request])   

     const updateTables = useCallback(async()=>{
        try {
          var data = await request('/api/db', 'GET', null, { Authorization: `Bearer ${token}` });
          var rows = [];
          if (data && data.length > 0) {
            data.sort(function(a,b){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.ex_updatedAt) - new Date(a.ex_updatedAt);
            })
            /*
            for (let id = 0; id < data.length; id++) {
              let newRow = { ...data[id] };
              if (newRow.icon) {
                newRow.title = newRow.title

              }

              var bot = await request(`/api/db/${newRow._id}/getBotChannel`, 'GET', null, { Authorization: `Bearer ${token}` });
              if (bot) {
                //newRow.bot = bots
                //newRow.title = bot.name
                newRow.mention_name = bot.mention_name
                newRow.invite_url = bot.invite_url
                newRow.channel = bot.channel
              }
              rows.push(newRow)
            }
            */
          }
          setTables(data);          

        } catch (e) {
            console.log('updateTables error', e); 
        }
    },[token,  request])    

    const onClickTemplateHandle = useCallback(async(original_db)=>{
      try {
        var bot_params = {}        
        if(original_db){
          var data = await request(`/api/db/${original_db}/copyDB`,'POST',{bot_params:bot_params, copyItems: false}, {Authorization: `Bearer ${token}`});
          //console.log('onSelectHandle data', data); 
          window.ym(53785333,'reachGoal','createdb')
          history.push(`/db/${data._id}`); 
        } else {
          var data = await request(`/api/db/`,'POST',{bot_params:bot_params}, {Authorization: `Bearer ${token}`});
          window.ym(53785333,'reachGoal','createdb')
          //console.log('onSelectHandle data', data); 
          history.push(`/db/${data._id}`);       
        }
              
          console.log('onSelectHandle data', data);            
      } catch (e) {
          console.log('onSelectHandle error', e); 
      }
    },[token, history, request])  

    const saveDb = useCallback(async (dbId, params) => {
        try {
  
          var req_wi = await request(`/api/db/${dbId}`, 'POST', params, { Authorization: `Bearer ${token}` });                    
          setTables(cur => {
            var newTables = [...cur]
            var idx = newTables.findIndex(e => e._id === dbId)
            newTables[idx] = {...newTables[idx], ...params}
            return newTables
          })  
        } catch (e) {
          setErrorMsg(e.message ? 'Ошибка:' + e.message : 'Произошла ошибка! Попробуйте еще раз...')
          console.log('saveDb error', e); 
        }
    }, [token, request])
  

    const onChangeIcon = (dbId, value) => {
      const v = value;
      if (v.length > 0) {

        saveDb(dbId, { icon: v });
      }
  
    }
  
    const onChangeDbBackgroundColor = (dbId, value) => {
      const v = value;

      saveDb(dbId,{ dbBackgroundColor: v });
    }
  
    const onChangeTitle = (dbId, value) => {
      const v = value;
      if (v.length > 0) {

          saveDb(dbId, { title: v });
           
      }  
    }

    const onChangeDbScreen = (dbId, value) => {
      const v = value;

      saveDb(dbId,{ screen: v });
    }

    const onCopyDbHandle = useCallback(async (dbId) => {
        try {
          var data = await request(`/api/db/${dbId}/copyDB`, 'POST', { copyItems: true}, { Authorization: `Bearer ${token}` });
          window.ym(53785333,'reachGoal','createdb')
          history.push(`/db/${data._id}`); 
        } catch (e) {
          //console.log('onCopyHandle error', e); 
          setErrorMsg(e.message ? 'Ошибка:' + e.message : 'Произошла ошибка! Попробуйте еще раз...')
          console.log('onCopyDbHandle error', e); 
        }
    }, [token, request])
  
    const handleCopy = (dbId) => {
      onCopyDbHandle(dbId);
    };

    const deleteDb = useCallback(async (dbId) => {

        try {

          var req_wi = await request(`/api/db/${dbId}`, 'DELETE', null, { Authorization: `Bearer ${token}` });
          setTables(cur => {
            const newTables = cur.filter(v => {
              return v._id !== dbId
            })
            return newTables
          })
        } catch (e) {
          setErrorMsg(e.message ? 'Ошибка:' + e.message : 'Произошла ошибка! Попробуйте еще раз...')
          console.log('deleteDb error', e); 
        }
    }, [token, request])
    
    const handleDelete = (dbId) => {
      deleteDb(dbId);
  
    };

    useEffect(()=>{
      getTemplates()
      updateTables();              
    }, [])

    useEffect(()=>{
      //console.log('useEffect templates ', templates)
      if(templates && templates.length>3){
      if(isMDWidth){
        setViewTemplates(templates.slice(0, 4))
      } else {
        setViewTemplates(templates.slice(0, 2))
      }  
    } else {
      setViewTemplates([...templates])
    }          
    }, [isMDWidth, templates])    

    useEffect(()=>{
      if(tables && tables.length>0){
        if(searchBase && searchBase.length>0){
          const _searchBase = searchBase.toUpperCase()
          var _tbs = tables.filter(e => {
            if(e.title.toUpperCase().indexOf(_searchBase)>=0){
              return true
            } else {
              return false
            }
          })
          setTablesVisible(_tbs)

        } else {
          setTablesVisible(tables)
        }
      } else {
        setTablesVisible([])
      }        
    }, [searchBase, tables, setTablesVisible])    
    
    
      return (
          <React.Fragment>     
          <div >         
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <LinearProgress />
            </Fade>
                   
        
        <Container  className={classes.cardGrid} maxWidth="lg">
        <Typography variant="h5"  color="textPrimary" paragraph>
            Создать из шаблона
            </Typography>  
        <Grid container wrap='nowrap' spacing={1}>
        <Grid item xs={3} sm={3} md={2}>
        <Card className={classes.cardTemlate}>
        <CardActionArea
      //href={`/db/${tb._id}`}
      onClick={e=> onClickTemplateHandle(null)}
      >
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://cdn4.iconfinder.com/data/icons/flat-pro-database-set-3/32/tables-512.png"
                    title="Создать пустую базу"
                  />      
                  <CardContent >
                    <b style={{
                      whiteSpace: 'nowrap',
                      fontSize:"12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                      Создать пустую базу
                    </b>
                  </CardContent>                 
        </CardActionArea>
        </Card>
        </Grid>    
        {viewTemplates &&  viewTemplates.map(tm=>
        <Grid item xs={3} sm={3} md={2} >
        <Card className={classes.cardTemlate}>
        <CardActionArea
      //href={`/db/${tb._id}`}
      onClick={e=> onClickTemplateHandle(tm.original_db?tm.original_db:null)}
      >
                  <CardMedia
                    className={classes.cardMedia}
                    image={tm.cover}
                    title={tm.title}
                  />      
                  <CardContent >
                  <b style={{
                      whiteSpace: 'nowrap',
                      fontSize:"12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis"

                    }}>+ {tm.title}</b>
                  </CardContent>                    
        </CardActionArea>
        </Card>
        </Grid>                  
                  )                
        }
        <Grid item  xs={3} sm={3} md={2}>
        <Card className={classes.cardTemlate}>
        <CardActionArea      
      href='https://dinabot.com/templates'
      >
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://png.pngtree.com/png-vector/20190401/ourmid/pngtree-more-icon-design--essential-icon-vector-design-png-image_902831.jpg"
                    title="Посмотреть все шаблоны"
                  />        
                  <CardContent >
                  <b style={{
                      whiteSpace: 'nowrap',
                      fontSize:"12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis"

                    }}>Все шаблоны</b>
                  </CardContent>                   
        </CardActionArea>
        </Card>
        </Grid>                                 
        </Grid>
        </Container>                      
        
        {tablesVisible && tablesVisible.length>0 && 
        
        <Container className={classes.cardGrid} maxWidth="lg">
          {/* End hero unit */} 
            <div>
          <Typography component="span" variant="h5" color="textPrimary">
            Последние базы данных
            </Typography>       
                </div>           
          <Grid container spacing={4}>
            {tablesVisible.map((tb) => (
              <Grid item key={tb._id} xs={12} sm={6} md={4} lg={3}>
            <DbSettingsPopover
              _id={tb._id}
              title={tb.title}
              ex_updatedAt={tb.ex_updatedAt}
              mention_name={tb.botchannel?tb.botchannel.mention_name:null}
              invite_url={tb.botchannel?tb.botchannel.invite_url:null}
              onChangeTitle={onChangeTitle}
              icon={tb.icon}
              screen={tb.screen}
              onChangeIcon={onChangeIcon}
              handleDelete={handleDelete}
              handleCopy={handleCopy}
              dbBackgroundColor={tb.dbBackgroundColor}
              onChangeDbBackgroundColor={onChangeDbBackgroundColor}
              onChangeDbScreen={onChangeDbScreen}
            /> 
              </Grid>
            ))}
              <Grid item key="new_db_in_team" xs={12} sm={6} md={4} lg={3}>
      <ListItem
        //disableGutters
        button   
        component="a"
        onClick= {e=> onClickTemplateHandle(null)}
        ContainerComponent="div"
      >
                <ListItemAvatar>
          <Avatar
          >
          <AddIcon />
          </Avatar>
        </ListItemAvatar>
      
      <ListItemText 
      
      id={"db-el-label-new"} 
      primary="Создать пустую базу" 
      />
    </ListItem>
              </Grid>
          </Grid>
        </Container> }                 
              </div>
          </React.Fragment>
      );
  }    