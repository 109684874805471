import {useCallback} from 'react'

export const useMessage = () =>{
    return useCallback(text =>{
        
        if(window.M && text){
            window.M.toast({html:text})
        }
        /**
         * Позже прикрутить https://material-ui.com/ru/components/snackbars/ для уведомлений
         * может даже https://github.com/iamhosseindhv/notistack
         * 
         */
    },[]);
}