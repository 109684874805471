const SummaryPos = {
    Top: 0,
    TopRight: 1,
    TopLeft: 2,
    Bottom: 3,
    BottomRight: 4,
    BottomLeft: 5
};

export default SummaryPos
