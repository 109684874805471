import { useEffect, useState, useCallback, useContext, Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { MentionsInput, Mention } from './Mentions'
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import _clone from 'lodash/clone'
import _escapeRegExp from 'lodash/escapeRegExp'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
   
  }));

export default function FormulaTextEditor(props) {
    //const [open, setOpen] = React.useState(false);
    const [value, setValue] = useState("");
    const [plainText, setPlainText] = useState("");
    const [openTooltip, setOpenTooltip] = useState(false);
    const classes = useStyles(); 
  
    const handleTooltipClose = () => {
      setOpenTooltip(false);
    };
  
    const handleTooltipOpen = () => {
      setOpenTooltip(true);
    };
  
    const onChange = (event, newValue, newPlainTextValue, mention) => {
      //console.log('onChangeQuestionText ', event, newValue, newPlainTextValue, mention)
      const v = newValue //event.target.value;        
      //console.log('FormulaTextEditor ', newValue, newPlainTextValue, mention)
      setValue(v)
      setPlainText(newPlainTextValue)
      //const v = saveFormulaParam(val)
       //setJob(curJob=>{
       //   var newJob={...curJob}
       //   newJob.update_item_id=v        
       //   return newJob
       //})     
  }
  
  const saveFormulaParam = useCallback( (val) => {
  
  
    let saveText = "";
    if (val && props.variabliesForFormula && props.variabliesForFormula.length>0) {
      saveText = _clone(val)
      const tags = saveText.match(/{([^}]+)}/gi) || []
      if (tags.length > 0) {
        console.log("saveFormulaParam tags ", tags, saveText)
        const variablies = props.variabliesForFormula
        tags.map(myTag => {
          const tagData = myTag.slice(1, -1)
          let v = variablies.find(vr=> vr.display===myTag)
          const tagDisplayValue = v ? v.id : ""
          saveText = saveText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
        })
        console.log("saveFormulaParam saveText ", saveText)
      }
    }
    return saveText
  }, [props.variabliesForFormula])
  
  
    useEffect(()=>{
        console.log('FormulaTextEditor useEffect ', props.value, props.variabliesForFormula)
      var displayText = ""
      var text = ""
      if(props.value && props.variabliesForFormula && props.variabliesForFormula.length>0){
          const variablies = props.variabliesForFormula
   
          displayText = _clone(props.value)
          text = _clone(props.value)
          const tags = displayText.match(/var_\w+/gi) || []
          if (tags && tags.length > 0) {
            
            tags.map(myTag => {
              //const tagData = myTag.substring(4)
              let v = variablies.find(vr=> vr.id===myTag)
              var tagDisplayValue = ""
              var tagDisplayValue2 = ""
              if(v){
                tagDisplayValue = `@[${v.display}](${v.id})`
                tagDisplayValue2 = v.display 
    
              }
              displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
              text = text.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue2)
            })
          }
  
      } 
  
      setPlainText(text)
      setValue(displayText)
      
    
    },[props.value, props.variabliesForFormula]) 
    
    let container

    return (
      <Fragment>                   

        <LightTooltip 
                        //PopperProps={{
                        //  disablePortal: true,
                        //}}
                        //onClose={handleTooltipClose}
                        //open={openTooltip}
                        //disableFocusListener
                        disableHoverListener
                        //disableTouchListener
        interactive 
        title={props.tooltip?props.tooltip:<Fragment>
            <Typography variant="subtitle2" >Примеры:</Typography><br />
            
            <Typography variant="caption" >{`{Количество} * {Цена}`}</Typography><br />
            <Typography variant="caption" >{`AVERAGE({Поле1}; {Поле2})`}</Typography><br />
<Typography variant="caption" >{`{Имя} & "-" & {Дата}`}</Typography><br />
<Typography variant="caption" >{`IF({Цена} * {Количество} > 5; "Да"; "Нет")}`}</Typography><br />
<ButtonBase  href="https://dinabot.com/help/formulas/" target="_blank">Список поддерживаемых функций</ButtonBase>
            </Fragment>} placement="left">                           
                                                        <MentionsInput
                                                        {...props}
                      //singleLine
                                                      value={value}
                                                     onBlur={e => props.onBlur(saveFormulaParam(plainText))}
                                                    // onClick={handleTooltipOpen}
                                                      //helperText={p.description}
                                            onChange={onChange}
                                            //inputRef={inputRef}
                                             //defaultValue = {props.question.question_text}
                                             //onBlur={onSaveQuestionText}
                                            //displayTransform={(id, display) => `[${id}]`}        
                                            //placeholder="Используйте { для вставки значения полей в текст"
                                            className="mentions"
                                            suggestionsPortalHost={props.suggestionsPortalHost}
                                            
                                          >
                                            <Mention
                                              //type="user"
                                              //markup="(__id__)" 
                                              displayTransform={(id, display) => `${display}`}  
                                              trigger="{"
                                              data={props.variabliesForFormula}
                                              //style={{ backgroundColor: '#cee4e5' }}
                                            />
                                          </MentionsInput> 
                                          </LightTooltip>      
                                         
      </Fragment>
    );
  }    
  