import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';

export default function ColorPicker({ value, onChange, presetColors}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const defaultPresetColors = [
      {title:"Light gray",color:'rgba(227, 226, 224, 0.5)'},
      {title:"blue",color:'rgb(227, 226, 224)'},
      {title:"green",color:'rgb(238, 224, 218)'},
      {title:"yellow",color:'rgb(250, 222, 201)'},
      {title:"cyan",color:'rgb(253, 236, 200)'},
      {title:"lime",color:'rgb(219, 237, 219)'},
      {title:"gray",color:'rgb(211, 229, 239)'},
      {title:"orange",color:'rgb(232, 222, 238)'},
      {title:"purple",color:'rgb(245, 224, 233)'},
      {title:"black",color:'rgb(255, 226, 221)'},
      //pink: 'pink',
      //darkblue: 'darkblue',
    ];  
    const handleClickOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };    

    const handleChangeComplete = (color) => {
      console.log('color ', color)
      onChange(color)
      setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'color-picker-popover' : undefined;

    return (
      <React.Fragment>
      <Tooltip title="Выберите цвет">
        <IconButton onClick={handleClickOpen} size="small" variant="text" >
          <ColorLensIcon style={{color: value}}/>
        </IconButton>
      </Tooltip>      
      <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >    
    <Paper>
    <SketchPicker 
        color={ value }
        onChangeComplete={ handleChangeComplete }   
        presetColors={presetColors} 
    />
    </Paper>
    </Popover>  
    </React.Fragment>
    );
  }