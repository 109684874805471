import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ObjectID from "../ObjectId";
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { orange, green,yellow, purple } from '@material-ui/core/colors';
import SubjectIcon from '@material-ui/icons/Subject';
import Looks3Icon from '@material-ui/icons/Looks3';
import EventIcon from '@material-ui/icons/Event';
import FilterListIcon from '@material-ui/icons/FilterList';
import PersonIcon from '@material-ui/icons/Person';
import LinkIcon from '@material-ui/icons/Link';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import RootRef from "@material-ui/core/RootRef";
import SvgIcon from '@material-ui/core/SvgIcon';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
//import { ColorPicker } from 'material-ui-color';
import ColorPicker from './ColorPicker'
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MentionsInput, Mention } from '../Mentions'
import SmallSwitch from '../DinaComponents/SmallSwitch'
import Chip from '@material-ui/core/Chip';
import FormulaTextEditor from '../FormulaTextEditor';
import { Typography } from '@material-ui/core';
import _clone from 'lodash/clone'
import _escapeRegExp from 'lodash/escapeRegExp'
import '../DialogEditor.css'

const useStyles = makeStyles((theme) => ({

  menuButton: {
    marginRight: theme.spacing(2),
  },    
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

  },
  list: {
    marginBottom: theme.spacing(2),
  },
  paper_send_invite: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: purple[50]
  },    

  paper_invite_links: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },       
  question_list: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    subheader: {
    backgroundColor: theme.palette.background.paper,
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  fabButton:  {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 99
  },
  fileinput: {
    display: 'none',
  },    
  selectEmpty: {
      marginTop: theme.spacing(2),
    }, 
  scheduler:{
      display: 'flex',
  },
  settingsPaper: {
    //marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)      
  },
  dialogCloseButton:{
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  nonSelectedListItemSecondaryAction:{
    visibility: "hidden",
    //cursor:"move"
  },
  selectedListItemSecondaryAction:{
    
  },    
  nonSelectedListItem: {
    "&:hover $nonSelectedListItemSecondaryAction": {
      visibility: "inherit"
    }
  }       
}));

function LookupIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
    </SvgIcon>
  );
}  


export default function ColumnSettingsDialog(props) {
  
  const [columnSettings, setColumnSettings] = useState({name:'', type:'text', options:[], editable:true});
  const [isNew, setIsNew] = useState(true);
  const [newOption, setNewOption] = useState("");
  const [linkIdError, setLinkIdError] = useState(null);
  const [linkIdColumns, setLinkIdColumns] = useState([]);
  const [linkIdViews, setLinkIdViews] = useState([]);
  const [columnsNameById, setColumnsNameById] = useState(null);
  const [columnsIdByName, setColumnsIdByName] = useState(null);
  const [formulaText, setFormulaText] = useState("")
  const [linkIdTablesColumns, setLinkIdTablesColumns] = useState({});
  
  const [colors, setColors] = useState([ 'rgba(227, 226, 224, 0.5)','rgb(227, 226, 224)','rgb(238, 224, 218)','rgb(250, 222, 201)',  'rgb(232, 222, 238)', 'rgb(245, 224, 233)','black', 'rgb(255, 226, 221)'])
  const suggestionsPortalHost = useRef(null)
    const classes = useStyles();    
  
  const variabliesForFormula = useMemo(() => {
    const columns = props.columns?props.columns:[]
    var _formulaParamsByName = [
    ]

    if (columns) {
      for (var ic = 0; ic < columns.length; ic++) {
        _formulaParamsByName.push({display:"{" + columns[ic].name+"}",id: "var_" + columns[ic]._id})
      }
    }
    return _formulaParamsByName
  }, [props.columns]);

  const handleClose = () => {
    props.setOpen(null);
    //props.setEditingSets(null);
  };

  const handleSave = () => {
    if(columnSettings.type ==='link' && !columnSettings.linkId){
      setLinkIdError("Укажите тип ссылки")     
      return   
    }

    if(columnSettings.type ==='link' && !columnSettings.linkViewField){
      setLinkIdError("Укажите представление ссылки")     
      return   
    }   
    
    
    columnSettings.formula = formulaText
    

    if(isNew){
      var _id = ObjectID()
      columnSettings._id = _id.toHexString()
      props.onColumnInsert(props.tableId,  columnSettings.idx, columnSettings, props.viewId);
      if(props.onNewColumnCallback){
        props.onNewColumnCallback(columnSettings)
      }
    } else {
      props.onChangeColumn(props.tableId, columnSettings.idx, columnSettings);
    }    
    handleClose();
  };

  const  onChangeHandler = (event) =>{
      //console.log('onChangeHandler event.target.name',event.target.name, event.target.value, columnSettings);
      setColumnSettings({...columnSettings, [event.target.name]:event.target.value})
  }

  const  onChangeOnIsFormula = (event) =>{
    const v = event.target.checked;
    setColumnSettings({...columnSettings, is_formula:v})
}  

const onChangeFormula = (value) => {     
  setFormulaText(value)
}
  

  const onChangeNewOptionHandler = (event)=>{
    setNewOption(event.target.value)
  }

  const handleAddOption = useCallback(event =>{
    const newOpt = newOption;
    const newCs = {...columnSettings};
    const coloridx = newCs.options.length
    newCs.options.push({label:newOpt, value:newOpt, color: colors[coloridx]})
    setColumnSettings(newCs)    

    setNewOption("")
  }, [columnSettings, newOption])    
  

  const onChangeEditable = (event) => {
    const v = event.target.checked;
    setColumnSettings({...columnSettings, [event.target.name]:v})      
}     

  const handleAddOptionChoice = (chip) => {
    const newOpt  = chip;
    setColumnSettings((cs)=>{
      const newCs = {...cs};
      newCs.options.push({label:newOpt, value:newOpt })
      return newCs;
    })
    //props.handleAddAnswerChoice(props.idx, chip);
}  

const handleDeleteOptionChoice = (chip, index) => {
  const idx  = index;
  setColumnSettings((cs)=>{    
    const newCs = {...cs};
    newCs.options = [
      ...newCs.options.slice(0, idx),
      ...newCs.options.slice(idx + 1)
    ];
    //console.log('handleDeleteOptionChoice ', newCs);
    return newCs;
  })      
}     

const onChangeColor = (idx, e) =>{
  console.log('onChangeColor ', idx, e)
  const index = idx;
  const newColor = e.hex
  setColumnSettings((cs)=>{          
    const newCs = {...cs};
    newCs.options = [...newCs.options]
    newCs.options[index].color = newColor
    return newCs;
  })       
}

const onDragEnd = (result) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  const si =result.source.index;
  const di =result.destination.index;
  
  //console.log('onDragEnd ', result.source, result.destination)
  setColumnSettings((cs)=>{          
    const newCs = {...cs};
    //newCs.options = [...newCs.options]      
    newCs.options.splice(
      di,
      0,
      newCs.options.splice(si, 1)[0]
    );         
    return newCs;
  })       
}  

const getFormulaText = (txt)=>{
  console.log('getFormulaText txt', txt )
  var _columnsNameById ={}
  if(props.columns){
    props.columns.forEach(cl => {
      _columnsNameById[cl._id] = cl.name
    });
  }
  let displayText = _clone(txt)
  const tags = displayText.match(/var_\w+/gi) || []
  console.log('getFormulaText tags in', tags )
  tags.map(myTag => {
    const tagData = myTag.substring(4)        
    const tagDisplayValue = "{"+_columnsNameById[tagData]+"}"
    displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
  })
  return displayText
}

useEffect(()=>{        
  if(props.columns && props.tables) {
    var __columnsNameById = {}
    var __linkIdColumns = []
    var __linkIdViews = []
    var __linkIdTablesColumns = {}
    for(var ic=0; ic<props.columns.length; ic++){
      __columnsNameById[props.columns[ic]._id] = props.columns[ic].name
      if(props.columns[ic].type==="link" && props.columns[ic].linkId){
        __linkIdColumns.push(props.columns[ic])
        if(props.tables[props.columns[ic].linkId]){
           let linkColums = props.tables[props.columns[ic].linkId].columns//.find(cl=> cl.type!=="link")
          __linkIdTablesColumns[props.columns[ic]._id] = linkColums?linkColums:[]          
          if(props.tables[props.columns[ic].linkId].views){
            var __views = props.tables[props.columns[ic].linkId].views
            for(var iv in __views){
              __linkIdViews.push(__views[iv])
            }
          }
        }
      }
      
    }       
    setLinkIdTablesColumns(__linkIdTablesColumns)
    setLinkIdColumns(__linkIdColumns)
    setLinkIdViews(__linkIdViews)
    setColumnsNameById(__columnsNameById)
    //console.log('__columnsNameById ', __columnsNameById)
    //setLinkIdColumns(props.columns.find(cl=> ))
    //props.tables && props.tables[lookupColumn.linkId]
  } else {
    setLinkIdColumns([])
    setLinkIdViews([])
  }
}, [props.columns, props.tables])  

  useEffect(()=>{     
    console.log('ColumnSettings useEffect ', props.editingSets) 
    if(props.editingSets && !props.editingSets.isNew){   
      setColumnSettings(props.editingSets)
      setIsNew(false);
    } else {
      setIsNew(true);
      setColumnSettings({idx:(props.editingSets && props.editingSets.idx)?props.editingSets.idx:0, name:'Новая колонка', type:'text', options:[], editable:true, desc: '', check_true:"Да", check_false:"Нет" })
    }
  }, [props.editingSets])

console.log("props.protalEl ", props.protalEl)

  return (
      <React.Fragment >     
      <Popover
        id="column-settings-popover"
        open={Boolean(props.open)}
        //anchorEl={props.open}
        anchorPosition={props.open}
        anchorReference="anchorPosition"        
        onClose={handleClose}
        //anchorOrigin={{
        //  vertical: 'bottom',
        //  horizontal: 'center',
        //}}
        //transformOrigin={{
        //  vertical: 'top',
        //  horizontal: 'center',
        //}}
      //        style={{minWidth:"250px"}}
      >
        <Paper elevation={3} >
        <div className={classes.settingsPaper} ref={suggestionsPortalHost}>
                      <TextField
                          id="column_name"
                          name="name"
                          autoComplete="off"
                          //label="Название колонки"
                          //type="number"
                          //inputProps={{ min: "1", max: "30", step: "1" }}
                          value={columnSettings.name}
                          //defaultValue={1}
                          onChange={onChangeHandler}
                          //placeholder=""
                          //helperText=""                            
                          //margin="normal"
                          size="small"
                          variant="outlined"
                          fullWidth
                          autoFocus
                          onFocus={event => {
                            event.target.select();
                          }}          
                          onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {                              
                              handleSave()
                            }
                          }}                                           
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onPaste={event => event.stopPropagation()}
                          onCopy={event => event.stopPropagation()}                           
                      />
                 <TextField
                            id={"column_desc"}
                    value={columnSettings.desc}

                    name="desc"
                    onChange={onChangeHandler}                    
                    placeholder="Введите описание..."
                    helperText=""
                    multiline
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InfoOutlinedIcon fontSize="small"/>
                        </InputAdornment>
                      ),                      
                      style:{
                        fontSize:"12px"
                      }                      
                    }}
                    maxRows={5}
                    fullWidth
                    onPaste={event => event.stopPropagation()}
                    onCopy={event => event.stopPropagation()} 
                    InputLabelProps={{
                      shrink: true,
                    }}                                       
                    //margin="normal"
                />                                         
                      <div>                                                       
                      <TextField
                          id="column_type"
                          name="type"
                          //label="Тип"
                          value={columnSettings.type}
                          onChange={onChangeHandler}
                          placeholder=""
                          helperText=""
                          fullWidth
                          select
                          //disabled={isNew?false:true}
                          SelectProps={{
                            MenuProps: {
                              MenuListProps: {
                                dense: true
                              }
                            },
                          }}                          
                          //margin="normal"
                          className={classes.selectEmpty}
                          InputLabelProps={{
                              shrink: true,
                          }}>

              <MenuItem key='text' value='text' ><ListItemIcon><TextFieldsIcon fontSize="small"/></ListItemIcon>Текст</MenuItem>
              <MenuItem key='multiline' value='multiline'><ListItemIcon><SubjectIcon fontSize="small"/></ListItemIcon>Многострочный текст</MenuItem>
              <MenuItem key='title' value='title' ><ListItemIcon><TextFieldsIcon fontSize="small"/></ListItemIcon>Заголовок</MenuItem>
              <MenuItem key='number' value='number'><ListItemIcon><Looks3Icon fontSize="small"/></ListItemIcon>Число</MenuItem>
              <MenuItem key='date' value='date'><ListItemIcon><EventIcon fontSize="small"/></ListItemIcon>Дата</MenuItem>
              <MenuItem key='select' value='select'><ListItemIcon><FilterListIcon fontSize="small"/></ListItemIcon>Выбор из списка</MenuItem>                
              <MenuItem key='member' value='member'><ListItemIcon><PersonIcon fontSize="small"/></ListItemIcon>Пользователь</MenuItem>                              
              <MenuItem key='link' value='link'><ListItemIcon><LinkIcon fontSize="small"/></ListItemIcon>Ссылка</MenuItem>              
              <MenuItem key='geolocation' value='geolocation'><ListItemIcon><LocationOnIcon fontSize="small"/></ListItemIcon>Геолокация</MenuItem>              
              <MenuItem key='attachments' value='attachments'><ListItemIcon><AttachFileIcon fontSize="small"/></ListItemIcon>Файлы</MenuItem>              
              <MenuItem key='check' value='check'><ListItemIcon><CheckBoxIcon fontSize="small"/></ListItemIcon>Флаг</MenuItem>
              <MenuItem key='lookup' value='lookup'><ListItemIcon><LookupIcon fontSize="small"/></ListItemIcon>Lookup</MenuItem>

                      </TextField>
                      </div>

                      {columnSettings.type ==='date' && <div>
                      <TextField
                          id="column_date_format"
                          name="date_format"
                          label="Формат даты"
                          //type="number"
                          //inputProps={{ min: "1", max: "30", step: "1" }}
                          value={columnSettings.date_format}
                          defaultValue="DD.MM.YYYY HH:mm"
                          onChange={onChangeHandler}
                          //placeholder=""
                          helperText="Н-р: DD.MM.YYYY HH:mm"  
                                                  
                          //margin="normal"
                          fullWidth
                          
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onPaste={event => event.stopPropagation()}
                          onCopy={event => event.stopPropagation()}                            
                      />                  
                      </div>}

                      {columnSettings.type ==='select' && <Paper             
          className={classes.question_list} elevation={1} borderColor="primary.main" >

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableOptions">
                {(provided, snapshot) => (
                  <RootRef rootRef={provided.innerRef} >
                    
                    <List dense={true} 
                    style={{
                      maxHeight: "170px",
                      overflowX: "hidden", 
                      overflowY:"auto"
                    }}>
                      {columnSettings.options.map((o, index) => (
                        <Draggable key={o.value} draggableId={o.value} index={index}>
                          {(provided, snapshot) => (

                            <ListItem
                              ContainerComponent="li"
                              ContainerProps={{ ref: provided.innerRef }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItemIcon>
                              <ColorPicker 
                              value={o.color} 
                              //disableAlpha value={o.color} 
                              //hideTextfield 
                              presetColors = {[
                                {title:"Light gray",color:'rgba(227, 226, 224, 0.5)'},
                                {title:"blue",color:'rgb(227, 226, 224)'},
                                {title:"green",color:'rgb(238, 224, 218)'},
                                {title:"yellow",color:'rgb(250, 222, 201)'},
                                {title:"cyan",color:'rgb(253, 236, 200)'},
                                {title:"lime",color:'rgb(219, 237, 219)'},
                                {title:"gray",color:'rgb(211, 229, 239)'},
                                {title:"orange",color:'rgb(232, 222, 238)'},
                                {title:"purple",color:'rgb(245, 224, 233)'},
                                {title:"black",color:'rgb(255, 226, 221)'},
                                //pink: 'pink',
                                //darkblue: 'darkblue',
                              ]}
                              onChange={(e) =>onChangeColor(index, e)}
                              
                              />
                              </ListItemIcon>
                              <ListItemText id={"switch-column-option-label-" + index} primary={
                                <Chip
                                size="small"
                                label={<span         
                                  //style={
                                  //{                  
                                  //  color:"white", 
                                    //mixBlendMode: "darken",
                                  //}
                                //}
                                >{o.label}</span>}                  
                                style={
                                  { 
                                    backgroundColor: o.color, 
              
                
                                    fontSize:"12px", 
                                    height: "20px"  
                                  }
                                }
                              />}                  
                            />
                              <ListItemSecondaryAction>
                                <IconButton                                    
                                  size="small"
                                  onClick={(e) => handleDeleteOptionChoice(o, index)}
                                >
                                  <HighlightOffIcon fontSize="small" />
                                  </IconButton>

                              </ListItemSecondaryAction>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                    
                  </RootRef>
                )}
              </Droppable>
            </DragDropContext>     
            <TextField
                          id="column_new_option"
                          name="new_option"
                          //label="Введите новое значение"
                          //type="number"
                          //inputProps={{ min: "1", max: "30", step: "1" }}
                          value={newOption}
                          defaultValue=""
                          onChange={onChangeNewOptionHandler}
                          onKeyPress={(ev) => { //глючит (добавляет 2 элемента за раз!) надо разобравться
                            if (ev.key === 'Enter' && newOption) {
                              handleAddOption(null)
                              ev.preventDefault();
                            }
                          }}                             
                          placeholder="Введите новое значение"
                          //helperText=""     
                          fullWidth                       
                          //margin="normal"
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                {newOption && <IconButton
                                  aria-label=""
                                  onClick={handleAddOption}
                                  //onMouseDown={handleAddOption}
                                >
                                  <ArrowForwardIcon />
                                </IconButton>}
                              </InputAdornment>
                            
                          }}                            
                          
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onPaste={event => event.stopPropagation()}
                          onCopy={event => event.stopPropagation()}                           
                      />                                                         
                      </Paper>}

                      {columnSettings.type ==='check' && <div>
                      <TextField
                          id="column_check_true"
                          name="check_true"
                          label="Значение для установленного флага"
                          //type="number"
                          //inputProps={{ min: "1", max: "30", step: "1" }}
                          value={columnSettings.check_true}
                          defaultValue="Да"
                          onChange={onChangeHandler}
                          //placeholder=""
                          //helperText=""  
                                                  
                          //margin="normal"
                          fullWidth
                          
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                      <TextField
                          id="column_check_false"
                          name="check_false"
                          label="Значение для снятого флага"
                          //type="number"
                          //inputProps={{ min: "1", max: "30", step: "1" }}
                          value={columnSettings.check_false}
                          defaultValue="Нет"
                          onChange={onChangeHandler}
                          //placeholder=""
                          //helperText=""                            
                          //margin="normal"
                          fullWidth
                          
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />                     
                      </div>}
                      {columnSettings.type ==='link' && <div>
                      <TextField
                          id="column_linkId"
                          name="linkId"
                          //label="Тип"
                          value={columnSettings.linkId}
                          onChange={onChangeHandler}
                          placeholder=""                          
                          fullWidth
                          select
                          //disabled={isNew?false:true}
                          error={Boolean(!columnSettings.linkId)}
                          helperText={columnSettings.linkId?"":"Укажите тип ссылки"}  
                          //margin="normal"
                          className={classes.selectEmpty}
                          InputLabelProps={{
                              shrink: true,
                          }}>
                            {props.tablesList && props.tablesList.map(tb=>(
              <MenuItem key={tb._id} value={tb._id} >{tb.title}</MenuItem>
                                ))}  
                      </TextField>  
                      {columnSettings.linkId && <TextField
                          id="column_linkViewField"
                          name="linkViewField"
                          //label="Тип"
                          value={columnSettings.linkViewField}
                          onChange={onChangeHandler}
                          placeholder=""                          
                          fullWidth
                          select
                          //disabled={isNew?false:true}
                          error={Boolean(!columnSettings.linkViewField)}
                          helperText={columnSettings.linkViewField?"":"Поле для представления ссылки"}  
                          //margin="normal"
                          className={classes.selectEmpty}
                          InputLabelProps={{
                              shrink: true,
                          }}>
                            {props.tables[columnSettings.linkId].columns && props.tables[columnSettings.linkId].columns.map(cl=>(
              <MenuItem key={cl._id} value={cl._id} >{cl.name}</MenuItem>
                                ))}  
                      </TextField>     
                      }   
                      {columnSettings.linkId && <TextField
                          id="column_linkSelectView"
                          name="linkSelectView"
                          //label="Тип"
                          value={columnSettings.linkSelectView}
                          onChange={onChangeHandler}
                          placeholder=""                          
                          fullWidth
                          select
                          //disabled={isNew?false:true}
                          error={Boolean(!columnSettings.linkSelectView)}
                          helperText={columnSettings.linkSelectView?"":"Вид для фильтрации"}  
                          //margin="normal"
                          className={classes.selectEmpty}
                          InputLabelProps={{
                              shrink: true,
                          }}>
                            <MenuItem key={"linkIdView_null"} value={null} >-</MenuItem>
                            {linkIdViews.map(vi=>(
              <MenuItem key={"linkIdViews_"+vi._id} value={vi._id} >{vi.title}</MenuItem>
                                ))}  
                      </TextField>     
                      }                                                                
                      </div>}
                      {columnSettings.type ==='lookup' && <div>
                      <TextField
                          id="column_lookupId"
                          name="lookupId"
                          //label="Тип"
                          value={columnSettings.lookupId}
                          onChange={onChangeHandler}
                          placeholder=""
                          fullWidth
                          select
                          //disabled={isNew?false:true}
                          error={Boolean(!columnSettings.lookupId)}
                          helperText={columnSettings.lookupId?"":"Укажите ссылочную колонку"}  
                          //margin="normal"
                          className={classes.selectEmpty}
                          InputLabelProps={{
                              shrink: true,
                          }}>
                            {linkIdColumns && linkIdColumns.map(cl=>(
              <MenuItem key={cl._id} value={cl._id} >{cl.name}</MenuItem>
                                ))}  
                      </TextField>  
                      {columnSettings.lookupId && <TextField
                          id="column_lookupField"
                          name="lookupField"
                          //label="Тип"
                          value={columnSettings.lookupField}
                          onChange={onChangeHandler}
                          placeholder=""
                          fullWidth
                          select
                          //disabled={isNew?false:true}
                          error={Boolean(!columnSettings.lookupField)}
                          helperText={columnSettings.lookupField?"":"Укажите поле для вывода"}  
                          //margin="normal"
                          className={classes.selectEmpty}
                          InputLabelProps={{
                              shrink: true,
                          }}>
                            {linkIdTablesColumns && linkIdTablesColumns[columnSettings.lookupId] && linkIdTablesColumns[columnSettings.lookupId].map(cl=>(
              <MenuItem key={cl._id} value={cl._id} >{cl.name}</MenuItem>
                                ))}  
                      </TextField>     }                                   
                      </div>}

                {(columnSettings.type ==='text'
                || columnSettings.type ==='multiline'
                || columnSettings.type ==='number'
                || columnSettings.type ==='date'
                || columnSettings.type ==='select'
                || columnSettings.type ==='check')
                &&   columnsNameById &&  <>        
                <FormControlLabel 
                style={{
                  marginLeft:0
                }}               
                control={
                  <SmallSwitch
                  checked={columnSettings.is_formula}
                  onChange={onChangeOnIsFormula}
                  id="column_settings_is_formula"
                  color="primary"
                  //size="small"
                />                          
                }
                label=" Формула"
            /> 
               {columnSettings.is_formula 
               && <>
               <FormulaTextEditor 
               id={"column_formula"}
               value={columnSettings.formula?columnSettings.formula:""}
               variabliesForFormula={variabliesForFormula}
               onBlur={onChangeFormula}
               placeholder="Введите формулу расчета"
               suggestionsPortalHost={suggestionsPortalHost.current?suggestionsPortalHost.current:null}
             />
             <div style={{paddingLeft:8, paddingRight:8}}>
             <Typography variant='caption' >Формат функций как в Excel</Typography> <br />
             <Typography variant='caption' >Используйте значения полей &#123;Поле&#125;</Typography>
           </div>
           </>
               }
                 
            </>
                }                                        
                                                                       

        <DialogActions>
          <Button size="small"  onClick={handleClose} color="primary">
            Отмена
          </Button>        
          <Button size="small" onClick={handleSave} variant="contained"  color="primary">
          {isNew? "Создать":"ОК"}
          </Button>
        </DialogActions>
        </div>
        </Paper>
      </Popover> 
      </React.Fragment>
  );
}  