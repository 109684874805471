import React, {useState, useContext, useCallback, useEffect, useRef  } from 'react';
import {useHistory, useParams, Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {NavContext} from '../context/NavContext'
import {useHttp} from '../hooks/http.hook';
import moment from 'moment-timezone';
//import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';  
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import ChipInput from 'material-ui-chip-input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Query, Builder, BasicConfig, Utils as QbUtils} from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import './QueryBuilder.css'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ChatIcon from '@material-ui/icons/Chat';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import Looks3Icon from '@material-ui/icons/Looks3';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LinkIcon from '@material-ui/icons/Link';
import 'moment/locale/ru'
import { IconButton } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import RootRef from "@material-ui/core/RootRef";
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuIcon from '@material-ui/icons/Menu';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { MentionsInput, Mention } from './Mentions'
import SubjectIcon from '@material-ui/icons/Subject';
import './DialogEditor.css'
import ColumnSettingsDialog from './WorkItemsTable/ColumnSettingsDialog'
import _clone from 'lodash/clone'
import _escapeRegExp from 'lodash/escapeRegExp'
import AceEditor from 'react-ace'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListSubheader from '@material-ui/core/ListSubheader';
import Chip from '@material-ui/core/Chip';
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
const InitialConfig = MaterialConfig;

moment.locale('ru');


//const MenuProps = {
//  PaperProps: {
//    style: {
//      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//      width: 250,
//    },
//  },
//};

//function getStyles(name, personName, theme) {
//    return {
//      fontWeight:
//        personName.indexOf(name) === -1
//          ? theme.typography.fontWeightRegular
//          : theme.typography.fontWeightMedium,
//    };
//  }

//const Transition = React.forwardRef(function Transition(props, ref) {
//    return <Slide direction="up" ref={ref} {...props} />;
//  });
function LookupIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
    </SvgIcon>
  );
}  


function ChatMemberIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4l4 4l4-4h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2m-8 2.3c1.5 0 2.7 1.2 2.7 2.7c0 1.5-1.2 2.7-2.7 2.7c-1.5 0-2.7-1.2-2.7-2.7c0-1.5 1.2-2.7 2.7-2.7M18 15H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" />
    </SvgIcon>
  );
}

function SendLocationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12 2A7 7 0 0 0 5 9C5 14.25 12 22 12 22S19 14.25 19 9A7 7 0 0 0 12 2M16.5 9H14V14H10V9H7.5L12 4.5Z" />
    </SvgIcon>
  );
}

function SendFilesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2M18 20H6V4H13V9H18V20M16 11V18.1L13.9 16L11.1 18.8L8.3 16L11.1 13.2L8.9 11H16Z" />
    </SvgIcon>
  );
}


  
  const useStyles = makeStyles((theme) => ({
    
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    question_paper_selected: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      borderLeft: "5px solid "+theme.palette.primary.main
    },    
    question_paper: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },    
    question_list: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
      },
      question_menu: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
      },      
      subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    grow: {
      flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    fabButton:  {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    fileinput: {
      display: 'none',
    },    
    selectEmpty: {
        marginTop: theme.spacing(2),
      }, 
    scheduler:{
        display: 'flex',
    },
    smallControlLabel: {
      fontSize: "12px"
    }  
  }));

  function normilizeQuestionText (qtext){
    let displayText = _clone(qtext)
    const tags = qtext.match(/\{\{[^\}]+\}\}/gi) || []
    tags.map(myTag => {
      const tagData = myTag.slice(2, -2)
      const tagDataArray = tagData.split('||')
      const tagDisplayValue = "{"+tagDataArray[1]+"}"
      displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
    })
    return displayText
  }
  

  function QuestionIntentViewButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [anchorElColEdit, setAnchorElColEdit] = React.useState(null);
    const [editingSets, setEditingSets] = React.useState(null);
    const [showBt, setShowBt] = React.useState(false);

    const handleClick = () => {
      if(props.question.intent){
        const  col = props.editedColumns.find(e => { return e._id === props.question.intent });
        //setAnchorElColEdit(anchorRef.current)
        var rect = anchorRef.current.getBoundingClientRect();
        setAnchorElColEdit({ top: rect.top, left: rect.left })                    
  
        setEditingSets(col) 
      }
    };
  
    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };

    var intentIcon = undefined;
    var intentText = ""; 
    if(props.question.intent) {
      var currentCol = props.editedColumns.find(col=> col._id===props.question.intent)
      if(currentCol){
      intentIcon = currentCol.type==='text'? <TextFieldsIcon fontSize="small" color="disabled"/>:
      currentCol.type==='number'? <Looks3Icon fontSize="small"  color="disabled"/>:
      currentCol.type==='date'?<EventIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='select' ? <FilterListIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='multiline' ? <SubjectIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='member' ? <PersonIcon fontSize="small"  color="disabled" />:
      currentCol.type==='chatmember' ? <ChatMemberIcon fontSize="small"  color="disabled" />:
      currentCol.type==='link' ? <LinkIcon fontSize="small"  color="disabled" />:
      currentCol.type==='geolocation' ? <LocationOnIcon fontSize="small"  color="disabled" />:
      currentCol.type==='attachments' ? <AttachFileIcon fontSize="small"  color="disabled" />:
      currentCol.type==='check' ? <CheckBoxIcon fontSize="small"  color="disabled"/>: undefined      
      intentText =  currentCol.name 
    } else {
        //нет такой колонки
        if(props.question.intent === "__exly_dialog_menu"){
          intentText = 'Меню'
          intentIcon =<MenuIcon fontSize="small" color="disabled"/>
        } else if(props.question.intent === "__exly_child_dialog"){
          intentText = 'Дочерний диалог'
            intentIcon =<ChatIcon fontSize="small" color="disabled"/>
                          
        } else {
          intentText = 'Колонка удалена!'
        }
        
      }
    } else {
      
      intentText = 'Не требует ответа'
    }

    //console.log('QuestionIntentButton ', props)
  
    return (
      <>
          <div
      //onContextMenu={handleOpenColumnContextMenu}
      //ref={useCombinedRefs(drag, drop, divRef)} 
      ref={anchorRef}         
      onClick={handleClick}   
      onMouseEnter={() => setShowBt(true)}
      onMouseLeave={() => setShowBt(false)}    
      aria-haspopup="true"
      aria-controls={"question-intent-"+props.idx}
      style={{
        //opacity: isDragging ? 0.5 : 1,
        //backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'pointer',   
        display: 'flex',  
        fontSize:13,
        fontWeight:400 //был толстый 700      
      }}
    >
      
      <ListItem
        button
        ContainerComponent="div" 
        disableGutters
        style={{
          padding:0,
          //width: props.column.width-8
        }}
        id={'question-intent-column-'+currentCol._id}   
      >
      <ListItemIcon style={{minWidth:"25px"}}>
      {intentIcon}
      </ListItemIcon>
      <ListItemText 
      id={"question-intent-column-label-"+currentCol._id} 
      style={{marginTop:"2px", marginBottom:0}}
      primary={props.column.name} 
      //color={props.question.hasCondition?"secondary":"default"}
      disableTypography
      />
      
        
      {showBt && <ListItemSecondaryAction
       style={{right:"3px"}}
       >
            <IconButton size="small" onClick={handleClick} style={{float: "right"}}><ArrowDropDownIcon       style={{
              cursor: 'pointer'
            }} color="disabled" fontSize="small"/>
            </IconButton>
            </ListItemSecondaryAction>}
    </ListItem>      

    </div>          
          <ColumnSettingsDialog  
              tableId={props.tableId} 
              open={anchorElColEdit} 
              setOpen={setAnchorElColEdit} 
              editingSets={editingSets} 
              setEditingSets={setEditingSets} 
              onColumnInsert={props.onColumnInsert} 
              onChangeColumn={props.onChangeColumn} 
              tables={props.tables}
              tablesList={props.tablesList} 
              viewId={props.viewId}
              columns={props.columns}
              />           
          </>
    );
  }

  function QuestionIntentButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [anchorElColEdit, setAnchorElColEdit] = React.useState(null);
    const [editingSets, setEditingSets] = React.useState(null);
    const [showBt, setShowBt] = React.useState(false);
    const classes = useStyles();

    const handleClick = () => {
      if(props.question.intent && props.question.intent!=="__exly_dialog_menu"){
        const  col = props.editedColumns.find(e => { return e._id === props.question.intent });        
        //setAnchorElColEdit(anchorRef.current)
        var rect = anchorRef.current.getBoundingClientRect();
        setAnchorElColEdit({ top: rect.top, left: rect.left })         
        setEditingSets(col)        
      }
    };

    const onAddQuestionIntent = () =>{
      //setAnchorElColEdit(anchorRef.current)
      var rect = anchorRef.current.getBoundingClientRect();
      setAnchorElColEdit({ top: rect.top, left: rect.left })       
      setEditingSets({idx:props.columns.length, isNew:true})         
    }

    /**
     * Добавили новую колонку в диалоге
     * @param {*} newCol 
     */
    const onNewColumnCallback = (newCol) =>{
      props.onChangeQuestionIntent(props.idx, newCol._id); 
      setOpen(false);      
    }
         

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };  

    const toggleIsFormula = () => {
      props.onChangeQuestionToggleIsFormula(props.idx, !props.question.is_excel_formula);
    }    

    const toggleIsJSFormula = () => {
      props.onChangeQuestionToggleIsJSFormula(props.idx, !props.question.is_formula);
    }       

    const onDeleteQuestion = () => {
      //const v = event.target.value;
      props.onDeleteQuestion(props.idx);
  }    

    const handleToggle = (e) => {
      setOpen((prevOpen) => !prevOpen);
      e.stopPropagation();
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };

    var intentIcon = undefined;
    var intentText = ""; 
    if(props.question.intent) {
      var currentCol = props.editedColumns.find(col=> col._id===props.question.intent)
      if(currentCol){
      intentIcon = currentCol.type==='text'? <TextFieldsIcon fontSize="small" color="disabled"/>:
      currentCol.type==='number'? <Looks3Icon fontSize="small"  color="disabled"/>:
      currentCol.type==='date'?<EventIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='select' ? <FilterListIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='multiline' ? <SubjectIcon fontSize="small"  color="disabled"/>:
      currentCol.type==='member' ? <PersonIcon fontSize="small"  color="disabled" />:
      currentCol.type==='chatmember' ? <ChatMemberIcon fontSize="small"  color="disabled" />:
      currentCol.type==='link' ? <LinkIcon fontSize="small"  color="disabled" />:
      currentCol.type==='geolocation' ? <LocationOnIcon fontSize="small"  color="disabled" />:
      currentCol.type==='attachments' ? <AttachFileIcon fontSize="small"  color="disabled" />:
      currentCol.type==='check' ? <CheckBoxIcon fontSize="small"  color="disabled"/>: undefined      
      intentText =  currentCol.name 
    } else {
        //нет такой колонки
        if(props.question.intent === "__exly_dialog_menu"){
          intentText = 'Меню'
          intentIcon =<MenuIcon fontSize="small" color="disabled"/>
        } else if(props.question.intent === "__exly_child_dialog"){
          intentText = 'Дочерний диалог'
            intentIcon =<ChatIcon fontSize="small" color="disabled"/>
                
        } else {
          intentText = 'Колонка удалена!'
        }
        
      }
    } else {
      
      intentText = 'Не требует ответа'
    }

    //console.log('QuestionIntentButton ', props)
  
    return (
      <>
<Grid
  container
  direction="row"
  justifyContent="space-between"
  alignItems="baseline"
>

          <Grid
      //onContextMenu={handleOpenColumnContextMenu}
      //ref={useCombinedRefs(drag, drop, divRef)} 
      ref={anchorRef}         
      onClick={handleClick}   
      onMouseEnter={() => setShowBt(true)}
      onMouseLeave={() => setShowBt(false)}    
      aria-haspopup="true"
      aria-controls={"question-intent-"+props.idx}
      style={{
        //opacity: isDragging ? 0.5 : 1,
        //backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'pointer',   
        display: 'flex',  
        fontSize:13,
        fontWeight:400 //был толстый 700      
      }}
    >
      
      <ListItem
        button
        ContainerComponent="div" 
        disableGutters
        style={{
          //padding:0,
          //width: props.column.width-8
        }}
        id={'question-intent-column-'+props.idx}   
      >
      <ListItemIcon style={{minWidth:"25px"}}>      
      {intentIcon}
      </ListItemIcon>
      <ListItemText 
      id={"question-intent-column-label-"+props.idx} 
      style={{marginTop:"2px", marginBottom:0}}
      primary={intentText} 
      //color={props.question.hasCondition?"secondary":"default"}
      disableTypography
      />
      </ListItem>
      </Grid>
      <Grid>
    {props.question.intent 
            && props.question.intent !== "__exly_dialog_menu"  
            && props.question.intent !== "__exly_child_dialog"             
            && props.editingQuestionIdx === props.idx && 
            <>
              <FormControlLabel
                classes={{label: classes.smallControlLabel}}
                control={<Switch  size="small" checked={props.question.is_excel_formula} onChange={toggleIsFormula} />}
                label="Формула"
                labelPlacement= 'start'
              />
              {props.question.is_formula && <FormControlLabel
                classes={{label: classes.smallControlLabel}}
                control={<Checkbox size="small" checked={props.question.is_formula} onChange={toggleIsJSFormula} />}
                label="JS"
                labelPlacement= 'start'
              />}
              </>
            }  
            <span
            style={{
              marginLeft:"20px"
            }}
            >
            <IconButton 
            size="small" 
            aria-label="delete"             
            onClick={onDeleteQuestion}>
                <HighlightOffIcon fontSize="small" />
              </IconButton>  
              </span>               
    </Grid>
   

    </Grid>              
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 99 }} disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" dense>
                    <MenuItem
                          key="nothing"
                          value=""
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {props.onChangeQuestionIntent(props.idx, ""); setOpen(false);}}
                          >
                          <ListItemIcon>
                          
                          </ListItemIcon>                              
                          <em>НЕ ТРЕБУЕТ ОТВЕТА</em>
                        </MenuItem>                            
                        <MenuItem
                          key="__exly_dialog_menu"
                          value="__exly_dialog_menu"
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {props.onChangeQuestionIntent(props.idx, "__exly_dialog_menu"); setOpen(false);}}
                          >
                          <ListItemIcon>
                          <MenuIcon fontSize="small"/>
                          </ListItemIcon>                              
                          МЕНЮ
                        </MenuItem>                                                                                                                
                      {props.editedColumns.map((option, index) => (
                        <MenuItem
                          key={option._id}
                          value={option._id}
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {props.onChangeQuestionIntent(props.idx, option._id); setOpen(false);}}
                          >
                          <ListItemIcon size="small">
                          {option.type==='text' && <TextFieldsIcon fontSize="small" color="disabled"/>}
                          {option.type==='title' && <TextFieldsIcon fontSize="small" color="disabled"/>}
                        {option.type==='number' && <Looks3Icon fontSize="small"  color="disabled"/>}
                        {option.type==='date' && <EventIcon fontSize="small"  color="disabled"/>}
                        {option.type==='select' && <FilterListIcon fontSize="small"  color="disabled"/>}
                        {option.type==='multiline' && <SubjectIcon fontSize="small"  color="disabled"/>}
                        {option.type==='member' && <PersonIcon fontSize="small"  color="disabled" />}
                        {option.type==='chatmember' && <ChatMemberIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='link' && <LinkIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='geolocation' && <LocationOnIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='attachments' && <AttachFileIcon fontSize="small"  color="disabled" />}                        
                        {option.type==='check' && <CheckBoxIcon fontSize="small"  color="disabled"/>}
                          </ListItemIcon>                              
                          {option.name}
                        </MenuItem>
                      ))}
                        <MenuItem
                          key="__exly_dialog_menu_add_intent"
                          value="__exly_dialog_menu_add_intent"
                          //disabled={index === 2}
                          //selected={index === selectedIndex}
                          onClick={(event) => {onAddQuestionIntent()}}
                          >
                          <ListItemIcon>
                          <AddCircleOutlineIcon fontSize="small"/>
                          </ListItemIcon>                              
                          Добавить колонку
                        </MenuItem>                       
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <ColumnSettingsDialog  
              tableId={props.tableId} 
              open={anchorElColEdit} 
              setOpen={setAnchorElColEdit} 
              editingSets={editingSets} 
              setEditingSets={setEditingSets} 
              onColumnInsert={props.onColumnInsert} 
              onChangeColumn={props.onChangeColumn} 
              tables={props.tables}
              tablesList={props.tablesList} 
              viewId={props.viewId}
              columns={props.columns}
              onNewColumnCallback={onNewColumnCallback}
              />           
          </>
    );
  }  

function QuestionEditor(props){
    const classes = useStyles();
    
    const [questionText, setQuestionText]  = useState("");
    const [questionSelectedType, setQuestionSelectedType]  = useState(null);
    const [answerType, setAnswerType]  = useState(null);
    const [condition, setCondition] = useState(null);
    const [childDialogName, setChildDialogName] = useState("");
    const [formulaText, setFormulaText] = useState("") 

    const inputRef = useRef(null);

    const onChangeQuestionText = (event, newValue, newPlainTextValue, mention) => {
        //console.log('onChangeQuestionText ', event, newValue, newPlainTextValue, mention)
        const v = newValue //event.target.value;        
        setQuestionText(v);
    }


    const onChangeFormulaText = (event) => {
      //console.log('onChangeQuestionText ', event, newValue, newPlainTextValue, mention)
      const v = event.target.value;        
      setQuestionText(v);
  }    

  const onChangeJSFormulaText = (newValue) => {
    //console.log('onChangeQuestionText ', event, newValue, newPlainTextValue, mention)
    const v = newValue //event.target.value;        
    setQuestionText(v);
}      

  const onSaveQuestionText = (e, c)=>{
    
    //setQuestionText(cur => {
      if (questionText !== props.question.question_text) {
        console.log('onSaveQuestionText ', questionText, props.question.question_text)
        props.onChangeQuestionText(props.idx, questionText);
        
      }
    //})
  }



    const onChangeQuestionMenuItem = (idx, value) => {      
      props.onChangeQuestionMenuItem(props.idx, idx, value);
  } 

  
  const onChangeQuestionMenuItemType = (idx, value) => {      
    props.onChangeQuestionMenuItemType(props.idx, idx, value);
}    

const onChangeQuestionMenuItemParam = (idx, value) => {      
  props.onChangeQuestionMenuItemParam(props.idx, idx, value);
}    

const onAddQuestionMenuItem = () => {
  //const v = event.target.value;
  props.onAddQuestionMenuItem(props.idx, {
    cmd:"Команда",
    cmd_type: "next_step",
    cmd_param:null
  });
}



const onDeleteQuestionMenuItem = (item_index) => {
  //const v = event.target.value;
  props.onDeleteQuestionMenuItem(props.idx, item_index);
}
    
    const onChangeQuestionAnswerType = (event) => {
        const v = event.target.value;
        props.onChangeQuestionAnswerType(props.idx, v);
    }

    const onChangeQuestionListStyle = (event) => {
      const v = event.target.value;
      props.onChangeQuestionListStyle(props.idx, v);
  }  

  const onChangeQuestionDelay = (event) => {
    const v = event.target.value;
    props.onChangeQuestionDelay(props.idx, v);
}    

const onChangeQuestionPhoneButtonText = (event) => {
  const v = event.target.value;
  props.onChangeQuestionPhoneButtonText(props.idx, v);
}  

const onChangeQuestionChildDialog = (event) => {
  const v = event.target.value;
  props.onChangeQuestionChildDialog(props.idx, v);
}  


  
  const onChangeQuestionSendField = (event) => {
    const v = event.target.value;
    props.onChangeQuestionSendField(props.idx, v);
}    


  
    
    


    
    
    
    const onAddQuestion = () => {
        //const v = event.target.value;
        props.onAddQuestion(props.idx);
    }
    
    const onChangeQuestionIntent = (event) => {
        const v = event.target.value;
        props.onChangeQuestionIntent(props.idx, v);
    }

    const onChangeQuestionNextQuestionParam = (midex, v) => {
      var next_question = parseInt(v, 10);
      if (isNaN(next_question)) { next_question = 1; } 
      next_question = next_question 
      onChangeQuestionMenuItemParam(midex, next_question)
  }    
    

    const handleAddAnswerChoice = (chip) => {
        props.handleAddAnswerChoice(props.idx, chip);
    }  
    
    const handleDeleteAnswerChoice = (chip, index) => {
        props.handleDeleteAnswerChoice(props.idx, chip, index);
    }     

    const onChangeQuestionCondition = (event) => {
        const v = event.target.value;
        props.onChangeQuestionCondition(props.idx, v);
    }     

    const toggleHasCondition = () => {
      props.onChangeQuestionToggleHasCondition(props.idx, !props.question.hasCondition);
    }

    const toggleTyping = () => {
      props.onChangeQuestionToggleTyping(props.idx, !props.question.typing);
    }    

    const toggleHasPhoneButton = () => {
      props.onChangeQuestionToggleHasPhoneButton(props.idx, !props.question.has_phone_button);
    }      

         
    

    const onChangeEditedQuestion = (e) =>{
      if(props.editingQuestionIdx!==  props.idx) {
        props.setEditingQuestionIdx(props.idx)
        //console.log('inputRef ', inputRef)
      }
      
    }

       

  const getFormulaText = (txt) => {
    let displayText = _clone(txt)
    //console.log('getFormulaText txt', txt )
    if (props.question.is_excel_formula) {
      var _columnsNameById = {}
      if (props.columns) {
        props.columns.forEach(cl => {
          _columnsNameById[cl._id] = cl.name
        });
      }

      const tags = displayText.match(/var_\w+/gi) || []
      //console.log('getFormulaText tags in', tags )
      tags.map(myTag => {
        const tagData = myTag.substring(4)
        const tagDisplayValue = "{" + _columnsNameById[tagData] + "}"
        displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
      })
    }
    return displayText
  }


    const renderBuilder = (props) => (
      <div className="query-builder-container" >
        <div className="query-builder qb-lite">
            <Builder {...props} />
        </div>
      </div>
    )    

    const onChange = (immutableTree, config) => {
      // Tip: for better performance you can apply `throttle` - see `examples/demo`
      //setTree(immutableTree);
      //setConfig(config);
      setCondition(immutableTree)
      props.onChangeQuestionCondition(props.idx, QbUtils.getTree(immutableTree))
      //const jsonTree = QbUtils.getTree(immutableTree);
      //console.log(jsonTree);
      // `jsonTree` can be saved to backend, and later loaded to `queryValue`
    }  

    const onPasteParamInQuestionText = (param)=> {
      const newText = props.question.question_text+" ["+param+"] "
      props.onChangeQuestionText(props.idx, newText);
    }
    
    const onBlurQuestionText = (e) =>{
      if (questionText !== props.question.question_text) {
        //console.log('onSaveQuestionText ', questionText, props.question.question_text)
        let displayText = _clone(questionText)
        if(props.question.is_excel_formula){
          //преобразуем формулу
          var _columnsIdByName = {}
          props.columns.forEach(cl => {
            _columnsIdByName[cl.name] = cl._id
          });      
          
          const tags = displayText.match(/{([^}]+)}/gi) || []

          tags.map(myTag => {
            const tagData = myTag.slice(1, -1)        
            const tagDisplayValue = "var_"+_columnsIdByName[tagData]
            displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
          })  
        }        
        props.onChangeQuestionText(props.idx, displayText);
        
      }            
    };
    

    useEffect(()=>{
      var _filter = props.question.condition?props.question.condition:{"id": QbUtils.uuid(), "type": "group"}

      var _immutableTree = QbUtils.checkTree(QbUtils.loadTree(_filter), props.config)
      setCondition(_immutableTree) //QbUtils.checkTree(QbUtils.loadTree({"id": QbUtils.uuid(), "type": "group"}), _config))             
      if(props.question){
        
        let displayText = _clone(props.question.question_text)
        //console.log('getFormulaText txt', txt )
        if (props.question.is_excel_formula) {
          var _columnsNameById = {}
          if (props.columns) {
            props.columns.forEach(cl => {
              _columnsNameById[cl._id] = cl.name
            });
          }
    
          const tags = displayText.match(/var_\w+/gi) || []
          //console.log('getFormulaText tags in', tags )
          tags.map(myTag => {
            const tagData = myTag.substring(4)
            const tagDisplayValue = "{" + _columnsNameById[tagData] + "}"
            displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
          })
        }
        setQuestionText(displayText)                  
      } else {
        setQuestionText("")
      }
      
    }, [props.question, props.config])   
    
    useEffect(()=>{

        if(props.question.intent) {
          var currentCol = props.editedColumns.find(col=> col._id===props.question.intent)
          if(currentCol){
            setAnswerType(currentCol.type)
            if (currentCol.type==='select' 
            || currentCol.type==='member' 
           || currentCol.type==='chatmember'
            || currentCol.type==='link' 
            || currentCol.type==='check'){
              setQuestionSelectedType(currentCol.type)
            } else {
              setQuestionSelectedType(null)
              setChildDialogName("")
            }
        } else {
            //нет такой колонки
            if(props.question.intent === "__exly_dialog_menu"){
              setQuestionSelectedType("__exly_dialog_menu")
            } else  if(props.question.intent === "__exly_child_dialog"){
                setQuestionSelectedType("__exly_child_dialog")
                var child_dialog_name = ""
                if(props.question.child_dialog && props.dialogs){
                  var child_dialog = props.dialogs.find(v=> v._id===props.question.child_dialog)
                  if(child_dialog){
                    child_dialog_name = child_dialog.name
                  }
                  
                }
                setChildDialogName(child_dialog_name)
            } else {
              setQuestionSelectedType(null)
              setChildDialogName("")
            }
          }   
        } else {
          setQuestionSelectedType(null)
          setChildDialogName("")
        }          
    }, [props.question.intent])      

    
    useEffect(()=>{
      //console.log('useEffect inputRef!')
      if (inputRef && inputRef.current) {
          //console.log('inputRef focus!')
          inputRef.current.onblur = onBlurQuestionText
      }     

    }, [inputRef.current, questionText, props.idx, props.question.question_text])  

    
    return(
        <Paper             
            className={props.editingQuestionIdx=== props.idx  ? classes.question_paper_selected : classes.question_paper}     
            variant={props.editingQuestionIdx=== props.idx  ? 'elevation' : "outlined"} 
            elevation={props.editingQuestionIdx=== props.idx  ? 5 : 0} 
            
             >
                {props.editingQuestionIdx===  props.idx  ?                         
                        <div style={{width: '100%'}}> 

                          <QuestionIntentButton {...props} /> 
                           

                         
                        {props.question.is_formula && !props.question.is_excel_formula &&
                         <AceEditor
                         placeholder="Введите код"
                         mode="javascript"
                         theme="monokai"
                         name="formula_questionText"
                         //onLoad={this.onLoad}
                         onChange={onChangeJSFormulaText}
                         onBlur={onBlurQuestionText}
                         fontSize={12}
                         showPrintMargin={true}
                         showGutter={true}
                         highlightActiveLine={true}
                         value={questionText}
                         maxLines={15}
                         minLines={5}
                         setOptions={{
                         enableBasicAutocompletion: true,
                         enableLiveAutocompletion: true,
                         enableSnippets: false,
                         showLineNumbers: true,
                         tabSize: 2,
                         }}/>}
                        {!props.question.is_formula && props.question.is_excel_formula &&
                               <TextField
                               id={"quiestion_formula"}
                               value={questionText}
                       name="formula"
                       onChange={onChangeFormulaText}
                       onBlur={onBlurQuestionText}                 
                       placeholder="Введите формулу расчета"
                       helperText=""
                       multiline
                       fullWidth
                       variant="outlined"
                       size="small"
                       onPaste={event => event.stopPropagation()}
                       onCopy={event => event.stopPropagation()}    
                       InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={{marginTop:"0px"}}>
                            =
                          </InputAdornment>
                        ),                                            
                      }}                           
                       //margin="normal"
                   />                         
                        }
                {!props.question.is_formula 
                && !props.question.is_excel_formula 
                && props.question.intent!=="__exly_child_dialog"
                && 
                <MentionsInput
                    autoFocus
                   value={questionText} //{props.question.question_text}
                 //defaultValue={getFormulaText(props.question.question_text?props.question.question_text:"")}
          onChange={onChangeQuestionText}
          inputRef={inputRef}
           //defaultValue = {props.question.question_text}
           //onBlur={onSaveQuestionText}
          //displayTransform={(id, display) => `[${id}]`}        
          placeholder="Используйте { для вставки значения полей в текст"
          className="mentions"
        >
          <Mention
            type="user"
            markup="{{__id__||__display__}}" 
            displayTransform={(id, display) => `{${display}}`}  
            trigger="{"
            data={props.columns.map(col=> { return{id:col._id, display: col.name}})}
            className="mentions__mention"
          />
        </MentionsInput>   
        }
                              
                     
{props.question.intent==="__exly_dialog_menu" &&
  <Paper className={classes.question_menu}>
    <Grid container alignItems="flex-end" spacing={1}>

    <Grid item xs={4} >Пункты меню</Grid>  
    
    <Grid item xs={4} >Команда</Grid>  
         
    <Grid item xs={3} ></Grid>  
    <Grid item xs={1} ></Grid>
    </Grid>
    {props.question.menu_items && props.question.menu_items.map( (mi, midex)=>(
    <Grid container alignItems="flex-end" spacing={1}>      
    <Grid item xs={4} >
      <TextField

                    id={"dialog-question-"+props.idx+"menu-item"+midex}
                    //label="Команда"
                    value={mi.cmd}
                    onChange={event=> onChangeQuestionMenuItem(midex, event.target.value)}                    
                    placeholder="Введите название команды"
                    helperText=""
                    fullWidth      
                    InputProps={{style: {fontSize: 12}}}   
                    InputLabelProps={{ style: { fontSize: 12 } }}                               
                    //variant="outlined"                                  
                />    
                  </Grid>                              
                  <Grid item xs={4} >
          <TextField

            id={"dialog-question-" + props.idx + "menu-item-type" + midex}
           // label="Тип"
            value={mi.cmd_type}
            onChange={event => onChangeQuestionMenuItemType(midex, event.target.value)}
            placeholder="Введите тип команды"
            helperText=""
            fullWidth
            select
            InputProps={{style: {fontSize: 12}}}   
            InputLabelProps={{ style: { fontSize: 12 } }}   
          //variant="outlined"                                  
          >
                            <MenuItem style={{ fontSize: 12 }} key="next_step" value="next_step">
                                Продолжить текущий диалог
                                </MenuItem>
                                <MenuItem style={{ fontSize: 12 }}  key="next_dialog" value="next_dialog">
                                Перейти в другой диалог
                                </MenuItem>
                                <MenuItem style={{ fontSize: 12 }}  key="next_question" value="next_question">
                                Перейти к другому вопросу
                                </MenuItem>                                
                                <MenuItem style={{ fontSize: 12 }}  key="end_dialog" value="end_dialog">
                                Завершить текущий диалог
                                </MenuItem>
                                <MenuItem style={{ fontSize: 12 }}  key="repeate_dialog" value="repeate_dialog">
                                Повторить текущий диалог
                                </MenuItem>              
          </TextField>
                    </Grid>                      
                    <Grid item xs={3} >
                      {mi.cmd_type ==="next_dialog" && 
          <TextField

          id={"dialog-question-" + props.idx + "menu-item-params" + midex}
         // label="Следующий диалог"
          value={mi.cmd_param}
          onChange={event => onChangeQuestionMenuItemParam(midex,event.target.value)}
          placeholder="Введите тип команды"
          helperText=""
          fullWidth
          select
          InputProps={{style: {fontSize: 12}}}   
          InputLabelProps={{ style: { fontSize: 12 } }}   
        //variant="outlined"                                  
        >      
                            { props.dialogs &&  props.dialogs.map(dialog => (
                            <MenuItem style={{ fontSize: 12 }}  key={dialog._id} value={dialog._id}>
                              {dialog.name}
                            </MenuItem>
                            )

                            ) }             
        </TextField>                      
                      }
                      {mi.cmd_type ==="next_question" && 
          <TextField
          id={"dialog-question-" + props.idx + "menu-item-params" + midex}
         // label="Следующий диалог"
          value={mi.cmd_param}
          onChange={event => onChangeQuestionNextQuestionParam(midex, event.target.value)}
          placeholder="Номер вопроса"
          helperText=""
          fullWidth   
          InputProps={{style: {fontSize: 12}}}   
          InputLabelProps={{ style: { fontSize: 12 } }}                          
        />}                      
                      </Grid> 
                      <Grid item xs={1} style={{textAlign:"right"}} >
                        <IconButton size="small" onClick={e=>onDeleteQuestionMenuItem(midex)}>
                          <HighlightOffIcon fontSize='small'/>
                        </IconButton>
                        </Grid>   

    </Grid>
    ))}
    <Grid container justify="center" alignItems="flex-end">
    </Grid>
    <Grid container justify="center" alignItems="flex-end">
      <Button onClick={e=>onAddQuestionMenuItem()} size="small" color="secondary">
        Добавить пункт меню
      </Button>
    </Grid>
  </Paper>
}

            {props.question.intent === "__exly_child_dialog" &&
              <Paper className={classes.question_menu}>
                <TextField
                  id={"dialog-question-" + props.idx + "-child_dialog"}
                  label="Запустить дочерний диалог"
                  value={props.question.child_dialog}
                  onChange={onChangeQuestionChildDialog}
                  placeholder="Выберите диалог"
                  helperText=""
                  fullWidth
                  select
                //variant="outlined"                                  
                >
                  {props.dialogs && props.dialogs.map(dialog => (
                    <MenuItem style={{ fontSize: 12 }} key={dialog._id} value={dialog._id}>
                      {dialog.name}
                    </MenuItem>
                  )

                  )}
                </TextField>
              </Paper>
            }

{answerType==="text" &&
            <div>
            <FormGroup row>
            <FormControlLabel
              control={<Switch size="small" checked={props.question.has_phone_button} onChange={toggleHasPhoneButton} />}
              label = "Кнопка отправки телефона"
              labelPlacement = "end"
            />
              {props.question.has_phone_button &&
              <TextField
                id={"dialog-question-" + props.idx + "_phone_button_text"}
                value={props.question.phone_button_text}
                onChange={onChangeQuestionPhoneButtonText}                
                helperText="текст на кнопке"
              //variant="outlined"                                  
              ></TextField>
              }
            </FormGroup>

          </div>  
}

{ questionSelectedType 
&& props.question.intent !== "__exly_child_dialog" 
&& !props.question.is_formula 
&& !props.question.is_excel_formula 
&& <div>
<TextField
id={"dialog-question-" + props.idx + "_list_style"}
label="Представление списка"
value={props.question.list_style}
onChange={onChangeQuestionListStyle}
//placeholder="Введите тип команды"
helperText=""
fullWidth
select
//variant="outlined"                                  
>
                <MenuItem key="auto" value="auto">
                    Автоматическое определение
                    </MenuItem>
                    <MenuItem key="inline" value="inline">
                    Внутри сообщения
                    </MenuItem>
                    <MenuItem key="list" value="list">
                    Списком
                    </MenuItem>
                    <MenuItem key="suggestedAction" value="suggestedAction">
                    Клавиатурные кнопки
                    </MenuItem> 
                    <MenuItem key="heroCard" value="heroCard">
                      Кнопки под сообщением
                    </MenuItem>                                  
</TextField>  
              </div>}   



            <div>
              <FormGroup row>
              <FormControlLabel
                control={<Switch size="small" checked={props.question.typing} onChange={toggleTyping} />}
                label = "Печатать и задержка перед отправкой (мс)"
                labelPlacement = "end"
              />

                <TextField
                  id={"dialog-question-" + props.idx + "_delay"}
                  value={props.question.delay}
                  onChange={onChangeQuestionDelay}
                  type="number"
                  helperText="длительностью (мс)"
                //variant="outlined"                                  
                ></TextField>
              </FormGroup>

            </div> 
                <div>
                <FormGroup>
  <FormControlLabel
    control={<Switch size="small" checked={props.question.hasCondition} onChange={toggleHasCondition} />}
    label="Выполнять при соблюдении условий"
  />
</FormGroup>      
         
                { props.question.hasCondition &&  <Query
            {...props.config} 
            value={condition}//QbUtils.checkTree(QbUtils.loadTree(props.question.condition?props.question.condition:{"id": QbUtils.uuid(), "type": "group"}), props.config)}
            onChange={onChange}
            renderBuilder={renderBuilder}
        />}
        </div>                   
        </div>
                                     :
                        <div 
                        onClick={onChangeEditedQuestion}
                        style={{width: '100%', minHeight:"30px", cursor:"pointer"}}
                        > 
                       {props.question.intent !== "__exly_child_dialog" &&                        
                       <Box                          
                        display="flex" 
                        p={1} 
                        bgcolor="background.paper" 
                        padding={0} 
                        >
                        
                            {(props.question.is_formula || props.question.is_excel_formula)?
                            <Typography 
                              component="span" 
                              color="error"                               
                              variant="subtitle2">Формула</Typography>:
                            <Typography                               
                              variant="subtitle2" 
                              style={{ whiteSpace:"pre-line"}} 
                              dangerouslySetInnerHTML={{__html: normilizeQuestionText(questionText)}}  
                            />                                                                                  
                            
                            }
                        </Box>}
                        <div>                        
                        {props.question.intent==="__exly_dialog_menu" && props.question.menu_items 
                                && props.question.menu_items.map(mi =>
                                  <Chip size="small" label={mi.cmd} style={{marginRight:"10px"}}/>
                                )
                            }
                        {props.question.intent==="__exly_child_dialog" 

                                 && <Chip 
                                 avatar={<ChatIcon />} 

                                 label={"-> "+childDialogName} 
                                 style={{marginRight:"10px"}}
                                 />
                                
                            }                            
                               
                        </div>                     
                        </div>  
}

        </Paper>        
    )
}

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
      <div
        role="tabpanel"      
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Paper className={classes.paper}>
            <Typography>{children}</Typography>
          </Paper>
        )}
      </div>
    );
  }



export default function DialogEditor(props) {
    const {token} = useContext(AuthContext);
    const {request} = useHttp();
    
    //const unblockHandle = useRef();
    //const [isShowSaving, setShowSaving] = useState(false)
    //const [nextPage, setNextPage] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null);
    const [anchorElColEdit, setAnchorElColEdit] = React.useState(null);
    const [editingSets, setEditingSets] = React.useState(null);    
    const anchorRef = React.useRef(null);
    //const [config, setConfig] = useState({}); 
    const [fields, setFields] = useState({});
    //const [workitemlist, setWorkItemList] = useState(null);
    const [editedColumns, setEditedColumns] = useState([]);  
    const [editedColumnsInQuestions, setEditedColumnsInQuestions] = useState({}); 
    const [loadingDialog, setLoadingDialog] = useState(false);
    //const history = useHistory();
    //const [respondents, setRespondents] = useState([]);
    //const [chatgroups, setChatgroups] = useState([]);
    const [editingQuestionIdx, setEditingQuestionIdx] = useState(-1);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [goodbyeMessage, setGoodbyeMessage] = useState('');
    const [cancelKeywords, setCancelKeywords] = useState('');
    const [cancelMessage, setCancelMessage] = useState('');
    const [helpKeywords, setHelpKeywords] = useState('');
    const [helpMessage, setHelpMessage] = useState('');

    const [emptyMessage, setEmptyMessage] = useState('');
    const [selectItemMessage, setSelectItemMessage] = useState('');
    
    
    //const wiId = useParams().wi;
    //const dialogId = useParams().dialogid;
    const classes = useStyles();
    //const theme = useTheme();
    const [tab, setTab] = React.useState(0);
    const [tabIdx, setTabIdx] = React.useState({schedule:2, dialog:0, delivery:1});


    /*
     const init = useCallback(async()=>{
        try {
            //setLoadingDialog(true);         
             //var data_respondents = await request('/api/respondent','GET',null, {Authorization: `Bearer ${token}`});
             // setRespondents(data_respondents.map(r => {return {value: r.channelId, label: r.name}}));                                              
              var data_chatgroups = await request('/api/chatgroup','GET',null, {Authorization: `Bearer ${token}`});
              console.log('getchatgroups data', data_chatgroups); 
              setChatgroups(data_chatgroups);
              setRespondents(props.respondents);
              //if(props.isTemplate){
                var jb = props.dialog;
              //} else {              
              //var data = await request(`/api/dialog/${wiId}/${dialogId}`,'GET',null, {Authorization: `Bearer ${token}`});             
              //console.log('getDialog data', data);  
              //}
            if(jb.type==='send_report'){
                setTabIdx({schedule:0, delivery:1})
            } else {
                setTabIdx({ schedule:0, dialog:1, delivery:2})
                    //var workitems = await request(`/api/workitemlist/${data.work_item_list}`,'GET',null, {Authorization: `Bearer ${token}`});
                    //console.log('getDialog workitems', workitems);                      
                    //if(workitems.columns){
                    //    for(var i=0; i<workitems.columns.length; i++){
                    //        if(workitems.columns[i].type ===   "assigned"){
                    //            workitems.columns[i].lookup = Object.assign({}, ...data_respondents.map(item => ({ [item.channelId]: item.name })));
                    //        }
                    //    }
                    //} 
                    setColumns(props.columns);   
                    var resp_cols = [];
                    for(let ic=0; ic<props.columns.length; ic++){
                      if(props.columns[ic].type==='assigned'){
                        resp_cols.push(props.columns[ic]);
                      }
                    }
                    setRespondentColumns(resp_cols);   
                    setWITitle(props.title)               
            }
            setDialog(jb);          
            setLoadingDialog(false);
            //setDialogEdited(false);
        } catch (e) {
            console.log('getRespondents error', e); 
            setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
        }
    },[token, props.columns, props.dialog, props.respondents, props.title, request])    
*/

    /*
    const saveDialog = useCallback(async()=>{
        try {
            setLoadingDialog(true);
            console.log('saveDialog dialog', dialog);    
            var req_dialog = await request(`/api/dialog/${wiId}/${dialogId}`,'POST',dialog, {Authorization: `Bearer ${token}`});
            console.log('saveDialog data', req_dialog);
            setDialog(req_dialog);   
            setLoadingDialog(false);      
            setDialogEdited(false);
            //history.push(`/dialogs/${groupId}`);                
        } catch (e) {
            console.log('getDialog error', e); 
            setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
        }
    },[token, wiId, dialog, request]) 
    */

  //  const runDialog = useCallback(async()=>{
  //    try {
  //        props.runDialog(dialog._id);
            //var data = await request(`/api/dialog/${wiId}/${dialogId}/run`,'GET',null, {Authorization: `Bearer ${token}`});
  //          init(); 
  //    } catch (e) {
  //        console.log('runDialog error', e);
  //        setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...') 
  //    }
  //},[token, dialog, request])

  //const stopDialog = useCallback(async()=>{
  //  try {
        //var data = await request(`/api/dialog/${wiId}/${dialogId}/stop`,'GET',null, {Authorization: `Bearer ${token}`});
        //console.log('stopDialog data', data); 
  //      props.stopDialog(dialog._id); 
  //      init();
                  
  //  } catch (e) {
  //      console.log('getDialogList error', e); 
  //      setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
  //  }
//},[token, dialog, props, request, init])   

const onAddColumn = () =>{
  //setAnchorElColEdit(anchorRef.current)
  var rect = anchorRef.current.getBoundingClientRect();
  setAnchorElColEdit({ top: rect.top, left: rect.left })   
  setEditingSets({idx:props.columns.length, isNew:true})         
}

const onNewColumnCallback = (newCol) =>{
  //props.onChangeQuestionIntent(props.idx, newCol._id); 
  //setOpen(false);      
}

const updateTemplate = useCallback(async(tmpl)=>{
  try {
      var formData = new FormData();
      console.log('updateTemplate tmpl', tmpl);
      formData.append("tmpl", tmpl)
        var data = await request(`/api/dialog/${props.wiId}/${props.dialog._id}/tmpl`,'POST',formData, {Authorization: `Bearer ${token}`});
        console.log('updateTemplate data', data);
        //TODO ДОБАВИТЬ ИНФОРМИРОВАНИЕ ЧТО ОБНОВЛЕН ШАБЛОН!!!
        //setDialog((prevDialog) =>{
        //  var newDialog = JSON.parse(JSON.stringify(prevDialog));
        //  newDialog.is_active = true;     
        //  return newDialog;
        //}); 
  } catch (e) {
      console.log('updateTemplate error', e); 
      setErrorMsg(e.message? 'Ошибка:'+e.message:'Произошла ошибка! Попробуйте еще раз...')
  }
},[token, props.wiId, props.dialog._id, request])

//console.log('Апдейт!!! ') 

 
/*
    useEffect(()=>{
        init();              
    }, [init])
    useEffect(()=>{
      if(dialogEdited){
      unblockHandle.current = history.block((targetLocation) => {
        // take some custom action here
        // i chose to show my custom modal warning user froim going back
        // rather than relying on the browser's alert
        console.log('unblockHandle ', targetLocation)      
        setNextPage(targetLocation.pathname);
        setShowSaving(true);
        return false;
      });
      return function () {
        unblockHandle.current.current && unblockHandle.current.current()
      }
      } else if (unblockHandle && unblockHandle.current){
        unblockHandle.current();
        setShowSaving(false);
      }
    }, [dialogEdited])    

    const handleSavingClose = () => {
      setShowSaving(false);
      if (unblockHandle) {
        unblockHandle.current()
      }            
      if(nextPage){
        history.push(nextPage) 
      }          
        history.push(`/reports/${wiId}`);
      };

      const handleClose = () => {      
          history.push(`/reports/${wiId}`);
        };      

        const handleSavingSave = () => {
          if (unblockHandle) {
            unblockHandle.current()
          }        
          saveDialog(); 
          if(nextPage){
            history.push(nextPage) 
          }        
        };   


*/    


    //const onChangeDialogName = (event) => {
    //    const v = event.target.value;
    //    props.onChangeDialog('name', v)
        //setDialog((prevDialog) =>{
        //  var newDialog =  {...prevDialog};
        //  newDialog.name = v;     
        //  return newDialog;
        //}); 
    //    setDialogEdited(true);         
    //}
         
      
    const onChangeWelcomeMessage = (event) => {
        const v = event.target.value;
        setWelcomeMessage(v)
        //setDialogEdited(true);          
    }   

    const onSaveWelcomeMessage = () => {
      //const v = event.target.value;
      props.onChangeDialog('welcome_message', welcomeMessage)
      //setDialogEdited(true);          
  }    
  
const onChangeSelectItemViewField = (event) => {
  const v = event.target.value;
  props.onChangeDialog('select_item_view_field', v)
  //setDialogEdited(true);          
}      

    
    
    const onChangeQuestionText = (idx, newVal) => {
        const qidx = idx;
        const v = newVal
        props.onChangeDialogQuestion(qidx, 'question_text', v)
        //setDialogEdited(true); 
    }
    
    const onChangeQuestionCondition = (idx, newVal) => {
        const qidx = idx;
        const v = newVal
        props.onChangeDialogQuestion(qidx, 'condition', v)
        //setDialogEdited(true); 
    }

    const onChangeQuestionToggleHasCondition = (idx, v) =>{
      props.onChangeDialogQuestion(idx, 'hasCondition', v)
    }

    const onChangeQuestionToggleTyping = (idx, v) =>{
      props.onChangeDialogQuestion(idx, 'typing', v)
    } 
    
    const onChangeQuestionToggleHasPhoneButton = (idx, v) =>{
      props.onChangeDialogQuestion(idx, 'has_phone_button', v)
    }     

        

    const onChangeQuestionToggleIsFormula = (idx, v) =>{
      props.onChangeDialogQuestion(idx, 'is_excel_formula', v)
    }    

    const onChangeQuestionToggleIsJSFormula = (idx, v) =>{
      props.onChangeDialogQuestion(idx, 'is_formula', v)
    }    
    
    
    
    const onChangeQuestionAnswerType = (idx, newVal) => {
        const qidx = idx;
        const v = newVal
        props.onChangeDialogQuestion(qidx, 'answer_type', v)
          //setDialogEdited(true); 
    }    

    const onChangeQuestionListStyle = (idx, newVal) => {
      const qidx = idx;
      const v = newVal
      props.onChangeDialogQuestion(qidx, 'list_style', v)
        //setDialogEdited(true); 
  }   

  const onChangeQuestionDelay = (idx, newVal) => {
    const qidx = idx;
    const v = newVal
    props.onChangeDialogQuestion(qidx, 'delay', v)
      //setDialogEdited(true); 
}     

const onChangeQuestionPhoneButtonText = (idx, newVal) => {
  const qidx = idx;
  const v = newVal
  props.onChangeDialogQuestion(qidx, 'phone_button_text', v)
    //setDialogEdited(true); 
}    

const onChangeQuestionChildDialog = (idx, newVal) => {
  const qidx = idx;
  const v = newVal
  props.onChangeDialogQuestion(qidx, 'child_dialog', v)
    //setDialogEdited(true); 
}    



  const onChangeQuestionSendField = (idx, newVal) => {
    const qidx = idx;
    const v = newVal
    props.onChangeDialogQuestion(qidx, 'send_field', v)
      //setDialogEdited(true); 
}     
  
  


    const onDeleteQuestion = (idx) => {

        const newQuestions = [...props.dialog.questions];
        newQuestions.splice(idx,1); 
        props.onChangeQuestions(newQuestions)

          //setDialogEdited(true); 
    }     
 
    const onAddQuestion = () => {  
        const newQuestions = [...props.dialog.questions];
        newQuestions.push({
          "answer_choice": [],
          "question_text": "",
          "intent": "",
          "answer_type": "text",
          "condition": ""
        }); 
        props.onChangeQuestions(newQuestions)
        setEditingQuestionIdx(newQuestions.length-1)
        //setDialogEdited(true); 
    }    
    
    const onAddQuestionWithIntent = (intent, question_text) => {  
      const newQuestions = [...props.dialog.questions];           
      var menu_items = [] 
      if(intent==="__exly_dialog_menu"){        
        question_text =  "Выберите пункт меню"
        menu_items = [
          {
            cmd:"Команда 1",
            cmd_type:"next_step",
            cmd_param:null
          },
          {
            cmd:"Команда 2",
            cmd_type:"end_dialog",
            cmd_param:null
          }, 
          {
            cmd:"Команда 3",
            cmd_type:"end_dialog",
            cmd_param:null
          },                               
        ]        
      }   else if(intent==="") {
        question_text =  "Сообщение"
      }        
      newQuestions.push({
        "answer_choice": [],
        "question_text": question_text,
        "intent": intent,
        "answer_type": "text",
        "condition": "",
        "menu_items":menu_items
      }); 
      props.onChangeQuestions(newQuestions)
      setEditingQuestionIdx(newQuestions.length-1)
      //setDialogEdited(true); 
  }       
 
    const handleAddAnswerChoice = () => {
        //setDialog((prevDialog) =>{
        //    var newDialog =  {...prevDialog};            
        //    newDialog.questions[qidx].answer_choice.push(v);    
        //    return newDialog;
        //  }); 
          //setDialogEdited(true); 
    } 
    
    const handleDeleteAnswerChoice = () => {
        //const qidx = idx;
        //const vidx = idx2
        //setDialog((prevDialog) =>{
        //    var newDialog =  {...prevDialog};           
        //    newDialog.questions[qidx].answer_choice.splice(vidx,1);    
        //    return newDialog;
        //  }); 
          //setDialogEdited(true); 
    }   
    const onChangeQuestionIntent = (idx, newVal) => {
        const v = newVal;
        const qidx = idx;  
        props.onChangeDialogQuestion(qidx, 'intent', v)
 
        //setDialogEdited(true);       
    }    

    const onChangeQuestionMenuItem = (qidx, idx, value)=>{
      props.onChangeDialogQuestionMenuItem(qidx, idx, 'cmd', value)  
    }

    const onChangeQuestionMenuItemType = (qidx, idx, value)=>{
      props.onChangeDialogQuestionMenuItem(qidx, idx, 'cmd_type', value)  
    }
    
    const onChangeQuestionMenuItemParam = (qidx, idx, value)=>{
      props.onChangeDialogQuestionMenuItem(qidx, idx, 'cmd_param', value)  
    }    

    const onAddQuestionMenuItem = (qidx, newItem) => {
      props.onAddQuestionMenuItem(qidx, newItem)
    }

    const onDeleteQuestionMenuItem = (qidx, item_index) => {
      props.onDeleteQuestionMenuItem(qidx, item_index)
    }    
  


const onChangeEmptyMessage = (event) => {
  const v = event.target.value;
  setEmptyMessage(v)
  //setDialogEdited(true);          
}   

const onSaveEmptyMessage = () => {
//const v = event.target.value;
props.onChangeDialog('empty_message', emptyMessage)
//setDialogEdited(true);          
}    

    const onChangeGoodbyMessage = (event) => {
      const v = event.target.value;
      setGoodbyeMessage(v)
      //setDialogEdited(true);          
  }   

  const onSaveGoodbyMessage = () => {
    //const v = event.target.value;
    props.onChangeDialog('goodbye_message', goodbyeMessage)
    //setDialogEdited(true);          
}      

const onChangeCancelKeywords = (event) => {
  const v = event.target.value;
  setCancelKeywords(v)
  //setDialogEdited(true);          
}   

const onSaveCancelKeywords = () => {
//const v = event.target.value;
props.onChangeDialog('cancel_keywords', cancelKeywords)
//setDialogEdited(true);          
}     

const onChangeCancelMessage = (event) => {
  const v = event.target.value;
  setCancelMessage(v)
  //setDialogEdited(true);          
}   

const onSaveCancelMessage = () => {
//const v = event.target.value;
props.onChangeDialog('cancel_message', cancelMessage)
//setDialogEdited(true);          
} 

const onChangeHelpKeywords = (event) => {
  const v = event.target.value;
  setHelpKeywords(v)
  //setDialogEdited(true);          
}   

const onSaveHelpKeywords = () => {
//const v = event.target.value;
props.onChangeDialog('help_keywords', helpKeywords)
//setDialogEdited(true);          
}     

const onChangeHelpMessage = (event) => {
  const v = event.target.value;
  setHelpMessage(v)
  //setDialogEdited(true);          
}   

const onSaveHelpMessage = () => {
//const v = event.target.value;
props.onChangeDialog('help_message', helpMessage)
//setDialogEdited(true);          
} 

    const onChangeTimeoutMessage = (event) => {
      const v = event.target.value;
      props.onChangeDialog('timeout_message', v)      
      //setDialogEdited(true);          
  }   
    


    const onDragEndQuestion = (result) => {
      //console.log('onDragEndQuestion ', result.source, result.destination)
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const sourceIndex = result.source.index;
      const targetIndex = result.destination.index;      
      if(result.source.droppableId === "dialog_columns_droppable" && result.destination.droppableId === "questions_droppable")
      {
        //т.е. перетащили колонку из списка в список вопросов.
        //добавим вопрос
        var sourseColumn = editedColumns[sourceIndex]
        console.log('onDragEndQuestion sourseColumn ', sourseColumn, editedColumns)
        if(sourseColumn && sourseColumn.name ){  
          var question_text =  "Введите "+sourseColumn.name  
          var menu_items = []
          if(sourseColumn.type==="__exly_dialog_menu"){
            question_text =  "Выберите пункт меню"
            menu_items = [
              {
                cmd:"Команда 1",
                cmd_type:"next_step",
                cmd_param:null
              },
              {
                cmd:"Команда 2",
                cmd_type:"end_dialog",
                cmd_param:null
              }, 
              {
                cmd:"Команда 3",
                cmd_type:"end_dialog",
                cmd_param:null
              },                               
            ]
          }   else if(sourseColumn.type==="") {
            question_text =  "Сообщение"
          }  
        const newQuestion = {
          "answer_choice": [],
          "question_text": question_text,
          "intent": sourseColumn._id,
          "answer_type": "text",
          "menu_items": menu_items,
          "condition": ""
        }

        var _questions = [...props.dialog.questions];

        _questions.splice(
          targetIndex,
          0,
          newQuestion
        ); 
        
        setEditingQuestionIdx(targetIndex)
        props.onChangeQuestions(_questions)  
        }

      } else if(result.source.droppableId === "dialog_columns_droppable" && result.destination.droppableId === "dialog_columns_droppable")
      {
        //передвигаем колонки между собой

      } else if(result.source.droppableId === "questions_droppable" && result.destination.droppableId === "questions_droppable")
      {
        //передвигаем вопросы между собой
        var _questions = [...props.dialog.questions];


        _questions.splice(
          targetIndex,
          0,
          _questions.splice(sourceIndex, 1)[0]
        ); 
        if(editingQuestionIdx === sourceIndex )   {
          setEditingQuestionIdx(targetIndex)
        }
        props.onChangeQuestions(_questions)   
      }
    }    

    const setUnEditedQuestion = (e) =>{

        setEditingQuestionIdx(-1)
        //console.log('inputRef ', inputRef)
      
    }     

    useEffect(()=>{
      if(props.dialog){
        setWelcomeMessage(props.dialog.welcome_message?props.dialog.welcome_message:"")
        setGoodbyeMessage(props.dialog.goodbye_message?props.dialog.goodbye_message:"")
        setEmptyMessage(props.dialog.empty_message?props.dialog.empty_message:"")                        
        
        setCancelKeywords(props.dialog.cancel_keywords?props.dialog.cancel_keywords:"")                        
        setCancelMessage(props.dialog.cancel_message?props.dialog.cancel_message:"")                        
        setHelpKeywords(props.dialog.help_keywords?props.dialog.help_keywords:"")                        
        setHelpMessage(props.dialog.help_message?props.dialog.help_message:"")                        



      } else {
        setWelcomeMessage("")
        setGoodbyeMessage("")   
        setEmptyMessage("")  

        setCancelKeywords("")                        
        setCancelMessage("")                        
        setHelpKeywords("")                        
        setHelpMessage("")                        

      }
    }, [props.dialog])    
    
    useEffect(()=>{
      var _colunms = [
        {_id:"", type:"" , name: "Простое сообщение"}, 
        {_id:"__exly_dialog_menu", type:"__exly_dialog_menu", name: "МЕНЮ"},
        {_id:"__exly_child_dialog", type:"__exly_child_dialog", name: "Дочерний диалог"},
        //{_id:"__exly_dialog_subdialog", type:"__exly_dialog_subdialog" , name: "Переход в другой диалог"}      
      ]
      var _colunmsInQuestions = {}
      if(props.editedColumns){
        _colunms = [..._colunms, ...props.editedColumns]
        if(props.dialog && props.dialog.questions){          
          for(var iqx=0; iqx<props.dialog.questions.length; iqx++){
            if(props.dialog.questions[iqx].intent){
              const intent = props.dialog.questions[iqx].intent
              var icx = _colunms.findIndex(cl=> cl._id===intent)
              if(icx>=0){
                _colunmsInQuestions[_colunms[icx]._id] = iqx
              }              
            }
          }
        }
      }
      setEditedColumns(_colunms)
      setEditedColumnsInQuestions(_colunmsInQuestions)
        
    }, [props.editedColumns, props.dialog])      

    return (
        <React.Fragment>     
          {errorMsg && <Alert variant="filled" severity="error" onClose={() => {setErrorMsg(null)}}>{errorMsg}</Alert>}                                
          <Fade
          in={loadingDialog}
          style={{
            transitionDelay: loadingDialog ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>     
        <DragDropContext onDragEnd={onDragEndQuestion}>            
        <div 
        ref={anchorRef}
        style={{           
          backgroundColor:"#fafafa",  
          display: "grid",
          width: "100%",
          gridTemplateColumns: "250px 1fr",
                 
        }}
        > 
        <div
        style={{ 
          height: props.minHeight, 
          overflow:"auto",      
        }}        
        >
        <Droppable droppableId="dialog_columns_droppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>       
        <List  dense={true} subheader={<ListSubheader>Поля и элементы</ListSubheader>}>

        {editedColumns && editedColumns.map((column, idx)=>
      <Draggable draggableId={"dialog_column_"+column._id} index={idx}>
      {(provided, snapshot) => (      
     <ListItem
     button
     ContainerComponent="li"    
     {...provided.draggableProps}
     {...provided.dragHandleProps}
     ref={provided.innerRef}     
   >
            <ListItemIcon
              style={{
                minWidth: "30px",
              }}
            >
              {column.type==='' && <ChatBubbleIcon fontSize="small" color="disabled"/>}
              {column.type==='__exly_dialog_menu' && <MenuIcon fontSize="small" color="disabled"/>}
              {column.type==='__exly_child_dialog' && <ChatIcon fontSize="small" color="disabled"/>}              
              {column.type==='__exly_dialog_subdialog' && <ChatIcon fontSize="small" color="disabled"/>}
   {column.type==='text' && <TextFieldsIcon fontSize="small" color="disabled"/>}
   {column.type==='title' && <TextFieldsIcon fontSize="small" color="disabled"/>}
 {column.type==='number' && <Looks3Icon fontSize="small"  color="disabled"/>}
 {column.type==='date' && <EventIcon fontSize="small"  color="disabled"/>}
 {column.type==='select' && <FilterListIcon fontSize="small"  color="disabled"/>}
 {column.type==='multiline' && <SubjectIcon fontSize="small"  color="disabled"/>}
 {column.type==='member' && <PersonIcon fontSize="small"  color="disabled" />}
 {column.type==='chatmember' && <ChatMemberIcon fontSize="small"  color="disabled" />}
 {column.type==='link' && <LinkIcon fontSize="small"  color="disabled" />}
 {column.type==='geolocation' && <LocationOnIcon fontSize="small"  color="disabled" />}
 {column.type==='attachments' && <AttachFileIcon fontSize="small"  color="disabled" />}
 {column.type==='check' && <CheckBoxIcon fontSize="small"  color="disabled"/>}
 {column.type==='lookup' && <LookupIcon fontSize="small"  color="disabled"/>}
   </ListItemIcon>
   <ListItemText id={"dialog-pull-column-label-"+idx} primary={column.name} />
   <ListItemSecondaryAction>
     {editedColumnsInQuestions[column._id]>=0 
     ? 
     <IconButton
     size="small"
      onClick={e=>setEditingQuestionIdx(editedColumnsInQuestions[column._id])}
     > 
       <ChatIcon fontSize="small" color="disabled"/>
      </IconButton>
     :
     <IconButton
     size="small"
      onClick={e=>onAddQuestionWithIntent(column._id, "Введите "+column.name)}
     >      
     <ArrowForwardIcon fontSize="small" color="disabled"/>     
     </IconButton>
    }
   </ListItemSecondaryAction>
 </ListItem>
)}
  </Draggable>
        )}
     <ListItem
     button
     ContainerComponent="li"   
     onClick={(event) => onAddColumn()}      
   >
            <ListItemIcon
              style={{
                minWidth: "30px",
              }}
            >
          <AddCircleOutlineIcon fontSize="small"/>
   </ListItemIcon>
   <ListItemText id={"dialog-pull-column-label-add"} primary="Добавить поле" />
 </ListItem>        
        </List>
        </div>
          )}
        </Droppable>
        <ColumnSettingsDialog  
              tableId={props.tableId} 
              open={anchorElColEdit} 
              setOpen={setAnchorElColEdit} 
              editingSets={editingSets} 
              setEditingSets={setEditingSets} 
              onColumnInsert={props.onColumnInsert} 
              onChangeColumn={props.onChangeColumn} 
              tables={props.tables}
              tablesList={props.tablesList} 
              viewId={props.viewId}
              columns={props.columns}
              onNewColumnCallback={onNewColumnCallback}
              />        
        </div>
        <div
        style={{ 
          height: props.minHeight, 
          overflow:"auto",      
        }}         
        >
                    <TextField
                            id="dialog-welcome_message"
                            label={props.dialog.type==="update_item_request"?"Сообщение для выбора элемента":"Приветственное сообщение:"}
                            //defaultValue={welcomeMessage}
                            value={welcomeMessage}
                            onChange={onChangeWelcomeMessage}
                            onBlur={onSaveWelcomeMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            rows={2}
                            margin="normal"
                            variant="outlined"
                            style={{
                              backgroundColor:"white"
                            }}
                        /> 

                  {props.dialog.type==="update_item_request"  && <TextField
                            id="dialog-select_item_view_field"
                            label={"Поле для представления элемента"}
                            //defaultValue={welcomeMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            value={props.dialog.select_item_view_field}
                            onChange={onChangeSelectItemViewField}
                            placeholder=""
                            helperText=""
                            fullWidth
                            select
                            margin="normal"
                            variant="outlined"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        >
                          {props.columns && props.columns.map(col=>
                <MenuItem key={col._id} value={col._id}>
                    {col.name} 
                    </MenuItem>)}
                          </TextField>      
                        }                    
          

            <Paper 
              elevation={3}
                style={{
                      paddingTop:"10px",
              //        width:"100%",
                      minHeight:"70px",
              //        border: "dashed grey",                    
                    }}
            >
          {props.dialog.type==="update_request" && 
                    <div 
                    style={{                              
                              textAlign: "center"                        
                            }}>
                              <Typography variant='body'>
                                Вопросы/сообщения для каждого элемента в списке
                              </Typography>
                            </div> }          
           
        <Droppable droppableId="questions_droppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {props.dialog.questions && props.dialog.questions.length>0 &&  props.dialog.questions.map((question, index) => (
      <Draggable draggableId={"q_"+index} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          // style={divStyle}
        >
          <QuestionEditor key={'question_' + index} 
          dialog={props.dialog} 
          columns={props.columns} 
          editedColumns = {props.editedColumns}
          question={question} 
          idx={index} 
          config={props.config}
          onColumnInsert={props.onColumnInsert} 
          onChangeColumn={props.onChangeColumn} 
          tables={props.tables}
          tablesList={props.tablesList} 
          viewId={props.viewId}       
          tableId={props.tableId}  
          onChangeQuestionIntent={onChangeQuestionIntent} 
          handleDeleteAnswerChoice={handleDeleteAnswerChoice} 
          handleAddAnswerChoice={handleAddAnswerChoice} 
          onAddQuestion={onAddQuestion} 
          onDeleteQuestion={onDeleteQuestion}  
          onChangeQuestionCondition={onChangeQuestionCondition}  
          onChangeQuestionText={onChangeQuestionText} 
          onChangeQuestionAnswerType={onChangeQuestionAnswerType}
          onChangeQuestionListStyle={onChangeQuestionListStyle}
          onChangeQuestionDelay={onChangeQuestionDelay}
          onChangeQuestionSendField={onChangeQuestionSendField}
          onChangeQuestionToggleHasCondition={onChangeQuestionToggleHasCondition}
          onChangeQuestionToggleTyping={onChangeQuestionToggleTyping}
          onChangeQuestionToggleHasPhoneButton={onChangeQuestionToggleHasPhoneButton}
          onChangeQuestionPhoneButtonText={onChangeQuestionPhoneButtonText}
          onChangeQuestionChildDialog={onChangeQuestionChildDialog}
          editingQuestionIdx={editingQuestionIdx}
          setEditingQuestionIdx = {setEditingQuestionIdx}
          handleOpenColumnSetting={props.handleOpenColumnSetting}
          onChangeQuestionMenuItem={onChangeQuestionMenuItem}
          onChangeQuestionMenuItemType={onChangeQuestionMenuItemType}
          onChangeQuestionMenuItemParam={onChangeQuestionMenuItemParam}
          onAddQuestionMenuItem={onAddQuestionMenuItem}
          onDeleteQuestionMenuItem={onDeleteQuestionMenuItem}
          onChangeQuestionToggleIsFormula={onChangeQuestionToggleIsFormula}
          onChangeQuestionToggleIsJSFormula={onChangeQuestionToggleIsJSFormula}
          dialogs={props.dialogs}
          defaultColumns={props.defaultColumns}  
          attachmentsColumns={props.attachmentsColumns}
          geolocationColumns={props.geolocationColumns}          
          />
          
        </div>
      )}
    </Draggable>
              ))}
                    <div 
                    style={{                              
                              textAlign: "center"                        
                            }}>
                              <Typography variant='body'>
                                Перетащите сюда поле или элемент и создайте сообщение/вопрос в чате
                              </Typography>
                            </div> 
            </div>
          )}
        </Droppable>
 
    </Paper>

                                                
                
                    <TextField
                            id="dialog-goodbye_message"
                            label="Итоговое сообщение:"
                            value={goodbyeMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onBlur={onSaveGoodbyMessage}
                            onChange={onChangeGoodbyMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />  
                {props.dialog.type==="update_request" &&    <TextField
                            id="dialog-empty_message"
                            label="Сообщение при пустом списке:"
                            value={emptyMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onBlur={onSaveEmptyMessage}
                            onChange={onChangeEmptyMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />   }                       
                         
                         <TextField
                            id="dialog-cancel_keywords"
                            label="Стоп-фразы для остановки диалога:"
                            value={cancelKeywords}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onBlur={onSaveCancelKeywords}
                            onChange={onChangeCancelKeywords}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />  
                {cancelKeywords &&    <TextField
                            id="dialog-cancel_message"
                            label="Сообщение при остановке диалога:"
                            value={cancelMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onBlur={onSaveCancelMessage}
                            onChange={onChangeCancelMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />   } 

<TextField
                            id="dialog-help_keywords"
                            label="Фразы для вывода помощи:"
                            value={helpKeywords}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onBlur={onSaveHelpKeywords}
                            onChange={onChangeHelpKeywords}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />  
                {helpKeywords &&    <TextField
                            id="dialog-help_message"
                            label="Сообщение помощи:"
                            value={helpMessage}
                            onClick={e=>setEditingQuestionIdx(-1)}
                            onBlur={onSaveHelpMessage}
                            onChange={onChangeHelpMessage}
                            placeholder=""
                            helperText=""
                            fullWidth
                            multiline
                            variant="outlined"
                            //rows={2}
                            margin="normal"
                            style={{
                              backgroundColor:"white"
                            }}                            
                        />   }                         


                         </div>
                         
                </div>
                </DragDropContext>
                </React.Fragment>
    );
  }

 // export default withStyles(styles, { withTheme: true })(AddDialogDialog); 