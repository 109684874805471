import React, {useState, useContext, useEffect,useRef   } from 'react';
import {useHistory} from 'react-router-dom'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import _ from "lodash";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
//import {QueryBuilder, formatQuery} from 'react-querybuilder';
//import GooglePicker from 'react-google-picker'
import TelegramIcon from '@material-ui/icons/Telegram';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { Scrollbars } from 'react-custom-scrollbars';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import Typography from '@material-ui/core/Typography';
import './QueryBuilder.css'
import SvgIcon from '@material-ui/core/SvgIcon';
//import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import EditIcon from '@material-ui/icons/Edit';
import 'emoji-mart/css/emoji-mart.css'
import './DbEditor.css'
import { purple } from '@material-ui/core/colors';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import SplitPane from 'react-split-pane';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Badge from '@material-ui/core/Badge';
import { setMilliseconds } from 'date-fns';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment-timezone';

import { ChatList, MessageList, MessageBox, Input,  Button as InputButton } from './ChatApp/src'//'react-chat-elements';
import './ChatApp/style/main.css';
import {
  MainContainer,
  ChatContainer,
  MessageList as MessageListKit,
  MessageSeparator,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
  InfoButton,
  Avatar,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  InputToolbox,
  AttachmentButton,
  SendButton
} from "@chatscope/chat-ui-kit-react";
import QRCode from 'qrcode.react' ;
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Allotment } from "allotment";
//import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './_chat_main.scss';
import 'moment/locale/ru'
moment.locale('ru');
moment.lang('qw', {
  'calendar' : {
      'lastDay' : 'DD.MM.YYYY',
       'sameDay' : 'HH:mm',
      'nextDay' : 'DD.MM.YYYY',
      'lastWeek' : 'DD.MM.YYYY',
      'nextWeek' : 'DD.MM.YYYY',
      'sameElse' : 'DD.MM.YYYY'
 }
});

function IconForViewBot (props){
    return (<QuestionAnswerIcon fontSize="small" {...props} color="secondary"/>)
  }  

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },

    drawerContainer: {
      overflow: 'auto',
    },

    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      //padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0//-drawerWidth,
    },
    contentShift: {
      height: '100vh',
      overflow: 'auto',      
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },    
    menuButton: {
      marginRight: theme.spacing(2),
    },    
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
  
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    paper_send_invite: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: purple[50]
    },    

    paper_invite_links: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
    },       
    question_list: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
      job_card: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //padding: theme.spacing(1),
      },      
      subheader: {
      backgroundColor: theme.palette.background.paper,
    },

    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    grow: {
      flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    fabButton:  {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 99
    },
    fileinput: {
      display: 'none',
    },    
    selectEmpty: {
        marginTop: theme.spacing(2),
      }, 
    scheduler:{
        display: 'flex',
    },
    settingsPaper: {
      //marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)      
    },
    dialogCloseButton:{
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    nonSelectedListItemSecondaryAction:{
      visibility: "hidden",
      //cursor:"move"
    },
    selectedListItemSecondaryAction:{
      
    },    
    nonSelectedListItem: {
      "&:hover $nonSelectedListItemSecondaryAction": {
        visibility: "inherit"
      }
    }       
  }));

  function DiscordIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27z" />
      </SvgIcon>
    );
  }
  
  function SlackIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5z" />
      </SvgIcon>
    );
  }
  
  
  
  function MsTeamsIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M19.19 8.77q-.46 0-.86-.17q-.39-.17-.69-.47q-.3-.3-.47-.69q-.17-.4-.17-.86q0-.45.17-.85q.17-.4.47-.69q.3-.3.69-.47q.4-.18.86-.17q.45-.01.85.17q.4.17.7.47q.29.29.47.69q.17.4.17.85q0 .46-.17.86q-.17.39-.47.69q-.3.3-.7.47q-.4.17-.85.17m0-3.12q-.39 0-.69.27q-.25.27-.25.66t.25.67q.3.25.69.25q.39 0 .66-.25q.28-.25.28-.67q0-.39-.28-.66q-.27-.27-.66-.27M22 10.33V15q0 .63-.24 1.2q-.26.57-.67.99q-.43.43-1 .67q-.59.25-1.21.25q-.38 0-.76-.11q-.39-.07-.71-.25q-.24.79-.71 1.44q-.47.65-1.1 1.11q-.63.46-1.39.7q-.76.27-1.58.27q-.96 0-1.81-.33q-.82-.33-1.5-.94q-.66-.57-1.09-1.36q-.44-.8-.57-1.74H2.83q-.33 0-.59-.25q-.24-.24-.24-.58V7.73q0-.34.24-.59q.26-.24.59-.24H10q-.29-.6-.29-1.25q0-.61.23-1.15q.22-.5.62-.92q.4-.39.94-.62q.5-.23 1.12-.23q.61 0 1.14.23q.53.23.93.62q.4.42.62.92q.23.54.23 1.15q0 .6-.23 1.14q-.22.53-.62.92q-.4.4-.93.63q-.53.23-1.14.23q-.15 0-.31-.02q-.15-.02-.31-.05v.9h9.06q.39 0 .67.27q.27.27.27.66M12.63 4q-.35 0-.63.11q-.33.13-.56.36q-.22.23-.35.53q-.13.31-.13.65q0 .35.13.65q.13.3.35.53q.23.22.56.36q.28.13.63.13q.34 0 .64-.13q.3-.14.53-.36q.23-.23.36-.53q.14-.3.14-.65q0-.34-.14-.65q-.13-.3-.36-.53q-.23-.23-.53-.36q-.3-.11-.64-.11m-4.85 6.18h1.88V8.62H4.34v1.56h1.88v5h1.56m8.6 1.09v-5.62H12v5.42q0 .34-.24.58q-.26.25-.59.25H8.92q.13.67.47 1.25q.34.57.82.99q.48.41 1.1.65q.61.21 1.32.21q.77 0 1.45-.27q.68-.3 1.2-.81q.51-.51.8-1.19q.3-.68.3-1.46M20.75 15v-4.35h-3.12v5.71q.25.25.57.38q.3.12.68.12q.39 0 .73-.15q.34-.15.59-.4q.26-.25.4-.6q.15-.34.15-.71z" />
      </SvgIcon>
    );
  }
  
  
  
  function RobotIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M12,2A2,2 0 0,1 14,4C14,4.74 13.6,5.39 13,5.73V7H14A7,7 0 0,1 21,14H22A1,1 0 0,1 23,15V18A1,1 0 0,1 22,19H21V20A2,2 0 0,1 19,22H5A2,2 0 0,1 3,20V19H2A1,1 0 0,1 1,18V15A1,1 0 0,1 2,14H3A7,7 0 0,1 10,7H11V5.73C10.4,5.39 10,4.74 10,4A2,2 0 0,1 12,2M7.5,13A2.5,2.5 0 0,0 5,15.5A2.5,2.5 0 0,0 7.5,18A2.5,2.5 0 0,0 10,15.5A2.5,2.5 0 0,0 7.5,13M16.5,13A2.5,2.5 0 0,0 14,15.5A2.5,2.5 0 0,0 16.5,18A2.5,2.5 0 0,0 19,15.5A2.5,2.5 0 0,0 16.5,13Z" />
      </SvgIcon>
    );
  }  

  const AntTabs = withStyles({
    root: {
      //borderBottom: '1px solid #e8e8e8',
      minHeight: 24,
    },
    indicator: {
      //backgroundColor: '#1890ff',
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      whiteSpace: 'nowrap',
      textTransform: 'none',
      minWidth: 72,
      minHeight: 24,
      paddingTop: 1,
      paddingRight: 12,
      paddingBottom: 1,
      paddingLeft: 12,     
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      backgroundColor: "rgba(0,0,0,0.1)",
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: "rgba(0, 0, 0, 0.87)", //'#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: "rgba(0, 0, 0, 0.87)", //'#1890ff',
        opacity: 0.7,
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#fafafa"
      },
      '&:focus': {
        color: "rgba(0, 0, 0, 0.87)",//'#40a9ff',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />); 


  function BotchannelSettingsPopover(props) {
    //const [name, setName] = useState("");
    //const [icon, setIcon] = useState(<></>);
    //const [invite_url, setInvite_url] = useState("");
    //const [viewEmoji, setViewEmoji] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const handleClickOpen = (event) => {
      //setGroup(props.groups[0]._id);
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
    };

    const onChangeBotDefaultDialog = (_botId, _dialogId) => {
      const v = _dialogId
      const _id = _botId
      props.changeBot(
        _id,
        {
          defaultDialog:v
        })      
    }   

  
    const onChangeNameHandler = (event) =>{
      //console.log('event.target.name',event.target.name);
      //props.updateTable(props._id, {title: event.target.value})
  }
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        setAnchorEl(null);       
    };

    const handleConnectBot = () => {
      props.connectBot(props.botchannel._id);
      handleClose();
    };  

    const handleDel = () => {
      props.deleteBot(props.botchannel._id)
      handleClose();
    };  
  
    const handleCopy = () => {
      //props.handleCopyTable(props._id); //handleDelete={handleDelete} handleSaveAsTeamplate={handleSaveAsTeamplate} handleCopy={handleCopy}
      handleClose();
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
      <React.Fragment>
      <span style={{color:props.isActive?null:"white"}}><span onContextMenu={handleClickOpen} style={{textOverflow: "ellipsis", maxWidth:"240px", overflow: "hidden"}}>{props.botchannel.channel==="telegram" ? 
        <TelegramIcon  fontSize="small"/>:
    props.botchannel.channel==="slack" ? 
        <SlackIcon  fontSize="small"/>:
      props.botchannel.channel==="teams" ?
        <MsTeamsIcon  fontSize="small"/>:
      props.botchannel.channel==="discord" ? 
        <DiscordIcon  fontSize="small"/>:<></>}  {props.botchannel.name}</span>
      {props.isActive &&                  
        <Tooltip title={'Нажмите, чтобы отредактировать'}>        
          <IconButton  size="small" onClick={handleClickOpen}><ArrowDropDownIcon fontSize="small"/></IconButton>
        </Tooltip>}
        </span>   
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        //        style={{minWidth:"250px"}}
        >
          <Paper elevation={3} >
          <div className={classes.settingsPaper}>
              {(props.botchannel.channelId && props.botchannel.channel)?
              <>
              <div>
              <a target="_blank" href={props.botchannel.invite_url}>{props.botchannel.name}</a> <CopyToClipboard text={props.botchannel.invite_url}>
                <IconButton size="small" >
                <FilterNoneIcon fontSize="small"/>                  
                </IconButton>
              </CopyToClipboard>                 
              </div>
              <div><b>@{props.botchannel.mention_name}</b> | <b>ID:</b>{props.botchannel.channelId} </div>              
              </>:<>
              <div>{props.botchannel.name}</div><ChatBotConnectDialog btTitle="Подключить"  botchannel={props.botchannel}  connectBot={props.connectBot} /></>}              
              <div>
              <TextField
                      id={"bot-default-dialog-"+props.botchannel._id}
                      //label="Уровень доступа:"
                      value={props.botchannel.defaultDialog}
                      onChange={(e) => onChangeBotDefaultDialog(props.botchannel._id, e.target.value)}
                      placeholder=""
                      helperText="Диалог по-умолчанию"
                      fullWidth
                      select
                      margin="normal"
                      className={classes.selectEmpty}
                      InputLabelProps={{
                        shrink: true,
                      }}>
                      {props.dialogs && props.dialogs.map(dialog => (
                        <MenuItem key={dialog._id} value={dialog._id}><IconForViewBot />
                          {dialog.name}
                        </MenuItem>
                      )

                      )}


                    </TextField>                 
                </div>
            </div>
            {props.botchannel.channelId && props.botchannel.channel &&
            <ChatBotChangeTokenDialog botchannel={props.botchannel}  connectBot={props.connectBot} />   }         
            <MenuItem onClick={handleDel}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>Удалить</MenuItem>
          </Paper>
        </Popover>  
      </React.Fragment>
    );
  } 
  
  function ChatBotChangeTokenDialog(props) {
    const {token} = useContext(AuthContext);
    //const {loading, error, request} = useHttp();
    const [open, setOpen] = useState(false);
    const [newChatChannel, setNewChatChannel] = useState(null);
    //const [newChatDefaultDialog, setNewChatDefaultDialog] = useState("");
    const [newTelegramBotToken, setNewTelegramBotToken] = useState("");

    const classes = useStyles();
    const history = useHistory();    

    const onChangeTelegramBotToken = (event) => {
      const v = event.target.value;
      setNewTelegramBotToken(v)
    }      

    const handleDeleteBotChannel = (channelId) => {
      //console.log('handleDeleteBotChannel ',linkId)
      //props.deleteLinkInvite(linkId);
  };  

  const handleDeleteChatMembar = (member_id) => {
    //console.log('handleDeleteBotChannel ',)
    //props.deleteLinkInvite(linkId);
  }; 
  
  const handleInviteByChat = () => {
    props.connectBot(props.botchannel._id, {
      channel:"telegram",
     // channel_type:"telegram",
      token:newTelegramBotToken,
      //defaultDialog:newChatDefaultDialog
    })
    setOpen(false);
  };  


  
    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);
      
      setOpen(true);
    };
  
    const handleClose = () => {      
      setOpen(false);
    };
  
    const handleSave = () => {
      handleInviteByChat()                
        handleClose();        
    };        

   
  

    return (
      <React.Fragment>  
        <Tooltip title={"Изменить токен telegram бота"}>
        <MenuItem onClick={handleClickOpen}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>Изменить токен</MenuItem>          
        </Tooltip>
        <Dialog  fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" ><Typography variant="h6" noWrap>Подключить telegram-бота </Typography>      
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>      
        {props.errorMsg && <Alert variant="filled" severity="error" onClose={() => {props.setErrorMsg(null)}}>{props.errorMsg}</Alert>}        
        </DialogTitle>
          <DialogContent dividers>

            <Paper className={classes.job_card} elevation={3}>
              <div >
                <Typography>Введите новый токен Telegram-бота</Typography>

                    <TextField
                      id={"new-telegram-bot-token"}
                      value={newTelegramBotToken}
                      onChange={onChangeTelegramBotToken}
                      placeholder="Telegram bot access token"
                      helperText=""
                      fullWidth
                      //multiline
                      //rows={3}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  
              </div>
            </Paper>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
                Отмена
              </Button>              
              <Button onClick={handleSave}>
                Изменить
              </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>
    );
  }   

  function ChatBotConnectDialog(props) {
    const {token} = useContext(AuthContext);
    //const {loading, error, request} = useHttp();
    const [open, setOpen] = useState(false);
    const [newChatChannel, setNewChatChannel] = useState(null);
    //const [newChatDefaultDialog, setNewChatDefaultDialog] = useState("");
    const [newTelegramBotToken, setNewTelegramBotToken] = useState("");

    const classes = useStyles();
    const history = useHistory();    

    const onChangeTelegramBotToken = (event) => {
      const v = event.target.value;
      setNewTelegramBotToken(v)
    }      

    const handleDeleteBotChannel = (channelId) => {
      //console.log('handleDeleteBotChannel ',linkId)
      //props.deleteLinkInvite(linkId);
  };  

  const handleDeleteChatMembar = (member_id) => {
    //console.log('handleDeleteBotChannel ',)
    //props.deleteLinkInvite(linkId);
  }; 
  
  const handleInviteByChat = () => {
    props.connectBot(props.botchannel._id, {
      channel:"telegram",
     // channel_type:"telegram",
      token:newTelegramBotToken,
      //defaultDialog:newChatDefaultDialog
    })
    setOpen(false);
  };  


  
    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);
      
      setOpen(true);
    };
  
    const handleClose = () => {      
      setOpen(false);
    };
  
    const handleSave = () => {
      handleInviteByChat()                
        handleClose();        
    };        

   
  

    return (
      <React.Fragment>  
        <Tooltip title={"Подключить telegram бота"}>
        <MenuItem onClick={handleClickOpen}>
              <ListItemIcon>
                <AddCircleOutlineIcon fontSize="small" />
              </ListItemIcon>Подключить</MenuItem>          
        </Tooltip>
        <Dialog  fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" ><Typography variant="h6" noWrap>Подключить telegram-бота </Typography>      
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>      
        {props.errorMsg && <Alert variant="filled" severity="error" onClose={() => {props.setErrorMsg(null)}}>{props.errorMsg}</Alert>}        
        </DialogTitle>
          <DialogContent dividers>

            <Paper className={classes.job_card} elevation={3}>
              <div >
                <Typography>Подключите Telegram-бота, который позволит пользователям работать с данными через Telegram</Typography>

                    <TextField
                      id={"new-telegram-bot-token"}
                      value={newTelegramBotToken}
                      onChange={onChangeTelegramBotToken}
                      placeholder="Telegram bot access token"
                      helperText=""
                      fullWidth
                      //multiline
                      //rows={3}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  
              </div>
            </Paper>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
                Отмена
              </Button>              
              <Button onClick={handleSave}>
                Подключить
              </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>
    );
  }   

  function ChatBotAddDialog(props) {
    const {token} = useContext(AuthContext);
    //const {loading, error, request} = useHttp();
    const [open, setOpen] = useState(false);
    const [newChatChannel, setNewChatChannel] = useState(null);
    const [newChatDefaultDialog, setNewChatDefaultDialog] = useState("");
    const [newTelegramBotToken, setNewTelegramBotToken] = useState("");

    const classes = useStyles();
    const history = useHistory();    

    const onChangeTelegramBotToken = (event) => {
      const v = event.target.value;
      setNewTelegramBotToken(v)
    }      

    const handleDeleteBotChannel = (channelId) => {
      //console.log('handleDeleteBotChannel ',linkId)
      //props.deleteLinkInvite(linkId);
  };  

  const handleDeleteChatMembar = (member_id) => {
    //console.log('handleDeleteBotChannel ',)
    //props.deleteLinkInvite(linkId);
  }; 
  
  const handleInviteByChat = () => {
    props.inviteByChat({
      channel:"telegram",
     // channel_type:"telegram",
      token:newTelegramBotToken,
      defaultDialog:newChatDefaultDialog
    })
  };  

  const onChangeNewChaDefaultDialog = (event) => {
    const v = event.target.value;
    setNewChatDefaultDialog(v)
  }          

    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);
      
      setOpen(true);
    };
  
    const handleClose = () => {      
      setOpen(false);
    };
  
    const handleSave = () => {
      handleInviteByChat()                
        handleClose();        
    };        


  

    return (
      <React.Fragment>  
        <Tooltip title={"Добавить чат-бота"}>
          <Button size="small"
            edge="end" aria-label="menu" color="inherit" aria-controls="bot-menu" aria-haspopup="true"
            onClick={handleClickOpen}
            startIcon={<AddCircleOutlineIcon fontSize="small"/>}
            >
              Бот
          </Button>
        </Tooltip>
        <Dialog  fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" ><Typography variant="h6" noWrap>Новый чат-бот </Typography>      
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>      
        {props.errorMsg && <Alert variant="filled" severity="error" onClose={() => {props.setErrorMsg(null)}}>{props.errorMsg}</Alert>}        
        </DialogTitle>
          <DialogContent dividers>

            <Paper className={classes.job_card} elevation={3}>
              <div >
                <Typography>Подключите Telegram-бота, который позволит пользователям работать с данными через Telegram</Typography>
                <Grid container alignItems="flex-end">
                  <Grid item xs={8}>
                    <TextField
                      id={"new-telegram-bot-token"}
                      value={newTelegramBotToken}
                      onChange={onChangeTelegramBotToken}
                      placeholder="Telegram bot access token"
                      helperText=""
                      fullWidth
                      //multiline
                      //rows={3}
                      margin="normal"
                      label="API Token"
                      variant="outlined"                      
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="new-telegram-bot-default-dialog"
                      //label="Уровень доступа:"
                      value={newChatDefaultDialog}
                      onChange={onChangeNewChaDefaultDialog}
                      placeholder=""
                      helperText=""
                      fullWidth
                      select
                      margin="normal"
                      label="Диалог по-умолчанию"
                      variant="outlined"                       
                      className={classes.selectEmpty}
                      InputLabelProps={{
                        shrink: true,
                      }}>
                      {props.dialogs && props.dialogs.map(dialog => (
                        <MenuItem key={dialog._id} value={dialog._id}><IconForViewBot />
                          {dialog.name}
                        </MenuItem>
                      )

                      )}


                    </TextField>
                  </Grid>
                </Grid>

              </div>
            </Paper>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
                Отмена
              </Button>              
              <Button color="primary" onClick={handleSave}>
                Подключить бота
              </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>
    );
  } 

  export default function ChatBotsPanel(props) {
    const {token} = useContext(AuthContext);
    //const {loading, error, request} = useHttp();
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(0); //0 - открыты обе панели, 1 - только список контактов, 2 только диалог
    const [currentBotChannel, setCurrentBotChannel] = useState(null);
    //const [currentMember, setCurrentMember] = useState({});
    const [chatListDataSource, setChatListDataSource] = useState([])
    const [chatMsgsDataSource, setChatMsgsDataSource] = useState([])
    const [memberTyping, setMemberTyping] = useState(false);
    const [findInput, setFindInput] = useState("");
    const [newMsg, setNewMsg] = useState("");
    const [loadingMessages, setLoadingMessages] = useState(false);
    
    const classes = useStyles();
    const history = useHistory();    
    const input = useRef()

    const handleClickOpen = () => {
      //setGroup(props.groups[0]._id);      
      setOpen(true);
    };
  
    const handleClose = () => {      
      setOpen(false);
    };
  
    const handleSave = () => {
        //onSelectHandle();        
        handleClose();        
    };

    const handleChangeCurrentBotchannel = (event, newCurrentBotchannel) => {
      //console.log('handleChangeView ', event, newView);
      setCurrentBotChannel(newCurrentBotchannel)
    }; 
    
    const  openDialog = (dialogId) => {
      props.setEditedDialogId(dialogId); 
      //props.setEditedDialogTableId(dialog.work_item_list); 
      props.setIsEditedJobOpen('dialog') 
      props.setRigthPanelContent('jobs')         
  };    

  const handleClickOpenConnectTelegramDialog = (botchannel) => {
    //setGroup(props.groups[0]._id);  
    
    props.setEditedChatbot(botchannel)  
    props.setOpenConnectTelegramDialog(true);
  };

    const sendMessage = () => {
      //console.log('sendMessage ', newMsg, currentMember.id)
      //var list = this.state.messageList;
      //list.push(this.random('message', mtype));
      //this.setState({
      //    messageList: list,
      //});
      props.sendMessage(newMsg, props.currentChatMember._id)
      setNewMsg("")
      
  }

  const onSend = (innerHtml, textContent, innerText) =>{
    props.sendMessage(textContent, props.currentChatMember._id)
    setNewMsg("")
  }
  
  const readAllMessages = (member) =>{
    if(member && member._id){
      props.readAllMessages(member._id)
    }
  }
  const setCurrentMember = (member) =>{
    props.setCurrentChatMember(member)
    if(member && member._id){
      props.updateBotchannelMessages(member._id, setLoadingMessages)
      if(openList === 1){
        setOpenList(2)
      }
    } else {
      props.updateBotchannelMessages(null, setLoadingMessages)
    }

  }


    useEffect(() => {
      var _msgs = []
         
      if (props.botchannelMessages && props.currentChatMember) {
        var lastDate = new Date(1900,1,1)
        for (let im = 0; im < props.botchannelMessages.length; im++) {
          let msg = props.botchannelMessages[im]
          
          if(msg.member ===props.currentChatMember._id){
            msg.__date = new Date(msg.date)
            //msg.dateString = moment(msg.date).locale('ru').calendar();
            //var dateText = msg.date && !isNaN(msg.date) && (
            //  msg.dateString ||
            //  "format(msg.date)"
          //)
            //console.log('msg.dateString ', lastDate, msg.__date)
            if(lastDate && msg.__date && lastDate.getFullYear() === msg.__date.getFullYear() &&
            lastDate.getMonth() === msg.__date.getMonth() &&
            lastDate.getDate() === msg.__date.getDate()){

            } else {
              _msgs.push({
                type:"separator",
                text: moment(msg.date).locale('ru').format('DD.MM.YYYY')
              });

            }
            _msgs.push({...msg});
            lastDate = msg.__date
          }          
        }

      } else {

      }
      setChatMsgsDataSource(_msgs)
      readAllMessages(props.currentChatMember)
    }, [props.currentChatMember, props.botchannelMessages, setChatMsgsDataSource, setLoadingMessages])    
  
  

    
    useEffect(()=>{
      if(props.chatmembers && props.chatmembers.length>0){
        if(findInput && findInput.length>0){
          var _members = props.chatmembers.filter(m=> m.name.toUpperCase().indexOf(findInput.toUpperCase())>=0)
          setChatListDataSource((_members && _members.length>0)?_members.sort((cn1, cn2)=> {
            var cn1_dt = (cn1.lastMessage && cn1.lastMessage.date)? Date.parse(cn1.lastMessage.date):0
            var cn2_dt = (cn2.lastMessage && cn2.lastMessage.date)? Date.parse(cn2.lastMessage.date):0
            if( cn1_dt<cn2_dt) return 1 
            else if(cn1_dt===cn2_dt) return 0 
            else return -1
          }):[])
        } else {
          setChatListDataSource((props.chatmembers && props.chatmembers.length>0)?props.chatmembers.sort((cn1, cn2)=> {
            var cn1_dt = (cn1.lastMessage && cn1.lastMessage.date)? Date.parse(cn1.lastMessage.date):0
            var cn2_dt = (cn2.lastMessage && cn2.lastMessage.date)? Date.parse(cn2.lastMessage.date):0
            if( cn1_dt<cn2_dt) return 1 
            else if(cn1_dt===cn2_dt) return 0 
            else return -1
          }):[])
        }

      } else {
        setChatListDataSource([])
      }
    }, [props.chatmembers, findInput])

    
  return (
    <React.Fragment>

<div  style={{ height: `calc(100vh - 88px)`,  backgroundColor:"white"  }} >   
      {(props.botchannel && props.botchannel._id)? 
      <Allotment
      defaultSizes={[200,250]}
      onChange = {(sizes) => {
        console.log("ChatBotsPanel Allotment sizes ", sizes)
        var fullSize = sizes[0]+sizes[1]
        if(fullSize < 510 && openList === 0){
          if(props.currentChatMember){
            setOpenList(2)
          } else {
            setOpenList(1)
          }
          
        } else if(fullSize >= 510 && openList >0){
          //открываем оба списка
          setOpenList(0)
        }
      }}
      >
        <Allotment.Pane
                    minSize={250}
                    maxSize={550}
                    visible={openList === 0 || openList === 1}
        >
                          <Sidebar position="left" scrollable={false} 
            //  style={collapsedConversationContent?{
      //flexBasis: "calc(1.6em + 40px)",
    //  minWidth: "calc(1.6em + 40px)",
   // }:null}
    >
                          <TextField
              id="find_conversation_input"
              name="find_conversation_input"
              autoFocus
              value={findInput}
              onChange={(e) => { setFindInput(e.target.value) }}
              placeholder="Поиск..."
              //helperText=""   
              size='small'                         
              margin="dense"
              fullWidth
              autoComplete="off"
              style={{
                paddingLeft:"5px",
                paddingRight:"5px"           
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: 16 }} />
                  </InputAdornment>
                ),
            
                style:{
                  fontSize:12
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />  
                 <ConversationList>                                                     
                {chatListDataSource && chatListDataSource.length>0 ? chatListDataSource.map(conv =>  
                    <Conversation 
                    name={conv.name} 
                    //lastSenderName={(conv.lastMessage && conv.lastMessage.in)?(conv.first_name?(conv.first_name+(conv.last_name?(" "+conv.last_name):"")):conv.name):"Dinabot"} 
                    info={(conv.lastMessage && conv.lastMessage.text)?conv.lastMessage.text:""} 
                    className={"hide_conversation_content"}
                    active={props.currentChatMemberId && props.currentChatMemberId===conv._id}
                    lastActivityTime={(conv.lastMessage && conv.lastMessage.date)?moment(conv.lastMessage.date).locale('qw').calendar():moment(conv.joinDate).locale('qw').calendar()} 
                    unreadCnt={conv.unreadCount}
                    onClick={e=>{setCurrentMember(conv)}}
                    >
                    <Avatar 
                      src={conv.avatar?conv.avatar:`https://avatar.oxro.io/avatar.svg?name=${encodeURIComponent(conv.name)}`} 
                      name={conv.name}  
                      //status="available" 
                    />
                  </Conversation>)
                :    (props.botchannel && props.botchannel.channelId && props.botchannel.channel && props.botchannel.invite_url) &&
                  <div style={{textAlign: "center", marginTop:15, padding: 5 }}>
                    <div>
                      <span>Подключите клиентов или пользователей к базе через чат-бота в Telegram, используя ссылку или QR</span>
                    </div>              
                    <div>
                      <QRCode
                        value={props.botchannel.invite_url}
                        size={200}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        renderAs={"svg"}
                      />
                    </div>
                    <div>
                    <div>{props.botchannel.name}</div> 
                    <div><a target="_blank" href={props.botchannel.invite_url}> @{props.botchannel.mention_name}</a>
                    <CopyToClipboard text={props.botchannel.invite_url}>
                        <IconButton size="small" >
                          <FilterNoneIcon fontSize="small" />
                        </IconButton>
                      </CopyToClipboard>
                      </div>
                    </div>                               
                  </div> 
                }
                  
                                                  
                </ConversationList>
              </Sidebar>
              </Allotment.Pane>
        <Allotment.Pane
            minSize={250}
            //maxSize={500}
            visible={openList === 0 || openList === 2}
            >
              {(props.currentChatMember && props.currentChatMember._id) ? <ChatContainer>
          <ConversationHeader>
           {openList === 2 && <ConversationHeader.Back onClick={e=>setOpenList(1)}/>} 
          <Avatar 
          src={props.currentChatMember.avatar?props.currentChatMember.avatar:`https://avatar.oxro.io/avatar.svg?name=${encodeURIComponent(props.currentChatMember.name)}`}
           name={props.currentChatMember.name} />
          <ConversationHeader.Content userName={props.currentChatMember.name} info={props.currentChatMember.channelName?<a target="_blank" rel="noreferrer" href={"https://t.me/"+props.currentChatMember.channelName}>@{props.currentChatMember.channelName}</a>:""} />
          <ConversationHeader.Actions>
            <InfoButton />
          </ConversationHeader.Actions>          
        </ConversationHeader>
      <MessageListKit 
      typingIndicator={memberTyping ? <TypingIndicator content="Dinabot пишет" />: <></>}
      loading={loadingMessages}
      >
        {chatMsgsDataSource && chatMsgsDataSource.map(msg=>msg.type==="separator"?
        <MessageSeparator content={msg.text} />:msg.type==="dialog"?<MessageSeparator content={<span style={{cursor:"pointer"}} onClick={e=>openDialog(msg.dialog)}>{msg.text}</span>}>
      </MessageSeparator>:
        <Message
          model={{
            message: msg.text,
            sentTime: msg.date,//?moment(msg.date).locale('qw').calendar():"", //,//"just now",
            sender: props.currentChatMember.name,
            position: "normal",
            direction: msg.in?"outgoing":"incoming"
          }}

        >
                    <Message.Footer 
          sender="Emily" 
          sentTime={msg.date?moment(msg.date).locale('ru').format('HH:mm'):""}

          />
          </Message>
          

                  
        )}
      </MessageListKit>
        <MessageInput 
          placeholder="Введите сообщение" 
          onSend={onSend} 
          onClick={e=>{ readAllMessages(props.currentChatMember)}} 
          autoFocus
          //sendButton={false} 
          attachButton={false} 
        />
        {false && <InputToolbox>
        <AttachmentButton />
        <SendButton />      
        </InputToolbox>}
    </ChatContainer>: <Grid container justify="center" alignItems="center"><Grid item><Alert style={{marginTop:10}} icon={<KeyboardBackspaceIcon fontSize="inherit" />} variant="outlined" severity="info">Выберите контакт, чтобы начать с ним переписку</Alert></Grid></Grid>}
    </Allotment.Pane>
    </Allotment>: <Grid   
    container
  direction="column"
  justifyContent="center"
  alignItems="center">
      <Grid item>
            <img src='/okr.png' height={200} />
            </Grid>
            <Grid item>
            <Alert variant="outlined" severity="info">Подключите базу к Telegram, чтобы иметь возможность работать с пользователями через чат</Alert>
            </Grid>
            <Grid item>
            <Button 
            variant="contained" 
            size="small"
            color="primary" 
            startIcon={<TelegramIcon fontSize="small"/>}
            style={{marginTop:10}}
            onClick={e=>handleClickOpenConnectTelegramDialog(null)}
            >
            
            Подключить к Telegram
          </Button>
          </Grid>
          </Grid>
            }
      </div>

    </React.Fragment>
  );
}