import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Paragraph from '@editorjs/paragraph'
import "./styles.css"
//import "./index.css"

const DEFAULT_INITIAL_DATA =  {
      "time": new Date().getTime(),
      "blocks": [
      ]
  }

const EditorJSComponent = ({ id, data, tools, onChange, readOnly, ...config}) => {
    const ejInstance = useRef();


    const initEditor = () => {
       const editor = new EditorJS({
          holder: 'editorjs_'+id,
          onReady: () => {
            ejInstance.current = editor;
          },
          //autofocus: true,
          data: (data?data:DEFAULT_INITIAL_DATA),//DEFAULT_INITIAL_DATA,
          onChange: async () => {
            var content = data
            if(!readOnly && editor.saver){
              content = await editor.saver.save();
            }
            

           console.log('EditorJSComponent onChange', content);
            if(onChange && (typeof onChange === 'function')){
              onChange(content)
            }
          },
          tools: { 
            ...tools,
          },
          readOnly,
          ...config
        });
      };

      // This will run only once
  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor();
    }


    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

    return  <><div id={'editorjs_'+id}></div></>;
}

export default EditorJSComponent;