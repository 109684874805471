import React from 'react';
import {Switch, Route, Redirect, BrowserRouter} from 'react-router-dom'
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import './App.css';
import {useAuth} from './hooks/auth.hook'
import {AuthContext} from './context/AuthContext'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import IsSigned from './pages/IsSigned'
import CreateFromTemplate from './pages/CreateFromTemplate'
import SignUpDemo from './pages/SignUpDemo'
import SignUpMeetingReport from './pages/SignUpMeetingReport'
import Invite from './pages/Invite'
import ConnectTelegram from './pages/ConnectTelegram'
import ResetPassword from './pages/ResetPassword';
import NewPasswordByToken from './pages/NewPasswordByToken';
import Dashboard from './components/Dashboard'
import DbEditor from './components/DbEditor'
import SharedView from './components/SharedView'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
//import { YMInitializer } from 'react-yandex-metrika';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none"
      }
    }
  }
});

const useStyles = makeStyles((theme) => {
  root: {
    // some css that access to theme
  }
});

function App() {
  const {login, logout, token, userId, ready} = useAuth();
  const isAuthenticated = !!token;
  const currentURLPath = window.location.pathname+''+window.location.search  
  const redirectURLSearch = (window.location.search && (window.location.search.indexOf("?redirect_url=")>=0))?decodeURIComponent(window.location.search).replace('?redirect_url=',''):""


  if(!ready){
    return (<Backdrop open={!ready}>
      <CircularProgress color="inherit" />
      </Backdrop>)
  }

  return (
    <ThemeProvider theme={theme}><div className="App">
      <AuthContext.Provider value={{
        login, logout, token, userId, isAuthenticated
      }}>
        {isAuthenticated &&
          <BrowserRouter>
        <Switch>
        <Route exact path="/db/:dbId/:wiId/:viewId/:openItemId" component={DbEditor} />
        <Route exact path="/db/:dbId/:wiId/:viewId/" component={DbEditor} />
        <Route exact path="/db/:dbId/:wiId" component={DbEditor} />
        <Route exact path="/db/:dbId" component={DbEditor} />    
        <Route exact path="/shared/:sharedId" component={SharedView} />                           
        <Route exact path="/dashboard/:path" component={Dashboard} />
        <Route exact path="/invite/:inviteId" component={Invite} />  
        <Route exact path="/connectTelegram" component={ConnectTelegram} />          
        <Route exact path="/demo" component={SignUpDemo} /> 
        <Route exact path="/issigned" component={IsSigned} /> 
        <Route exact path="/create_from_template" component={CreateFromTemplate} /> 
        <Redirect from="/signin" to={redirectURLSearch?redirectURLSearch:"/dashboard/dbs"} />
        <Redirect from="/signup" to={redirectURLSearch?redirectURLSearch:"/dashboard/dbs"} /> 
        <Redirect from="/" to="/dashboard/dbs" />
        <Redirect from="/dashboard" to="/dashboard/dbs" />      
        </Switch>          
          </BrowserRouter>}
        {!isAuthenticated &&
          <BrowserRouter>
            <Switch>
              <Route exact path="/db/:dbId/:wiId/:viewId/:openItemId" component={DbEditor} />
              <Route exact path="/db/:dbId/:wiId/:viewId" component={DbEditor} />
              <Route exact path="/db/:dbId/:wiId" component={DbEditor} />
              <Route exact path="/db/:dbId" component={DbEditor} />               
              <Route exact path="/shared/:sharedId" component={SharedView} />  
              <Route exact path="/demo" component={SignUpDemo} />              
              <Route path="/signin" exact>
                <SignIn />
              </Route>
              <Route path="/signup" exact>
                <SignUp />
              </Route>             
              <Route path="/resetpswd" exact>
                <ResetPassword />
              </Route> 
              <Route path="/newpswd" exact>
                <NewPasswordByToken />
              </Route>              
              <Route path="/use_meeting_report" exact>
                <SignUpMeetingReport />
              </Route>      
              <Route exact path="/issigned" component={IsSigned} /> 
              <Redirect from="/create_from_template" to={"/signup"+((currentURLPath && currentURLPath!=="/")?("?redirect_url="+encodeURIComponent(currentURLPath)):"")} />                                          
              <Redirect from="/" to={"/signin"+((currentURLPath && currentURLPath!=="/")?("?redirect_url="+encodeURIComponent(currentURLPath)):"")} />                                  
            </Switch>
          </BrowserRouter>}
      </AuthContext.Provider>
    </div></ThemeProvider>
  );
}

export default App;
