import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TextField from '@material-ui/core/TextField';

export default function MemberFormEditor({ value, onChange, members, rowHeight, menuPortalTarget, noOptionsMessage, inForm }) {
  console.log('LinkEditor ',  value, members )
    return (
      <Autocomplete
      openOnFocus
      id="member-cell-editor"
      size="small"
      options={members}
      getOptionLabel={option => option.name}
      defaultValue={members.find(o => o._id === value)}
      style={{height: rowHeight}}
      disableClearable
      onChange={(event, value) => 
         onChange(value?value._id:null)
      }              
      onContextMenu={event => event.stopPropagation()}  
      PopperComponent={(props) => {
        return <Popper {...props} style={{minWidth: 250}} placement="bottom-start" />;
     }}
      renderInput={(params) => (
        <TextField         
        {...params} 
        autoFocus = {!inForm}
        variant="standard" 
        placeholder="Веберите " 
        InputProps={{ ...params.InputProps, style:{fontSize:"12px", paddingLeft: 6, paddingTop:6}, disableUnderline: !inForm }}                
        />
      )}
      renderOption={(option) => (
        <React.Fragment>
          <Chip
            size="small"
            icon={<AccountCircleIcon />}
            //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
            label={option.name}
            //avatar={<Avatar>M</Avatar>}
            //clickable
            //color={o.color ? o.color : "default"}
            style={{ fontSize: "12px", height: "20px" }}
          //onDelete={(e) => handleDeleteOptionChoice(o, index)}
          //deleteIcon={<DeleteIcon />}
          />
        </React.Fragment>
      )}    
      onKeyDown={event => {
        if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
          event.stopPropagation();
        }

        if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
          event.stopPropagation();
        }
      }}                        
    />   
    );
  }