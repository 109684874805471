import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
  MovableCardWrapper
} from '../styles/Base'
import Tag from './Card/Tag'
import DeleteButton from '../widgets/DeleteButton'

class DinaCard extends Component {
  onDelete = e => {
    this.props.onDelete()
    e.stopPropagation()
  }

  render()  {
    const {
      cardColor,
      clickDelete,
      subTitle,
      escalationText,
      showDeleteButton,
      style,
      tagStyle,
      onClick,
      onDelete,
      onChange,
      className,
      id,
      title,
      label,
      description,
      tags,
      cardDraggable,
      editable,
      t
    } = this.props

    const updateCard = (card) => {
      onChange({...card, id})
    }

    return (
      <MovableCardWrapper
        data-id={id}
        onClick={onClick}
        style={style}
        className={className}
      >
        <header
          style={{
            borderBottom: '1px solid #eee',
            paddingBottom: 6,
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: cardColor
          }}>
          <div style={{fontSize: 14, fontWeight: 'bold'}}>{title}</div>
          <div style={{fontSize: 11}}>{label}</div>
          {showDeleteButton && <DeleteButton onClick={clickDelete} />}
        </header>
        <div style={{fontSize: 12}}>
          <div style={{color: '#4C4C4C', fontWeight: 'bold'}}>{subTitle}</div>
          <div style={{padding: '5px 0px'}} >{description}</div>
          <div style={{marginTop: 10, textAlign: 'center', color: cardColor, fontSize: 15, fontWeight: 'bold'}}>{escalationText}</div>
          {tags && (
            <div
              style={{
                borderTop: '1px solid #eee',
                paddingTop: 6,
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}>
              {tags.map(tag => (
                <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
              ))}
            </div>
          )}
        </div>
      </MovableCardWrapper>
      )
  }
}

DinaCard.propTypes = {
  cardColor: PropTypes.string,
  clickDelete: PropTypes.func,
  subTitle: PropTypes.string,
  escalationText: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
}

DinaCard.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  tags: [],
  className: ''
}

export default DinaCard
