import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TextField from '@material-ui/core/TextField';
import { Utils, BasicConfig} from 'react-awesome-query-builder';
import Query from '../../Utils/query' 

export default function LinkFormEditor(
  { value,
    onChange,
    
    rowHeight,
    menuPortalTarget, 
    noOptionsMessage, 
    titleCol, 
    inForm,
    filterView,
    tables,
    linkId,
    currentMember,
    members,
    chatmembers  
  }) {
    //  console.log('LinkEditor ',  value, options, titleCol )
    //var _value = "123"
    //var _options=[{'_id': "324", namev:"dvdfsdg"}, {'_id': "123"}, {'_id': "3324", namev:"dvdfdfdfdfsdg"}, {'_id': "3241", namev:"dvdfvccvsddffddfg"}]
  //var _titleCol = "namev"

  var options = []
    if(linkId){
      if(filterView 
        && tables[linkId].views[filterView] 
        && tables[linkId].views[filterView].filter)
        {
        
        var _fields = {}
     
          for(let ic=0; ic<tables[linkId].columns.length; ic++){
            _fields[tables[linkId].columns[ic]._id] = {
              label: tables[linkId].columns[ic].name,
              type: tables[linkId].columns[ic].type,
           
            }
            if(tables[linkId].columns[ic].type === 'select'){
              _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
              _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                listValues: tables[linkId].columns[ic].options.map(opt=>{return {value:opt.value, title:opt.label}}),
              }               
            } else if(tables[linkId].columns[ic].type === 'check'){
              _fields[tables[linkId].columns[ic]._id].type = 'boolean'            
            } else if(tables[linkId].columns[ic].type === 'link'){
              if(tables[tables[linkId].columns[ic].linkId] && tables[tables[linkId].columns[ic].linkId].columns){
                var titleColId = tables[linkId].columns[ic].linkViewField//?props.columns[ic].linkViewField:props.tables[props.columns[ic].linkId].columns.find(cl=> cl.type === 'title')
                if(titleColId){
                var tableValues = tables[tables[linkId].columns[ic].linkId].itemsList?tables[tables[linkId].columns[ic].linkId].itemsList:[]
                //console.log('filter tableValues', tableValues, titleColId)
                _fields[tables[linkId].columns[ic]._id].type = 'select'
                _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
                _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                  
                  listValues: tableValues.map(opt=>{return {value:opt._id, title:opt[titleColId]}}),
                } 
              }
              }  
            } else if(tables[linkId].columns[ic].type === 'member'){
              _fields[tables[linkId].columns[ic]._id].type = 'select'
              _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
              _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                listValues: members.map(opt=>{return {value:opt._id, title:opt.name?opt.name:opt.email}}),            
              } 
            } else if(tables[linkId].columns[ic].type === 'chatmember'){
              _fields[tables[linkId].columns[ic]._id].type = 'select'
              _fields[tables[linkId].columns[ic]._id].valueSources = ['value'];
              _fields[tables[linkId].columns[ic]._id].fieldSettings= {
                listValues: chatmembers.map(opt=>{return {value:opt._id, title:opt.first_name?(opt.first_name+(opt.last_name?(" "+opt.last_name):"")):opt.name}}),
              }  
            } else if(tables[linkId].columns[ic].type === 'date' || tables[linkId].columns[ic].type === 'datetime'){
              _fields[tables[linkId].columns[ic]._id].funcs = ['TODAY']
              _fields[tables[linkId].columns[ic]._id].valueSources = ["value", "field", "func"]
            } else if(tables[linkId].columns[ic].type === 'title'){
              _fields[tables[linkId].columns[ic]._id].type = 'text'
            } else if(tables[linkId].columns[ic].type === 'multiline'){
              _fields[tables[linkId].columns[ic]._id].type = 'text'
            }     
          }       
                  
          const _config = {
            ...BasicConfig,
            fields: _fields
          };  
          _config.operators.current_member={
            label: "@Текуший_пользователь",
            labelForFormat: "@Current_Member",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$eq': currentMember?currentMember._id:null }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }          
          _config.operators.after_now={
            label: "> Сейчас",
            labelForFormat: "AFTER NOW",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': "new Date()" }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }     
          _config.operators.befor_now={
            label: "< Сейчас",
            labelForFormat: "BEFOR NOW",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$lt': "new Date()" }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }          
          _config.operators.for_today={
            label: "За сегодня",
            labelForFormat: "FOR TODAY",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0))), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }
          _config.operators.for_week={
            label: "За неделю",
            labelForFormat: "FOR WEEK",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0)-604800000)), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }    
          _config.operators.for_30_day={
            label: "За 30 дней",
            labelForFormat: "FOR 30 DAY",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).setHours(0,0,0,0)-2592000000)), '$lt':(new Date((new Date()).setHours(23,59,59))) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }  
          _config.operators.for_current_month={
            label: "За текущий месяц",
            labelForFormat: "FOR CURRENT MONTH",
            //reversedOp:"not_today",  
            cardinality: 0,
            formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
            mongoFormatOp: (field, op, value) => ({[field]: {'$gte': (new Date((new Date()).getFullYear(), (new Date()).getMonth(),1)), '$lt':(new Date((new Date()).getFullYear(), (new Date()).getMonth()+1,0)) }}),
            //mongoFormatOp: (field, op, value) => { return {[field]: { '$gte': new Date(new Date().setHours(00,00,00)), '$lt':new Date(new Date().setHours(23,59,59)) } }},
            
          }          
          console.log('_config.types', _config.types)
          //console.log('_config.types.date.operators ',_config.types.date.operators)
          if(_config.types.select.widgets.select.operators.findIndex(el=> el==='current_member')<0){
            _config.types.select.widgets.select.operators.push('current_member')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='after_now')<0){
          _config.types.date.widgets.date.operators.push('after_now')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='befor_now')<0){
          _config.types.date.widgets.date.operators.push('befor_now')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_today')<0){
          _config.types.date.widgets.date.operators.push('for_today')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_week')<0){
          _config.types.date.widgets.date.operators.push('for_week')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_30_day')<0){
          _config.types.date.widgets.date.operators.push('for_30_day')
          }
          if(_config.types.date.widgets.date.operators.findIndex(el=> el==='for_current_month')<0){
          _config.types.date.widgets.date.operators.push('for_current_month')
          }
          _config.types.geolocation = {
            valueSources: ['value'],
            defaultOperator: 'is_not_empty',
            widgets: {
                time: {
                  operators: ['is_empty', 'is_not_empty'],
                  opProps: {
                  },
              },          
            },
        }                



        var _immutableTree = Utils.checkTree(Utils.loadTree(tables[linkId].views[filterView].filter), _config)
        //console.log('filterMongo _immutableTree', _immutableTree)
        var mongoFilter = Utils.mongodbFormat(_immutableTree, _config)

        console.log('LinkFormEditor mongoFilter ', tables[linkId].columns, mongoFilter, tables[linkId].views[filterView].filter)
        options = Query.query(tables[linkId].itemsList, mongoFilter);
      } else{
        options = tables[linkId].itemsList
      }
      
    }
  



    return (
      <Autocomplete
      openOnFocus
      id="link-cell-editor"
      size="small"
      options={options}
      getOptionLabel={option => {if(titleCol && option && option[titleCol]){return option[titleCol]} else return "X"}}
      defaultValue={options.find(o => o._id === value)}
      style={{height: rowHeight}}
      disableClearable
      onChange={(event, value) => 
         onChange(value?value._id:null)
      }      
      onContextMenu={event => event.stopPropagation()}        
      PopperComponent={(props) => {
        return <Popper {...props} style={{minWidth: 250}} placement="bottom-start" />;
     }}
      renderInput={(params) => (
        <TextField         
        {...params} 
        autoFocus = {!inForm}
        variant="standard" 
        placeholder="Веберите " 
        InputProps={{ ...params.InputProps, style:{fontSize:"12px", paddingLeft: 6, paddingTop:6}, disableUnderline: !inForm }}                
        />
      )}
      renderOption={(option) => (
        <React.Fragment>
          <Chip
            size="small"
            //icon={<AccountCircleIcon />}
            //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
            label={titleCol?option[titleCol]:""}
            //avatar={<Avatar>M</Avatar>}
            //clickable
            //color={o.color ? o.color : "default"}
            style={{ fontSize: "12px", height: "20px" }}
          //onDelete={(e) => handleDeleteOptionChoice(o, index)}
          //deleteIcon={<DeleteIcon />}
          />
        </React.Fragment>
      )}    
      onKeyDown={event => {
        if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
          event.stopPropagation();
        }

        if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
          event.stopPropagation();
        }
      }}                        
    />   
    );
  }