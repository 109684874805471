import {useState, useCallback, useContext} from 'react'
//import {useHistory} from 'react-router-dom'
import {AuthContext} from '../context/AuthContext'

export const useHttp = () => {
    const [loading, setLoading] = useState(false);
    const [error, serError] = useState(null);
    //const history = useHistory();
    const auth = useContext(AuthContext);
    const request = useCallback (async (url, method='GET', body=null, headers={}) => {
        setLoading(true);
        try {
                       
            const req_params = {
                method, 
                headers
            };
            if((method==='POST' || method==='DELETE') && body){
                if(body instanceof FormData){
                    //console.log('useHttp body is FormData!'); 
                    req_params.body = body;
                }else {
                    //console.log('useHttp body', body); 
                    req_params.body = JSON.stringify(body);
                    req_params.headers['Content-Type'] = 'application/json'; 
                }               
            }
            const response = await fetch(url, req_params);
            //console.log('useHttp response', await response.text());
            const data = await response.json();
            
            if(!response.ok){
                if(response.status===401){
                    //history.push('/signin');
                    
                    auth.logout();
                    throw new Error(data.message || 'Ошибка авторизации')
                } else {
                    throw new Error(data.message || 'Что-то пошло не так')
                }                
            }

            setLoading(false);

            return data;
        } catch (e) {
            //console.log('useHttp e', e); 
            setLoading(false);
            serError(e.message);
            throw e; //выкидываем для отработки в компонентах
        }
    }, [auth])

    const clearError = useCallback ( () =>  serError(null),[]);

    return {loading, request, error, clearError}
}