import React, { useState, Fragment, useContext, useReducer, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useLayoutEffect, inForm } from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Select, { components } from 'react-select';

export default function ChatMemberEditor({ value, onChange, members, rowHeight, menuPortalTarget, noOptionsMessage,inForm }) {
    const [isMenuOpen, setMenuOpen] = useState(true);
  
    const formatOptionLabel = (option) => (
      <Chip
      size="small"
      //icon={<FormatColorFillIcon />}
      //icon={<ColorPicker defaultValue={o.color} value={o.color} hideTextfield onChange={(e) => onChangeColor(index, e)} />}
      label={option.first_name?(option.first_name+(option.last_name?(" "+option.last_name):"")):option.name}
      avatar={<Avatar>M</Avatar>}
      //clickable
      //color={o.color ? o.color : "default"}
      style={{  fontSize:"12px",height: "20px" }}
      //onDelete={(e) => handleDeleteOptionChoice(o, index)}
    //deleteIcon={<DeleteIcon />}
    />     
    );
  
    
    return (
      <Select
        autoFocus={!inForm}
        //isClearable={true}
        defaultMenuIsOpen={!inForm}
        value={members.find(o => o._id === value)}
        onChange={o => onChange(o?o._id:null)}
        options={members}
        getOptionLabel={option => option.first_name?(option.first_name+(option.last_name?(" "+option.last_name):"")):option.name}
        getOptionValue={option => option._id}
        noOptionsMessage={({inputValue}) => <span>{noOptionsMessage}</span>}
        formatOptionLabel={formatOptionLabel}
        placeholder="Выберите значение..."
        menuPortalTarget={menuPortalTarget}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        styles={{
          menu: (provided, state) => ({
            ...provided,
            minWidth: 250,          
            
          }),             
          control: (provided) => ({
            ...provided,
            height: rowHeight - 1,
            minHeight: rowHeight - 1,
            lineHeight: 'normal'
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            height: rowHeight - 1,
            paddingTop: '0',
            paddingBottom: '0',          
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: rowHeight - 1,
          }),             
        }}
        onKeyDown={event => {
          if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key) && isMenuOpen) {
            event.stopPropagation();
          }
  
          if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
            event.stopPropagation();
          }
        }}
      />
    );
  }